import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ZintGrowSubscriptionsService } from '../../../../services/zint-grow-subscriptions.service';

declare var $: any;

@Component({
  selector: 'opensearch-term-config',
  templateUrl: './opensearch-term-config.component.html',
  styleUrls: ['./opensearch-term-config.component.css'],
})
export class OpensearchTermConfigComponent implements OnInit, OnChanges {
  // come from event-source-type-rules component
  @Input() suggestedTerms: Record<string, any>;

  @Input() eventSourceType: string;
  @Input() extraParam: string;

  @Input() isCreated: boolean;

  @Output() searchTerm = new EventEmitter<string>();

  isLoading: Record<string, any>;

  userDefinedTerms = '';

  shortlistedTerms = [];

  selectedTerms = [];

  selectedTermQueryString = '';

  termSearchingOn = '';

  isEditingQuery = false;

  reStart = false;

  isQuickAdding = false;

  isQuickAddError = false;

  powerEditInfo = false;

  constructor(
    private zintGrowSubscriptionsService: ZintGrowSubscriptionsService
  ) {}

  ngOnInit(): void {
    this.isLoading = this.zintGrowSubscriptionsService.isLoading;
  }

  ngOnChanges(): void {
    this.clearBadges();
  }

  showModal(): void {
    $(`#suggestedKeywordModal${this.eventSourceType}`).modal();
  }

  addToShortList(term: string): void {
    this.shortlistedTerms = [term];
    this.reStart = false;
  }

  getEventCategoryIndex(): string {
    if (this.eventSourceType === 'AccountsSearchEventSourceType') {
      return 'accounts';
    }

    if (this.eventSourceType === 'HiringEventSourceType') {
      return 'jobs';
    }

    if (this.eventSourceType === 'NewsSearchEventSourceType') {
      //TODO: consider 'either' case
      return this.extraParam === 'external'
        ? 'external_news'
        : 'self_published_news';
    }

    if (this.eventSourceType === 'WebsiteSearchEventSourceType') {
      return this.extraParam === 'full' ? 'full_website' : 'homepage';
    }
  }

  getSimilarTerms(term: string): void {
    this.termSearchingOn = term;
    this.zintGrowSubscriptionsService
      .requestSimilarTerms(term, this.getEventCategoryIndex())
      .subscribe(data => {
        this.updateShortlistedTerms(data);
      });
  }

  updateShortlistedTerms(response): void {
    this.shortlistedTerms = [this.shortlistedTerms, ...response].flat();
  }

  processQueryString(term: string): void {
    this.selectedTermQueryString +=
      this.selectedTerms.length > 1 ? `OR "${term}" ` : `"${term}" `;
  }

  removeTermFromQueryString(term: string, isFirst?: boolean): void {
    const regexTerm = new RegExp(
      '(\\s*OR\\s*|\\s*AND\\s*|\\s*NOT\\s*|\\s*)?"?' + term + '"?',
      'g'
    );

    const regexFirstTerm = new RegExp(
      '"?' + term + '"?(\\s*OR\\s*|\\s*AND\\s*|\\s*NOT\\s*|\\s*)',
      'g'
    );

    if (isFirst) {
      this.selectedTermQueryString = this.selectedTermQueryString.replace(
        regexFirstTerm,
        ''
      );
      this.searchTerm.emit(this.selectedTermQueryString);
      return;
    }
    this.selectedTermQueryString = this.selectedTermQueryString.replace(
      regexTerm,
      ''
    );
    this.searchTerm.emit(this.selectedTermQueryString);
  }

  addToSelectedTerms(term: string): void {
    if (!term) {
      return;
    }

    // No duplicates
    if (this.selectedTerms.includes(term)) {
      this.userDefinedTerms = '';
      return;
    }
    //remove quotes and double quotes from the term
    term = term.replace(/'|"/g, '');
    this.selectedTerms.push(term);
    this.processQueryString(term);
    this.editSuggestion(term);

    this.userDefinedTerms = '';
    this.searchTerm.emit(this.selectedTermQueryString);
  }

  removeSelectedTerm(term: string): void {
    this.removeTermFromQueryString(term, term === this.selectedTerms[0]);

    this.selectedTerms = this.selectedTerms.filter(
      selectedTerm => selectedTerm !== term
    );
  }

  populateQueryString(value?: string): void {
    this.addToSelectedTerms(value);
    this.userDefinedTerms = '';
    this.isEditingQuery = true;
  }

  // this method reconstructs the selectedTerms array(badges) from the power edit query string
  powerEditQueryString(query: string): void {
    this.selectedTermQueryString = query;
    this.searchTerm.emit(this.selectedTermQueryString);

    const tempArray = query
      .split(/OR|AND|NOT/)
      .filter(Boolean)
      .map(term => term.trim().replace(/"/g, ''));

    this.selectedTerms = tempArray;
    this.isEditingQuery = false;
    this.userDefinedTerms = '';
  }

  startModalAgain(): void {
    this.reStart = true;
  }

  toggleQuickAdd(categoryKey: string): void {
    const categories = Object.keys(this.suggestedTerms);
    categories.forEach(category => {
      if (category === categoryKey) {
        this.suggestedTerms[category].isQuickAdding = this.suggestedTerms[
          category
        ].isQuickAdding
          ? false
          : true;
      }
    });
    this.isQuickAddError = false;
  }

  quickAddTerm(term: string, categoryKey: string): void {
    const isDupe = this.suggestedTerms[categoryKey].some(obj => {
      return obj.term === term;
    });

    if (!term || this.shortlistedTerms.includes(term) || isDupe) {
      this.isQuickAddError = true;
      return;
    }

    const newObject = { term, count: '' };
    this.isQuickAddError = false;
    this.suggestedTerms[categoryKey].push(newObject);
    this.shortlistedTerms.push(newObject);
    this.toggleQuickAdd(categoryKey);
  }

  editSuggestion(term: string): void {
    this.shortlistedTerms.forEach(shortlistedTerm => {
      if (shortlistedTerm.term === term) {
        // this is to toggle the edit mode
        shortlistedTerm.isEditing = shortlistedTerm.isEditing ? false : true;
      }
    });
  }

  clearBadges(): void {
    if (this.isCreated) {
      this.selectedTerms = [];
      this.shortlistedTerms = [];
      this.selectedTermQueryString = '';
      this.searchTerm.emit(this.selectedTermQueryString);
    }
  }

  showPowerEditInfo(): void {
    this.powerEditInfo = true;
  }
}
