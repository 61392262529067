// auth.guard.ts

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RouteService } from './route.service';
import { AuthenticationService } from './authentication.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private routeService: RouteService
  ) {}

  // With this setup, the AuthGuard will store the attempted URL in the RouteService when users are not authenticated. After successful login, it will redirect users to the stored URL.

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isUserAuthenticated().pipe(
      switchMap(response => {
        if (response.success === true) {
          return of(true);
        } else {
          // redirect to login while saving the current url
          this.routeService.redirectUrl = state.url;
          this.router.navigate(['/login']);
          return of(false);
        }
      }),
      catchError(error => {
        console.error(error);
        return of(false);
      })
    );
  }
}
