import { Component, OnInit } from '@angular/core';
import { publicMethods } from '../../globals';
import { AlertsService } from '../../services/alerts.service';
import { CommunicationService } from '../../services/communication.service';
declare var $: any;

@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css'],
})
export class AlertsComponent implements OnInit {
  constructor(
    private alertsService: AlertsService,
    private pubMethods: publicMethods,
    private communicationService: CommunicationService
  ) {}

  isLoading = this.alertsService.isLoading;
  isSuperUser: boolean = false;
  alerts: Array<any> = [];
  alertCurrentlyBeingDeleted = null;

  ngOnInit() {
    document.title = 'Alerts';
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.getUserAlertDetails();
    $('#confirmDeleteAlertModal').on('hide.bs.modal', function (e) {
      this.alertCurrentlyBeingDeleted = null;
    });
  }

  updateAlertDetails(data): void {
    this.alerts = data.alerts;
  }

  getUserAlertDetails(): void {
    this.alertsService
      .getAlertDetails()
      .subscribe(data => this.updateAlertDetails(data));
  }

  alertDeletedConfirm(data): void {
    if (data.success) {
      $('#confirmDeleteAlertModal').modal('hide');
      if (this.isSuperUser) {
        var userString = 'This';
      } else {
        var userString = 'Your';
      }
      this.pubMethods.showInfoMessage(
        userString + ' alert was successfully deleted.'
      );
      this.alerts = data.alerts;
    }
  }

  deleteAlert(alertId): void {
    this.alertsService
      .callDeleteAlert(alertId)
      .subscribe(data => this.alertDeletedConfirm(data));
  }
}
