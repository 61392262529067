import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLoopPipe',
})
export class FilterLoopPipe implements PipeTransform {
  constructor() {}

  transform(inputValue: any, filterList: any, attributeName: string) {
    if (!filterList) {
      return true;
    }
    return (
      filterList
        .map(el => el[attributeName])
        .indexOf(inputValue[attributeName]) < 0
    );
  }
}
