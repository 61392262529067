import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ZintGrowService } from '../../../../services/zint-grow.service';
declare var $: any;

@Component({
  selector: 'accounts-filing',
  templateUrl: './accounts-filing.component.html',
  styleUrls: ['./accounts-filing.component.css'],
})
export class AccountsFilingComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor(
    private datePipe: DatePipe,
    private zintGrowService: ZintGrowService
  ) {}

  event_metadata: any;
  companyNumber: any;
  htmlBelowImage: any;
  accountsSummary: any;
  displayAccountsSummary = false;
  noAnalysisFound = false;
  randomId = (Math.random() + 1).toString(36).substring(2);
  isLoading = this.zintGrowService.isLoading;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.notifications[0].company_number;
    this.htmlBelowImage =
      `<i class="fal fa-table"></i> Accounts Made Up To: ` +
      this.datePipe.transform(this.event_metadata.made_up_to_string, 'd MMM y');
  }

  openSummaryModal(notificationsForGroup) {
    $('#accountsSummaryModal__' + this.randomId).modal();
    this.displayAccountsSummary = false;
    this.noAnalysisFound = false;
    // Just take the first notification in the group.
    const accountsSummaryID =
      notificationsForGroup[0].event_metadata.accounts_summary_id;
    if (accountsSummaryID) {
      this.zintGrowService
        .getAccountSummaryById(accountsSummaryID)
        .subscribe(data => this.updateAccountsSummary(data));
    } else {
      this.noAnalysisFound = true;
    }
  }

  updateAccountsSummary(data) {
    this.accountsSummary = data;
    this.displayAccountsSummary = true;
  }
}
