import { Component, OnInit, NgZone } from '@angular/core';
import { TaggingService } from '../../services/tagging.service';
import { HttpParams } from '@angular/common/http';
import { DataSharingService } from '../../services/data-sharing.service';
import { publicMethods } from '../../globals';
import { CommunicationService } from '../../services/communication.service';
declare var $: any;

@Component({
  selector: 'tagging',
  templateUrl: './tagging.component.html',
  styleUrls: ['./tagging.component.css'],
})
export class TaggingComponent implements OnInit {
  selectedTagToSave: number;
  userTags: Array<any> = null;
  newTagName = '';
  companyIds: Array<any> = [];
  teamMembers: Array<any> = [];
  teamMemberIdToCreateTagFor: number = null;
  searchParams: HttpParams = null;
  countOfSuccessfulTags: number;
  countOfSuccessfulTagRemovals: number;
  tagIdToDelete: number;

  isSuperUser: boolean;
  emailOnAccount: string;
  isEditingCollaborativeTags = false;

  constructor(
    private communicationService: CommunicationService,
    private taggingService: TaggingService,
    private data: DataSharingService,
    private pubMethods: publicMethods,
    private ngZone: NgZone
  ) {}

  isLoading = this.taggingService.isLoading;

  ngOnInit() {
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.communicationService.getEmailMessage().subscribe(accountEmail => {
      this.emailOnAccount = accountEmail;
    });
    let taggingSelf = this;
    $('#tagModal').on('show.bs.modal', function (e) {
      if (taggingSelf.userTags == null) {
        taggingSelf.ngZone.run(() => taggingSelf._getUserTags());
      }
    });
    this.data.currentSelectedCompanyIds.subscribe(
      companyIds => (this.companyIds = companyIds)
    );
    this.data.currentSelectedSearchParams.subscribe(
      searchParams => (this.searchParams = searchParams)
    );
    this.data.currentSelectedTagIdToDelete.subscribe(
      tagId => (this.tagIdToDelete = tagId)
    );
    $('#tagModal').on('hide.bs.modal', function (e) {
      taggingSelf.ngZone.run(() => taggingSelf.data.clearTagToDelete());
    });
  }

  updateUserTagDetails(data): void {
    this.userTags = data.user_tags;
    this.teamMembers = data.team_members;
    if (data.team_members && data.team_members.length != 0) {
      this.teamMembers
        .filter(usr => usr.isOrganisationAdmin)
        .map(usr => (usr.checkedForViewable = true));
      this.teamMembers
        .filter(usr => usr.isOrganisationAdmin)
        .map(usr => (usr.checkedForEditable = true));
      this.teamMemberIdToCreateTagFor = data.team_members[0].id;
    }
  }

  _getUserTags(): void {
    this.taggingService
      .getUserTags(false)
      .subscribe(output => this.updateUserTagDetails(output));
  }

  updateNewTagInList(newTag): void {
    this.userTags.unshift(newTag);
    this.isEditingCollaborativeTags = false;
  }

  updateNewTagInListAndTagCompanies(newTag): void {
    this.updateNewTagInList(newTag);
    this.selectedTagToSave = newTag['id'];
    this.tagCompanies();
  }

  processCreateNewTagResponse(output): void {
    if (output.length == 0) {
      return;
    }
    this.updateNewTagInListAndTagCompanies(output);
  }

  createNewUserTagAndUpdateTags(): void {
    this.taggingService
      .createUserTag(this.newTagName, this.teamMemberIdToCreateTagFor)
      .subscribe(output => this.processCreateNewTagResponse(output));
  }

  createNewOrganisationTagAndUpdateTags(): void {
    this.taggingService
      .createOrganisationTag(
        this.newTagName,
        this.teamMembers
          .filter(user => user.checkedForViewable)
          .map(user => user.userId),
        this.teamMembers
          .filter(user => user.checkedForEditable)
          .map(user => user.userId)
      )
      .subscribe(output => this.updateNewTagInList(output));
  }

  taggedSuccessful(total): void {
    this.countOfSuccessfulTags = total;
    this.countOfSuccessfulTagRemovals = null;

    this.data.companyTagsUpdated.update(flag => (flag = true));
  }

  tagCompanies(): void {
    this.taggingService
      .tagOrUntagCompanies(
        this.selectedTagToSave,
        this.companyIds,
        this.searchParams,
        true
      )
      .subscribe(output => this.taggedSuccessful(output.total_added));
  }

  untaggedSuccessful(total): void {
    this.countOfSuccessfulTagRemovals = total;
    this.countOfSuccessfulTags = null;

    this.data.companyTagsUpdated.update(flag => (flag = true));
  }

  untagCompanies(): void {
    this.taggingService
      .tagOrUntagCompanies(
        this.selectedTagToSave,
        this.companyIds,
        this.searchParams,
        false
      )
      .subscribe(output => this.untaggedSuccessful(output.total));
  }

  tagDeleted(data): void {
    this.pubMethods.showInfoMessage(
      'The tag "' +
        data.name +
        '" was deleted and removed from ' +
        data.count +
        ' organisations.'
    );
    this._getUserTags();
    $('#tagModal').modal('hide');
  }

  deleteTag(tagId): void {
    this.taggingService
      .deleteTagById(tagId)
      .subscribe(output => this.tagDeleted(output));
  }
}
