import { Component, NgZone, OnInit } from '@angular/core';
import { DataSharingService } from '../../../services/data-sharing.service';
import { SalesforceExporterService } from '../../../services/salesforce-exporter.service';
declare var $: any;
import { publicMethods } from '../../../globals';

@Component({
  selector: 'salesforce-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.css'],
})
export class ExportModalComponent implements OnInit {
  queryResults: Array<any>;
  companyId: number;
  urlOfSalesforceObjectUpdatedOrCreated: String;
  createFieldsToValues: Array<any> = [];
  updateFieldsToValues: Array<any> = [];

  sobjectIdForUpdate = null;
  salesforceIdForUpdate = null;

  constructor(
    private dataSharingService: DataSharingService,
    private salesforceExporterService: SalesforceExporterService,
    private ngZone: NgZone,
    private pubMethods: publicMethods
  ) {}

  exportableSobjects: Array<any>;
  isLoading = this.salesforceExporterService.isLoading;

  ngOnInit(): void {
    let exportModalSelf = this;
    $('#exportToSalesforceModal').on('show.bs.modal', function (e) {
      exportModalSelf.ngZone.run(() =>
        exportModalSelf.getCompanyIdAndRunSalesforceExportQuery()
      );
    });
    $('#exportToSalesforceModal').on('hide.bs.modal', function (e) {
      exportModalSelf.ngZone.run(() =>
        exportModalSelf.wipeOldDataOnModalClose()
      );
    });
  }

  wipeOldDataOnModalClose(): void {
    this.companyId = null;
    this.urlOfSalesforceObjectUpdatedOrCreated = null;
    this.exportableSobjects = null;
    this.createFieldsToValues = [];
    this.updateFieldsToValues = [];
    this.sobjectIdForUpdate = null;
    this.salesforceIdForUpdate = null;
  }

  updateExportableSobjects(data): void {
    this.exportableSobjects = data.sobjects;
  }

  getCompanyIdAndRunSalesforceExportQuery(): void {
    this.dataSharingService.currentSelectedCompanyIds.subscribe(
      companyIds => (this.companyId = companyIds[0])
    );
    if (this.companyId) {
      this.salesforceExporterService
        .getResultsFromSalesforceQueries(this.companyId)
        .subscribe(data => this.updatePossibleMatches(data));
      this.salesforceExporterService
        .listExportableSobjects()
        .subscribe(data => this.updateExportableSobjects(data));
    } else {
      this.pubMethods.showInfoMessage('Please select a company to export');
    }
  }

  updatePossibleMatches(data): void {
    this.queryResults = data.query_results;
  }

  displayUIAfterSalesforceObjectUpdatedOrCreated(data): void {
    if (data.url_of_object) {
      this.urlOfSalesforceObjectUpdatedOrCreated = data.url_of_object;
      this.pubMethods.showInfoMessage(
        'Changes successfully made to your Salesforce object.'
      );
      this.createFieldsToValues = [];
      this.updateFieldsToValues = [];
    }
  }

  updateSalesforceRecord(
    sobjectId,
    salesforceId,
    updateSalesforceRecord
  ): void {
    this.salesforceExporterService
      .updateSalesforceRecord(
        sobjectId,
        salesforceId,
        updateSalesforceRecord,
        this.companyId
      )
      .subscribe(data =>
        this.displayUIAfterSalesforceObjectUpdatedOrCreated(data)
      );
  }

  createNewSalesforceRecord(): void {
    let sobjectId = this.exportableSobjects[0]['id'];
    this.salesforceExporterService
      .createNewSalesforceRecord(
        sobjectId,
        this.companyId,
        this.createFieldsToValues
      )
      .subscribe(data =>
        this.displayUIAfterSalesforceObjectUpdatedOrCreated(data)
      );
  }

  updateCreateFieldsToValues(fieldsToValuesToUpdateAsString, data): void {
    if (data.salesforce_fields_to_values) {
      this[fieldsToValuesToUpdateAsString] = data.salesforce_fields_to_values;
    }
  }

  getValuesToExport(fieldsToValuesToUpdateAsString): void {
    let sobjectId = this.exportableSobjects[0]['id'];
    this.salesforceExporterService
      .getSalesforceValuesToExport(sobjectId, this.companyId)
      .subscribe(data =>
        this.updateCreateFieldsToValues(fieldsToValuesToUpdateAsString, data)
      );
  }
}
