import { Component, Input, OnInit } from '@angular/core';
import { SalesforceConfigService } from '../../../services/salesforce-config.service';
import { publicMethods } from '../../../globals';

@Component({
  selector: 'salesforce-oauth',
  templateUrl: './salesforce-oauth.component.html',
  styleUrls: ['./salesforce-oauth.component.css'],
})
export class SalesforceOauthComponent implements OnInit {
  salesForceAuthorisationURL = '';
  isSalesforceEnabled = false;
  hasAccessToSalesforce = false;
  hasAgreedToSalesforceDisclaimer = false;
  salesforceOauth2Window: any;
  continuousCaller;
  countOfCallsToCheckSalesforceStatus = 0;
  showHavingProblemsMessage = false;
  popupsAreBlocked = false;

  constructor(
    private salesforceConfigService: SalesforceConfigService,
    private pubMethods: publicMethods
  ) {}

  ngOnInit(): void {
    this.salesforceConfigService
      .getSalesforceOAuthDetails()
      .subscribe(data => this.updateDetails(data));
  }

  updateDetails(data): void {
    this.salesForceAuthorisationURL = data.salesforce_authorisation_url;
    this.isSalesforceEnabled = data.salesforce_enabled;
    this.hasAccessToSalesforce = data.has_access_to_salesforce;
  }

  processRevokeSalesforceAccessResponse(data): void {
    if (data.salesforce_authorisation_url) {
      this.showHavingProblemsMessage = false;
      this.isSalesforceEnabled = false;
      this.salesForceAuthorisationURL = data.salesforce_authorisation_url;
      this.pubMethods.showInfoMessage(
        'Revocation successful. Zint is not longer connected to your Salesforce.'
      );
    }
  }

  revokeSalesforceAccess(): void {
    this.salesforceConfigService
      .doRevokeSalesforceAccess()
      .subscribe(data => this.processRevokeSalesforceAccessResponse(data));
  }

  checkIfSalesforceAccessHasBeenGranted(data): void {
    if (data.salesforce_enabled) {
      this.isSalesforceEnabled = true;
      if (this.salesforceOauth2Window) {
        this.salesforceOauth2Window.close();
      }
    } else {
      this.countOfCallsToCheckSalesforceStatus += 1;
      if (this.countOfCallsToCheckSalesforceStatus < 10) {
        this.continuousCaller = setTimeout(() => {
          this.showHavingProblemsMessage = true;
          this.testSalesforceAccessGrantedStatus();
        }, 5000);
      } else {
        this.pubMethods.showInfoMessageWithoutAutoDisappear(
          'If you have enabled the Salesforce integration, please refresh this page.'
        );
      }
    }
  }

  openOAuth2SalesforceWindowAndStartCheckingStatus() {
    const salesforcePopupHeight = screen.height * 0.5;
    const salesforcePopupWidth = screen.width * 0.5;
    this.salesforceOauth2Window = window.open(
      this.salesForceAuthorisationURL,
      'salesforceOAuth2Window',
      'width=' +
        salesforcePopupWidth +
        ',height=' +
        salesforcePopupHeight +
        ',menubar=0,toolbar=0'
    );
    if (
      !this.salesforceOauth2Window ||
      this.salesforceOauth2Window.closed ||
      typeof this.salesforceOauth2Window.closed == 'undefined'
    ) {
      this.pubMethods.showWarningMessageWithoutAutoDisappear(
        'You have popups blocked. Please re-authorise with the button below instead.'
      );
      this.popupsAreBlocked = true;
    } else {
      this.testSalesforceAccessGrantedStatus();
    }
  }

  testSalesforceAccessGrantedStatus() {
    this.salesforceConfigService
      .getSalesforceOAuthDetails()
      .subscribe(data => this.checkIfSalesforceAccessHasBeenGranted(data));
  }
}
