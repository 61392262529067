import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { publicMethods } from '../../../globals';
import { HttpParams } from '@angular/common/http';
import { DataSharingService } from '../../../services/data-sharing.service';
import { CustomColumnService } from '../../../services/custom-column.service';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'custom-column-edit',
  templateUrl: './custom-column-edit.component.html',
  styleUrls: ['./custom-column-edit.component.css'],
})
export class CustomColumnEditComponent implements OnInit {
  @Output() onCustomColumnSaved: EventEmitter<any> = new EventEmitter();

  @Input()
  inputParams: any = {
    // customColumn is an object. It will have a value attribute (which will be a string) only when we load the data for an individual
    // company. There will be no value set on it when we are using it on the bulk edit modal or when we are on the spreadsheet view.
    customColumn: Array,
    companyIds: Array,
    displayTight: Boolean,
    // inputValue is the value that the user has submitted. It will be e.g. a string like user@user.com or an empty string. This will be
    // undefined when we try to load it from the bulk update modal.
    inputValue: Object,
    submitThroughButton: Boolean,
    isSuperUser: Boolean,
    lockablePermittedEditValue: String,
    teamMembers: Array,
    datePickerIndex: Number,
    secondDatePickerIndex: Number,
  };

  constructor(
    private data: DataSharingService,
    private companyService: CompanyService,
    private customColumnService: CustomColumnService,
    private pubMethods: publicMethods
  ) {}

  searchParams: HttpParams = null;
  isLoading = this.companyService.isLoading;
  isLoadingFromQuery = this.customColumnService.isLoading;
  requiredValue: any;
  canEdit: boolean;
  now = new Date().getTime();

  ngOnInit() {
    this.data.currentSelectedSearchParams.subscribe(
      searchParams => (this.searchParams = searchParams)
    );
    this.determineEditableStatus();
  }

  ngAfterViewInit() {
    $('.text-custom-column-textarea').each(function () {
      $(this)[0].style.height = $(this)[0].scrollHeight + 2 + 'px';
    });
  }

  determineEditableStatus(): void {
    const col = this.inputParams.customColumn;
    const inputDetails = this.inputParams.inputValue;
    // comparisonValue is the value we will compare against. It depends if this is inputted through the modal for bulk updating, the
    // spreadsheet view; or the individual company page.
    const comparisonValue = inputDetails || (col ? col.value : '');
    if (!col.can_edit) {
      this.canEdit = false;
    } else {
      this.canEdit = true; // by default you should be able to edit as sometimes we do not have an unlock datetime or a value.
      if (this.inputParams.isSuperUser) {
        this.canEdit = true;
      } else {
        if (col && col.is_lockable) {
          let unlockDatetime = null;
          if (col.is_locked_indefinitely) {
            this.canEdit =
              (comparisonValue &&
                comparisonValue ===
                  this.inputParams.lockablePermittedEditValue) ||
              comparisonValue === '' ||
              comparisonValue === undefined;
          } else {
            if (col.unlock_datetime) {
              unlockDatetime = Date.parse(col.unlock_datetime);
              if (unlockDatetime > this.now) {
                this.canEdit =
                  comparisonValue &&
                  comparisonValue ===
                    this.inputParams.lockablePermittedEditValue;
              }
            }
          }
        }
      }
    }
  }

  updateHeightOfTextArea($event): void {
    $event.target.style.height = $event.target.scrollHeight + 2 + 'px';
  }

  processCustomColumnUpdated(data, customColumn): void {
    customColumn['has_changed'] = false;
    if (data.success) {
      this.onCustomColumnSaved.emit([data.value]);
      this.determineEditableStatus();
    }
  }

  customColumnChanged(customColumn): void {
    this.inputParams.customColumn['has_changed'] = true;
  }

  closeModalAndRerunSearch(data): void {
    if (data.success) {
      this.onCustomColumnSaved.emit([data.value]);
    }
  }

  saveCustomColumnDetailFree(customColumn, value, force): void {
    if (
      !this.inputParams.submitThroughButton &&
      (customColumn['has_changed'] || force)
    ) {
      this.companyService
        .updateCustomFreeColumn(
          customColumn.id,
          this.inputParams.companyIds,
          value
        )
        .subscribe(data => this.processCustomColumnUpdated(data, customColumn));
    }
  }

  saveCustomColumnDetailFreeFromButton(customColumn, value): void {
    this.companyService
      .updateCustomFreeColumn(
        customColumn.id,
        this.inputParams.companyIds,
        value
      )
      .subscribe(data => this.closeModalAndRerunSearch(data));
  }

  saveOptionColumnDetailOption(customColumn, optionSelectedId): void {
    if (!this.inputParams.submitThroughButton) {
      this.companyService
        .updateCustomOptionColumn(
          customColumn.id,
          this.inputParams.companyIds,
          optionSelectedId
        )
        .subscribe(data => this.processCustomColumnUpdated(data, customColumn));
    }
  }

  saveOptionColumnDetailOptionFromButton(customColumn, optionSelectedId): void {
    if (optionSelectedId == undefined) {
      this.pubMethods.log('Please choose a value from the dropdown.');
    } else {
      this.companyService
        .updateCustomOptionColumn(
          customColumn.id,
          this.inputParams.companyIds,
          optionSelectedId
        )
        .subscribe(data => this.closeModalAndRerunSearch(data));
    }
  }

  saveCustomColumnDetailFreeFromButtonViaQuery(customColumn, value): void {
    this.customColumnService
      .setCustomColumnFreeFromQuery(customColumn.id, value, this.searchParams)
      .subscribe(data => this.closeModalAndRerunSearch(data));
  }

  saveCustomColumnDetailOptionFromButtonViaQuery(
    customColumn,
    optionSelectedId
  ): void {
    this.customColumnService
      .setCustomColumnOptionFromQuery(
        customColumn.id,
        optionSelectedId,
        this.searchParams
      )
      .subscribe(data => this.closeModalAndRerunSearch(data));
  }

  getDateTimeInISOStringFromEvents($event) {
    // We have come up with our own method to return ISO formatted dates
    // This is because if you use the standard $event.value.toISOString()
    // then this accounts for the user timezone, which can push it before midnight or even after,
    // causing the date to be +/- 1 day!
    var datePipe = new DatePipe('en-us');
    return datePipe.transform($event.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
  }
}
