import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ManageTeamsService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingInitialData: false,
    loadingTopLevelOrgData: false,
    addingNewTeam: false,
    isUpdatingAssignedDownloads: false,
    isUpdatingAssignedPropensityModels: false,
    isUpdatingAssignedUsersModels: false,
  };

  loadInitialTeamData(): Observable<any> {
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/get_manage_team_data/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError('loadInitialTeamData', [], this.isLoading)
        )
      );
  }

  loadTopLevelPlanDetails(): Observable<any> {
    this.isLoading['loadingTopLevelPlanData'] = true;
    return this.http
      .post(
        baseUrl + '/get_organisation_plan_details/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTopLevelPlanData'] = false)),
        catchError(
          this.pubMethods.handleError(
            'loadTopLevelPlanDetails',
            [],
            this.isLoading
          )
        )
      );
  }

  callCreateNewTeam(newTeamName, newTeamSuperuserEmail): Observable<any> {
    this.isLoading['addingNewTeam'] = true;
    let params = new HttpParams()
      .set('newTeamName', newTeamName)
      .set('newTeamSuperuserEmail', newTeamSuperuserEmail);
    return this.http
      .post(
        baseUrl + '/create_new_team/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['addingNewTeam'] = false)),
        catchError(
          this.pubMethods.handleError('callCreateNewTeam', [], this.isLoading)
        )
      );
  }

  setTeamAssignedDownloads(teamId, assignedDownloads): Observable<any> {
    this.isLoading['isUpdatingAssignedDownloads'] = true;
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('assignedDownloads', assignedDownloads);
    return this.http
      .post(
        baseUrl + '/update_team_assigned_downloads/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isUpdatingAssignedDownloads'] = false)),
        catchError(
          this.pubMethods.handleError(
            'setTeamAssignedDownloads',
            [],
            this.isLoading
          )
        )
      );
  }

  setTeamAssignedPropensityModels(
    teamId,
    assignedPropensityModels
  ): Observable<any> {
    this.isLoading['isUpdatingAssignedPropensityModels'] = true;
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('assignedPropensityModels', assignedPropensityModels);
    return this.http
      .post(
        baseUrl + '/update_team_assigned_propensity_models/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['isUpdatingAssignedPropensityModels'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'setTeamAssignedPropensityModels',
            [],
            this.isLoading
          )
        )
      );
  }

  setTeamAssignedUsers(teamId, assignedUsers): Observable<any> {
    this.isLoading['isUpdatingAssignedUsersModels'] = true;
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('assignedUsers', assignedUsers);
    return this.http
      .post(
        baseUrl + '/update_team_assigned_user_count/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isUpdatingAssignedUsersModels'] = false)),
        catchError(
          this.pubMethods.handleError(
            'setTeamAssignedUsers',
            [],
            this.isLoading
          )
        )
      );
  }
}
