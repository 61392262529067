import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'related-officer',
  templateUrl: './related-officer.component.html',
  styleUrls: ['./related-officer.component.css'],
})
export class RelatedOfficerComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor(private datePipe: DatePipe) {}

  event_metadata: any;
  eventDate: any;
  companyNumber: any;
  companyChanged: any;
  htmlBelowImage: any;
  importance: any;
  ruleSetName: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.event_metadata.company_number;
    this.companyChanged = this.event_metadata.company_name;
    this.eventDate =
      this.event_metadata.appointed_date ||
      this.notifications[0].creation_datetime;
    this.htmlBelowImage =
      '<i class="far fa-briefcase"></i> Appointed: ' +
      this.datePipe.transform(this.eventDate, 'd MMM y');
  }
}
