import { Component } from '@angular/core';

@Component({
  selector: 'three-dots-action',
  templateUrl: './three-dots-action.component.html',
  styleUrl: './three-dots-action.component.css'
})
export class ThreeDotsActionComponent {

}
