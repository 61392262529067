import { Component, Input, OnInit } from '@angular/core';
import { ZintGrowService } from '../../../services/zint-grow.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyDataPipe } from '../../../pipes/company-data.pipe';
import { EventSourceTypes } from '../../../globals';
import { DataSharingService } from '../../../services/data-sharing.service';

@Component({
  selector: 'event-actions',
  templateUrl: './event-actions.component.html',
  styleUrls: ['./event-actions.component.css'],
  providers: [CompanyDataPipe],
})
export class EventActionsComponent implements OnInit {
  @Input() notificationGroup: Record<string, any> = {};
  @Input() isActionableByUser: boolean;

  isMarkedCompleted: boolean;
  isLiked: boolean;
  isProblematic: boolean;
  isReporting: boolean = false;
  isSharing: boolean = false;

  constructor(
    private zintGrowService: ZintGrowService,
    private _snackBar: MatSnackBar,
    private companyDataPipe: CompanyDataPipe,
    private dataShareStore: DataSharingService
  ) {}

  ngOnInit(): void {
    const { isMarkedCompleted, isLiked, isProblematic } =
      this.notificationGroup;

    this.isMarkedCompleted = isMarkedCompleted;
    this.isLiked = isLiked;
    this.isProblematic = isProblematic;
  }

  toggleRead(notificationGroupId: number): void {
    this.zintGrowService
      .toggleNotificationGroupMarkedCompleted(notificationGroupId)
      .subscribe(
        () => {
          this.isMarkedCompleted = !this.isMarkedCompleted;

          this.zintGrowService
            .getUnreadGrowNotificationsCount()
            .subscribe(({ count }) => {
              this.dataShareStore.updateZgResultCount(count);
            });
        },
        error => {
          console.error(error);
          this._snackBar.open('Error, please refresh and try again.', 'x', {
            duration: 1500,
          });
        }
      );
  }

  toggleUseful(notificationGroupId: number): void {
    this.isLiked = !this.isLiked;

    this.zintGrowService
      .sendNotificationGroupFeedback(notificationGroupId, this.isLiked)
      .subscribe(() => {
        this._snackBar.open('Thank you for your feedback.', 'X', {
          duration: 1500,
        });
      });
  }

  reportIssues() {
    if (this.isProblematic === true) {
      this._snackBar.open('Already reported! Thank you.', 'Close', {
        duration: 1500,
      });
      return;
    }
    this.isReporting = !this.isReporting;
  }

  submitReport(notificationId: any, feedback: string) {
    if (!feedback.trim()) {
      this._snackBar.open('Cannot be empty!', 'Close', {
        duration: 1500,
      });
      return;
    }
    this.zintGrowService
      .sendNotificationGroupFeedback(notificationId, null, feedback)
      .subscribe(() => {
        this.isProblematic = true;
        this.isReporting = false;
        this._snackBar.open('Thank you for your feedback.', 'Close', {
          duration: 1500,
        });
      });
  }

  share() {
    const { companyName, eventSourceType, eventMetadata, importance } =
      this.notificationGroup.notifications[0];
    const emailSubject = `Sharing Zint Grow Notification for ${companyName}`;

    const eventImportance = importance || '';
    let eventDetails: string;
    let trimmedPreview: string;
    let sourceURL: string;

    // NewsSearchEventSourceType
    if (eventSourceType === EventSourceTypes.NewsSearchEventSourceType) {
      // line below replaces html tags from the preview with single quotes
      trimmedPreview = eventMetadata.preview.replace(/<\/?[^>]+(>|$)/g, "'");
      sourceURL = eventMetadata.articleLink;
      eventDetails = `${eventMetadata.articleTitle} %0A ${encodeURIComponent(
        sourceURL
      )} %0A%0A ${trimmedPreview}`;
    } // AccountsFilingEventSourceType
    else if (
      eventSourceType === EventSourceTypes.AccountsFilingEventSourceType
    ) {
      const formattedRevenue = this.companyDataPipe.transform(
        eventMetadata.revenue,
        'Revenue'
      );
      const filingURL = eventMetadata.accountsUrl;
      eventDetails = `A New Account Filing with a revenue figure of ${formattedRevenue} %0A ${encodeURIComponent(
        filingURL
      )}`;
    } // WebsiteSearchEventSourceType
    else if (
      eventSourceType === EventSourceTypes.WebsiteSearchEventSourceType
    ) {
      trimmedPreview = eventMetadata.preview.replace(/<\/?[^>]+(>|$)/g, "'");
      sourceURL = eventMetadata.urls[0];

      eventDetails = `${trimmedPreview} %0A ${encodeURIComponent(sourceURL)}`;
    } // AccountsSearchEventSourceType
    else if (
      eventSourceType === EventSourceTypes.AccountsSearchEventSourceType
    ) {
      trimmedPreview = eventMetadata.preview.replace(/<\/?[^>]+(>|$)/g, "'");
      sourceURL = eventMetadata.accountsUrl;
      eventDetails = `${trimmedPreview} %0A%0A ${encodeURIComponent(
        sourceURL
      )}`;
    } // HiringEventSourceType
    else if (eventSourceType === EventSourceTypes.HiringEventSourceType) {
      sourceURL = eventMetadata.link;
      eventDetails = `The company is hiring for ${
        eventMetadata.jobTitle
      } %0A ${encodeURIComponent(sourceURL)}%0A`;
    } // OfficerEventSourceType
    else if (eventSourceType === EventSourceTypes.OfficerEventSourceType) {
      eventDetails = `${eventMetadata.occupation} ${eventMetadata.changeType} - ${eventMetadata.name}`;
    } // RelatedOfficerEventSourceType
    else if (
      eventSourceType === EventSourceTypes.RelatedOfficerEventSourceType
    ) {
      const changeMessage =
        eventMetadata.changeType === 'appointed'
          ? `been appointed as ${eventMetadata.occupation} at`
          : `resigned as ${eventMetadata.occupation} from`;

      eventDetails = `An officer from ${companyName} has ${changeMessage} ${eventMetadata.companyName}`;
    } // PSCEventSourceType
    else if (eventSourceType === EventSourceTypes.PSCEventSourceType) {
      eventDetails = `PSC ${eventMetadata.changeType} - ${
        eventMetadata.name
      } has been ${
        eventMetadata.changeType === 'added' ? 'added' : 'removed'
      }.`;
    } // ImportExportEventSourceType
    else if (eventSourceType === EventSourceTypes.ImportExportEventSourceType) {
      const {
        newCategory,
        importOrExport,
        newCategoryDescription,
        impEventsCount,
        expEventsCount,
      } = eventMetadata;
      if (newCategory === 'true') {
        eventDetails = `${importOrExport.toUpperCase()} - A New Category: ${newCategoryDescription}`;
      }

      if (newCategory !== 'true') {
        if (impEventsCount) {
          eventDetails = `Number of Import Event: ${impEventsCount}`;
        }
        if (expEventsCount) {
          eventDetails = `Number of Export Event: ${expEventsCount}`;
        }
      }
    } // CorporateStructureEventSourceType
    else if (
      eventSourceType === EventSourceTypes.CorporateStructureEventSourceType
    ) {
      const {
        typeOfChange,
        changeType,
        childNodeName,
        parentNodeName,
        childCompanyNumber,
        parentCompanyNumber,
      } = eventMetadata;

      const childLink = `https://app.zint.io/profile/${childCompanyNumber}`;
      const parentLink = `https://app.zint.io/profile/${parentCompanyNumber}`;

      if (typeOfChange === 'sibling') {
        eventDetails = `${childNodeName} was ${changeType} as a sibling company with a shared parent ${parentNodeName}.`;
      } else if (typeOfChange === 'child') {
        eventDetails = `${childNodeName} was ${changeType} as a child of ${parentNodeName}.`;
      } else if (typeOfChange === 'parent') {
        eventDetails = `${parentNodeName} was ${changeType} as a parent of ${childNodeName}
        %0A %0A ${encodeURIComponent(childLink)}
        %0A ${encodeURIComponent(parentLink)}%0A
        `;
      }
    } // RelatedPSCEventSourceType
    else if (eventSourceType === EventSourceTypes.RelatedPSCEventSourceType) {
      const link = `https://app.zint.io/${eventMetadata.companyNumber}`;
      eventDetails = `${eventMetadata.name} has become a PSC at ${
        eventMetadata.companyName
      }. %0A${encodeURIComponent(link)}`;
    }

    const emailBody = `Hi, I thought you might be interested in this notification from Zint Grow for ${companyName}.%0A%0A Details: ${eventDetails}%0A%0A Importance: ${eventImportance}%0A%0A%0A%0A%0A%0A%0ASent from Zint Grow`;

    const mailToString = `mailto:?subject=${emailSubject}&body=${emailBody}`;

    window.location.href = mailToString;
  }
}
