import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roundResultCount' })

/**
 * Transform 1000 plus values to shortened readable format
 * e.g. 1000 -> 1k, 1500 -> 1.5k
 * e.g. 20000 -> 20k, 25000 -> 25k
 */
export class RoundResultCountPipe implements PipeTransform {
  transform(count?: string | number): string {
    if (count === undefined) return '';
    const countNum = Number(count);
    if (isNaN(countNum)) return count.toString();
    if (!countNum || countNum < 1000) return countNum.toString();

    const thousands = Math.floor(countNum / 1000);
    const mod = Math.floor((countNum % 1000) / 100);

    return mod < 5 ? `${thousands}k` : `${thousands}.5k`;
  }
}
