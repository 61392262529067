<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
    <div class="col-md-8">
        <h5>
            New Accounts Filing - Made up to {{event_metadata.made_up_to_string | date:'d MMM y'}}
        </h5>
        <hr>
        <div class="row small-margin-top ">

            <div class="col d-flex flex-column align-items-baseline">

                <figure class="preview mb-3 px-2 text-sm">
                    
                    <blockquote>{{event_metadata.summary_preview || "preview yet unavailable"}}</blockquote>
                </figure>
                <span role="button" class="badge badge-link mt-auto" data-toggle="modal"
                    data-target="accountsSummaryModal" (click)="openSummaryModal(notifications)">
                    <i class="fal fa-robot"></i>
                    View AI Summary
                </span>
            </div>
            <div class="col">
                <div class="" *ngFor="let notification of notifications">
                    <ng-container *ngIf="notification.event_metadata.revenue">
                        <div>Revenue of {{notification.event_metadata.revenue | companyDataPipe:'Revenue':''}}</div>
                    </ng-container>
                </div>
                <a href="{{event_metadata.accounts_url}}" target="_blank">
                    <span class="badge badge-link">
                        <i class="fal fa-external-link"></i>
                        &nbsp;View accounts
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80'"
        [htmlBelowImage]="htmlBelowImage">
    </div>
</div>

<div class="modal fade" [id]="'accountsSummaryModal__' + randomId" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">AI Accounts Summary</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="isLoading.loadingAISummary">
                    <i class="fal fa-spinner fa-spin"></i>
                </ng-container>
                <p *ngIf="noAnalysisFound">No accounts analysis was found.</p>
                <ng-container *ngIf="displayAccountsSummary">
                    <p *ngIf="(accountsSummary.bullets | keyvalue)?.length === 0">No accounts analysis was found.</p>
                    <ng-container *ngFor="let category of accountsSummary.bullets | keyvalue">
                        <span class="badge badge-info">{{category.key}}</span>
                        <br>
                        <ul>
                            <li *ngFor="let bullet of category.value">
                                {{bullet.text}}
                            </li>
                        </ul>
                    </ng-container>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="basic-button-base button-dark" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>