import { Component, Input, SimpleChanges } from '@angular/core';
import { CompanyService } from '../../../services/company.service';

@Component({
  selector: 'news-carousel',
  templateUrl: './news-carousel.component.html',
  styleUrl: './news-carousel.component.css',
})
export class NewsCarouselComponent {
  @Input() latestNews: Record<string, any> = {};
  @Input() companyNumber: string = '';
  @Input() isSmallScreen: boolean = false;
  @Input() newsSearchTerm = '';
  @Input() selfPubNewsSearchTerm = '';

  allNewsToRender: Record<string, any> = {};
  news: Record<string, any>[] = null;
  selfPublishedNews: Record<string, any>[] = null;
  hasBothNewsType: boolean = false;
  isNewsSearchResultsShowing: Record<string, boolean> = {};

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {
    this.setUpNewsToRender();

    this.hasBothNewsType =
      !!this.news?.length && !!this.selfPublishedNews?.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    /* If either search terms were passed from the parent,
     * call performNewsSearch()
     */
    if (changes['newsSearchTerm']) {
      this.performNewsSearch('news');
    }
    if (changes['selfPubNewsSearchTerm']) {
      this.performNewsSearch('self_published_news');
    }
  }

  setUpNewsToRender(): void {
    /* if array has length assign it to allNewsToRender, else falsy
    In the template, 1st if check uses this flag (KV.value)
  */
    this.allNewsToRender = {
      news: this.latestNews.news?.length && this.latestNews.news,
      self_published_news:
        this.latestNews.self_published_news?.length &&
        this.latestNews.self_published_news,
    };
  }

  chunkArticles(articles: Record<string, any>[]): any[][] {
    const chunkSize = this.isSmallScreen ? 2 : 3;
    const chunks = [];
    for (let i = 0; i < articles.length; i += chunkSize) {
      chunks.push(articles.slice(i, i + chunkSize));
    }
    return chunks;
  }

  isThirdPartyNews(newsObj: Record<string, any>): boolean {
    return newsObj.hasOwnProperty('source') || newsObj.hasOwnProperty('guid');
  }

  resetSearchInput(key: string): void {
    if (key === 'news') {
      this.newsSearchTerm = '';
      this.isNewsSearchResultsShowing[key] = false;
      this.allNewsToRender = {
        ...this.allNewsToRender,
        news: this.latestNews.news,
      };
    } else {
      this.selfPubNewsSearchTerm = '';
      this.isNewsSearchResultsShowing[key] = false;
      this.allNewsToRender = {
        ...this.allNewsToRender,
        self_published_news: this.latestNews.self_published_news,
      };
    }
  }

  shouldShowWideControls(): string {
    const sizeThresholds = [
      { count: 72, size: '30rem' },
      { count: 40, size: '26rem' },
      { count: 12, size: '18rem' },
    ];

    const articleCount = Object.values(this.allNewsToRender).reduce(
      (total, news) => total + (news?.length || 0),
      0
    );

    const matchedThreshold = sizeThresholds.find(
      threshold => articleCount >= threshold.count
    );
    return matchedThreshold ? matchedThreshold.size : null;
  }

  getIndicatorsSize(chunkSize: Array<any>): string {
    if (!chunkSize.length) return '';
    if (chunkSize.length <= 20) {
      return '';
    } else {
      return 'indicators-sm';
    }
  }

  performNewsSearch(key: string): void {
    if (key === 'news') {
      if (!this.newsSearchTerm.trim().length) {
        this.resetSearchInput(key);
        return;
      }

      this.companyService
        .searchThirdPartyNews(this.companyNumber, this.newsSearchTerm)
        .subscribe(result => {
          if (result) {
            this.isNewsSearchResultsShowing[key] = true;
            this.allNewsToRender = {
              news: result,
              self_published_news: this.allNewsToRender.self_published_news,
            };
          }
        });
    }
    if (key === 'self_published_news') {
      if (!this.selfPubNewsSearchTerm.trim().length) {
        this.resetSearchInput(key);
        return;
      }

      this.companyService
        .searchSelfPublishedNews(this.companyNumber, this.selfPubNewsSearchTerm)
        .subscribe(result => {
          if (result) {
            this.isNewsSearchResultsShowing[key] = true;
            this.allNewsToRender = {
              news: this.allNewsToRender.news,
              self_published_news: result,
            };
          }
        });
    }
  }
}
