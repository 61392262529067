<div class="container">
    <div class="row">
        <div class="col-md-12">

            <div class="d-flex justify-content-center small-margin-top" *ngIf="isLoading.loadingSavedSearches">
                <zint-loader></zint-loader>
            </div>

            <div id="saved-searches-tab" *ngIf="!isLoading.loadingSavedSearches" [ngClass]="{'updating-saved-search': isLoading.loadingSavedSearchesAddRemoveUserOrTeam}">

                <h2 class="small-margin-top">Saved Searches:</h2>

                <h5 class="medium-margin-top" *ngIf="sharedSavedSearches">Shared Searches:</h5>
                <p class="small-margin-top text-center" *ngIf="!sharedSavedSearches || sharedSavedSearches.length == 0">There are no shared searches associated with your account.</p>
                <table *ngIf="sharedSavedSearches && sharedSavedSearches.length > 0" class="table table-striped table-bordered small-margin-top" style="table-layout:fixed;">
                    <thead>
                        <tr>
                            <td>Search Name</td>
                            <td style="width: 40%">Parameters</td>
                            <td>Re-run Search</td>
                            <td *ngIf="isSuperUser">Users Shared With</td>
                            <td *ngIf="isSuperUser">Teams Shared With</td>
                            <td *ngIf="isSuperUser">Delete</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let search of sharedSavedSearches">
                            <td>{{ search.name }}</td>
                            <td class="overflow-scroll"> <search-parameters [inputParams]="{'searchTags': search.outputForParamsDisplay, 'enableClearing': false}"></search-parameters> </td>
                            <td>
                                <a class="basic-button-base button-dark" [attr.href]="encodeSavedSearchURL(search)" target="_blank"><i class="fal fa-play"></i></a>
                            </td>
                            <td *ngIf="search.can_edit && isSuperUser">
                                <table class="table table-sm table-within-table sub-table" [ngClass]="{'zero-margin-bottom': !search.shared_users || search.shared_users.length == 0}">
                                    <tr *ngFor="let sharedSavedSearchUser of search.shared_users">
                                        <td>{{ sharedSavedSearchUser.email }}</td>
                                        <td *ngIf="search.shared_users && search.shared_users.length > 1">
                                            <button class="basic-button-base button-dark" style="font-size: 10px" (click)="removeUserOnSharedSavedSearch(sharedSavedSearchUser.userId, search.id)"><i class="fal fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </table>
                                <select class="custom-select" (change)="addUserOnSharedSavedSearch($event.target.value, search.id)" *ngIf="search.isAddingSharedeUser">
                                    <ng-container *ngIf="search.shared_users">
                                        <option selected></option>
                                        <ng-container *ngFor="let organisationUser of organisationUsers;">
                                            <option [attr.value]="organisationUser.userId" *ngIf="organisationUser | filterLoopPipe:search.shared_users:'userId'">{{organisationUser.email}}</option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                                <button *ngIf="!search.isAddingSharedeUser && organisationUsers && search.shared_users && organisationUsers.length != search.shared_users.length" class="btn-sm basic-button-base button-dark full-width" (click)="search.isAddingSharedeUser = true">
                                    <i class="fal fa-plus"></i> Add User
                                </button>
                            </td>
                            <td *ngIf="search.can_edit && isSuperUser">
                                <table class="table table-sm table-within-table sub-table" [ngClass]="{'zero-margin-bottom': !search.shared_teams || search.shared_teams.length == 0}">
                                    <tr *ngFor="let sharedSavedSearchTeam of search.shared_teams">
                                        <td>{{ sharedSavedSearchTeam.name }}</td>
                                        <td>
                                            <button class="basic-button-base button-dark" style="font-size: 10px" (click)="removeTeamOnSharedSavedSearch(sharedSavedSearchTeam.id, search.id)"><i class="fal fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </table>
                                <select class="custom-select" (change)="addTeamOnSharedSavedSearch($event.target.value, search.id)" *ngIf="search.isAddingSharedTeam">
                                    <ng-container>
                                        <option selected></option>
                                        <ng-container *ngFor="let organisation of organisations;">
                                            <option [attr.value]="organisation.id" *ngIf="organisation | filterLoopPipe:search.shared_teams:'id'">{{organisation.name}}</option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                                <button *ngIf="!search.isAddingSharedTeam && organisations && organisations.length != search.shared_teams.length" class="btn-sm basic-button-base button-dark full-width" (click)="search.isAddingSharedTeam = true">
                                    <i class="fal fa-plus"></i> Add Team
                                </button>
                            </td>
                            <td *ngIf="isSuperUser">
                                <button *ngIf="search.can_edit" class="basic-button-base button-dark" (click)="deleteSavedSearch(search.id)"><i class="fal fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h5 class="medium-margin-top">Your Searches:</h5>
                <p class="small-margin-top text-center" *ngIf="!privateSavedSearches || privateSavedSearches.length == 0">You have not yet saved any searches.</p>
                <table *ngIf="privateSavedSearches && privateSavedSearches.length > 0" class="table table-striped table-bordered small-margin-top" style="table-layout:fixed;">
                    <thead>
                        <tr>
                            <td>Search Name</td>
                            <td style="width: 50%">Parameters</td>
                            <td>Re-run Search</td>
                            <td>Delete</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let search of privateSavedSearches">
                            
                            <td>{{ search.name }}</td>
                            <td class="overflow-scroll"> <search-parameters [inputParams]="{'searchTags': search.outputForParamsDisplay, 'enableClearing': false}"></search-parameters> </td>
                            <td><a class="basic-button-base button-dark" [attr.href]="encodeSavedSearchURL(search)" target="_blank"><i class="fal fa-play"></i></a></td>
                            <td><button class="basic-button-base button-dark" (click)="deleteSavedSearch(search.id)"><i class="fal fa-trash"></i></button></td>
                        </tr>
                    </tbody>
                </table>

            </div>


        </div>
    </div>
</div>
