export let apiUrl = 'https://api.zint.io';

if (window.location.hostname === 'localhost') {
  apiUrl = 'https://localhost:8001';
} else if (window.location.hostname === 'app.zint.io') {
  apiUrl = 'https://api.zint.io';
} else if (window.location.hostname === 'zint.io') {
  apiUrl = 'https://api.zint.io';
} else if (window.location.hostname === 'www.zint.io') {
  apiUrl = 'https://api.zint.io';
} else if (window.location.hostname === 'app.zint.technology') {
  apiUrl = 'https://api.zint.technology';
} else if (window.location.hostname === 'zint.technology') {
  apiUrl = 'https://api.zint.technology';
} else if (window.location.hostname === 'www.zint.technology') {
  apiUrl = 'https://api.zint.technology';
} else {
  apiUrl = 'https://api.' + window.location.hostname;
}
