import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'psc',
  templateUrl: './psc.component.html',
  styleUrls: ['./psc.component.css'],
})
export class PSCComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor() {}

  event_metadata: any;
  companyNumber: any;
  eventDate: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.notifications[0].company_number;
    this.eventDate = this.event_metadata.notified_on_date
      ? this.event_metadata.notified_on_date
      : this.event_metadata.ceased_on_date;
  }
}
