
<div class="container-fluid">

    <div class="row" style="padding-left: 5vw; padding-right: 5vw">

        <div class="col-md-12">
            <h3>Manage your alerts</h3>
            <p class="small-margin-top">Alerts are used to notify you of new organisations that match pre-defined filters, set by you.</p>
            <p *ngIf="isSuperUser">As a team or organisation superuser, you may create these alerts on behalf of your colleagues.</p>
            <div style="overflow-x: scroll">
                <table class="table table-striped table-bordered small-margin-top">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th *ngIf="isSuperUser">Owner</th>
                            <th>Search Parameters</th>
                            <th>Frequency</th>
                            <th>Last Run</th>
                            <th>Status</th>
                            <th>Is Active?</th>
                            <th>Number in Last Run</th>
                            <th>Number Unread</th>
                            <th>Run Search</th>
                            <th>Delete Alert</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="(!isLoading.isLoadingAlerts && alerts && alerts.length == 0) || isLoading.isLoadingAlerts">
                            <td class="text-center" [attr.colspan]="isSuperUser ? 11: 10">
                                <i class="fal fa-spin fa-spinner" *ngIf="isLoading.isLoadingAlerts" style="font-size: 50px"></i>
                                <span *ngIf="!isLoading.isLoadingAlerts && alerts && alerts.length == 0">You have not set up any alerts.</span>
                            </td>
                        </tr>
                        <tr *ngFor="let alert of alerts">
                            <td>{{ alert.name }}</td>
                            <td *ngIf="isSuperUser && alert.owner">{{ alert.owner }}</td>
                            <td class="overflow-scroll max-width-search-param-cell">
                                <search-parameters [inputParams]="{'searchTags': pubMethods.changeSearchParamsToDisplayFormat(alert.search_parameters), 'enableClearing': false}"></search-parameters>
                            </td>
                            <td>{{ alert.frequency }}</td>
                            <td>{{ alert.last_run || 'Not Yet Run' }}</td>
                            <td>
                                <span *ngIf="alert.status_message">{{ alert.status_message }}</span>
                                <span *ngIf="!alert.status_message">-</span>
                            </td>
                            <td><span *ngIf="alert.is_paused">ALERT PAUSED</span><span *ngIf="!alert.is_paused">Active</span></td>
                            <td class="text-center">{{ alert.organisations_in_last_run_count }}</td>
                            <td class="text-center">{{ alert.unread_organisations_count }}</td>
                            <td class="text-center">
                                <a class="basic-button-base button-dark" [attr.href]="'/dashboard' + pubMethods.getGetSearchStringFromParams(alert.search_parameters)" target="_blank">
                                    <i class="fal fa-play"></i>
                                </a>
                            </td>
                            <td class="text-center">
                                <button class="basic-button-base button-dark" (click)="alertCurrentlyBeingDeleted = alert" data-toggle="modal" data-target="#confirmDeleteAlertModal">
                                    <i class="fal fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>



    <div class="modal fade" id="confirmDeleteAlertModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="confirmDeleteAlertModalLabel">Are you sure?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p *ngIf="alertCurrentlyBeingDeleted">This will delete the alert "{{ alertCurrentlyBeingDeleted.name }}" and all organisations previously flagged by this alert.</p>
                    <p>This alert will not run in the future and will be permanently removed from your account.</p>
                    <button class="basic-button-base button-dark" (click)="deleteAlert(alertCurrentlyBeingDeleted.id)" [attr.disabled]="isLoading.isDeletingAlert ? 'disabled': null"><i class="fal fa-spinner fa-spin" *ngIf="isLoading.isDeletingAlert"></i> Confirm</button>
                </div>
            </div>
        </div>
    </div>


</div>
