<div class="modal fade" id="termsForChatGPTFeatures" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="customColumnEditModalLabel">Terms and Conditions for Enabling ChatGPT-powered Features</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ol>
                    <li><strong>Introduction</strong></li>
                    <p>These Terms and Conditions ("Terms") apply to the use of the ChatGPT-powered features ("Features") available on our platform ("Platform"). By enabling these Features, you agree to be bound by these Terms, which govern the relationship between you (the "User") and Zint Technology Limited ("us" or "we") concerning the access and use of the Features.</p>
                    <li><strong>Acceptance of Terms</strong></li>
                    <p>By enabling the Features, you confirm that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not enable or use the Features.</p>
                    <li><strong>Third Party API</strong></li>
                    <p>The Features are powered by ChatGPT, an artificial intelligence language model developed by OpenAI ("Third Party"). To provide these Features, we submit User data to the Third Party's application programming interface ("API"). By enabling the Features, you acknowledge and accept that the User data will be submitted to the Third Party's API and that we do not control what the Third Party does with the submitted data. You can find OpenAI's privacy policy <a href="https://openai.com/policies/privacy-policy" target="_blank">here</a> and their API data usage policies <a href="https://openai.com/policies/api-data-usage-policies" target="_blank">here</a>.</p>
                    <li><strong>Data Subprocessor</strong></li>
                    <p>By enabling the Features, you acknowledge and agree that you are activating an additional subprocessor on your account. The Third Party will be processing the User data on our behalf as part of providing their service. You are responsible for ensuring that your use of the Features complies with any applicable data protection laws and regulations, including but not limited to the General Data Protection Regulation (GDPR) and the Data Protection Act 2018. Zint Technology Limited and OpenAI have a signed data processing agreement in place.</p>
                    <li><strong>Disclaimers and Limitation of Liability</strong></li>
                    <p>We disclaim any responsibility or liability arising from your use of the Features, including any errors or inaccuracies generated by the Third Party's API. We do not warrant that the Features will always be available, uninterrupted, or error-free.</p>
                    <p>To the fullest extent permitted by law, we, our affiliates, and the Third Party shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill, arising out of or in connection with your use of or inability to use the Features.</p>
                    <li><strong>Changes to Terms</strong></li>
                    <p>We reserve the right to modify or update these Terms at any time. We will notify you of any material changes by posting the updated Terms. Your continued use of the Features after any such changes constitutes your acceptance of the new Terms.</p>
                    <li><strong>Governing Law and Jurisdiction</strong></li>
                    <p>These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
                    <li><strong>Contact</strong></li>
                    <p>If you have any questions about these Terms, please contact us at info&#64;zint.io</p>
                </ol>
            </div>
        </div>
    </div>
</div>
