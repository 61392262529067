import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { MessageService } from '../../services/message.service';
declare var zE: any; // Zendesk SDK variable
declare var $: any;

type ClusterGraphType = 'full' | 'immediate';

@Component({
  selector: 'app-company-clusters',
  templateUrl: './company-clusters.component.html',
  styleUrls: ['./company-clusters.component.css'],
})
export class CompanyClustersComponent implements OnInit {
  companyId: string;
  isLoadingCorporateStructureGraph: boolean;
  clustersGraphData: Record<string, any> = { links: [], nodes: [] };
  clusters: any = []; // clusters are calculated from the graph
  centreGraph = false;
  zoomLevel: number = 0.5;
  expandedNodes = new Set<string>();

  @Input() clustersGraphType: ClusterGraphType = 'full';

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private messageService: MessageService // retain this so we can show errors
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.companyId = id;

    this.loadGraphData();
  }

  loadGraphData(): void {
    this.isLoadingCorporateStructureGraph = true;
    const serviceMethod =
      this.clustersGraphType === 'full'
        ? 'getCorporateStructureGraphWithClusters'
        : 'getImmediateCorporateClustersGraph';

    this.companyService[serviceMethod](this.companyId).subscribe(graphInfo => {
      this.createClustersFromGraph(graphInfo);
      this.updateCorporateStructureGraph(graphInfo);
    });

    this.zoomLevel = this.clustersGraphType === 'full' ? 0.5 : 1;
  }

  createClustersFromGraph(graphInfo: any) {
    let clustersMap = {};
    graphInfo.corporate_structure_graph.nodes.forEach(node => {
      if (node.cluster_id) {
        let clusterId = node.cluster_id.toString();
        if (!clustersMap[clusterId]) {
          clustersMap[clusterId] = {
            id: clusterId,
            label: clusterId, // need this separately for the library to display the correct colors
            labelText: node.cluster_name,
            childNodeIds: [node.id],
          };
        } else {
          clustersMap[clusterId].childNodeIds.push(node.id);
        }
      }
    });

    // Convert to array
    this.clusters = Object.values(clustersMap);
  }

  updateCorporateStructureGraph(graphInfo): void {
    this.clustersGraphData = graphInfo['corporate_structure_graph'];

    this.isLoadingCorporateStructureGraph = false;
  }

  setGraphLinks() {
    return this.clustersGraphData?.links || [];
  }

  setGraphNodes() {
    return this.clustersGraphData?.nodes || [];
  }

  setGraphClusters() {
    return this.clusters;
  }

  generateDirectorsList(directors): string[] {
    return directors.map(name => `<span>${name}</span>`).join('<br>');
  }

  toggleNode(nodeId: string): void {
    if (this.expandedNodes.has(nodeId)) {
      this.expandedNodes.delete(nodeId);
    } else {
      this.expandedNodes.add(nodeId);
    }
  }

  reCentreGraph(): void {
    this.centreGraph = true;
    // reset to false after a small delay so we can recentre again if need be.
    setTimeout(() => {
      this.centreGraph = false;
    }, 600);
  }

  generateClusterReasons(cluster_member_reason): string {
    let returnString: string = ''

    if (cluster_member_reason.distance_name_match < 2.5) {
      returnString += ' Similar name to other companies. '
    }

    if (cluster_member_reason.distance_name_similarity < 0.5) {
      returnString += 'Name has similar meaning to other companies. '
    }

    if (cluster_member_reason.distance_distance < 5.0) {
      returnString += 'Company is close in ownership to other companies. '
    }

    if (cluster_member_reason.distance_directors < 2.0) {
      returnString += 'Company has similar directors as other companies. '
    }

    if (cluster_member_reason.distance_sic_code < 1) {
      returnString += 'Similar SIC code to other companies. '
    }

    return returnString
  }
}
