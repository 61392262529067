import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { publicMethods } from '../../../globals';
import { SalesforceImporterService } from '../../../services/salesforce-importer.service';
import { CompanyMatcherComponent } from '../../company-matcher/company-matcher.component';

@Component({
  selector: 'salesforce-importer',
  templateUrl: './salesforce-importer.component.html',
  styleUrls: ['./salesforce-importer.component.css'],
})
export class SalesforceImporterComponent implements OnInit {
  availableSalesforceObjects: Array<any>;
  zintAttributeColumnHeadersMatchableOn: Array<any>;

  // Below are attrs of the SalesforceHarmoniserSettings for this user.
  selectedSalesforceObject: string;
  salesforceUniqueIdColumnName: string;
  columnHeadersAvailableToSynchronise: Array<string>;
  columnHeadersAvailableToMatch: Array<string>;
  totalNumberOfRows: any;
  dataPreview: Array<any>;
  userHasAccess = false;

  stepNumber = 0;
  // Required for step 5
  countOfNonIgnoreSelectedToMatchOn = 0;
  // Required for file upload
  self = this;
  // Required for timeout caller
  continuousCaller;
  rowsProcessed: any;
  status: any;

  @ViewChild('childCompanyMatcher')
  companyMatcherComponent: CompanyMatcherComponent;

  constructor(
    private salesforceImporterService: SalesforceImporterService,
    private pubMethods: publicMethods
  ) {}

  isLoading = this.salesforceImporterService.isLoading;

  ngOnInit() {
    this.loadPage();
  }

  /// --------------INITIALISATION METHODS --------------

  loadPage(): void {
    this.salesforceImporterService
      .loadStatus()
      .subscribe(data => this.updatePageBasedUponStatus(data));
  }

  updatePageBasedUponStatus(data): void {
    this.userHasAccess = true;
    this.status = data['status'];
    if (this.status === 'defining_settings') {
      this.loadSettings();
    }
    if (this.status === 'matching') {
      this.testCompanyMatcherProgress();
    }
    if (this.status === 'completed_matching') {
      this.initMatcher();
    }
  }

  loadSettings(): void {
    this.salesforceImporterService
      .loadSalesforceHarmoniserSettings()
      .subscribe(data => this.updateConfigurationSettings(data));
  }

  updateConfigurationSettings(data): void {
    this.zintAttributeColumnHeadersMatchableOn =
      data.zint_column_headers_matchable_on;
    this.availableSalesforceObjects = data.available_salesforce_objects;
    this.updateConfiguration(data.salesforce_harmoniser_settings);
    this.setInitialPageNumber();
  }

  /// ----------------------------------------------------------------------

  // -------------- CONFIGURATION METHODS --------------

  updateConfiguration(configObject): void {
    this.selectedSalesforceObject = configObject.sobject_type;
    this.salesforceUniqueIdColumnName =
      configObject.salesforce_unique_identifier_column_name;
    this.columnHeadersAvailableToSynchronise =
      configObject.column_headers_available_to_synchronise;
    this.columnHeadersAvailableToMatch =
      configObject.column_headers_available_to_match;
    this.totalNumberOfRows = configObject.total_rows;
    this.dataPreview = configObject.data_preview;

    // increment the counter of non ignore matching columns.
    this.countOfNonIgnoreSelectedToMatchOn = 0;
    if (this.columnHeadersAvailableToMatch) {
      for (var columnHeaderAvailableToMatch of this
        .columnHeadersAvailableToMatch) {
        if (columnHeaderAvailableToMatch['ZintAttribute'] !== 'ignore') {
          this.countOfNonIgnoreSelectedToMatchOn =
            this.countOfNonIgnoreSelectedToMatchOn + 1;
        }
      }
    }
  }

  setInitialPageNumber(): void {
    if (this.selectedSalesforceObject) {
      this.stepNumber = 1;
    }
    if (this.columnHeadersAvailableToSynchronise) {
      this.stepNumber = 2;
    }
    if (this.salesforceUniqueIdColumnName) {
      this.stepNumber = 3;
    }
  }

  emptyEventHandler(): void {}

  nextButtonClicked(): void {
    this.stepNumber = this.stepNumber + 1;
  }

  previousButtonClicked(): void {
    if (this.stepNumber > 0) {
      this.stepNumber = this.stepNumber - 1;
    }
  }

  updateConfigAndGoToNextStep(data): void {
    this.updateConfiguration(data);
    this.nextButtonClicked();
  }

  // Step 1
  chooseSalesforceObjectType(): void {
    this.salesforceImporterService
      .chooseSalesforceObjectType(this.selectedSalesforceObject)
      .subscribe(data => this.updateConfigAndGoToNextStep(data));
  }

  // Step 2
  fileUploadComplete(response, _self) {
    _self.updateConfigAndGoToNextStep(response);
  }

  // Step 3
  chooseSalesforceObjectUniqueIdColumnHeader(): void {
    this.salesforceImporterService
      .chooseSalesforceObjectUniqueIdColumnHeader(
        this.salesforceUniqueIdColumnName
      )
      .subscribe(data => this.updateConfigAndGoToNextStep(data));
  }

  // Step 4
  chooseColumnHeadersToSynchronise(): void {
    this.salesforceImporterService
      .chooseColumnHeadersToSynchronise(
        this.columnHeadersAvailableToSynchronise
      )
      .subscribe(data => this.updateConfigAndGoToNextStep(data));
  }

  // Step 5 intermediate stage
  matchingColumnHeaderSelected(val): void {
    if (val && val !== 'ignore') {
      this.countOfNonIgnoreSelectedToMatchOn =
        this.countOfNonIgnoreSelectedToMatchOn + 1;
    }
    if (val === 'ignore') {
      this.countOfNonIgnoreSelectedToMatchOn =
        this.countOfNonIgnoreSelectedToMatchOn - 1;
    }
  }

  // Step 5 Final Stage
  chooseColumnHeadersForMatching(): void {
    this.salesforceImporterService
      .chooseColumnHeadersForMatching(this.columnHeadersAvailableToMatch)
      .subscribe(data => this.updateConfigAndGoToNextStep(data));
  }

  // Step 6
  startMatchingProcess(): void {
    this.salesforceImporterService
      .startMatchingProcess()
      .subscribe(data => this.awaitResultsFromMatchingProcess(data));
  }

  /// ----------------------------------------------------------------------

  /// --------------MATCHER RUNNING METHODS --------------

  awaitResultsFromMatchingProcess(data): void {
    this.rowsProcessed = data.rows_processed;
    this.totalNumberOfRows = data.total_rows;
    this.status = data.status;
    if (this.status === 'matching') {
      this.continuousCaller = setTimeout(() => {
        this.testCompanyMatcherProgress();
      }, 10000);
    }
    if (this.status === 'completed_matching') {
      this.initMatcher();
    }
  }

  testCompanyMatcherProgress() {
    this.salesforceImporterService
      .loadSalesforceMatcherProgress()
      .subscribe(data => this.awaitResultsFromMatchingProcess(data));
  }

  postCancelMatchingProcess(data): void {
    this.stepNumber = 0;
    this.salesforceUniqueIdColumnName = null;
    this.columnHeadersAvailableToSynchronise = null;
    this.selectedSalesforceObject = null;
    this.status = 'defining_settings';
    this.updateConfigurationSettings(data);
  }

  cancelMatchingProcess(): void {
    this.salesforceImporterService
      .cancelMatchingProcess()
      .subscribe(data => this.postCancelMatchingProcess(data));
  }

  /// ----------------------------------------------------------------------

  // ------------ RENDERING MATCHER ----------
  initMatcher(): void {
    var self = this;
    setTimeout(function () {
      self.companyMatcherComponent.loadDataForUser();
    }, 1);
  }

  showNumberUpdated(data): void {
    if (data['created']) {
      this.pubMethods.showInfoMessage(
        'Created ' + data['created'] + ' results.'
      );
    }
  }

  createSalesforceSelectedOnPage(rowIdsToSelectedCompanyIds): void {
    this.salesforceImporterService
      .createSalesforceSelectedOnPage(rowIdsToSelectedCompanyIds)
      .subscribe(data => this.showNumberUpdated(data));
  }

  createSalesforceRecordsForAll(params): void {
    let lowerMatchLevel = params[0];
    let upperMatchLevel = params[1];
    this.salesforceImporterService
      .createSalesforceRecordsForAll(lowerMatchLevel, upperMatchLevel)
      .subscribe(data => this.showNumberUpdated(data));
  }
}
