import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

const DSN =
  'https://d89ce9dcf4954129927929ca326959c2@o212294.ingest.sentry.io/6619336';
Sentry.init({
  ignoreErrors: ['CSRF cookie is not set. Please refresh the page.'],
  dsn: environment.production ? DSN : null,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['app.zint.technology', 'app.zint.io'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
