import { Component, OnInit, Input } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { DataSharingService } from '../../../services/data-sharing.service';
import { CRMIntegrationsService } from '../../../services/crm-integrations.service';
import { publicMethods } from '../../../globals';
declare var $: any;

@Component({
  selector: 'salesforce',
  templateUrl: './salesforce.component.html',
  styleUrls: ['./salesforce.component.css'],
})
export class SalesforceComponent implements OnInit {
  @Input()
  inputParams: any = {
    maximumCompanies: Object,
  };

  companyIds: Array<any> = [];
  searchParams: HttpParams = null;
  generalErrors: Array<any> = [];
  companyErrors: Array<any> = [];
  recordsCreated: Array<any> = [];
  recordsUpdated: Array<any> = [];

  preCheckSalesforceMatches: Array<any> = [];
  companyIdsToSalesforceIds: any = {};

  allowExportButton = true;

  constructor(
    private data: DataSharingService,
    private crmIntegrationService: CRMIntegrationsService,
    private pubMethods: publicMethods
  ) {}

  isLoading = this.crmIntegrationService.isLoading;

  ngOnInit() {
    this.data.currentSelectedCompanyIds.subscribe(
      companyIds => (this.companyIds = companyIds)
    );
    this.data.currentSelectedSearchParams.subscribe(
      searchParams => (this.searchParams = searchParams)
    );
    let salesforceSelf = this;
    $('#salesforceModal').on('hide.bs.modal', function (e) {
      salesforceSelf.clearSalesforceExportData();
    });
  }

  clearSalesforceExportData(): void {
    this.generalErrors = [];
    this.companyErrors = [];
    this.preCheckSalesforceMatches = [];
    this.companyIdsToSalesforceIds = {};
    this.recordsCreated = [];
    this.recordsUpdated = [];
    this.allowExportButton = true;
  }

  processPreCheckData(data): void {
    if (data.success) {
      this.preCheckSalesforceMatches = data.salesforce_matches;
      this.preCheckSalesforceMatches.forEach(sfMatch => {
        if (sfMatch.matches) {
          if (sfMatch.matches.length > 0) {
            this.companyIdsToSalesforceIds[sfMatch.id] =
              sfMatch.matches[0].salesforce_id;
          } else {
            this.companyIdsToSalesforceIds[sfMatch.id] = true;
          }
        }
      });
    }
  }

  salesforceUpdateComplete(data): void {
    if (data.success) {
      if (
        (data.general_errors && data.general_errors.length > 0) ||
        (data.company_errors && Object.keys(data.company_errors).length > 0)
      ) {
        this.pubMethods.log(
          'Some entries may have exported successfully; but there were errors in your export. Please see details below.'
        );
        this.companyErrors = data.company_errors;
        this.generalErrors = data.general_errors;
      } else {
        this.pubMethods.showInfoMessage(
          'These entries were updated in Salesforce.'
        );
      }
      if (data.records_created) {
        this.recordsCreated = data.records_created;
      }
      if (data.records_updated) {
        this.recordsUpdated = data.records_updated;
      }
      this.allowExportButton = false;
    }
  }

  exportCompaniesToSalesforcePreCheck(): void {
    this.crmIntegrationService
      .exportCompaniesToSalesforcePreCheck(this.companyIds)
      .subscribe(data => this.processPreCheckData(data));
  }

  exportCompaniesToSalesforce(): void {
    this.crmIntegrationService
      .exportCompaniesToSalesforce(this.companyIdsToSalesforceIds)
      .subscribe(data => this.salesforceUpdateComplete(data));
  }
}
