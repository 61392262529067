import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { throwError } from 'rxjs';

@Injectable()
export class CompanyService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  cachedImmediateClusters$: Observable<any>;
  cachedFullClusters$: Observable<any>;
  cachedCorporateStructure$: Observable<any>;
  cachedSupplierData$: Observable<any>;
  cachedDetailedImportsExportsData$: Observable<any>;
  cachedCrossSellData$: Observable<any>;

  isLoading = {
    loadingBulkEdit: false,
    loadingSearchNews: false,
    loadingSearchSelfPublishedNews: false,
    loadingJobSearch: false,
    loadingFullWebsiteResults: false,
    loadingGovernmentContracts: false,
    loadingAIPitchBuilderSettings: false,
    loadingAIPitch: false,
    loadingAISummary: false,
    loadingAIResearchResponses: false,
    loadingCustomInsights: false,
    loadingRequestCustomInsights: false,
  };

  getAllCompanySections(): Observable<any> {
    this.isLoading['loadingAllCompanySections'] = true;
    return this.http
      .post(
        baseUrl + '/get_user_format_company_sections/',
        '',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => {
          this.isLoading['loadingAllCompanySections'] = false;
        }),
        catchError(
          this.pubMethods.handleError(
            'getAllCompanySections',
            [],
            this.isLoading
          )
        )
      );
  }

  saveUserPreferredNavOrder(navSectionList: Array<string>): Observable<any> {
    const params = new HttpParams().set(
      'sections',
      JSON.stringify(navSectionList)
    );

    return this.http
      .post(
        baseUrl + '/save_profile_navs_order/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(err => {
          this.pubMethods.handleError(
            'saveUserPreferredNavOrder',
            [],
            this.isLoading
          );
          return throwError(err);
        })
      );
  }

  getCompanyUserDetails(company_number: string): Observable<any> {
    this.isLoading['loadingCompanyUserDetails'] = true;
    let params = new HttpParams().set('company_number', <string>company_number);
    return this.http
      .post(
        baseUrl + '/get_company_user_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCompanyUserDetails'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getCompanyUserDetails',
            [],
            this.isLoading
          )
        )
      );
  }

  getCompanyProfileData(companyNumber: string): Observable<any> {
    this.isLoading['loadingCompanyProfileData'] = true;
    let params = new HttpParams().set('id', companyNumber);
    return this.http
      .post(
        baseUrl + '/company_profile_data/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => {
          this.isLoading['loadingCompanyProfileData'] = false;
        }),
        catchError(error => {
          this.pubMethods.handleError('getCompanyProfile', [], this.isLoading);
          return throwError(error);
        })
      );
  }

  getSupplierGraph(company_number, supplierDataPageNumber): Observable<any> {
    this.isLoading['loadingSupplierData'] = true;
    const params = new HttpParams()
      .set('id', <string>company_number)
      .set('supplierDataPageNumber', supplierDataPageNumber);
    this.cachedSupplierData$ = this.http
      .post(
        baseUrl + '/company_mentions_graph/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => {
          this.isLoading['loadingSupplierData'] = false;
        }),
        catchError(
          this.pubMethods.handleError('getSupplierGraph', [], this.isLoading)
        ),
        shareReplay()
      );

    return this.cachedSupplierData$;
  }

  getCorporateStructureGraph(id: string): Observable<any> {
    if (!this.cachedCorporateStructure$) {
      let params = new HttpParams().set('id', <string>id);
      this.cachedCorporateStructure$ = this.http
        .post(
          baseUrl + '/corporate_structure_graph/',
          params,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => _),
          catchError(
            this.pubMethods.handleError(
              'getCorporateStructureGraph',
              [],
              this.isLoading
            )
          ),
          shareReplay()
        );
    }

    return this.cachedCorporateStructure$;
  }

  getCorporateStructureGraphWithClusters(id: string): Observable<any> {
    if (!this.cachedFullClusters$) {
      let params = new HttpParams().set('id', <string>id);

      this.cachedFullClusters$ = this.http
        .post(
          baseUrl + '/corporate_structure_graph_with_clusters/',
          params,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => _),
          catchError(
            this.pubMethods.handleError(
              'getCorporateStructureGraphWithClusters',
              [],
              this.isLoading
            )
          ),
          shareReplay()
        );
    }
    return this.cachedFullClusters$;
  }

  getImmediateCorporateClustersGraph(id: string): Observable<any> {
    if (!this.cachedImmediateClusters$) {
      let params = new HttpParams().set('id', <string>id);
      this.cachedImmediateClusters$ = this.http
        .post(
          baseUrl + '/company_immediate_cluster/',
          params,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => _),
          catchError(
            this.pubMethods.handleError(
              'getImmediateCorporateClustersGraph',
              [],
              this.isLoading
            )
          ),
          shareReplay()
        );
    }
    return this.cachedImmediateClusters$;
  }

  getExpandedCorporateStructure(
    nodeId: string,
    direction: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('nodeId', <string>nodeId)
      .set('direction', direction);
    return this.http
      .post(
        baseUrl + '/get_expanded_corporate_structure/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError(
            'getExpandedCorporateStructure',
            [],
            this.isLoading
          )
        )
      );
  }

  getCompanyShareholders(companyNumber: string): Observable<any> {
    let params = new HttpParams().set('id', <string>companyNumber);
    return this.http
      .post(
        baseUrl + '/company_shareholders/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError(
            'getCompanyShareholders',
            [],
            this.isLoading
          )
        )
      );
  }

  getCompaniesShareholderIn(companyNumber: string): Observable<any> {
    let params = new HttpParams().set('id', <string>companyNumber);
    return this.http
      .post(
        baseUrl + '/companies_shareholder_in/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError(
            'getCompaniesShareholderIn',
            [],
            this.isLoading
          )
        )
      );
  }

  getRelatedPeopleGraph(id: string): Observable<any> {
    if (!this.cachedCrossSellData$) {
      let params = new HttpParams().set('id', <string>id);
      this.cachedCrossSellData$ = this.http
        .post(
          baseUrl + '/related_people_graph/',
          params,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => _),
          catchError(
            this.pubMethods.handleError(
              'getRelatedPeopleGraph',
              [],
              this.isLoading
            )
          ),
          shareReplay()
        );
    }
    return this.cachedCrossSellData$;
  }

  getExistingCustomInsights(companyID: string): Observable<any> {
    const params = new HttpParams().set('company_id', <string>companyID);
    this.isLoading['loadingCustomInsights'] = true;
    return this.http
      .post(
        baseUrl + '/get_custom_insights/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(() => (this.isLoading['loadingCustomInsights'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getExistingCustomInsights',
            [],
            this.isLoading
          )
        )
      );
  }

  requestCustomInsights(companyIds): Observable<any> {
    const params = new HttpParams().set(
      'companyIds',
      this.pubMethods.stringifyListForGetParams(companyIds)
    );
    this.isLoading['loadingRequestCustomInsights'] = true;
    return this.http
      .post(
        baseUrl + '/request_custom_insights/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(() => (this.isLoading['loadingRequestCustomInsights'] = false)),
        catchError(
          this.pubMethods.handleError(
            'requestCustomInsights',
            [],
            this.isLoading
          )
        )
      );
  }

  searchCompanyAccounts(
    id: string,
    accountsSearchText: string,
    accountsDateSelected: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('id', <string>id)
      .set('accountsSearchText', <string>accountsSearchText)
      .set('accountsDateSelected', <string>accountsDateSelected);
    this.isLoading['loadingAccountsSearchResults'] = true;
    return this.http
      .post(
        baseUrl + '/search_a_companys_accounts/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingAccountsSearchResults'] = false)),
        catchError(
          this.pubMethods.handleError(
            'searchCompanyAccounts',
            [],
            this.isLoading
          )
        )
      );
  }

  runFullWebsiteSearch(
    id: string,
    fullWebsiteSearchTerm: string
  ): Observable<any> {
    let _params = new HttpParams()
      .set('id', <string>id)
      .set('fullWebsiteSearchTerm', <string>fullWebsiteSearchTerm);
    this.isLoading['loadingFullWebsiteResults'] = true;
    return this.http
      .post(
        baseUrl + '/search_a_companys_website/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingFullWebsiteResults'] = false)),
        catchError(
          this.pubMethods.handleError(
            'runFullWebsiteSearch',
            [],
            this.isLoading
          )
        )
      );
  }

  searchThirdPartyNews(
    companyNumber: string,
    newsSearchText: string
  ): Observable<any> {
    let _params = new HttpParams()
      .set('companyNumber', companyNumber)
      .set('newsSearchText', newsSearchText);
    this.isLoading['loadingSearchNews'] = true;
    return this.http
      .post(
        baseUrl + '/search_third_party_news_for_company/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSearchNews'] = false)),
        catchError(
          this.pubMethods.handleError('searchCompanyNews', [], this.isLoading)
        )
      );
  }

  searchSelfPublishedNews(
    companyNumber: string,
    newsSearchText: string
  ): Observable<any> {
    let _params = new HttpParams()
      .set('companyNumber', companyNumber)
      .set('newsSearchText', newsSearchText);
    this.isLoading['loadingSearchSelfPublishedNews'] = true;
    return this.http
      .post(
        baseUrl + '/search_self_published_news_for_company/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSearchSelfPublishedNews'] = false)),
        catchError(
          this.pubMethods.handleError(
            'searchSelfPublishedCompanyNews',
            [],
            this.isLoading
          )
        )
      );
  }

  searchCompanyJobs(id: string, jobSearchText: string): Observable<any> {
    let _params = new HttpParams()
      .set('id', <string>id)
      .set('jobSearchText', <string>jobSearchText);
    this.isLoading['loadingJobSearch'] = true;
    return this.http
      .post(
        baseUrl + '/search_a_companys_jobs/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingJobSearch'] = false)),
        catchError(
          this.pubMethods.handleError('searchCompanyJobs', [], this.isLoading)
        )
      );
  }

  getAllGovernmentContracts(id: string): Observable<any> {
    this.isLoading['loadingGovernmentContracts'] = true;
    let params = new HttpParams().set('companyNumber', <string>id);
    return this.http
      .post(
        baseUrl + '/get_company_government_contracts/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingGovernmentContracts'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getAllGovernmentContracts',
            [],
            this.isLoading
          )
        )
      );
  }

  updateCustomFreeColumn(customColumnId, companyIds, value): Observable<any> {
    let _params = new HttpParams()
      .set('columnId', customColumnId)
      .set('companyIds', companyIds)
      .set('value', value);
    this.isLoading['loadingBulkEdit'] = true;
    return this.http
      .post(
        baseUrl + '/save_custom_column_free/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingBulkEdit'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updateCustomFreeColumn',
            [],
            this.isLoading
          )
        )
      );
  }

  updateCustomOptionColumn(
    customColumnId,
    companyIds,
    optionSelectedId
  ): Observable<any> {
    let _params = new HttpParams()
      .set('columnId', customColumnId)
      .set('companyIds', companyIds)
      .set('optionSelectedId', optionSelectedId);
    this.isLoading['loadingBulkEdit'] = true;
    return this.http
      .post(
        baseUrl + '/save_custom_column_option/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingBulkEdit'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updateCustomOptionColumn',
            [],
            this.isLoading
          )
        )
      );
  }

  scrollToSelectorOnceExists(selector: string) {
    let element = document.getElementById(selector);
    if (element) {
      element.scrollIntoView({ block: 'start' });
      return;
    }
    // Wait for element to exist before scrolling to it
    const observer = new MutationObserver(() => {
      element = document.getElementById(selector);
      if (element) {
        element.scrollIntoView({ block: 'start' });
        observer.disconnect();
        return;
      }
    });
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  }

  validateAIPitchBuilderSettings(): Observable<any> {
    let _params = new HttpParams();
    this.isLoading['loadingAIPitchBuilderSettings'] = true;
    return this.http
      .post(
        baseUrl + '/validate_ai_pitch_builder_settings/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingAIPitchBuilderSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'ValidateAIPitchBuilderSettings',
            [],
            this.isLoading
          )
        )
      );
  }

  createLogoFromInitials(companyName: string): string {
    const initials = companyName
      .split(/\s/g)
      .map(chunk =>
        // takes the 2nd letter if 1st if non-alphanumeric e.g bracket
        /\w/gi.test(chunk[0])
          ? chunk[0].toLocaleUpperCase()
          : chunk[1]?.toLocaleUpperCase()
      )
      .join('');

    //only return the even number of letters for the ui consistency
    return initials.length % 2 === 0 ? initials : initials.slice(0, -1);
  }

  getAIResearchAssistantResponses(companyId): Observable<any> {
    let _params = new HttpParams().set('companyNumber', companyId);
    this.isLoading['loadingAIResearchResponses'] = true;
    return this.http
      .post(
        baseUrl + '/get_ai_research_responses/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingAIResearchResponses'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getAIResearchAssistantResponses',
            [],
            this.isLoading
          )
        )
      );
  }

  createAssistantThread(companyId?: string): Observable<any> {
    let _params = new HttpParams().set('company_number', companyId ?? '');
    return this.http
      .post(
        baseUrl + '/create_assistant_thread/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(error => {
          this.pubMethods.handleError(
            'createAssistantThread',
            [],
            this.isLoading
          );
          return throwError(error);
        })
      );
  }

  getLLMEnergyEmissionsData(companyNumber: string): Observable<any> {
    let params = new HttpParams().set('id', companyNumber);

    return this.http
      .post(
        baseUrl + '/llm_energy_emissions_data/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(error => {
          this.pubMethods.handleError(
            'llmEnergyAndEmissions',
            [],
            this.isLoading
          );
          return throwError(error);
        })
      );
  }

  getCompanyDetailedImportsExports(companyNumber: string): Observable<any> {
    if (!this.cachedDetailedImportsExportsData$) {
      let params = new HttpParams().set('id', companyNumber);

      this.cachedDetailedImportsExportsData$ = this.http
        .post(
          baseUrl + '/company_detailed_imports_exports/',
          params,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => _),
          catchError(error => {
            this.pubMethods.handleError(
              'detailedImportsExports',
              [],
              this.isLoading
            );
            return throwError(error);
          }),
          shareReplay()
        );
    }
    return this.cachedDetailedImportsExportsData$;
  }
}
