<ng-container *ngIf="pushMessagesService.messages && pushMessagesService.messages.length > 0 && showingMessages">
    <div class="fixed-bottom">
        <div class="chat-window-header d-flex justify-content-between align-items-center col-sm-10 col-md-5 col-lg-4 col-xl-3">
            <div style="margin-left: 20px">
                Message from Zint
            </div>
            <div (click)="closeMessageWindow()" class="chat-window-header-text-close" style="margin-right: 20px">
                x
            </div>
        </div>
        <div class="chat-window col-sm-10 col-md-5 col-lg-4 col-xl-3">
            <ng-container *ngFor="let msg of pushMessagesService.messages">
                <div class="message">
                    <div [innerHTML]="msg.message">                       
                    </div>
                    <div *ngIf="msg.embed_url" class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" [src]="msg.embed_url | safe:'url'" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="ignore-text" (click)="ignorePushMessage(msg)">Ignore</div>
            </ng-container>

             <button class="basic-button-base button-dark xsmall-margin-top" (click)="openZendeskChat()">
                Respond
            </button>
        </div>
    </div>
</ng-container>
