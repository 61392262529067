


<div class="container">
    <div class="row">
        <div class="col-md-12">
            <br>
            <label>
                <input type="checkbox"
                   [attr.disabled]="isLoading.loadingConfigChange ? 'disabled': null"
                   [(ngModel)]="useAdminCredentialsForReadQueries"
                   (ngModelChange)="changeOfUseAdminCredentialsForReadQueries()"/>
                Use Your Salesforce Access Token for All Read Requests to Salesforce?
            </label>
        </div>

        <ng-container *ngIf="exporterSettings">

            <div class="col-md-12">
                <br>
                <hr>
                <br>
                <h4>Exporter Permissions:</h4>
            </div>

            <div class="col-md-6">
                <add-remove-team
                                 [inputParams]="{
                                    'confirmDeleteModalId': 'confirmDeleteTeamOnExporterSettingsModal',
                                    'objectAddingOrRemovingTeam': exporterSettings,
                                    'attributeNameForTeams': 'view_teams',
                                    'organisationTeams': organisationTeams
                                }"
                                (ParentFunctionOnAddNewTeam)="addTeamToExporterSettings($event[0])"
                                (ParentFunctionOnClickToDeleteTeam)="teamToDeleteFromExporterSettings = $event[0]"
                ></add-remove-team>
            </div>
            <div class="col-md-6">
                <add-remove-individual
                         [inputParams]="{
                            'confirmDeleteModalId': 'confirmDeleteUserOnExporterSettingsModal',
                            'objectAddingOrRemovingIndividual': exporterSettings,
                            'attributeNameForIndividuals': 'view_users',
                            'organisationUsers': organisationUsers
                        }"
                        (ParentFunctionOnAddNewIndividual)="addUserToExporterSettings($event[0])"
                        (ParentFunctionOnClickToDeleteIndividual)="userToDeleteFromExporterSettings = $event[0];"
                ></add-remove-individual>
            </div>
        </ng-container>

    </div>
</div>




<div class="modal fade" id="confirmDeleteUserOnExporterSettingsModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteUserOnExporterSettingsModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="userToDeleteFromExporterSettings">
                <p>This will remove access for {{ userToDeleteFromExporterSettings.email }} from the Salesforce exporter.</p>
                <button class="basic-button-base button-dark" (click)="deleteUserFromSalesforceExporter()" [attr.disabled]="isLoading.loadingConfigChange ? 'disabled': null">Confirm</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="confirmDeleteTeamOnExporterSettingsModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteTeamOnExporterSettingsModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="teamToDeleteFromExporterSettings">
                <p>This will remove access for {{ teamToDeleteFromExporterSettings.name }} from the Salesforce exporter.</p>
                <button class="basic-button-base button-dark" (click)="deleteTeamFromSalesforceExporter()" [attr.disabled]="isLoading.loadingConfigChange ? 'disabled': null">Confirm</button>
            </div>
        </div>
    </div>
</div>

