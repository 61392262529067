import { Component, OnInit } from '@angular/core';
import { StripePurchaseService } from '../../services/stripe-purchase.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'purchase-plan',
  templateUrl: './purchase-plan.component.html',
  styleUrls: ['./purchase-plan.component.css'],
})
export class PurchasePlanComponent implements OnInit {
  products: Array<any>;
  selectedProductOption = null;
  promoCode = null;
  selectedPriceOption = null;
  hasAcceptedTerms = false;

  isLoading = this.stripePurchaseService.isLoading;

  constructor(
    private stripePurchaseService: StripePurchaseService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const purchaseId = queryParams['purchaseId'];
    const fromCancellation = queryParams['cancel'];
    const forceLoginNext = queryParams['forceLoginNext'] || false;
    this.promoCode = queryParams['promoCode'] || '';
    if (fromCancellation === '1') {
      this.messageService.showInfo('You cancelled the checkout process');
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('cancel');
      history.replaceState(null, null, currentUrl.href);
    }
    this.stripePurchaseService
      .getPurchaseStatus(purchaseId, forceLoginNext, this.promoCode)
      .subscribe(data => this.processInitialisation(data));
  }

  processInitialisation(data): void {
    if (data.message) {
      this.messageService.showInfo(data.message);
    }
    if (data.redirection_url) {
      setTimeout(
        () => window.location.replace(decodeURI(data.redirection_url)),
        3750
      );
    }
    if (data.success) {
      this.products = data.pricing_details.products;
      if (this.products.length > 0) {
        this.selectedProductOption = this.products[0];
        if (this.selectedProductOption.prices.length > 0) {
          this.selectedPriceOption = this.selectedProductOption.prices[0];
        }
        this.promoCode = data.promo_code;
      }
    }
  }

  defGetStripeCheckoutSession(): void {
    if (this.selectedProductOption && this.selectedPriceOption) {
      this.stripePurchaseService
        .defGetStripeCheckoutSession(
          this.selectedProductOption.stripe_product_id,
          this.selectedPriceOption.stripe_price_id,
          this.promoCode
        )
        .subscribe(data => this.stripeCheckoutRedirect(data));
    } else {
      this.messageService.show(
        'Please ensure you have selected a plan and price.'
      );
    }
  }

  stripeCheckoutRedirect(data): void {
    if (data.success && data.payment_url) {
      window.location.replace(data.payment_url);
    }
  }
}
