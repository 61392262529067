import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'related-psc',
  templateUrl: './related-psc.component.html',
  styleUrls: ['./related-psc.component.css'],
})
export class RelatedPscComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor(private datePipe: DatePipe) {}

  event_metadata: any;
  eventDate: any;
  companyNumber: string;
  relatedCompanyNumber: string;
  htmlBelowImage: any;
  importance: any;
  ruleSetName: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.notifications[0].company_number;
    this.relatedCompanyNumber = this.event_metadata.company_number;
    this.eventDate =
      this.event_metadata.appointed_date ||
      this.notifications[0].creation_datetime;
    this.htmlBelowImage =
      '<i class="far fa-briefcase"></i> Appointed: ' +
      this.datePipe.transform(this.eventDate, 'd MMM y');
  }
}
