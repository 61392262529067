import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.css'],
})
export class HiringComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor() {}

  event_metadata: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
  }
}
