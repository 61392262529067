


<div class="row small-margin-top">
    <div class="col-md-12">
        <div *ngIf="isLoading.loadingInitialData" style="font-size: 20px; text-align: center">
            <i class="fal fa-spinner fa-spin"></i>
        </div>
        <ng-container *ngIf="!isLoading.loadingInitialData && !userHasAccess">
            You do not have access to this functionality.
        </ng-container>
    </div>
</div>


<ng-container *ngIf="!isLoading.loadingInitialData && userHasAccess && status === 'defining_settings'">

    <div class="container" *ngIf="stepNumber < 6">
        <div class="progress">
            <div class="progress-bar progress-bar-striped" role="progressbar" [attr.aria-valuenow]="stepNumber" aria-valuemin="0" aria-valuemax="6" [style.width]="(stepNumber / 6) * 100 + '%'" style="background-color: #000A36"></div>
        </div>
    </div>

    <div *ngIf="isLoading.loadingNextStepDetails" class="small-margin-top" style="font-size: 20px; text-align: center">
        <i class="fal fa-spinner fa-spin"></i>
    </div>

    <div class="container" *ngIf="stepNumber == 0">
        <div class="row medium-margin-top d-flex justify-content-center">
            <div class="col-md-4">
                Select which type of object you would like to upload from Salesforce into Zint:
                <select [(ngModel)]="selectedSalesforceObject" class="custom-select" name="selectedSalesforceObject">
                    <ng-container *ngFor="let availableSalesforceObject of availableSalesforceObjects">
                        <option [ngValue]="availableSalesforceObject.name" [attr.selected]="availableSalesforceObject.name == selectedSalesforceObject ? 'selected' : null">{{availableSalesforceObject.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <previous-next-buttons [inputParams]="{'isPreviousEnabled': false, 'isNextEnabled': selectedSalesforceObject}" (childPreviousButtonTrigger)="emptyEventHandler()" (childNextButtonTrigger)="chooseSalesforceObjectType()"></previous-next-buttons>
    </div>

    <div class="container" *ngIf="stepNumber == 1">
        <div class="row medium-margin-top d-flex justify-content-center text-center">
            <div class="col-md-4">
                <p>Upload a CSV file containing rows of {{selectedSalesforceObject}}. Please ensure there is a header row in place.</p>
                <file-upload [properties]="{endpoint: '/salesforce_matcher_process_uploaded_csv_file/', responseProcessor: fileUploadComplete, allowedFileExtensions: ['csv'], allowMultipleFiles: false, componentInstance: self}"></file-upload>
            </div>
            <div class="col-md-12" *ngIf="dataPreview">
                <br>
                <hr>
                <p>Preview:</p>
            </div>
            <div class="col-md-12" style="overflow-x: scroll">
                <table class="table table-striped table-bordered table-sm">
                    <tbody>
                        <tr *ngFor="let row of dataPreview">
                            <td *ngFor="let cell of row">
                                {{cell}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <previous-next-buttons [inputParams]="{'isPreviousEnabled': true, 'isNextEnabled': this.columnHeadersAvailableToMatch && this.totalNumberOfRows}" (childPreviousButtonTrigger)="previousButtonClicked()" (childNextButtonTrigger)="nextButtonClicked()"></previous-next-buttons>
    </div>

    <div class="container" *ngIf="stepNumber == 2">
        <div class="row medium-margin-top d-flex justify-content-center">
            <div class="col-md-6 text-center">
                Please select which column in your CSV file corresponds to the unique identifier for Salesforce objects. This will be used to link the organisations in Zint with your records in Salesforce.
                <select [(ngModel)]="salesforceUniqueIdColumnName" class="custom-select" name="salesforceUniqueIdColumnName">
                    <ng-container *ngFor="let columnHeaderAvailableToMatch of columnHeadersAvailableToMatch">
                        <option [ngValue]="columnHeaderAvailableToMatch.CSVHeader" [attr.selected]="columnHeaderAvailableToMatch.CSVHeader == salesforceUniqueIdColumnName ? 'selected' : null">{{columnHeaderAvailableToMatch.CSVHeader}}</option>
                    </ng-container>
                </select>

            </div>
        </div>
        <previous-next-buttons [inputParams]="{'isPreviousEnabled': true, 'isNextEnabled': salesforceUniqueIdColumnName}" (childPreviousButtonTrigger)="previousButtonClicked()" (childNextButtonTrigger)="chooseSalesforceObjectUniqueIdColumnHeader()"></previous-next-buttons>
    </div>


    <div class="container" *ngIf="stepNumber == 3">
        <div class="row medium-margin-top d-flex justify-content-center text-center">
            <div class="col-md-8">
                <p>We have matched the following column headers in your CSV file with the corresponding Salesforce attributes.</p>
                <p>Please choose which of the following columns we should synchronise between Zint and Salesforce.</p>
                <p>This will create a new custom column searchable in Zint that will regularly pull in updates from your Salesforce, allowing you to search on your Salesforce data in the Zint platform.</p>
                <p>If we detect that you already have a custom column in Zint relating to this field in Salesforce, we will instead update that column.</p>
                <p>The initial data load will be derived from the data provided in this file, and ongoing changes will be synchronised automatically.</p>
            </div>

            <div class="col-md-12">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <td>CSV Header Name Identified</td>
                            <td>Corresponding Salesforce Label</td>
                            <td>Zint Column Type to Create</td>
                            <td>Synchronise This Column? (maximum 5)</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="4" *ngIf="!columnHeadersAvailableToSynchronise">No matching column headers found. Please ensure the column headers in your CSV file correspond to the name of the attribute in your Salesforce and not the label. Note that this means your column header should normally appear as e.g. "AccountName" (the Salesforce object attribute name) and not "Account Name" (the Salesforce object attribute label).</td>
                        </tr>
                        <tr *ngFor="let columnHeaderAvailableToSynchronise of columnHeadersAvailableToSynchronise">
                            <td>{{columnHeaderAvailableToSynchronise.CSVHeader}}</td>
                            <td>{{columnHeaderAvailableToSynchronise.SFLabel}}</td>
                            <td>{{columnHeaderAvailableToSynchronise.CustomColumnType}}</td>
                            <td>
                                <label class="expandable-label">
                                    <input type="checkbox"
                                       [attr.name]="'columnHeaderAvailableToSynchronise' + columnHeaderAvailableToSynchronise.isChecked"
                                       [ngModelOptions]="{standalone: true}"
                                       value="{{columnHeaderAvailableToSynchronise.isChecked}}"
                                       [(ngModel)]="columnHeaderAvailableToSynchronise.isChecked"/>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <previous-next-buttons [inputParams]="{'isPreviousEnabled': true, 'isNextEnabled': this.columnHeadersAvailableToMatch && this.totalNumberOfRows}" (childPreviousButtonTrigger)="previousButtonClicked()" (childNextButtonTrigger)="chooseColumnHeadersToSynchronise()"></previous-next-buttons>
    </div>


    <div class="container" *ngIf="stepNumber == 4">
        <div class="row medium-margin-top d-flex justify-content-center text-center">
            <div class="col-md-8">
                <p>Please choose which of the following columns we should match on.</p>
                <p>These columns will be triangulated against Zint data in an effort to identify the UK legal entity this record is associated with.</p>
            </div>

            <div class="col-md-12">
                <table class="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>
                            <td>CSV Header Name</td>
                            <td>Zint Attribute Name</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let columnHeaderAvailableToMatch of columnHeadersAvailableToMatch">
                            <td style="vertical-align: middle">
                                {{columnHeaderAvailableToMatch.CSVHeader}}
                            </td>
                            <td>
                                <select [(ngModel)]="columnHeaderAvailableToMatch.ZintAttribute" (change)="matchingColumnHeaderSelected($event.target.value)" class="custom-select" name="salesforceUniqueIdColumnName">
                                    <ng-container *ngFor="let zintAttributeColumnHeaderMatchableOn of zintAttributeColumnHeadersMatchableOn">
                                        <option [ngValue]="zintAttributeColumnHeaderMatchableOn" [attr.selected]="columnHeaderAvailableToMatch.ZintAttribute == zintAttributeColumnHeaderMatchableOn ? 'selected' : null">{{zintAttributeColumnHeaderMatchableOn}}</option>
                                    </ng-container>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <previous-next-buttons [inputParams]="{'isPreviousEnabled': true, 'isNextEnabled': this.countOfNonIgnoreSelectedToMatchOn > 0}" (childPreviousButtonTrigger)="previousButtonClicked()" (childNextButtonTrigger)="chooseColumnHeadersForMatching()"></previous-next-buttons>
    </div>


    <div class="container large-margin-bottom" *ngIf="stepNumber == 5">
        <div class="row medium-margin-top d-flex justify-content-center text-center">
            <div class="col-md-12">
                <h3>Summary:</h3>
                <p>We will import Salesforce <span class="badge">{{selectedSalesforceObject}}</span> objects from the provided CSV file.</p>
                <br>
                <hr>
                <br>

                <p>We will use the column <span class="badge">{{salesforceUniqueIdColumnName}}</span> in your CSV as the unique salesforce identifier for each record.</p>
                <br>
                <hr>
                <br>

                <p>We will attempt to match <span class="badge">{{totalNumberOfRows}}</span> rows in your CSV file using the following columns:</p>
                <table class="table table-bordered table-sm">
                    <tbody>
                        <tr *ngFor="let columnHeaderAvailableToMatch of columnHeadersAvailableToMatch">
                            <td *ngIf="columnHeaderAvailableToMatch.ZintAttribute != 'ignore'">
                                <p style="margin-bottom: 0px"><span class="badge">{{columnHeaderAvailableToMatch.CSVHeader}}</span> in CSV file will be matched on the <span class="badge">{{columnHeaderAvailableToMatch.ZintAttribute}}</span> attribute in organisations in Zint.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <hr>
                <br>

                <p>Once the matching process is complete, we will synchronise the following attributes from your Salesforce {{selectedSalesforceObject}} objects to make them searchable in Zint:</p>
                <table class="table table-bordered table-sm">
                    <tbody>
                        <tr *ngFor="let columnHeaderAvailableToSynchronise of columnHeadersAvailableToSynchronise">
                            <td *ngIf="columnHeaderAvailableToSynchronise.isChecked">
                                <p style="margin-bottom: 0px"><span class="badge">{{columnHeaderAvailableToSynchronise.CSVHeader}}</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <previous-next-buttons [inputParams]="{'isPreviousEnabled': true, 'isNextEnabled': true, 'overrideNextButtonText': 'Start Matching Process'}" (childPreviousButtonTrigger)="previousButtonClicked()" (childNextButtonTrigger)="startMatchingProcess()"></previous-next-buttons>
    </div>
</ng-container>



<ng-container *ngIf="!isLoading.loadingInitialData && userHasAccess && status === 'matching'">
    <div class="container" *ngIf="!rowsProcessed || !totalNumberOfRows">
        <div class="row medium-margin-top d-flex justify-content-center text-center">
            <div class="col-md-8">
                <p>Your matching process will begin shortly and results will be displayed below.</p>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="rowsProcessed && totalNumberOfRows">
        <div class="row medium-margin-top d-flex justify-content-center text-center">
            <div class="col-md-12">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuenow]="rowsProcessed" aria-valuemin="0" aria-valuemax="totalNumberOfRows" [style.width]="(rowsProcessed / totalNumberOfRows) * 100 + '%'" style="background-color: #000A36"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row medium-margin-top d-flex justify-content-center text-center">
            <div class="col-md-12">
                <button class="basic-button-base button-dark xsmall-margin-right" (click)="cancelMatchingProcess()"><i class="fal fa-times-circle" *ngIf="!isLoading.loadingCancellingProcess"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingCancellingProcess"></i>
                    Cancel Matching Process
                </button>
            </div>
        </div>
    </div>
</ng-container>


<div class="container">
    <div class="row small-margin-top">
        <div class="col-md-12">
            <div *ngIf="isLoading.loadingDataSync" style="font-size: 20px; text-align: center">
                <i class="fal fa-spinner fa-spin"></i>
            </div>
        </div>
    </div>
</div>
<div [ngStyle]="{'opacity': (isLoading.loadingDataSync) ? 0.1 : 1.0, 'pointer-events': (isLoading.loadingDataSync) ? 'none' : 'inherit'}">
    <company-matcher
            [inputParams]="{
                searchMatcherDetailsEndpoint: '/search_salesforce_matcher_details/',
                downloadMatcherDetailsForPageEndpoint: '/download_salesforce_matcher_records_for_page/',
                downloadMatcherDetailsForFilterEndpoint: '/download_salesforce_matcher_records_with_filter/'
            }"
            (ParentCancelMatchingProcess)="cancelMatchingProcess()"
            (ParentFunctionForAll)="createSalesforceRecordsForAll($event)"
            (ParentFunctionForSelectedOnPage)="createSalesforceSelectedOnPage($event)"
            *ngIf="!isLoading.loadingInitialData && userHasAccess && status === 'completed_matching'"
            #childCompanyMatcher></company-matcher>
</div>
