import { Injectable, NgZone } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  private visibilityChange$: Observable<boolean>;

  constructor(private ngZone: NgZone) {
    this.initVisibilityChange();
  }

  private initVisibilityChange() {
    this.visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(
      throttleTime(1000), // 1s delay before action
      map(() => {
        return !document.hidden;
      })
    );
  }

  getVisibilityChange(): Observable<boolean> {
    return this.ngZone.runOutsideAngular(() => this.visibilityChange$);
  }
}
