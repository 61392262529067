import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'custom-column-filter',
  templateUrl: './custom-column-filter.component.html',
  styleUrls: ['./custom-column-filter.component.css'],
})
export class CustomColumnFilterComponent implements OnInit {
  @Input()
  inputParams: any = {
    customColumns: Array,
    filterOnAttribute: String,
    equalsOrNot: Boolean,
    teamMembers: Array,
  };

  constructor() {}

  ngOnInit() {}

  updateBoxInput(event, customColumn) {
    let date = event.value;
    // Take away time zone offset to get time in utc
    customColumn.boxInput = new Date(
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    );
  }

  updateLowerBoxInput(event, customColumn) {
    let date = event.value;
    // Take away time zone offset to get time in utc
    customColumn.boxInputLower = new Date(
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    );
  }

  updateUpperBoxInput(event, customColumn) {
    let date = event.value;
    // Take away time zone offset to get time in utc
    customColumn.boxInputUpper = new Date(
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    );
  }

  customColumnDropDownChanged(customColumn): void {
    if (
      ['isSet', 'isNotSet', 'isMe', 'lockState'].indexOf(
        customColumn.dropdownInput
      ) >= 0
    ) {
      delete customColumn.boxInput;
      delete customColumn.boxInputLower;
      delete customColumn.boxInputUpper;
    }
    if (customColumn.dropdownInput == 'between') {
      delete customColumn.boxInput;
    }
    if (
      ['exact', 'greaterThan', 'lessThan'].indexOf(
        customColumn.dropdownInput
      ) >= 0
    ) {
      delete customColumn.boxInputLower;
      delete customColumn.boxInputUpper;
    }
    if (customColumn.dropdownInput == '') {
      delete customColumn.boxInput;
      delete customColumn.boxInputLower;
      delete customColumn.boxInputUpper;
      customColumn.options.map(option => (option.checked = false));
    }
    if (customColumn.dropdownInput == 'isNotSet') {
      delete customColumn.lockFilter;
    }
  }
}
