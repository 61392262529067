import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'div [right-image]',
  templateUrl: './right-image.component.html',
  styleUrls: ['./right-image.component.css'],
  host: { class: 'col-lg-4 float-right d-none d-lg-block' },
})
export class RightImageComponent implements OnInit {
  @Input() imageLink: any;

  @Input() htmlBelowImage: any;

  constructor() {}

  ngOnInit(): void {}
}
