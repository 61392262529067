import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

type SafePipeValueType = 'url' | 'html';
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value, type: SafePipeValueType = 'url') {
    if (type === 'html') {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
