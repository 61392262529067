import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { DataSharingService } from '../../../services/data-sharing.service';
import { AlertsService } from '../../../services/alerts.service';
declare var $: any;

@Component({
  selector: 'mark-as-read-modal',
  templateUrl: './mark-as-read-modal.component.html',
  styleUrls: ['./mark-as-read-modal.component.css'],
})
export class MarkAsReadModalComponent implements OnInit {
  companyIds: Array<any> = [];
  searchParams: HttpParams = null;
  numberOfCompaniesMarkedAsRead = null;

  constructor(
    private alertsService: AlertsService,
    private data: DataSharingService
  ) {}

  isLoading = this.alertsService.isLoading;

  ngOnInit() {
    var markAsUnreadSelf = this;
    $('#markAlertedCompaniesAsReadModal').on('show.bs.modal	', function (e) {
      markAsUnreadSelf.numberOfCompaniesMarkedAsRead = null;
    });
    $('#markAlertedCompaniesAsReadModal').on('hide.bs.modal', function (e) {
      markAsUnreadSelf.numberOfCompaniesMarkedAsRead = null;
    });
    this.data.currentSelectedCompanyIds.subscribe(
      companyIds => (this.companyIds = companyIds)
    );
    this.data.currentSelectedSearchParams.subscribe(
      searchParams => (this.searchParams = searchParams)
    );
  }

  processOutcomeOnBulkMarkingCompaniesAsRead(data): void {
    if (data.success) {
      this.numberOfCompaniesMarkedAsRead = data.number_of_companies_updated;
    }
  }

  markCompaniesAsRead(): void {
    this.alertsService
      .bulkMarkAlertCompaniesAsRead(this.companyIds, this.searchParams)
      .subscribe(output =>
        this.processOutcomeOnBulkMarkingCompaniesAsRead(output)
      );
  }
}
