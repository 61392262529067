import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ZintGrowService } from '../../services/zint-grow.service';

@Component({
  selector: 'manage-emails',
  templateUrl: './manage-emails.component.html',
  styleUrls: ['./manage-emails.component.css'],
})
export class ManageEmailsComponent implements OnInit {
  selectedImportanceThreshold: number;
  selectedFrequency: 'Daily' | 'Weekly';
  isUserSubscribed: boolean = true;
  existingImportance: number;
  existingFrequency: string;
  existingSubscribed: boolean;

  constructor(
    private zintGrowService: ZintGrowService,
    private _snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    document.title = 'Manage Emails';
    this.getEmailSettings();
  }

  getEmailSettings(): void {
    this.zintGrowService.getZintGrowEmailSettings().subscribe(data => {
      this.isUserSubscribed = data.subscribed;
      this.existingSubscribed = data.subscribed;

      if (!this.isUserSubscribed) {
        return;
      }

      this.selectedImportanceThreshold = data.importance_threshold;
      this.existingImportance = data.importance_threshold;

      this.selectedFrequency = data.email_frequency;
      this.existingFrequency = data.email_frequency;
    });
  }

  updateSettings() {
    if (!this.isUserSubscribed) {
      if (!this.selectedFrequency || !this.selectedImportanceThreshold) {
        this._snackbar.open(
          'Please select a frequency and importance threshold',
          'X',
          {
            duration: 4000,
          }
        );
        return;
      }
      this.isUserSubscribed = true;
    }

    if (
      this.existingSubscribed &&
      this.selectedFrequency === this.existingFrequency &&
      this.selectedImportanceThreshold === this.existingImportance
    ) {
      this._snackbar.open('No new data to update', 'X', {
        duration: 2000,
      });
      return;
    }

    const savedText = `Settings saved! You will only receive emails with importance score of ${this.selectedImportanceThreshold} or higher and ${this.selectedFrequency} from now on.`;

    this.zintGrowService
      .updateZintGrowEmailSettings(
        this.isUserSubscribed,
        this.selectedFrequency,
        this.selectedImportanceThreshold
      )
      .subscribe(data => {
        if (data && Object.keys(data).length) {
          this._snackbar.open(savedText, 'Dismiss', {
            duration: 5000,
          });
        }
      });
  }

  confirmUnsubscribe() {
    this.isUserSubscribed = false;

    this.zintGrowService
      .updateZintGrowEmailSettings(this.isUserSubscribed)
      .subscribe(data => {
        if (data && Object.keys(data).length) {
          this._snackbar.open('You have been unsubscribed.', 'Dismiss', {
            duration: 5000,
          });
        }
        this.getEmailSettings();
      });
  }
}
