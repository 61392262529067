<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
    <div class="col-md-8">
        <h5>
            <ng-container *ngIf="this.event_metadata.change_type === 'appointed'">
                <i class="fal fa-user-plus"></i>
            </ng-container>
            <ng-container *ngIf="this.event_metadata.change_type !== 'appointed'">
                <i class="fal fa-user-minus"></i>
            </ng-container>
            Officer {{event_metadata.change_type}} - {{event_metadata.name}}
        </h5>
        <hr>
        <div *ngFor="let notification of notifications">
            <div notification-search-term
                 [notification]="notification"
                 [searchTermLink]=""
                 [searchTermString]="(this.event_metadata.occupation !== undefined && event_metadata.occupation_contains !== undefined) ? event_metadata.occupation + ' matches your search term ' + event_metadata.occupation_contains: ''"
            ></div>
        </div>
        <div class="row small-margin-top">
            <div class="col-md-12">
                <a href="/profile/{{companyNumber}}" target="_blank">
                    <span class="badge badge-link">
                        <i class="fal fa-external-link"></i>
                        &nbsp;View company page
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1521790797524-b2497295b8a0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'"
    ></div>
</div>

