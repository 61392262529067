import { Component, OnInit } from '@angular/core';
import { SalesforceConfigService } from '../../../services/salesforce-config.service';

@Component({
  selector: 'salesforce-sync-details',
  templateUrl: './salesforce-sync-details.component.html',
  styleUrls: ['./salesforce-sync-details.component.css'],
})
export class SalesforceSyncDetailsComponent implements OnInit {
  salesforceSynchronisationDetails = [];

  constructor(private salesforceConfigService: SalesforceConfigService) {}

  ngOnInit(): void {
    this.salesforceConfigService
      .getSalesforceSynchronisationDetails()
      .subscribe(data => this.updateDetails(data));
  }

  updateDetails(data): void {
    this.salesforceSynchronisationDetails =
      data.salesforce_synchronisation_details;
  }
}
