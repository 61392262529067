import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class FileUploadService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  uploadFile(files, data, endpoint): Observable<any> {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(String(i), files[i]);
    }
    for (var key in data) {
      formData.append(key, data[key]);
    }
    return this.http
      .post(
        baseUrl + endpoint,
        formData,
        this.auth.helperGetVerifiedFilesDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('uploadFile', []))
      );
  }
}
