import { Component, OnInit } from '@angular/core';
import { SalesforceConfigService } from '../../../services/salesforce-config.service';

@Component({
  selector: 'salesforce-settings-page',
  templateUrl: './salesforce-settings-page.component.html',
  styleUrls: ['./salesforce-settings-page.component.css'],
})
export class SalesforceSettingsPageComponent implements OnInit {
  constructor(private salesforceConfigService: SalesforceConfigService) {}

  hasSalesforceSuperuserAccess = false;

  ngOnInit(): void {
    document.title = 'Salesforce';
    this.salesforceConfigService
      .getHasSalesforceSuperuserAccess()
      .subscribe(data => this.updateDetails(data));
  }

  updateDetails(data): void {
    this.hasSalesforceSuperuserAccess = data.has_superuser_access;
  }
}
