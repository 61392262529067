<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
  <div class="col-md-8">
    <h5>
      <ng-container>
        <i class="fal fa-users-crown"></i>
      </ng-container>
      Related PSC appointed - {{event_metadata.name}}
    </h5>
    <hr>
    <div>
      <div *ngFor="let notification of notifications">
        <p class="mb-0 psc-info"><strong>{{event_metadata.name}}</strong> has become a PSC at
          <strong>{{event_metadata.company_name}}</strong> <a class="text-reset ml-1" href="/profile/{{relatedCompanyNumber}}"><i
              class="fal fa-external-link"></i></a>.
        </p>
        <ng-container *ngIf="notification.event_metadata.occupation_contains; else elseBlock">
          <div notification-search-term [notification]="notification"
            [searchTerm]="notification.event_metadata.occupation_contains"
            [searchTermString]="' matches occupation ' + event_metadata.occupation + '.'"></div>
        </ng-container>

        <ng-template #elseBlock>
          <div notification-search-term [notification]="notification"
            [searchTermString]="'PSC ' + event_metadata.change_type + '.'"></div>
        </ng-template>
      </div>

    </div>
    <div class="row small-margin-top">
      <div class="col-md-12">
        <a href="/profile/{{companyNumber}}" target="_blank">
          <span class="badge badge-link">
            <i class="fal fa-external-link"></i>
            View company page
          </span>
        </a>
      </div>
    </div>
  </div>
  <div class="align-self-center" right-image
    [imageLink]="'https://images.unsplash.com/photo-1590172205940-5b6eedf7ec82?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=2970'"
    [htmlBelowImage]="htmlBelowImage"></div>
</div>