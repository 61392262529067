import { IConfigSlider } from './custom-range-slider';

// NOTE: The label is the main part of a variable name that would be sent to the backend. It should start from the capital later.
//  Final variables will be named "lower*" and "upper*"

export const heatScore: IConfigSlider[] = [
  {
    min: 0,
    max: 100,
    label: 'HeatScore',
    postfix: '%',
  },
];

export const ebitda: IConfigSlider[] = [
  {
    formName: 'Ebitda',
    min: -1000000,
    max: 100000000,
    label: 'Ebitda',
    prefix: '£',
  },
];

export const fxLossGain: IConfigSlider[] = [
  {
    formName: 'FX Gain or Loss',
    min: -100000000,
    max: 100000000,
    label: 'FXLossGain',
    prefix: '£',
  },
];

export const corporationTax: IConfigSlider[] = [
  {
    formName: 'Corporation Tax',
    min: 0,
    max: 100000000,
    label: 'CorporationTax',
    prefix: '£',
  },
];

export const taxCredit: IConfigSlider[] = [
  {
    formName: 'Tax Credit',
    min: 0,
    max: 100000000,
    label: 'TaxCredit',
    prefix: '£',
  },
];

export const wages: IConfigSlider[] = [
  {
    formName: 'Wages',
    min: 0,
    max: 100000000,
    label: 'Wages',
    prefix: '£',
  },
];
