import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToIterable',
})
export class MapToIterable implements PipeTransform {
  transform(dict: Record<string, any>) {
    let a = [];
    for (let key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({ key: key, val: dict[key] });
      }
    }
    return a;
  }
}
