import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'corporate-structure',
  templateUrl: './corporate-structure.component.html',
  styleUrls: ['./corporate-structure.component.css'],
})
export class CorporateStructureComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;
  @ViewChild('highlight') corporateStructureHighlight: ElementRef;

  constructor() {}

  ngOnInit(): void {}
}
