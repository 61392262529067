<div class="alert alert-danger custom-alert" *ngIf="messageService.message" role="alert">
    <span [innerHTML]="messageService.message"></span>&nbsp;
    <button class="basic-button-base button-dark" (click)="messageService.message=''">
        Dismiss
    </button>
</div>


<div class="alert alert-info custom-alert" *ngIf="messageService.infoMessage" role="alert">
    <span [innerHTML]="messageService.infoMessage"></span>&nbsp;
    <button class="basic-button-base button-dark" (click)="messageService.infoMessage=''">
        Dismiss
    </button>
</div>
