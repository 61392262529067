import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { publicMethods } from '../../globals';
declare var $: any;

@Component({
  selector: 'ai-accounts-summaries',
  templateUrl: './ai-accounts-summaries.component.html',
  styleUrls: ['./ai-accounts-summaries.component.css'],
})
export class AiAccountsSummariesComponent implements OnInit {
  @Input() aiAccountsSummaries: Record<string, Array<any>> = {};
  @Input() financials: Record<string, any>[] = [];
  @Input() isInModal: boolean = false;
  @Input() companyId: string = '';
  @Input() isOldView: boolean = false; //TODO: remove once new UI is live
  @Input() summariesYearAccount: number | string;

  summaryURL: string = '';
  reportYear: string = '';
  fallbackInfoText: string;
  canBeRendered: boolean = false;
  accountsType: string = '';
  totalNumberOfPages: number = null;
  pdfURL: string = '';
  fileName: string = '';

  constructor(
    private companyService: CompanyService,
    private pubMethod: publicMethods,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getLatestYearAccounts(this.financials);
  }

  ngAfterViewInit(): void {
    this.renderComponent();
    this.handleNonLatestSummariesYearAccounts();
    this.handleSummaryStates(this.summariesYearAccount);
    this.getReportYear();
    this.setPropertiesForLinks();
    this.cdr.detectChanges();
  }

  renderComponent(): void {
    // at least one yearAcc summaries needed to render anything
    if (this.pubMethod.isEmptyObjectOrArray(this.aiAccountsSummaries)) {
      this.canBeRendered = false;
    } else {
      this.canBeRendered = true;
    }
  }

  handleNonLatestSummariesYearAccounts(): void {
    /** For company page only not modal
     * if latest yearAcc doesn't have the aiSumms, replace it with the first one available.
     */
    if (this.isInModal) return;

    if (!this.aiAccountsSummaries[this.summariesYearAccount]) {
      const key = Object.keys(this.aiAccountsSummaries)[0];

      this.summariesYearAccount = key;
    }
  }

  handleSummaryStates(yearAccountID: string | number): void {
    const summaries = this.aiAccountsSummaries[yearAccountID];
    if (!summaries) {
      this.fallbackInfoText = 'No summaries have been generated for this year.';
      return;
    }

    const notEnoughInfo =
      'There is not enough information for this year account summaries.';

    const processingInfo =
      'We are currently processing the summary for this year. Please check back later.';

    const { summary_state } = summaries[0];
    if (summary_state.toLowerCase() === 'complete') {
      const anyInfoToShow = this.isAnyInsightsAvailable(summaries[0]);
      this.fallbackInfoText = anyInfoToShow ? null : notEnoughInfo;
    } else if (summary_state.toLowerCase() === 'skipped') {
      this.fallbackInfoText = notEnoughInfo;
    } else if (summary_state.toLowerCase() === 'processing') {
      this.fallbackInfoText = processingInfo;
    } else {
      this.fallbackInfoText = processingInfo;
    }
  }

  isAnyInsightsAvailable(insightsObj: Record<string, any>): boolean {
    // Turn insights properties into an array
    const insightsArray = [
      insightsObj.bullets,
      insightsObj.strategic_goals,
      insightsObj.business_model,
      insightsObj.pains_challenges,
    ];

    // Check if there is any available insight
    const hasAvailableInsights = insightsArray.some(
      insight => !this.pubMethod.isEmptyObjectOrArray(insight)
    );

    // Return the result
    return hasAvailableInsights;
  }

  setPropertiesForLinks(): void {
    if (this.aiAccountsSummaries[this.summariesYearAccount]) {
      this.accountsType =
        this.aiAccountsSummaries[this.summariesYearAccount][0]['accounts_type'];
      this.totalNumberOfPages =
        this.aiAccountsSummaries[this.summariesYearAccount][0][
          'number_of_pages'
        ];
      this.pdfURL =
        this.aiAccountsSummaries[this.summariesYearAccount][0]['pdf_url'];
      this.fileName =
        this.aiAccountsSummaries[this.summariesYearAccount][0]['file_name'];
    }
  }

  isAnySummariesForYearAccount(yearAcc: number | string): boolean {
    return this.aiAccountsSummaries.hasOwnProperty(yearAcc);
  }

  isFullSummariesAvailable(bullets: Record<string, any>): boolean {
    if (!bullets) return false;
    return Object.keys(bullets).length > 0;
  }

  getReportYear(): void {
    const filtered = this.financials.filter(
      yearAcc => yearAcc.id === Number(this.summariesYearAccount)
    )[0];
    this.reportYear = filtered?.made_up_to.slice(0, 4);
  }

  scrollAndSelectFinancials(): void {
    this.companyService.scrollToSelectorOnceExists('accounts-section');
    const fragment = `#fi-tab${this.summariesYearAccount}`;
    $(fragment).tab('show');
  }

  getLatestYearAccounts(financialsData: Record<string, any>[]): void {
    if (!this.summariesYearAccount) {
      const latestYearAccounts = financialsData.sort(
        (a, b) => a.made_up_to - b.made_up_to
      )[0];
      this.summariesYearAccount = latestYearAccounts?.id;
    }
  }
}
