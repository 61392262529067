<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h1><i class="fab fa-salesforce"></i> Salesforce Integration Settings (deprecated)</h1>
            <div class="alert alert-danger small-margin-top" role="alert">
                <p style="padding-bottom: 20px">
                    <i class="fal fa-exclamation-triangle"></i>
                    Please note that this is an old version of the Zint-Salesforce integration. It is no longer actively developed; in favour of the latest Zint-Salesforce integration v2.
                    The v2 integration includes real-time synchronisation, complex query building to prevent duplicate object creation and more.
                    Please reach out to your customer success manager for assistance with enabling the new migration.
                    <br>
                    <a class="basic-button-base button-dark float-right" href="/salesforce">Take me to the latest integration</a>
                </p>
            </div>
            <div *ngIf="isLoading.isLoadingCrmModule" style="font-size: 50px; text-align: center">
                <i class="fal fa-spinner fa-spin"></i>
            </div>
            <p *ngIf="!isLoading.isLoadingCrmModule && !isSalesforceAuthorised" class="medium-margin-top">
                You have not enabled the Salesforce integration for your account. Please navigate to the <a href="/salesforce">Salesforce Integration Page</a> to authorise.
            </p>
            <br><br><br>
        </div>
    </div>


    <ng-container *ngIf="isSalesforceAuthorised">

        <hr>

        <div class="row medium-margin-top medium-margin-bottom">
            <div class="col-md-12 small-margin-bottom">
                <h2>User Settings</h2>
                <p>Select which users in your team should have access to the Salesforce integration.</p>
            </div>
            <div class="col-md-6">
                 <ng-container *ngFor="let teamMember of teamMembers">
                    <label>
                        <input type="checkbox"
                           [attr.disabled]="salesforceAutomaticallyEnabledForAll ? 'disabled': null"
                           [(ngModel)]="teamMember['has_salesforce_access']"
                           (ngModelChange)="updateSalesforceUserAccess(teamMember['id'], teamMember['has_salesforce_access'])"/>
                        {{ teamMember.email }}
                    </label>
                    <br>
                </ng-container>
            </div>

            <div class="col-md-6">
                <label>
                    <input type="checkbox"
                       name="salesforceAutomaticallyEnabledForAll"
                       [(ngModel)]="salesforceAutomaticallyEnabledForAll"
                       (ngModelChange)="updateSalesforceEnabledForAll()"/>
                    Automatically enable the Salesforce integration for all users in my team.
                </label>
            </div>
            <div class="col-md-12 xsmall-margin-top">
                All the below settings affect every user within your team with the Salesforce integration enabled.
            </div>
        </div>


        <hr>


        <div class="row medium-margin-top medium-margin-bottom">
            <div class="col-md-9">
                <h2>Salesforce Record Type</h2>
            </div>

            <div class="col-md-3">
                <br class="d-md-none">
                <button class="basic-button-base button-dark float-right" style="width: 150px" data-toggle="modal" data-target="#verifyChangeSalesforceObjectModal">Save</button>
            </div>

            <div class="col-md-12 small-margin-bottom">
                <p>Choose the record in Salesforce you would like to export data to:</p>
            </div>

            <div class="col-md-6">
                <select [(ngModel)]="selectedSalesforceObject" class="custom-select" name="selectedSalesforceObject">
                    <option value="" selected></option>
                    <option *ngFor="let salesforceObjectOption of salesforceObjectOptions" [ngValue]="salesforceObjectOption">{{salesforceObjectOption}}</option>
                </select>
            </div>
        </div>


        <ng-container *ngIf="selectedSalesforceObject">


            <hr>


            <div class="row medium-margin-top medium-margin-bottom">
                <div class="col-md-9">
                    <h2>Unique Identifier:</h2>
                </div>
                <div class="col-md-3">
                    <button class="basic-button-base button-dark float-right" style="max-width: 100%; width: 150px;" (click)="saveUniqueIdentifierMapping()">Save</button>
                </div>
                <div class="col-md-12 small-margin-bottom">
                    <p>Select the unique identifier to map the organisation in Zint to your records in Salesforce. If Zint finds a matching record in Salesforce with the same value for this attribute, it will update that record; if not, a new record in Salesforce will be created.</p>
                </div>

                <div class="col-md-6">
                    Zint Attribute:
                    <select [(ngModel)]="zintAttributeUniqueIdentifier" class="custom-select" name="zintAttributeUniqueIdentifier">
                        <option value="" selected></option>
                        <ng-container *ngFor="let customColumn of customColumns">
                            <option *ngIf="customColumn.is_collaborative_column" [ngValue]="'custom_column__' + customColumn.id">{{ customColumn.name }}</option>
                        </ng-container>
                        <option *ngFor="let companyAttributeUniqueIdentifier of companyAttributeUniqueIdentifiers" [ngValue]="companyAttributeUniqueIdentifier">{{companyAttributeUniqueIdentifier}}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    Salesforce Attribute:
                    <select [(ngModel)]="salesforceAttributeUniqueIdentifier" class="custom-select" name="salesforceAttributeUniqueIdentifier">
                        <option value="" selected></option>
                        <option *ngFor="let salesforceTechnicalFieldNameAndFriendlyFieldName of salesforceObjectFieldNames | mapToIterable" [ngValue]="salesforceTechnicalFieldNameAndFriendlyFieldName.key">{{salesforceTechnicalFieldNameAndFriendlyFieldName.val}}</option>
                    </select>
                </div>
            </div>


            <hr>


            <div class="row medium-margin-top medium-margin-bottom">

                <div class="col-md-12 small-margin-bottom">
                    <h2>Salesforce Record Export Constants</h2>
                    <p>Choose one or more attributes within your Salesforce record which should be set to a constant value every time data is exported from Zint.</p>
                </div>

                <div class="col-md-12">
                    <table class="table table-bordered">
                        <ng-container *ngIf="salesforceAttributesToConstants">
                            <ng-container *ngFor="let salesforceAttributeToConstant of salesforceAttributesToConstants | keyvalue:keepOriginalOrder">
                                <tr>
                                    <td>"{{ salesforceAttributeToConstant.key }}"</td>
                                    <td>"{{ salesforceAttributeToConstant.value }}"</td>
                                    <td class="text-center">
                                        <button class="basic-button-base button-dark" style="min-width: 100%; width: 100px" (click)="deleteSalesforceAttributeConstantMapping(salesforceAttributeToConstant.key)">Delete</button>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <tr>
                            <td>
                                <select [(ngModel)]="salesforceAttributeToSaveAsConstant" class="custom-select">
                                    <option *ngFor="let salesforceTechnicalFieldNameAndFriendlyFieldName of salesforceObjectFieldNames | mapToIterable" [ngValue]="salesforceTechnicalFieldNameAndFriendlyFieldName.key">{{salesforceTechnicalFieldNameAndFriendlyFieldName.val}}</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" class="form-control" name="newColumnName" [(ngModel)]="salesforceConstantValue">
                            </td>
                            <td class="text-center">
                                <button class="basic-button-base button-dark" style="min-width: 100%; width: 100px" (click)="addSalesforceAttributeToConstantValue()">Add</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>


            <hr>


            <div class="row medium-margin-top medium-margin-bottom">
                <div class="col-md-9">
                    <h2>Zint to Salesforce Attribute Mappings</h2>
                </div>
                <div class="col-md-3">
                    <button class="basic-button-base button-dark float-right" style="max-width: 100%; width: 150px;" (click)="saveAttributeMappings()">Save</button>
                </div>
                <div class="col-md-12 small-margin-bottom">
                    <p>Select which attributes in Zint should be exported to the corresponding record in Salesforce.</p>
                    <p>Note that, if a matching record is identified in Salesforce and you choose to export the organisation from Zint to Salesforce, any attributes selected below will be overwritten in the Salesforce record.</p>
                </div>

                <div class="col-md-12 small-margin-bottom">
                    <h3>Custom Attributes:</h3>
                    <table class="table table-bordered">
                        <ng-container *ngFor="let customColumn of customColumns">
                            <ng-container *ngIf="customColumn.is_collaborative_column || customColumn.is_propensity">
                                <tr [ngClass]="{'row-column-selected': zintCustomColumnsToSalesforceAttributesMapping[customColumn.id] != 'ignore'}">
                                    <td>{{ customColumn.name }}</td>
                                    <td>
                                        <select [(ngModel)]="zintCustomColumnsToSalesforceAttributesMapping[customColumn.id]" class="custom-select" [ngClass]="{'transparent-select': zintCustomColumnsToSalesforceAttributesMapping[customColumn.id] == 'ignore'}" (change)="updateAttributeMappingOptions(customColumn.id, true)">
                                        <option value="ignore" selected>ignore</option>
                                        <option *ngFor="let salesforceTechnicalFieldNameAndFriendlyFieldName of salesforceObjectFieldNames | mapToIterable" [ngValue]="salesforceTechnicalFieldNameAndFriendlyFieldName.key">{{salesforceTechnicalFieldNameAndFriendlyFieldName.val}}</option>
                                    </select>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </table>

                    <h3 class="small-margin-top">Standard Attributes:</h3>
                    <table class="table table-bordered">
                        <ng-container *ngFor="let resultsHeader of resultsHeaders">
                            <tr [ngClass]="{'row-column-selected': zintAttributesToSalesforceAttributesMapping[resultsHeader] != 'ignore'}">
                                <td>{{ resultsHeader }}</td>
                                <td>
                                    <select [(ngModel)]="zintAttributesToSalesforceAttributesMapping[resultsHeader]" class="custom-select" [ngClass]="{'transparent-select': zintAttributesToSalesforceAttributesMapping[resultsHeader] == 'ignore'}" (change)="updateAttributeMappingOptions(resultsHeader, false)">
                                        <option value="ignore" selected>ignore</option>
                                        <option *ngFor="let salesforceTechnicalFieldNameAndFriendlyFieldName of salesforceObjectFieldNames | mapToIterable" [ngValue]="salesforceTechnicalFieldNameAndFriendlyFieldName.key">{{salesforceTechnicalFieldNameAndFriendlyFieldName.val}}</option>
                                    </select>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>


        </ng-container>


    </ng-container>


</div>





<div class="modal fade" id="verifyChangeSalesforceObjectModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>You are selecting a new Salesforce record type, therefore this will delete all the other Salesforce settings currently in place, such as unique identifier mappings, Zint to Salesforce export attribute mappings and export constants.</p>
                <p>This change will affect all users in your team with access to the Salesforce integration.</p>
                <p>Records that have previously been exported to Salesforce through this integration will remain unchanged in Salesforce.</p>
                <button
                        class="basic-button-base button-dark"
                        [attr.disabled]="isLoading.isLoadingSuperuserCrmSettings ? 'disabled': null"
                        (click)="updateSelectedSalesforceObjectOption()">
                    Confirm <ng-container *ngIf="isLoading.isLoadingSuperuserCrmSettings"><i class="fal fa-spinner fa-spin"></i></ng-container>
                </button>
            </div>
        </div>
    </div>
</div>
