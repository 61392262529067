import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appFixedOnHover]',
})
export class FixedOnHoverDirective {
  isHovered = false;
  @Input() topLimit: number = 80;
  @Input() fixedWidth: string = '800px';

  private originalPositionStyle: string;
  private originalTopStyle: string;
  private originalLeftStyle: string;
  private originalWidthStyle: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.originalPositionStyle = this.el.nativeElement.style.position;
    this.originalTopStyle = this.el.nativeElement.style.top;
    this.originalLeftStyle = this.el.nativeElement.style.left;
    this.originalWidthStyle = this.el.nativeElement.style.width;
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    if (rect.top > this.topLimit) {
      this.renderer.setStyle(this.el.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.el.nativeElement, 'width', this.fixedWidth);
      this.renderer.setStyle(this.el.nativeElement, 'top', `${rect.top}px`);
      this.renderer.setStyle(this.el.nativeElement, 'left', `${rect.left}px`);
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.2');
      this.renderer.addClass(this.el.nativeElement, 'no-hover');
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.renderer.setStyle(
      this.el.nativeElement,
      'position',
      this.originalPositionStyle
    );
    this.renderer.setStyle(this.el.nativeElement, 'top', this.originalTopStyle);
    this.renderer.setStyle(
      this.el.nativeElement,
      'left',
      this.originalLeftStyle
    );
    this.renderer.setStyle(
      this.el.nativeElement,
      'width',
      this.originalWidthStyle
    );
    this.renderer.removeStyle(this.el.nativeElement, 'opacity');
    this.renderer.removeClass(this.el.nativeElement, 'no-hover');
  }
}
