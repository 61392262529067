<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
    <div class="col-md-8">
        <h5>
            New Accounts Search Term Matches - Made up to {{event_metadata.made_up_to_string | date:'d MMM y'}}
        </h5>
        <hr>
        <div *ngFor="let notification of notifications">
            <div notification-search-term [notification]="notification"
            [searchTermHTML]="notification.event_metadata.preview"    
            [searchTermLink]="'/profile/' + companyNumber + '?accountsSearch=' + notification.event_metadata.search_term + '&accountsDate=' + notification.event_metadata.made_up_to_string"
            [searchTermString]="(notification.event_metadata.matching_pages[0] === 0 ?  ' found in accounts' : (' found on pages: ' + notification.event_metadata.matching_pages.join(', ')))">
            [searchTerm]="notification.event_metadata.search_term"
            </div>
        </div>
        <div class="row small-margin-top">
            <div class="col-md-12">
                <a href="{{event_metadata.accounts_url}}" target="_blank">
                    <span class="badge badge-link">
                        <i class="fal fa-external-link"></i>
                        View accounts
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1544377193-33dcf4d68fb5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2532&q=80'"
        [htmlBelowImage]="htmlBelowImage"></div>
</div>
