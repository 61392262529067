
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h3>Salesforce Synchronised Objects:</h3>
            <br>
            <br>
            <ng-container *ngFor="let salesforceSynchronisationDetail of salesforceSynchronisationDetails">
                <hr>
                <h5>{{salesforceSynchronisationDetail.salesforce_object.label}} objects from Salesforce are being synchronised:</h5>
                <p style="font-style: italic">Last synchronised: {{salesforceSynchronisationDetail.last_synchronised | date:'d MMM y h:mm' }}</p>
                <p>Salesforce fields being synchronised:</p>
                <table class="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Salesforce Field</th>
                            <th>Zint Custom Column</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let col of salesforceSynchronisationDetail.salesforce_synchronised_columns">
                            <td>
                                {{col.label}}
                            </td>
                            <td>
                                {{col.custom_column_name}}
                            </td>
                            <td>
                                <a class="basic-button-base button-dark btn-sm" href="/account"><i class="fal fa-edit"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </div>
</div>
