import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { CommunicationService } from './communication.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private cookieservice: CookieService,
    private communicationService: CommunicationService,
    private _router: Router
  ) {}

  helperGetEmptyGetParams(params) {
    return { params: params, headers: null, withCredentials: false };
  }

  helperGetVerifiedGetParams(params) {
    return {
      params: params,
      headers: this.getAuthHeaders(),
      withCredentials: true,
    };
  }

  helperGetVerifiedFilesDict() {
    return { headers: this.getAuthHeadersFileUpload(), withCredentials: true };
  }

  helperGetVerifiedPostDict() {
    return { headers: this.getAuthHeaders(), withCredentials: true };
  }

  helperGetVerifiedPatchDict() {
    const csrfToken = this.cookieservice.get('csrftoken');
    let requestHeaders = new HttpHeaders();

    if (csrfToken) {
      requestHeaders = requestHeaders
        .set('Content-Type', 'application/json')
        .set('X-CSRFToken', csrfToken);
    } else {
      this.pubMethods.showInfoMessageWithoutAutoDisappear(
        'App initialised. Please refresh the page.'
      );
    }
    return { headers: requestHeaders, withCredentials: true };
  }

  helperGetVerifiedDeleteDict(body) {
    return {
      headers: this.getAuthHeaders(),
      withCredentials: true,
      body: body,
    };
  }

  getCSRFFromServer(): Observable<any> {
    return this.http
      .get(baseUrl + '/get_csrf_token/', { withCredentials: true })
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('getCSRFFromServer', []))
      );
  }

  isUserAuthenticated(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/is_authenticated/',
        '',
        this.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('isUserAuthenticated', []))
      );
  }

  handleAuthenticationResponse(response, redirect, redirectUrl): void {
    if (response.success) {
      this.communicationService.sendUserAccountId(response.user_profile_id);
      this.communicationService.sendEmailMessage(response.email);
      this.communicationService.sendIsSuperUserMessage(
        response.is_org_superuser
      );
      this.communicationService.sendIsParentOrgSuperUserMessage(
        response.is_parent_org_superuser
      );
      if (redirect) {
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          this._router.navigateByUrl('/dashboard');
        }
      }
    }
  }

  logoutUser(): Observable<any> {
    return this.http
      .post(baseUrl + '/logout/', '', this.helperGetVerifiedPostDict())
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('Logout', []))
      );
  }

  getAuthHeadersFileUpload(): any {
    return new HttpHeaders({
      'X-CSRFToken': this.cookieservice.get('csrftoken'),
    });
  }

  getAuthHeaders(): any {
    const csrfToken = this.cookieservice.get('csrftoken');
    if (csrfToken) {
      return new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': csrfToken,
      });
    } else {
      // TODO: Instead of making the user refresh the page, we should guard the routes
      // that require a CSRF token with a function that ensures the CSRF cookie is set.
      this.pubMethods.showInfoMessageWithoutAutoDisappear(
        'App initialised. Please refresh the page.'
      );
      throw new Error('CSRF cookie is not set. Please refresh the page.');
    }
  }

  login(user): Observable<any> {
    let _params = new HttpParams()
      .set('email', user.email)
      .set('password', user.password);
    return this.http
      .post(baseUrl + '/login_user/', _params, this.helperGetVerifiedPostDict())
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('login', []))
      );
  }

  register(user, redirectUrl): Observable<any> {
    let _params = new HttpParams()
      .set('email', user.email)
      .set('password', user.password)
      .set('redirectUrl', redirectUrl);
    return this.http
      .post(
        baseUrl + '/register_user/',
        _params,
        this.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('register', []))
      );
  }

  verifyAccount(activationToken): Observable<any> {
    let _params = new HttpParams().set('token', activationToken);
    return this.http
      .post(
        baseUrl + '/confirm_account/',
        _params,
        this.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('passwordReset', []))
      );
  }

  passwordReset(user): Observable<any> {
    let _params = new HttpParams().set('email', user.email);
    return this.http
      .post(
        baseUrl + '/reset_password/',
        _params,
        this.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('passwordReset', []))
      );
  }

  verifyResetToken(resetToken): Observable<any> {
    let _params = new HttpParams().set('token', resetToken);
    return this.http
      .post(
        baseUrl + '/reset_password_confirm/',
        _params,
        this.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('verifyResetToken', []))
      );
  }

  requestNewActivationToken(): Observable<any> {
    let _params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/request_new_activation_token/',
        _params,
        this.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('requestNewActivationToken', []))
      );
  }

  choosePassword(token, user): Observable<any> {
    let _params = new HttpParams()
      .set('token', token)
      .set('password', user.password);
    return this.http
      .post(
        baseUrl + '/set_password/',
        _params,
        this.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('choosePassword', []))
      );
  }

  getPlanFeaturesEnabled(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/get_plan_features_enabled/',
        '',
        this.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('getPlanFeaturesEnabled', []))
      );
  }

  getZenDeskMessagingJWT(): Observable<any> {
    return this.http
      .post(baseUrl + '/chat_auth/', '', this.helperGetVerifiedPostDict())
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('getZenDeskMessagingJWT', []))
      );
  }
}
