import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class CrossSellService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingStartCrossSell: false,
  };

  testAllowed(): Observable<any> {
    this.isLoading['loadingStartCrossSell'] = true;
    return this.http
      .post(
        baseUrl + '/cross_sell_status/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingStartCrossSell'] = false)),
        catchError(
          this.pubMethods.handleError('testAllowed', [], this.isLoading)
        )
      );
  }

  submitCrossSellProcess(
    tagIds,
    newTagName,
    makeCrossSellTagCollaborative
  ): Observable<any> {
    this.isLoading['loadingStartCrossSell'] = true;
    let _params = new HttpParams()
      .set('tagIds', this.pubMethods.stringifyListForGetParams(tagIds))
      .set('newTagName', newTagName)
      .set('makeCrossSellTagCollaborative', makeCrossSellTagCollaborative);
    return this.http
      .post(
        baseUrl + '/submit_cross_sell_request/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingStartCrossSell'] = false)),
        catchError(
          this.pubMethods.handleError(
            'startCrossSellProcess',
            [],
            this.isLoading
          )
        )
      );
  }

  cancelCrossSellProcess(): Observable<any> {
    this.isLoading['loadingStartCrossSell'] = true;
    return this.http
      .post(
        baseUrl + '/cancel_cross_sell_process/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingStartCrossSell'] = false)),
        catchError(
          this.pubMethods.handleError(
            'cancelCrossSellProcess',
            [],
            this.isLoading
          )
        )
      );
  }
}
