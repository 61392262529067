import { Component, Input, OnInit } from '@angular/core';
import { publicMethods } from '../../../globals';
import { SalesforceConfigService } from '../../../services/salesforce-config.service';
declare var $: any;

@Component({
  selector: 'admin-config',
  templateUrl: './admin-config.component.html',
  styleUrls: ['./admin-config.component.css'],
})
export class AdminConfigComponent implements OnInit {
  useAdminCredentialsForReadQueries = false;

  exporterSettings: any;

  organisationUsers = [];
  organisationTeams = [];

  teamToDeleteFromExporterSettings: any;
  userToDeleteFromExporterSettings: any;

  constructor(
    private salesforceConfigService: SalesforceConfigService,
    private pubMethods: publicMethods
  ) {}

  isLoading = this.salesforceConfigService.isLoading;

  ngOnInit(): void {
    this.salesforceConfigService
      .getSalesforceConfigSettings()
      .subscribe(data => this.updateSalesforceConfigDetails(data));
  }

  updateSalesforceConfigDetails(data): void {
    this.organisationUsers = data.organisation_users;
    this.organisationTeams = data.organisation_teams;
    this.useAdminCredentialsForReadQueries =
      data.use_admin_credentials_for_read_queries;
    this.exporterSettings = data.exporter_settings;
  }

  changeOfUseAdminCredentialsForReadQueries(): void {
    this.salesforceConfigService
      .enableOrDisableUseAdminCredentialsForReadQueries(
        this.useAdminCredentialsForReadQueries
      )
      .subscribe(data => console.log('Changed'));
  }

  updateExporterPermissionsAndCloseModals(data): void {
    this.exporterSettings = data.exporter_settings;
    $('#confirmDeleteUserOnExporterSettingsModal').modal('hide');
    $('#confirmDeleteTeamOnExporterSettingsModal').modal('hide');
  }

  addUserToExporterSettings(userId) {
    this.salesforceConfigService
      .addUserToSalesforceExporter(userId)
      .subscribe(data => (this.exporterSettings = data.exporter_settings));
  }

  deleteUserFromSalesforceExporter() {
    this.salesforceConfigService
      .removeUserFromSalesforceExporter(
        this.userToDeleteFromExporterSettings.userId
      )
      .subscribe(data => this.updateExporterPermissionsAndCloseModals(data));
  }

  addTeamToExporterSettings(teamId) {
    this.salesforceConfigService
      .addTeamToSalesforceExporter(teamId)
      .subscribe(data => (this.exporterSettings = data.exporter_settings));
  }

  deleteTeamFromSalesforceExporter() {
    this.salesforceConfigService
      .removeTeamFromSalesforceExporter(
        this.teamToDeleteFromExporterSettings.id
      )
      .subscribe(data => this.updateExporterPermissionsAndCloseModals(data));
  }
}
