<div class="container-fluid grow-container">
    <section #filters class="row ml-1 ">
        <div class="col-12 mb-2">
            <a role="button" matTooltip="Configure Your Subscriptions" matTooltipPosition="before"
                class="basic-button-base button-dark float-right" href="/grow/subscriptions">Configure<i
                    class="fad fa-stream ml-1"></i></a>
        </div>
        <ng-container *ngIf="!isLoading.loadingNotifications">
            <div class="col row px-0" [ngClass]="{'fixed-top bg-light pb-2 pl-3': isFiltersDivAtTop}">
                <!-- first filter box -->
                <div class="col-xs-12 col-sm-12 col-lg-6 mt-1 mt-md-0 pr-sm-0">
                    <p class="mb-0">Filter by Company: </p>
                    <div class="input-group">
                        <input type="text" class="form-control"
                            [attr.disabled]="(isLoading.loadingCompanyNameContains ? 'disabled': null)"
                            [(ngModel)]="companyNameSearch" (keyup.enter)="searchForCompaniesWithNotifications()"
                            maxlength="160" data-toggle="dropdown" placeholder="Search for a company">
                        <div class="input-group-append">
                            <button class="btn basic-button-base button-dark" type="button"
                                (click)="searchForCompaniesWithNotifications()"
                                [attr.disabled]="(isLoading.loadingCompanyNameContains ? 'disabled': null)">
                                <ng-container *ngIf="!isLoading.loadingCompanyNameContains"><i
                                        class="fal fa-search"></i></ng-container>
                                <ng-container *ngIf="isLoading.loadingCompanyNameContains"><i
                                        class="fal fa-spinner fa-spin"></i></ng-container>
                            </button>
                        </div>

                        <div class="dropdown-menu" style="right: 0; left: unset">
                            <ng-container *ngFor="let shortlistedCompany of shortlistedCompaniesByName">
                                <div class="dropdown-item cursor-pointer"
                                    (click)="addCompanyToFilteredCompanyList(shortlistedCompany)">
                                    <table style="width: 100%;">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ shortlistedCompany.company_name }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!-- second filter box -->
                <div class="col-xs-12 col-sm-3 col-lg-6 mt-1 mt-md-0 pr-0">
                    <div>
                        <p class="mb-0">Filter by Event Type:</p>
                        <select *ngIf="eventSourceTypes" [(ngModel)]="selectedEventSourceType"
                            (change)="addSelectedEventSourceType()" class="custom-select">
                            <option selected="selected"></option>
                            <option *ngFor="let eventSourceType of eventSourceTypes | keyvalue"
                                [value]="eventSourceType.key" [selected]="">{{eventSourceType.value.friendly_name}}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- third filter box -->
                <div class="col-xs-12 col-sm-3 col-lg-4  mt-1 mt-md-0">
                    <div *ngIf="ruleSets && ruleSets.length > 1">
                        <p class="mb-0">Filter by Category:</p>
                        <select [(ngModel)]="selectedRuleSet" (change)="this.getUserRuleNotificationGroups()"
                            class="custom-select">
                            <option selected="selected"></option>
                            <option *ngFor="let ruleSet of ruleSets" [ngValue]="ruleSet" selected>
                                {{ ruleSet.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- fourth filter box -->
                <div class="col-xs-12 col-sm-3 col-lg-4  mt-1 mt-md-0">
                    <div *ngIf="orgUsers && orgUsers.length > 0">
                        <p class="mb-0">Filter by users:</p>
                        <select [(ngModel)]="eachSelectedMember" (change)="this.addSelectedMembers(eachSelectedMember)"
                            class="custom-select">
                            <option selected="selected"></option>
                            <option [ngValue]="'selectAll'">--Select All--</option>
                            <option *ngFor="let orgUser of orgUsers" [ngValue]="orgUser">
                                {{ orgUser.email }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- sortby box -->
                <div class="col-xs-12 col-sm-3 col-lg-4 mt-1 mt-md-0 pr-md-0">
                    <div *ngIf="notificationGroups?.length > 1">
                        <p class="mb-0 text-truncate">Sort by: <span
                                class="d-sm-none d-md-inline">{{extraNotificationsFilters.sortBy
                                }}</span></p>
                        <button class="custom-select px-1" type="button" data-toggle="dropdown" aria-expanded="false">
                            <div class="text-truncate" style="max-width: 80px;">
                                {{extraNotificationsFilters.sortDirection}}</div>
                        </button>
                        <div class="dropdown-menu">
                            <span class="pl-2">Importance</span>
                            <div class="dropdown-divider"></div>
                            <button class="dropdown-item"
                                (click)="sortNotificationsGroups('descending')">Descending</button>
                            <button class="dropdown-item"
                                (click)="sortNotificationsGroups('ascending')">Ascending</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </section>
    <section class="row ml-2 mr-1 py-1" [ngClass]="{'active-filters-top' : isFiltersDivAtTop}">
        <!-- TODO: for md or smaller viewports, we should use sidenav https://material.angular.io/components/sidenav/examples#sidenav-autosize -->

        <div class="col-12"
            *ngIf="(selectedCompanies && selectedCompanies.size > 0) || (selectedEventSourceTypes && selectedEventSourceTypes.size > 0) || selectedRuleSet || selectedMembersFilter.size">
            <p>Filtering By:</p>
            <ng-container *ngIf="eventSourceTypes">
                <ng-container *ngFor="let sourceType of selectedEventSourceTypes">
                    <span class="badge zg-badge-filter-component"
                        (click)="selectedEventSourceTypes.delete(sourceType); getUserRuleNotificationGroups()">
                        <i class="fal fa-times-circle"></i>

                        {{eventSourceTypes[sourceType].friendly_name}}
                    </span>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let selectedMember of selectedMembersFilter">
                <span class="badge zg-badge-filter-component" (click)="deleteSelectedMemberFilter(selectedMember)"><i
                        class="fal fa-times-circle"></i> {{selectedMember}}</span>
            </ng-container>
            <ng-container *ngIf="selectedCompanies.size || selectedRuleSet">
                <hr class="mr-3">
            </ng-container>
            <ng-container *ngFor="let selectedCompany of selectedCompanies">
                <span class="badge zg-badge-filter-component"
                    (click)="selectedCompanies.delete(selectedCompany); getUserRuleNotificationGroups()">
                    <i class="fal fa-times-circle"></i>
                    {{selectedCompany.company_name}}
                </span>
            </ng-container>
            <ng-container *ngIf="selectedRuleSet">
                <span class="badge zg-badge-filter-component"
                    (click)="this.selectedRuleSet = undefined; getUserRuleNotificationGroups()">
                    <i class="fal fa-times-circle"></i>
                    {{selectedRuleSet.name}}
                </span>
            </ng-container>
        </div>
    </section>

    <section class="row mt-4">
        <ng-container *ngIf="!isLoading.loadingNotifications">
            <div class="col-12 row mb-1 pr-0">
                <div class="col col-sm-5 pt-2">
                    <ng-container *ngIf="minNotificationGroupId || maxNotificationGroupId">
                        <span class="filter-info">A filter is applied limiting the notifications
                            you are seeing.</span>
                    </ng-container>
                </div>
                <div class="col col-sm-4 d-sm-flex justify-content-center align-items-baseline">

                    <span class="mr-2 text-nowrap">Show notifications</span>
                    <div class="btn-group" role="group">
                        <button type="button" class="btn-sm basic-button-base button-dark xsmall-margin-top"
                            [ngClass]="extraNotificationsFilters.showCompletedNotifications === null ? 'button-dark-selected' : null"
                            (click)="toggleShowHiddenNotifications('all')">All</button>
                        <button type="button" class="btn-sm basic-button-base button-dark xsmall-margin-top"
                            [ngClass]="extraNotificationsFilters.showCompletedNotifications === false ? 'button-dark-selected' : null"
                            (click)="toggleShowHiddenNotifications('uncompleted')">Unread</button>
                        <button type="button" class="btn-sm basic-button-base button-dark xsmall-margin-top"
                            [ngClass]="extraNotificationsFilters.showCompletedNotifications === true ? 'button-dark-selected' : null"
                            (click)="toggleShowHiddenNotifications('markedCompleted')">Read</button>
                    </div>
                </div>
                <div class="col col-sm-3">
                    <ng-container *ngIf="numberPages">
                        <ng-container *ngIf="pageNumber < numberPages">
                            <button class="btn-sm basic-button-base button-dark xsmall-margin-top float-right"
                                (click)="pageNumber = pageNumber + 1; getUserRuleNotificationGroups()">Next</button>
                        </ng-container>

                        <span class="float-right" style="margin-top: 12px; margin-right: 5px; margin-left: 5px;">Page
                            {{pageNumber}} of {{numberPages}}</span>
                        <ng-container *ngIf="pageNumber > 1">
                            <button class="btn-sm basic-button-base button-dark xsmall-margin-top float-right"
                                (click)="pageNumber = pageNumber - 1; getUserRuleNotificationGroups()">Previous</button>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>


        <div class="col-md-12 medium-margin-bottom">
            <ng-container *ngIf="isLoading.loadingNotifications">
                <div class="feed-item" *ngFor="let number of [0,1,2,3,4]">
                    <div class="row">
                        <div class="col-md-1 d-flex align-items-center justify-content-center zg-timeline-element">
                            <div class="zg-badge">
                                <div class="zg-badge-bell-container">
                                    <i class="fal fa-bell"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-11">
                            <div class="card small-margin-bottom">
                                <div class="card-body">
                                    <div class="zg-loading-header is-loading"></div>
                                    <div class="zg-loading-subheader is-loading"></div>
                                    <div class="zg-loading-p is-loading"></div>
                                    <div class="zg-loading-p is-loading"></div>
                                    <div class="zg-loading-p is-loading"></div>
                                    <div class="zg-loading-p is-loading"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="feed-item" *ngFor="let group of notificationGroups">
                <div class="row">
                    <div
                        class="col-xs-12 col-sm-4 col-md-3 col-lg-2 d-flex align-items-center justify-content-center zg-timeline-element">
                        <div class="zg-badge prevent-multi-line">
                            <div class="zg-badge-bell-container">
                                <i class="fal fa-bell"></i>
                            </div>
                            <span class="zg-badge-text">{{group.latest_timestamp | date:'d MMM y h:mm'}}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 xsmall-spacer d-xs-block d-sm-none full-width"></div>
                    <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10">
                        <div class="card small-margin-bottom">
                            <div class="card-body">
                                <event-source-type-component [isSuperuser]="isSuperuser"
                                    [group]="group"></event-source-type-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>