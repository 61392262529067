import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { DashboardService } from './dashboard.service';
import { CommunicationService } from './communication.service';
import { throwError } from 'rxjs';

@Injectable()
export class TaggingService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService,
    private dashboardService: DashboardService,
    private communicationService: CommunicationService
  ) {}

  isLoading = {
    loadingTags: false,
  };

  getUserTags(withCount): Observable<any> {
    let _params = new HttpParams().set('withCount', withCount);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/get_user_tags/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError('getUserTags', [], this.isLoading)
        )
      );
  }

  getUserCrossSellTags(withCount): Observable<any> {
    let _params = new HttpParams().set('withCount', withCount);
    this.isLoading['loadingCrossSellTags'] = true;
    return this.http
      .post(
        baseUrl + '/get_user_cross_sell_tags/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCrossSellTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'loadingCrossSellTags',
            [],
            this.isLoading
          )
        )
      );
  }

  createUserTag(tagName, teamMemberIdToCreateTagFor): Observable<any> {
    let _params = new HttpParams()
      .set('tagName', tagName)
      .set('teamMemberIdToCreateTagFor', teamMemberIdToCreateTagFor);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/create_user_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError('createUserTag', [], this.isLoading)
        )
      );
  }

  createOrganisationTag(
    tagName,
    viewableUserIds,
    editableUserIds
  ): Observable<any> {
    let _params = new HttpParams()
      .set('tagName', tagName)
      .set(
        'viewableBy',
        this.pubMethods.stringifyListForGetParams(viewableUserIds)
      )
      .set(
        'editableBy',
        this.pubMethods.stringifyListForGetParams(editableUserIds)
      );
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/create_organisation_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'createOrganisationTag',
            [],
            this.isLoading
          )
        )
      );
  }

  tagOrUntagCompanies(
    tagId: any,
    companyIds: any,
    searchParams: any,
    isTagging: boolean
  ): Observable<any> {
    let urls: Record<string, string> = {};
    if (isTagging) {
      urls = {
        ids: '/add_companies_to_tag_from_ids/',
        query: '/add_companies_to_tag_from_query/',
      };
    } else {
      urls = {
        ids: '/untag_companies_from_ids/',
        query: '/untag_companies_from_query/',
      };
    }
    if (companyIds.length) {
      let url = urls['ids'];
      this.isLoading['loadingTags'] = true;
      let _params = new HttpParams()
        .set('tagId', tagId)
        .set(
          'companyIds',
          this.pubMethods.stringifyListForGetParams(companyIds)
        );
      return this.http
        .post(baseUrl + url, _params, this.auth.helperGetVerifiedPostDict())
        .pipe(
          tap(_ => (this.isLoading['loadingTags'] = false)),
          catchError(error => {
            this.pubMethods.handleError(
              'tagOrUntagCompanies',
              [],
              this.isLoading
            );
            return throwError(error);
          })
        );
    } else if (searchParams) {
      let url = urls['query'];
      this.isLoading['loadingTags'] = true;
      let _params = searchParams.set('tagId', tagId);
      return this.http
        .post(baseUrl + url, _params, this.auth.helperGetVerifiedPostDict())
        .pipe(
          tap(_ => (this.isLoading['loadingTags'] = false)),
          catchError(error => {
            this.pubMethods.handleError(
              'tagOrUntagCompanies',
              [],
              this.isLoading
            );
            return throwError(error);
          })
        );
    }
  }

  deleteTagById(tagId): Observable<any> {
    this.isLoading['loadingTags'] = true;
    let _params = new HttpParams().set('tagId', tagId);
    return this.http
      .post(
        baseUrl + '/delete_tag_by_id/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError('deleteTagById', [], this.isLoading)
        )
      );
  }

  addViewableUserToTag(userId, tagId): Observable<any> {
    let _params = new HttpParams().set('userId', userId).set('tagId', tagId);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/add_viewable_user_to_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addViewableUserToTag',
            [],
            this.isLoading
          )
        )
      );
  }

  removeViewableUserFromTag(userId, tagId): Observable<any> {
    let _params = new HttpParams().set('userId', userId).set('tagId', tagId);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/remove_viewable_user_to_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'removeViewableUserFromTag',
            [],
            this.isLoading
          )
        )
      );
  }

  addViewableTeamToTag(teamId, tagId): Observable<any> {
    let _params = new HttpParams().set('teamId', teamId).set('tagId', tagId);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/add_viewable_team_to_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addViewableTeamToTag',
            [],
            this.isLoading
          )
        )
      );
  }

  removeViewableTeamFromTag(teamId, tagId): Observable<any> {
    let _params = new HttpParams().set('teamId', teamId).set('tagId', tagId);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/remove_viewable_team_from_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'removeViewableTeamFromTag',
            [],
            this.isLoading
          )
        )
      );
  }

  addEditableUserToTag(userId, tagId): Observable<any> {
    let _params = new HttpParams().set('userId', userId).set('tagId', tagId);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/add_editable_user_to_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addEditableUserToTag',
            [],
            this.isLoading
          )
        )
      );
  }

  removeEditableUserFromTag(userId, tagId): Observable<any> {
    let _params = new HttpParams().set('userId', userId).set('tagId', tagId);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/remove_editable_user_to_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'removeViewableUserFromTag',
            [],
            this.isLoading
          )
        )
      );
  }

  addEditableTeamToTag(teamId, tagId): Observable<any> {
    let _params = new HttpParams().set('teamId', teamId).set('tagId', tagId);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/add_editable_team_to_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addEditableTeamToTag',
            [],
            this.isLoading
          )
        )
      );
  }

  removeEditableTeamFromTag(teamId, tagId): Observable<any> {
    let _params = new HttpParams().set('teamId', teamId).set('tagId', tagId);
    this.isLoading['loadingTags'] = true;
    return this.http
      .post(
        baseUrl + '/remove_editable_team_from_tag/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTags'] = false)),
        catchError(
          this.pubMethods.handleError(
            'removeEditableTeamFromTag',
            [],
            this.isLoading
          )
        )
      );
  }
}
