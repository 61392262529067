import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'underscoreToUpper' })
export class UnderScoreToUpperCasePipe implements PipeTransform {
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  transform(inputString) {
    return this.toTitleCase(inputString.replace(/_/g, ' '));
  }
}
