import { Component, OnInit } from '@angular/core';
import { CRMQueryBuilderService } from '../../services/crm-query-builder.service';

@Component({
  selector: 'crm-query-builder',
  templateUrl: './crm-query-builder.component.html',
  styleUrls: ['./crm-query-builder.component.css'],
})
export class CrmQueryBuilderComponent implements OnInit {
  crmQueries: Array<any>;
  salesforceObjects: Array<any>;
  salesforceObjectFieldMapping: any = {};
  zintFields: Array<string>;
  logicalOperators: Array<string>;
  comparisonOperators: any = {};

  isLoading = this.crmQueryBuilderService.isLoading;

  selectedSalesforceObject: any = {};
  queryBuilderExpressions: Array<any> = [];

  constructor(private crmQueryBuilderService: CRMQueryBuilderService) {}

  ngOnInit(): void {
    this.listCRMQueries();
    this.listSalesforceObjects();
    this.getQueryBuilderConstants();
  }

  listCRMQueries(): void {
    this.crmQueryBuilderService
      .getCRMQueries()
      .subscribe(data => this.updateCRMQueries(data));
  }

  updateCRMQueries(data): void {
    // Construct human-friendly string of query.
    data.queries.forEach(query => {
      query.humanFriendlyString =
        this.crmQueryBuilderService.renderFriendlyString(query.query);
    });

    this.crmQueries = data.queries;
  }

  deleteQuery(id) {
    this.crmQueryBuilderService
      .deleteCRMQuery(id)
      .subscribe(data => this.updateCRMQueries(data));
  }

  listSalesforceObjects(): void {
    this.crmQueryBuilderService
      .getSalesforceObjects()
      .subscribe(data => this.updateSalesforceObjects(data));
  }

  syncSalesforceObjects(): void {
    this.crmQueryBuilderService
      .syncSalesforceObjects()
      .subscribe(data => this.updateSalesforceObjects(data));
  }

  updateSalesforceObjects(data): void {
    data.sobjects.forEach(sobject => {
      this.salesforceObjectFieldMapping[sobject.name] = sobject.fields;
    });

    this.salesforceObjects = data.sobjects;
    this.selectedSalesforceObject = data.sobjects[0];
  }

  getQueryBuilderConstants(): void {
    this.crmQueryBuilderService
      .getQueryBuilderConstants()
      .subscribe(data => this.updateQueryBuilderConstants(data));
  }

  updateQueryBuilderConstants(data): void {
    this.zintFields = data.zint_fields;
    this.logicalOperators = data.logical_operators;
    this.comparisonOperators = data.comparison_operators;
  }

  buildNewQuery(salesforceObject): void {
    this.queryBuilderExpressions.push({
      sObject: salesforceObject,
      sField: '',
      comparisonOperator: '',
      zintField: '',
      fixedValue: '',
      logicalOperator: '',
    });
  }

  saveNewQuery() {
    console.log(this.queryBuilderExpressions);
    this.crmQueryBuilderService
      .createCRMQuery(this.queryBuilderExpressions)
      .subscribe(data => {
        // This is called even if there is an upstream error, so check if data is defined.
        if (data) {
          this.updateCRMQueries(data);
          this.cancelNewQuery();
        }
      });
  }

  removeCondition(i) {
    this.queryBuilderExpressions.splice(i, 1);
  }

  cancelNewQuery(): void {
    this.queryBuilderExpressions = [];
    this.selectedSalesforceObject = this.salesforceObjects[0];
  }
}
