<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
    <div class="col-md-8">
        <h5>Hiring for {{event_metadata.job_title}} - {{event_metadata.published_date | date:'d MMM y'}}</h5>

        <div *ngFor="let notification of notifications" class="small-margin-top">
            <ng-container *ngIf="notification.event_metadata.job_title_contains">
                <div notification-search-term
                     [notification]="notification"
                     [searchTerm]="notification.event_metadata.job_title_contains"
                     [searchTermLink]=""
                     [searchTermString]="' contained in job advert title'"
                ></div>
            </ng-container>
            <ng-container *ngIf="notification.event_metadata.job_description_search_term">
                <div notification-search-term
                     [notification]="notification"
                     [searchTermHTML]="notification.event_metadata.preview"
                     [searchTermLink]=""
                     [searchTermString]="' contained in job description'"
                     [searchTerm]="notification.event_metadata.job_description_search_term"
                     ></div>
            </ng-container>
        </div>
        <div class="row small-margin-top">
            <div class="col-md-12">
                <a href="{{event_metadata.link}}" target="_blank" class="zint-muted-href">
                    <span class="badge badge-link">
                        <i class="fal fa-external-link"></i>
                        &nbsp;View job listing
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1487528278747-ba99ed528ebc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80'"
    ></div>
</div>
