import { Component, OnInit } from '@angular/core';
import { TerritoryService } from '../../services/territory.service';
import { publicMethods } from '../../globals';

@Component({
  selector: 'manage-territories',
  templateUrl: './manage-territories.component.html',
  styleUrls: ['./manage-territories.component.css'],
})
export class ManageTerritoriesComponent implements OnInit {
  constructor(
    private territoryService: TerritoryService,
    private pubMethods: publicMethods
  ) {}

  territories: any;
  isLoading = this.territoryService.isLoading;
  organisationUsersAndTeams: any;

  ngOnInit(): void {
    document.title = 'Territories';
    this.listTerritories();
    this.territoryService
      .getOrganisationUsersAndTeams()
      .subscribe(data => (this.organisationUsersAndTeams = data));
  }

  listTerritories(): void {
    this.territoryService.listTerritories(true).subscribe(data => {
      this.territories = data;
      for (let territory of this.territories) {
        territory['searchParams'] =
          this.pubMethods.changeSearchParamsToDisplayFormat(
            territory['params']
          );
      }
    });
  }

  updateTerritoryEnforcement(territory): void {
    this.territoryService.toggleTerritoryEnforcement(territory.id).subscribe(data => {
      territory.is_enforced = data.is_enforced
    });
  };

  deleteTerritory(territoryId): void {
    this.territoryService.deleteTerritory(territoryId).subscribe(_ => {
      this.listTerritories();
    });
  }

  addUserToTerritory(userId, territoryId) {
    this.territoryService
      .addUserToTerritory(userId, territoryId)
      .subscribe(_ => {
        this.listTerritories();
        this.pubMethods.showInfoMessage('Your territory was updated.');
      });
  }

  removeUserFromTerritory(userId, territoryId) {
    this.territoryService
      .removeUserFromTerritory(userId, territoryId)
      .subscribe(_ => {
        this.listTerritories();
        this.pubMethods.showInfoMessage('Your territory was updated.');
      });
  }

  addTeamToTerritory(teamId, territoryId) {
    this.territoryService
      .addTeamToTerritory(teamId, territoryId)
      .subscribe(_ => {
        this.listTerritories();
        this.pubMethods.showInfoMessage('Your territory was updated.');
      });
  }

  removeTeamFromTerritory(teamId, territoryId) {
    this.territoryService
      .removeTeamFromTerritory(teamId, territoryId)
      .subscribe(_ => {
        this.listTerritories();
        this.pubMethods.showInfoMessage('Your territory was updated.');
      });
  }
}
