<div class="container">
    <div class="row">

        <div class="col-md-12 small-margin-bottom">
            <h4>Find Cross-Sell Opportunities</h4>
            <p>The Zint Cross-Sell tool is able to identify opportunities that are already connected to companies you know.</p>
            <p>Select one or more tags, and every company with that tag will be analysed to find other organisations connected through related directorships, connected shareholdings, or associated corporate structures.</p>
            <hr>
        </div>

        <div class="col-md-12" *ngIf="!isAllowed && (percentComplete || percentComplete == 0)">
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuenow]="percentComplete" aria-valuemin="0" aria-valuemax="100" [style.width]="percentComplete + '%'" style="background-color: #000A36"></div>
            </div>
            <p *ngIf="percentComplete == 0">Your cross-sell analysis is in a queue and will run shortly.</p>
            <p *ngIf="percentComplete != 0">Your cross-sell analysis process is running and is {{ percentComplete | number  }}% complete.</p>
            <p>You can safely navigate away from this page and will be emailed when the process is complete.</p>
            <button class="basic-button-base button-dark small-margin-top" style="font-size: 23px; float: right" (click)="cancelCrossSellProcess()">Cancel Process</button>
        </div>

        <ng-container *ngIf="isAllowed">
            <div class="col-md-6">
                <span>Select one or more tags to run the cross-sell analysis on:<br></span>
                <div class="form-group select-table overflowing-table" style="padding: 15px">
                    <div *ngIf="isLoading.loadingTags" class="d-flex justify-content-center">
                        <zint-loader size="small"></zint-loader>
                    </div>
                    <span *ngIf="!isLoading.loadingTags && userTags && userTags.length == 0">You do not have any companies tagged</span>
                    <div *ngFor="let tag of userTags">
                        <div>
                            <label>
                                <input type="checkbox"
                                       [attr.name]="'tag' + tag.id"
                                       [ngModelOptions]="{standalone: true}"
                                       value="{{tag.id}}"
                                       [(ngModel)]="tag.checked"/>
                            </label>
                            <span>&nbsp;&nbsp;<i class="fal fa-tag"></i> {{tag.name}} - created {{tag.created | date:'d MMM y' }} - {{ tag.count }} companies</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <span>Name the cross-sell tag that will become available in your filters:</span>
                <input type="text" class="form-control" name="newCrossSellTagName" [(ngModel)]="newCrossSellTagName" placeholder="e.g. Cross Sell Accounts">
                <label *ngIf="isSuperUser">
                    <input type="checkbox"
                        name="makeCrossSellTagCollaborative"
                        value="false"
                        [(ngModel)]="makeCrossSellTagCollaborative"/>
                        Make cross-sell companies shareable with your team
                </label>
                <button class="basic-button-base button-dark small-margin-top" style="font-size: 23px; float: right" (click)="startCrossSellProcess()">Start Process</button>
            </div>
        </ng-container>

        <div class="col-md-12 small-margin-top small-margin-bottom">
            <hr>
            <tag-editing [inputParams]="{'inputTags': userCrossSellTags, 'tagTypeString': 'cross-sell', 'organisationUsers': organisationUsers}"></tag-editing>
        </div>

    </div>
</div>




<tagging></tagging>
