<div class="container">
    <div class="row">
        <div class="col-md-12 small-margin-bottom">
            <h2>Zint AI Pitch Builder</h2>
            <ng-container *ngIf="!hasAIPitchBuilderAccess">
                <p>You do not have access to this functionality. Please contact sales&#64;zint.io to access this on your account.</p>
            </ng-container>
            <ng-container *ngIf="hasAIPitchBuilderAccess && !hasAcceptedTermsAndConditions">
                <p>Your organisation has {{creditsAvailable}} credits available. If you would like to add more, please contact sales&#64;zint.io</p>
                <hr>
                <p>By continuing, you accept the additional additional <a href="#termsForChatGPTFeatures" data-toggle="modal" data-target="#termsForChatGPTFeatures">Terms and Conditions</a> of this additional functionality.</p>
                <openai-terms-modal></openai-terms-modal>
                <button class="basic-button-base button-dark" (click)="hasAcceptedTermsAndConditions=true">
                    Continue
                </button>
            </ng-container>

            <ng-container *ngIf="hasAIPitchBuilderAccess && hasAcceptedTermsAndConditions">
                <ng-container *ngIf="!isSuperUser">
                    <p>Please contact the superuser in your organisation to edit this configuration. You can still use the pitch building tool by navigating to any company page.</p>
                </ng-container>
                <ng-container>
                    <p>The following details will be used to customise the Zint AI Pitch Builder specifically for your organisation.</p>
                    <h4 class="medium-margin-top">Describe your company in 4 or 5 bullet points.</h4>
                    <textarea
                            [attr.disabled]="!isSuperUser ? 'disabled': null"
                            [(ngModel)]="aboutUs"
                            maxlength="1024"
                            style="min-height: 10em"
                            type="text"
                            placeholder="- Acme is a leading manufacturer of green packaging solutions.
- Acme empowers consumer goods brands to achieve their green goals without slowing down."
                            class="form-control text-custom-column-textarea"
                    >
                    </textarea>
                    <h4 class="small-margin-top">Input your tagline:</h4>
                    <input type="text" class="form-control" placeholder="Next Generation Green Packaging" [(ngModel)]="tagLine" [attr.disabled]="!isSuperUser ? 'disabled': null" maxlength="256">
                    <h4 class="small-margin-top">Categories of Customer You Help:</h4>
                    <table>
                        <tbody>
                            <ng-container *ngFor="let who of whoWeHelp">
                                <tr>
                                    <td><input type="text" class="form-control" [(ngModel)]="who.who_we_help" [attr.disabled]="!isSuperUser ? 'disabled': null" maxlength="256"></td>
                                    <td><input type="text" class="form-control" [(ngModel)]="who.how_we_help" [attr.disabled]="!isSuperUser ? 'disabled': null" maxlength="512"></td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td style="width: 20%">
                                    <input type="text" class="form-control" placeholder="Ecommerce Businesses" [(ngModel)]="newWhoWeHelpCategory" [attr.disabled]="!isSuperUser ? 'disabled': null" maxlength="256">
                                </td>
                                <td style="width: 70%">
                                    <input type="text" class="form-control" placeholder="Hit their green targets and provide a delightful experience for consumers" [(ngModel)]="newWhoWeHelpHowWeHelp" [attr.disabled]="!isSuperUser ? 'disabled': null" maxlength="512">
                                </td>
                                <td>
                                    <button class="basic-button-base button-dark" (click)="AddNewWhoWeHelp()" [attr.disabled]="!isSuperUser ? 'disabled': null">
                                        +
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h4 class="small-margin-top">Customers you can use as case studies:</h4>
                    <input type="text" class="form-control" placeholder="ABC Solutions, Chardford Toy Company, Fruit Online" [(ngModel)]="clients" [attr.disabled]="!isSuperUser ? 'disabled': null" maxlength="1024">

                    <button class="small-margin-top float-right basic-button-base button-dark" (click)="submitConfiguration()" [attr.disabled]="!isSuperUser ? 'disabled': null">
                        Submit Configuration
                    </button>

                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

