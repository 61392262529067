<ng-container *ngIf="(inputParams.customColumn.options && inputParams.customColumn.options.length > 0) || inputParams.customColumn.column_type == 'User'">
    <select
            *ngIf="inputParams.submitThroughButton"
            [(ngModel)]="requiredOption"
            class="custom-select tight-input"
            [ngClass]="{'small-margin-bottom': !inputParams.displayTight}">
        <option selected="selected"></option>
        <option value="">Clear Value</option>
        <ng-container *ngIf="inputParams.customColumn.column_type == 'User'">
            <option *ngFor="let teamMember of inputParams.teamMembers" [value]="teamMember.id" [selected]="teamMember.value && (teamMember.value == inputParams.customColumn.value || (inputParams.inputValue && teamMember.value == inputParams.inputValue))">{{teamMember.value}}</option>
        </ng-container>

        <ng-container *ngIf="inputParams.customColumn.column_type != 'User'">
            <option *ngFor="let columnOption of inputParams.customColumn.options" [value]="columnOption.id" [selected]="columnOption.value && (columnOption.value == inputParams.customColumn.value || (inputParams.inputValue && columnOption.value == inputParams.inputValue))">{{columnOption.value}}</option>
        </ng-container>
    </select>
    <select
            *ngIf="!inputParams.submitThroughButton"
            class="custom-select tight-input"
            (change)="saveOptionColumnDetailOption(inputParams.customColumn, $event.target.value)"
            [ngClass]="{'small-margin-bottom': !inputParams.displayTight, 'not-allowed-cursor': !inputParams.customColumn.can_edit}"
            [attr.disabled]="(!canEdit ? 'disabled': null)">
        <option selected="selected"></option>
        <option *ngFor="let columnOption of inputParams.customColumn.options" [value]="columnOption.id" [selected]="columnOption.value && (columnOption.value == inputParams.customColumn.value || (inputParams.inputValue && columnOption.value == inputParams.inputValue))">{{columnOption.value}}</option>
    </select>
     <button class="btn basic-button-base button-dark" *ngIf="inputParams.submitThroughButton && inputParams.companyIds.length > 0" (click)="saveOptionColumnDetailOptionFromButton(inputParams.customColumn, requiredOption)" [attr.disabled]="isLoading.loadingBulkEdit ? 'disabled': null">
        Submit <i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingBulkEdit"></i>
    </button>
    <button class="btn basic-button-base button-dark" *ngIf="inputParams.submitThroughButton && inputParams.companyIds.length == 0" (click)="saveCustomColumnDetailOptionFromButtonViaQuery(inputParams.customColumn, requiredOption)" [attr.disabled]="isLoadingFromQuery.loadingSettingCustomColumnsFromQuery ? 'disabled': null">
        Submit <i class="fal fa-spinner fa-spin" *ngIf="isLoadingFromQuery.loadingSettingCustomColumnsFromQuery"></i>
    </button>
</ng-container>

<ng-container *ngIf="(!inputParams.customColumn.options || inputParams.customColumn.options.length == 0) && inputParams.customColumn.column_type != 'User'">
    <div *ngIf="inputParams.customColumn.column_type == 'Text'" [ngClass]="{'small-margin-bottom': !inputParams.displayTight}">
        <input *ngIf="inputParams.submitThroughButton" type="text" [(ngModel)]="requiredValue" class="form-control tight-input">
        <textarea
                *ngIf="!inputParams.submitThroughButton"
                type="text"
                class="form-control tight-input text-custom-column-textarea"
                [value]="inputParams.customColumn.value === undefined ? inputParams.inputValue || '': inputParams.customColumn.value"
                (input)="customColumnChanged(inputParams.customColumn); updateHeightOfTextArea($event)"
                (focusout)="saveCustomColumnDetailFree(inputParams.customColumn, $event.srcElement.value)"
                (keyup.enter)="saveCustomColumnDetailFree(inputParams.customColumn, $event.srcElement.value)"
                [ngClass]="{'not-allowed-cursor': !inputParams.customColumn.can_edit}"
                [attr.disabled]="!inputParams.customColumn.can_edit && !inputParams.isSuperUser ? 'disabled': null">
        </textarea>
    </div>
    <div *ngIf="inputParams.customColumn.column_type == 'Number'" [ngClass]="{'small-margin-bottom': !inputParams.displayTight}">
        <input *ngIf="inputParams.submitThroughButton" type="number" [(ngModel)]="requiredValue" class="form-control tight-input">
        <input
                *ngIf="!inputParams.submitThroughButton"
                type="number"
                class="form-control tight-input"
                [ngClass]="{'xsmall-margin-bottom': !inputParams.displayTight, 'not-allowed-cursor': !inputParams.customColumn.can_edit}"
                [value]="inputParams.customColumn.value === undefined ? inputParams.inputValue || '': inputParams.customColumn.value"
                (input)="customColumnChanged(inputParams.customColumn)"
                (focusout)="saveCustomColumnDetailFree(inputParams.customColumn, $event.srcElement.value)"
                (keyup.enter)="saveCustomColumnDetailFree(inputParams.customColumn, $event.srcElement.value)"
                [attr.disabled]="!inputParams.customColumn.can_edit && !inputParams.isSuperUser ? 'disabled': null">
    </div>
    <div *ngIf="inputParams.customColumn.column_type == 'Date'">
        <ng-container *ngIf="(inputParams.inputValue || (inputParams.customColumn && inputParams.customColumn.value))">
            <i class="fal fa-times cursor-pointer five-px-l-r"
                (click)="requiredValue = ''; inputParams.customColumn.value = ''; saveCustomColumnDetailFree(inputParams.customColumn, '', true)"
            ></i>
        </ng-container>

        <span class="five-px-l-r" *ngIf="!inputParams.inputValue && !inputParams.customColumn.value">&nbsp;&nbsp;</span>
        <mat-form-field style="width: 90%;" [ngClass]="{'small-negative-margin-datepicker': !inputParams.displayTight, 'large-negative-margin-datepicker': inputParams.displayTight}">
            <input
                    *ngIf="inputParams.submitThroughButton"
                    matInput name="newColumnDateOption_{{inputParams.secondDatePickerIndex}}"
                    id="newColumnDateOption_{{inputParams.secondDatePickerIndex}}"
                    [(ngModel)]="requiredValue"
                    [matDatepicker]="datePickerIndex"
                    (dateChange)="requiredValue = getDateTimeInISOStringFromEvents($event); inputParams.customColumn.value = getDateTimeInISOStringFromEvents($event);"
                    [value]="inputParams.inputValue || inputParams.customColumn.value"
                    disabled>
            <input
                    *ngIf="!inputParams.submitThroughButton"
                    matInput
                    name="newColumnDateOption_{{inputParams.secondDatePickerIndex}}"
                    id="newColumnDateOption_{{inputParams.secondDatePickerIndex}}"
                    [matDatepicker]="datePickerIndex"
                    (dateChange)="inputParams.customColumn.value = getDateTimeInISOStringFromEvents($event); saveCustomColumnDetailFree(inputParams.customColumn, getDateTimeInISOStringFromEvents($event), true)"
                    [value]="inputParams.inputValue || inputParams.customColumn.value"
                    [readonly]="!inputParams.customColumn.can_edit && !inputParams.isSuperUser"
                    disabled>
            <mat-datepicker-toggle matSuffix [for]="datePickerIndex"></mat-datepicker-toggle>
            <mat-datepicker #datePickerIndex [disabled]="!inputParams.customColumn.can_edit"></mat-datepicker>
        </mat-form-field>
    </div>
    <button class="btn basic-button-base button-dark" *ngIf="inputParams.submitThroughButton && inputParams.companyIds.length > 0" (click)="saveCustomColumnDetailFreeFromButton(inputParams.customColumn, requiredValue)" [attr.disabled]="isLoading.loadingBulkEdit ? 'disabled': null">
        Submit <i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingBulkEdit"></i>
    </button>
    <button class="btn basic-button-base button-dark" *ngIf="inputParams.submitThroughButton && inputParams.companyIds.length == 0" (click)="saveCustomColumnDetailFreeFromButtonViaQuery(inputParams.customColumn, requiredValue)" [attr.disabled]="isLoadingFromQuery.loadingSettingCustomColumnsFromQuery ? 'disabled': null">
        Submit <i class="fal fa-spinner fa-spin" *ngIf="isLoadingFromQuery.loadingSettingCustomColumnsFromQuery"></i>
    </button>
</ng-container>
