import { Component, OnInit } from '@angular/core';
import { StripePurchaseService } from '../../services/stripe-purchase.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.css'],
})
export class PlanDetailsComponent implements OnInit {
  plan: any;
  subscriptions: Array<any>;

  constructor(
    private stripePurchaseService: StripePurchaseService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {}

  isLoading = this.stripePurchaseService.isLoading;

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const stripeCheckoutSuccess = queryParams['stripe_checkout_success'];
    // If we have just subscribed, put a delay in before we get the plan so we can wait some time for the webhook.
    let delayBeforeGettingStatus = 0;
    if (stripeCheckoutSuccess === '1') {
      this.messageService.showInfo(
        'Thanks for your purchase! Your new plan details are shown below.'
      );
      this.isLoading.loadingSubscriptions = true;
      delayBeforeGettingStatus = 5000;
    }
    setTimeout(
      () =>
        this.stripePurchaseService
          .getSubscriptions()
          .subscribe(data => this.showSubscriptions(data)),
      delayBeforeGettingStatus
    );
  }

  showSubscriptions(data): void {
    if (data.subscriptions) {
      this.subscriptions = data.subscriptions;
    }
    if (data.plan) {
      this.plan = data.plan;
    }
  }

  getStripeSubscriptionPortal(stripeCustomerId): void {
    this.stripePurchaseService
      .getStripeSubscriptionPortal(stripeCustomerId)
      .subscribe(data =>
        this.processStripeSubscriptionManagementResponse(data)
      );
  }

  processStripeSubscriptionManagementResponse(data): void {
    if (data.success && data.billing_portal_url) {
      window.location.replace(data.billing_portal_url);
    }
  }
}
