<div class="container">
    <div class="row">
        <div class="col-md-12">


            <div *ngIf="!hasAccessToSalesforce">
                <p>Your account does not have access to the Salesforce Integration. Please contact us if you would like to enable this functionality.</p>
            </div>
            <div *ngIf="hasAccessToSalesforce">
                <div *ngIf="!isSalesforceEnabled">
                    <div class="row">
                        <div class="col-md-12">
                            <p style="font-style: italic">Synchronise data between Zint and Salesforce</p>
                        </div>
                        <div class="col-md-6">
                            <p class="text-justify">By enabling this integration you agree to the <a href="https://drive.google.com/file/d/1i17pWAdTi-OV6P3Ul-MjfHcUdtPTjMKd/view?usp=sharing" target="_blank">terms and conditions</a> of the Zint-Salesforce Integration</p>
                            <div class="small-margin-bottom" style="height: 50px">
                                <div class="text-center vertically-centre-text">
                                    <label>
                                        <input type="checkbox"
                                           name="hasAgreedToSalesforceDisclaimer"
                                           value="false"
                                           [(ngModel)]="hasAgreedToSalesforceDisclaimer"/>
                                        I agree
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex justify-content-center text-center">
                            <button
                                *ngIf="!popupsAreBlocked"
                                style="position: absolute; padding-left: 30px; padding-right: 30px"
                                class="basic-button-base button-dark btn-lg vertically-centre-text"
                                [attr.disabled]="!hasAgreedToSalesforceDisclaimer ? 'disabled': null"
                                (click)="openOAuth2SalesforceWindowAndStartCheckingStatus()">
                                <span>Authorise</span> <i class="fab fa-salesforce salesforce-icon"></i>
                            </button>
                            <a
                                *ngIf="popupsAreBlocked && hasAgreedToSalesforceDisclaimer"
                                class="basic-button-base button-dark-selected btn-lg vertically-centre-text"
                                target="_blank"
                                style="cursor:pointer; padding-left: 30px; padding-right: 30px"
                                (click)="testSalesforceAccessGrantedStatus()"
                                href="{{ salesForceAuthorisationURL }}">
                                <span>Re-Authorise</span> <i class="fab fa-salesforce salesforce-icon"></i>
                            </a>
                            <ng-container>
                                <br>
                                <a
                                    *ngIf="showHavingProblemsMessage && !popupsAreBlocked && hasAgreedToSalesforceDisclaimer"
                                    class="vertically-centre-text"
                                    target="_blank"
                                    (click)="testSalesforceAccessGrantedStatus()"
                                    href="{{ salesForceAuthorisationURL }}">
                                    Having issues?
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div *ngIf="isSalesforceEnabled">
                    <i class="fal fa-check-circle"></i> Your Zint account is connected to Salesforce.<br><br>
                    <button *ngIf="isSalesforceEnabled" class="basic-button-base button-dark btn-small" (click)="revokeSalesforceAccess()"><i class="fal fa-minus-circle"></i> Revoke Access</button>
                </div>
            </div>


        </div>
    </div>
</div>
