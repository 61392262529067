<div class="container">

    <div class="row">

        <ul class="nav nav-tabs small-margin-top" role="tablist" id="nav-items-holder">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" data-target="#your-account-tab" role="tab">
                    Your Account
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" data-target="#custom-columns-tab" role="tab">
                    Custom Attributes
                </a>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button">Customise Formats</a>
                <div class="dropdown-menu">
                    <a class="dropdown-item" data-toggle="tab" data-target="#company-results-format-tab" role="tab" (click)="shouldLoadResultsView = true">Results View</a>
                    <a class="dropdown-item" data-toggle="tab" data-target="#csv-download-format-tab" role="tab">CSV Download Format</a>
                    <a class="dropdown-item" data-toggle="tab" data-target="#company-view-tab" role="tab">Company View Format</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" data-target="#saved-searches-tab" role="tab">
                    Saved Searches
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" data-target="#search-history-tab" role="tab">
                    Search History
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" data-target="#manage-tags-tab" role="tab">
                    Manage Tags
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" data-target="#crm-integrations-tab" role="tab">
                    CRM Integrations
                </a>
            </li>
        </ul>

        <div class="tab-content" style="width: 100%">

            <div class="tab-pane active" role="tabpanel" id="your-account-tab">
                <div class="row small-margin-top">
                    <div class="col-md-6">
                        <p><i class="fal fa-envelope"></i> Email: {{ emailOnAccount }}</p>
                    </div>
                    <div class="col-md-6">
                        <p><i class="fal fa-key"></i> Password: <a href="/reset_password">Reset Password</a></p>
                    </div>
                </div>

                <div class="row xsmall-margin-top xsmall-margin-bottom" *ngIf="organisationSuperuserEmail && organisationSuperuserEmail != emailOnAccount">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <span class="badge badge-info">
                                    <i class="fal fa-info-circle"></i>
                                    The administrator for your account is {{ organisationSuperuserEmail }}.
                                </span>&nbsp;
                                <span>Please contact them for password resets, access to collaborative columns and other account settings.</span>
                            </div>
                        </div>
                    </div>
                </div>
                

                
                <table class="table table-striped table-bordered">
                    <tbody>
                        <tr>
                            <td>Plan Name</td>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">{{ planName }}</div>
                                    <div class="col-md-6">
                                        <a href="/plan-details" class="basic-button-base button-dark btn-sm float-right" type="button" *ngIf="isParentOrgSuperUser">Manage Plan and Subscriptions</a>
                                    </div>
                                </div>
                            </td>         
                        </tr>
                        <tr>
                            <td>Period</td>
                            <td>{{ periodType }}</td>
                        </tr>
                        <tr>
                            <td>{{ periodType }} Download limit</td>
                            <td>{{ monthlyDownloadLimit }}</td>
                        </tr>
                        <tr>
                            <td>{{ periodType }} Downloads used this period</td>
                            <td>{{ downloadsThisPeriod }} / {{ monthlyDownloadLimit }}</td>
                        </tr>
                        @if (bulkDownloadLimit){
                        <tr>
                            <td>Bulk downloads also available to you</td>
                            <td>{{ bulkDownloadLimit }}</td>
                        </tr>
                        }
                        <tr>
                            <td>Tag limit</td>
                            <td>{{ tagLimit }}</td>
                        </tr>
                        <tr>
                            <td>Total Tags:</td>
                            <td>{{ currentTagCount }}</td>
                        </tr>
                        <tr>
                            <td>Period began</td>
                            <td>{{ periodStarted | date:'d MMM y'}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="row small-margin-top">
                    <div class="col-md-12"><hr class="w-100"></div>
                    <div class="col-md-12"><h5>Tracking Settings:</h5></div>
                    <div class="col-md-12">
                        <label>
                            <input type="checkbox"
                               name="checkboxHasTrackingEnabled"
                               value="true"
                               [(ngModel)]="checkboxHasTrackingEnabled"
                               (ngModelChange)="updateTrackingPreferences()"/>
                            Save my searches and viewed companies
                        </label>
                    </div>
                    <div class="col-md-12">
                        <label>
                            <input type="checkbox"
                               name="downloadDateTrackingEnabled"
                               value="true"
                               [(ngModel)]="downloadDateTrackingEnabled"
                               (ngModelChange)="updateDownloadDateTracking()"/>
                            Track "Last Download Date"
                        </label>
                    </div>
                </div>

            </div>


            <div class="tab-pane" role="tabpanel" id="custom-columns-tab">
                <div class="row small-margin-top">
                    <div class="col-md-4 xsmall-margin-bottom">
                        <h4>Custom Attributes:</h4>
                        <p>Create and manage your own editable data fields.</p>
                        <p>Each attribute that you add may then be displayed in the results format or included as a column when you download your data set.</p>
                    </div>

                    <div class="col-md-8 xsmall-margin-bottom">
                        <div class="card" style="padding: 15px">
                            <h5>Add a new attribute:</h5>
                            <label class="small-margin-top">Column Header:</label>
                            <input type="text" class="form-control" name="newColumnName" [(ngModel)]="newColumnName">
                            <br>
                            <label class="xsmall-margin-top">Field Type:</label>
                            <select [(ngModel)]="newColumnType" class="custom-select" name="newColumnType" (change)="updateOptionsWhenColumnTypeChanges()">
                                 <option *ngFor="let columnTypeOption of columnTypeOptions" [ngValue]="columnTypeOption">{{columnTypeOption}}</option>
                            </select>
                            <br>

                            <ng-container *ngIf="isSuperUser">
                                <label class="xsmall-margin-top">Column Privacy</label>
                                <select [(ngModel)]="columnPrivacy" class="custom-select" name="columnPrivacy">
                                    <option value="private" selected>Private to you</option>
                                    <option value="shared">Shared across your organisation</option>
                                </select>
                                <br>

                                <ng-container  *ngIf="isSuperUser && newColumnType == 'User' && columnPrivacy == 'shared'">
                                    <label class="small-margin-top">
                                        <input type="checkbox"
                                           name="newColumnLockable"
                                           value="true"
                                           [(ngModel)]="newColumnIsLockable"
                                        />
                                        Is Lockable?
                                    </label>
                                    <ng-container *ngIf="newColumnIsLockable">
                                        <label>Auto Unlock Day Delay:</label>
                                        <input type="number" class="form-control xsmall-margin-bottom" name="newColumnLockDays" onkeyup="if(value<0) value=7;" [(ngModel)]="newColumnLockDays">
                                    </ng-container>
                                    <ng-container *ngIf="newColumnIsLockable">
                                        <label>Maximum Number of Duplicate Entries:</label>
                                        <input type="number" class="form-control xsmall-margin-bottom" name="newColumnMaximumDuplicateEntries" onkeyup="if(value<0) value=100;" [(ngModel)]="newColumnMaximumDuplicateEntries">
                                    </ng-container>
                                </ng-container>

                            </ng-container>

                            <label *ngIf="newColumnType && newColumnType != 'User'">
                                <input type="checkbox"
                                       name="newColumnOptionsEnabled"
                                       value="true"
                                       (change)="mustBeFromOptionListClicked()"
                                       [(ngModel)]="newColumnOptionsEnabled"/>
                                    Define dropdown options
                            </label>
                            <div class="row">
                                <div class="col-md-8">
                                    <ng-container *ngIf="newColumnOptionsEnabled">
                                        <ng-container *ngIf="newColumnType == 'Text'">
                                            <ng-container *ngFor="let optionNumber of listOfOptionNumbers">
                                                <input type="text" class="form-control xsmall-margin-bottom" name="newColumnTextOption_{{optionNumber}}" [(ngModel)]="newColumnOptions[optionNumber-1]" [attr.placeholder]="'Option ' + optionNumber">
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="newColumnType == 'Number'">
                                            <ng-container *ngFor="let optionNumber of listOfOptionNumbers">
                                                <input type="number" class="form-control xsmall-margin-bottom" name="newColumnNumberOption_{{optionNumber}}" [(ngModel)]="newColumnOptions[optionNumber-1]" [attr.placeholder]="'Option ' + optionNumber">
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="newColumnType == 'Date'">
                                            <ng-container *ngFor="let optionNumber of listOfOptionNumbers; let datePickerIndex = index; let secondDatePickerIndex = index">
                                                <br>
                                                <mat-form-field>
                                                    <input matInput [(ngModel)]="newColumnOptions[secondDatePickerIndex]" name="newColumnDateOption_{{secondDatePickerIndex}}" placeholder="Option {{secondDatePickerIndex + 1}}" id="newColumnDateOption_{{secondDatePickerIndex}}" [matDatepicker]="datePickerIndex" disabled>
                                                    <mat-datepicker-toggle matSuffix [for]="datePickerIndex"></mat-datepicker-toggle>
                                                    <mat-datepicker #datePickerIndex disabled="false"></mat-datepicker>
                                                </mat-form-field>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="col-md-4">
                                    <ng-container *ngIf="newColumnOptionsEnabled">
                                        <button class="basic-button-base button-dark" (click)="removeNewOption()"><i class="fal fa-minus"></i></button>
                                        <button class="basic-button-base button-dark" (click)="addNewOption()"><i class="fal fa-plus"></i></button>
                                    </ng-container>
                                </div>
                            </div>
                            <br>
                            <button class="basic-button-base button-dark small-margin-top small-margin-bottom" (click)="createNewCustomColumn()">Create</button>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <h5 class="small-margin-top">Your Custom Attributes:</h5>
                        <table class="table table-striped table-bordered small-margin-top large-margin-bottom" style="table-layout:fixed;">
                            <thead style="text-align: center; vertical-align: middle">
                                <tr>
                                    <th class="align-middle">Attribute Name</th>
                                    <th style="width: 110px" class="align-middle">Attribute Type</th>
                                    <th class="align-middle">Options</th>
                                    <th style="width: 90px" class="align-middle">Private /<br>Shared</th>
                                    <th *ngIf="isSuperUser" class="align-middle">Viewable By</th>
                                    <th *ngIf="isSuperUser" class="align-middle">Editable By</th>
                                    <th style="width: 90px;" class="align-middle">You have view access</th>
                                    <th style="width: 90px;" class="align-middle">You have edit access</th>
                                    <th style="width: 70px;" class="align-middle">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="isLoading.loadingInitialData">
                                    <td [attr.colspan]="isSuperUser ? '9' : '7'">
                                        <div class="d-flex justify-content-center">
                                            <zint-loader></zint-loader>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="!isLoading.loadingInitialData && (!customColumns || customColumns.length == 0)">
                                    <td [attr.colspan]="isSuperUser ? '9' : '7'">You have not yet created any custom attributes.</td>
                                </tr>
                                <tr *ngFor="let customColumn of customColumns; let isOdd=odd;" class="custom-column-table">
                                    <ng-container *ngIf="!customColumn.is_propensity">
                                        <td style="position: relative">
                                            <span *ngIf="!customColumn.isEditingName">{{ customColumn.name }}</span>
                                            <ng-container *ngIf="(isSuperUser && customColumn.is_collaborative_column) || !customColumn.is_collaborative_column">
                                                <input *ngIf="customColumn.isEditingName" type="text" class="form-control" [value]="customColumn.name" (focusout)="editCustomColumnName(customColumn, $event.srcElement.value)" (keyup.enter)="editCustomColumnName(customColumn, $event.srcElement.value)">
                                                <i *ngIf="!customColumn.isEditingName" class="fal fa-pencil right-align-edit cursor-pointer" [ngClass]="{'custom-account-pencil-odd': isOdd, 'custom-account-pencil-even': !isOdd}" (click)="customColumn.isEditingName = true"></i>
                                            </ng-container>
                                        </td>
                                        <td>
                                            {{ customColumn.column_type }}
                                            <div *ngIf="customColumn.is_lockable" style="margin-top: 20px">
                                                <hr>
                                                <i class="fal fa-lock"></i> Lockable
                                                <br>
                                                <ng-container *ngIf="customColumn.lock_duration">
                                                    <hr>
                                                    Auto unlocks after {{customColumn.lock_duration}} day<ng-container *ngIf="customColumn.lock_duration > 1">s</ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="customColumn.maximum_duplicate_values">
                                                    <hr>
                                                    Maximum {{customColumn.maximum_duplicate_values}} duplicate entries
                                                </ng-container>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="customColumn.options && customColumn.options.length > 0">
                                                <table class="table table-sm sub-table">
                                                    <tr *ngFor="let option of customColumn.options">
                                                        <td>{{ option.value }}</td>
                                                    </tr>
                                                </table>
                                                <ng-container *ngIf="customColumn.isAddingOption">
                                                    <input *ngIf="customColumn.column_type == 'Text'" type="text" class="form-control xsmall-margin-bottom" name="optionToAddText" [(ngModel)]="customColumn.optionToAdd">
                                                    <input *ngIf="customColumn.column_type == 'Number'" type="number" class="form-control xsmall-margin-bottom" name="optionToAddNumber" [(ngModel)]="customColumn.optionToAdd">
                                                    <mat-form-field *ngIf="customColumn.column_type == 'Date'" style="width: 100%">
                                                        <input matInput [(ngModel)]="customColumn.optionToAdd" name="optionToAddDate" [matDatepicker]="datePickerNewOption" disabled>
                                                        <mat-datepicker-toggle matSuffix [for]="datePickerNewOption"></mat-datepicker-toggle>
                                                        <mat-datepicker #datePickerNewOption disabled="false"></mat-datepicker>
                                                    </mat-form-field>
                                                </ng-container>
                                                <div class="btn-group" role="group" *ngIf="customColumn.isAddingOption">
                                                    <button class="btn-sm basic-button-base button-dark" (click)="addNewOptionFromExistingColumn(customColumn)">Add</button>
                                                    <button class="btn-sm basic-button-base button-dark" (click)="customColumn.isAddingOption = false">Cancel</button>
                                                </div>
                                                <ng-container *ngIf="(isSuperUser && customColumn.is_collaborative_column) || !customColumn.is_collaborative_column">
                                                    <button *ngIf="!customColumn.isAddingOption" class="btn-sm basic-button-base button-dark full-width" (click)="customColumn.isAddingOption = true">
                                                        <i class="fal fa-plus"></i> New Option
                                                    </button>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="!customColumn.options || customColumn.options.length == 0">
                                                Any value allowed
                                            </ng-container>
                                        </td>
                                        <td class="privacy-status">
                                            <span *ngIf="!customColumn.is_collaborative_column" matTooltipPosition="above" matTooltip="Private to you only">
                                                <i class="fal fa-user-circle"></i>
                                            </span>
                                            <span *ngIf="customColumn.is_collaborative_column" matTooltipPosition="above" matTooltip="Shared across your team">
                                                <i class="fal fa-users"></i>
                                            </span>
                                        </td>

                                        <td *ngIf="isSuperUser">

                                            <ng-container *ngIf="customColumn.is_collaborative_column && customColumn.view_teams">
                                                <div class="card small-margin-bottom">
                                                    <div class="card-body">
                                                        <p>Teams:</p>
                                                        <table class="table table-sm table-within-table sub-table" [ngClass]="{'zero-margin-bottom': !customColumn.view_teams || customColumn.view_teams.length == 0}">
                                                            <tr *ngFor="let viewableTeam of customColumn.view_teams">
                                                                <td>{{ viewableTeam.name }}</td>
                                                                <td>
                                                                    <button class="basic-button-base button-dark" style="font-size: 10px" (click)="viewableTeamToDelete = viewableTeam; viewableTeamColumnToDeleteOn = customColumn;" data-toggle="modal" data-target="#confirmDeleteViewableTeamModal"><i class="fal fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <select class="custom-select" (change)="addNewViewableTeam($event.target.value, customColumn.id)" *ngIf="customColumn.isAddingViewableTeam">
                                                            <ng-container *ngIf="customColumn.view_teams">
                                                                <option selected></option>
                                                                <ng-container *ngFor="let organisationTeam of organisationTeams;">
                                                                    <option [attr.value]="organisationTeam.id" *ngIf="organisationTeam | filterLoopPipe:customColumn.view_teams:'id'">{{organisationTeam.name}}</option>
                                                                </ng-container>
                                                            </ng-container>
                                                        </select>
                                                        <ng-container>
                                                            <ng-container *ngIf="!customColumn.isAddingViewableTeam && organisationTeams && customColumn.view_teams && organisationTeams.length != customColumn.view_teams.length">
                                                                <button class="btn-sm basic-button-base button-dark full-width" (click)="customColumn.isAddingViewableTeam = true">
                                                                    <i class="fal fa-plus"></i> Add Team
                                                                </button>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="card">
                                                    <div class="card-body">
                                                        <p>Individuals:</p>
                                                        <table class="table table-sm table-within-table sub-table" [ngClass]="{'zero-margin-bottom': !customColumn.view_users || customColumn.view_users.length == 0}">
                                                            <tr *ngFor="let viewableUser of customColumn.view_users">
                                                                <td>{{ viewableUser.email }}</td>
                                                                <td *ngIf="customColumn.view_users && customColumn.view_users.length > 1">
                                                                    <button class="basic-button-base button-dark" style="font-size: 10px" (click)="viewableUserToDelete = viewableUser; viewableUserColumnToDeleteOn = customColumn;" data-toggle="modal" data-target="#confirmDeleteViewableUserModal"><i class="fal fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <ng-container *ngIf="customColumn.is_collaborative_column">
                                                            <select class="custom-select" (change)="addNewViewableUser($event.target.value, customColumn.id)" *ngIf="customColumn.isAddingViewableUser">
                                                                <ng-container *ngIf="customColumn.view_users">
                                                                    <option selected></option>
                                                                    <ng-container *ngFor="let organisationUser of organisationUsers;">
                                                                        <option [attr.value]="organisationUser.userId" *ngIf="organisationUser | filterLoopPipe:customColumn.view_users:'userId'">{{organisationUser.email}}</option>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </select>
                                                            <ng-container *ngIf="(isSuperUser && customColumn.is_collaborative_column) || !customColumn.is_collaborative_column">
                                                                <ng-container *ngIf="!customColumn.isAddingViewableUser && organisationUsers && customColumn.view_users && organisationUsers.length != customColumn.view_users.length">
                                                                    <button class="btn-sm basic-button-base button-dark full-width" (click)="customColumn.isAddingViewableUser = true">
                                                                        <i class="fal fa-plus"></i> Add User
                                                                    </button>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </td>


                                        <td *ngIf="isSuperUser">
                                            <ng-container *ngIf="customColumn.is_collaborative_column && customColumn.edit_teams">
                                                <div class="card small-margin-bottom">
                                                    <div class="card-body">
                                                        <p>Teams:</p>
                                                        <table class="table table-sm table-within-table sub-table" [ngClass]="{'zero-margin-bottom': !customColumn.edit_teams || customColumn.edit_teams.length == 0}">
                                                            <tr *ngFor="let editableTeam of customColumn.edit_teams">
                                                                <td>{{ editableTeam.name }}</td>
                                                                <td>
                                                                    <button class="basic-button-base button-dark" style="font-size: 10px" (click)="editableTeamToDelete = editableTeam; editableTeamColumnToDeleteOn = customColumn;" data-toggle="modal" data-target="#confirmDeleteEditableTeamModal"><i class="fal fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <select class="custom-select" (change)="addNewEditableTeam($event.target.value, customColumn.id)" *ngIf="customColumn.isAddingEditableTeam">
                                                            <ng-container *ngIf="customColumn.edit_teams">
                                                                <option selected></option>
                                                                <ng-container *ngFor="let organisationTeam of organisationTeams;">
                                                                    <option [attr.value]="organisationTeam.id" *ngIf="organisationTeam | filterLoopPipe:customColumn.edit_teams:'id'">{{organisationTeam.name}}</option>
                                                                </ng-container>
                                                            </ng-container>
                                                        </select>
                                                        <ng-container>
                                                            <ng-container *ngIf="!customColumn.isAddingEditableTeam && organisationTeams && customColumn.edit_teams && organisationTeams.length != customColumn.edit_teams.length">
                                                                <button class="btn-sm basic-button-base button-dark full-width" (click)="customColumn.isAddingEditableTeam = true">
                                                                    <i class="fal fa-plus"></i> Add Team
                                                                </button>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="card">
                                                    <div class="card-body">
                                                        <p>Individuals:</p>
                                                        <table class="table table-sm table-within-table sub-table" [ngClass]="{'zero-margin-bottom': !customColumn.edit_users || customColumn.edit_users.length == 0}">
                                                            <tr *ngFor="let editableUser of customColumn.edit_users">
                                                                <td>{{ editableUser.email }}</td>
                                                                <td *ngIf="customColumn.edit_users && customColumn.edit_users.length > 1">
                                                                    <button class="basic-button-base button-dark" style="font-size: 10px" (click)="editableUserToDelete = editableUser; editableUserColumnToDeleteOn = customColumn;" data-toggle="modal" data-target="#confirmDeleteEditableUserModal"><i class="fal fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <ng-container *ngIf="customColumn.is_collaborative_column">
                                                            <select class="custom-select" (change)="addNewEditableUser($event.target.value, customColumn.id)" *ngIf="customColumn.isAddingEditableUser">
                                                                <ng-container *ngIf="customColumn.edit_users">
                                                                    <option selected></option>
                                                                    <ng-container *ngFor="let organisationUser of organisationUsers;">
                                                                        <option [attr.value]="organisationUser.userId" *ngIf="organisationUser | filterLoopPipe:customColumn.edit_users:'userId'">{{organisationUser.email}}</option>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </select>
                                                            <ng-container *ngIf="(isSuperUser && customColumn.is_collaborative_column) || !customColumn.is_collaborative_column">
                                                                <ng-container *ngIf="!customColumn.isAddingEditableUser && organisationUsers && customColumn.edit_users && organisationUsers.length != customColumn.edit_users.length">
                                                                    <button class="btn-sm basic-button-base button-dark full-width" (click)="customColumn.isAddingEditableUser = true">
                                                                        <i class="fal fa-plus"></i> Add User
                                                                    </button>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </td>



                                        <td class="centre-cell">
                                            <ng-container *ngIf="!customColumn.is_collaborative_column">
                                                <i class="fal fa-check-circle"></i>
                                            </ng-container>
                                            <ng-container *ngIf="customColumn.is_collaborative_column">
                                                <i class="fal fa-check-circle" *ngIf="customColumn.can_view"></i>
                                                <i class="fal fa-times-circle" *ngIf="!customColumn.can_view"></i>
                                            </ng-container>
                                        </td>
                                        <td class="centre-cell">
                                            <ng-container *ngIf="!customColumn.is_collaborative_column">
                                                <i class="fal fa-check-circle"></i>
                                            </ng-container>
                                            <ng-container *ngIf="customColumn.is_collaborative_column">
                                                <i class="fal fa-check-circle" *ngIf="customColumn.can_edit"></i>
                                                <i class="fal fa-times-circle" *ngIf="!customColumn.can_edit"></i>
                                            </ng-container>
                                        </td>
                                        <td class="centre-cell">
                                            <button *ngIf="(isSuperUser && customColumn.is_collaborative_column && customColumn.view_teams) || !customColumn.is_collaborative_column" class="basic-button-base button-dark" (click)="customColumnIdToDelete = customColumn.id; customColumnNameToDelete = customColumn.name;" data-toggle="modal" data-target="#confirmDeleteCustomColumnModal">
                                                <i class="fal fa-trash"></i>
                                            </button>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>


            <div class="tab-pane" role="tabpanel" id="company-results-format-tab">
                <div class="row small-margin-top medium-margin-bottom">
                    <div class="col-md-12">
                        <results-format-editor *ngIf="shouldLoadResultsView" [inputParams]="{'isSuperUser': isSuperUser}"></results-format-editor>
                    </div>
                </div>
            </div>


            <div class="tab-pane" role="tabpanel" id="csv-download-format-tab">
                <div class="row small-margin-top medium-margin-bottom">
                    <div class="col-md-12">
                        <h4>CSV Download Format:</h4>
                        <p>This is the value and order of columns that will be present in the CSV file when you download from Zint.</p>
                        <p>Note that if you add "Trading Addresses" or "Key People" as a header, these will automatically be added to the end of the data and will appear on multiple rows, one for each Address or Key Person. If both are used there will be a row for every Address and Key Person combination. Company data will be duplicated across rows.</p>                        <button class="basic-button-base button-dark" style="width: 150px" (click)="saveDownloadFormat()">Save</button>
                    </div>
                    <div class="col-md-6 small-margin-top">
                        <h4>Current CSV Download Headers:</h4>                        
                        <div cdkDropList (cdkDropListDropped)="dragSort($event, 'userDownloadFormat')">
                            <div *ngFor="let csvCol of userDownloadFormat" class="cursor-move" cdkDrag>
                                <i class="fal fa-arrow-alt-circle-right cursor-pointer" (click)="addOrRemoveHeaderToDownload(csvCol, false)"></i>
                                {{ csvCol.name || csvCol  }} <span *ngIf="csvCol.is_propensity_model_components_column">Scoring Components</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 small-margin-top">
                        <h4>All CSV Download Header Options:</h4>
                        <div>
                            <div *ngFor="let unusedCSVDownloadHeader of unusedCSVDownloadHeaders">
                                <i class="fal fa-arrow-alt-circle-left cursor-pointer" (click)="addOrRemoveHeaderToDownload(unusedCSVDownloadHeader, true)"></i>
                                {{ unusedCSVDownloadHeader.name || unusedCSVDownloadHeader }} <span *ngIf="unusedCSVDownloadHeader.is_propensity_model_components_column">Scoring Components</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="tab-pane" role="tabpanel" id="company-view-tab">
                <div class="row small-margin-top medium-margin-bottom">
                    <div class="col-md-12">
                        <h4>Company View Format:</h4>
                        <p *ngIf="customColumns">Drag to change the order that your custom columns will be displayed on an individual company page.</p>
                        <button class="basic-button-base button-dark" style="width: 150px" (click)="saveCompanyViewCustomColumnOrder()" *ngIf="customColumns">Save</button>
                    </div>
                    <div class="col-md-6 small-margin-top">
                        <p *ngIf="!customColumns">There are no custom columns associated with your account.</p>                        
                        <div cdkDropList (cdkDropListDropped)="dragSort($event, 'customColumns')">
                            <ng-container *ngFor="let customColumn of customColumns">
                                <div class="cursor-move" cdkDrag>
                                    {{ customColumn.name }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>


            <div class="tab-pane" role="tabpanel" id="saved-searches-tab">
                <h5 class="small-margin-top">Your Searches:</h5>
                <p>Saved searches are now found on the dedicated <a href="saved-searches">Saved Searches</a> page.</p>
            </div>




            <div class="tab-pane" role="tabpanel" id="search-history-tab">
                <p class="small-margin-top text-center" *ngIf="!searchHistory || searchHistory.length == 0">You have no search history. To start saving your historical searches, please activate tracking under "Your Account".</p>
                <button *ngIf="searchHistory && searchHistory.length > 0" class="basic-button-base button-dark small-margin-top" (click)="deleteSearchHistory()"><i class="fal fa-trash"></i> Delete All Search History</button>
                <table *ngIf="searchHistory && searchHistory.length > 0" class="table table-striped table-bordered small-margin-top" style="table-layout:fixed;">
                    <thead>
                        <tr>
                            <td>Time</td>
                            <td style="width: 50%">Parameters</td>
                            <td>Re-run Search</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let search of searchHistory">
                            <td>{{ search.timestamp | date:'d MMM y h:mm' }}</td>
                            <td class="overflow-scroll"> <search-parameters [inputParams]="{'searchTags': search.outputForParamsDisplay, 'enableClearing': false}"></search-parameters> </td>
                            <td><a class="basic-button-base button-dark" [attr.href]="'/dashboard' + search.getParams" target="_blank"><i class="fal fa-play"></i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="tab-pane" role="tabpanel" id="manage-tags-tab">
                <tag-editing [inputParams]="{'inputTags': userTags, 'tagTypeString': '', 'organisationUsers': organisationUsers, 'organisationTeams': organisationTeams}"></tag-editing>
            </div>

            <div class="tab-pane" role="tabpanel" id="crm-integrations-tab">
                <div class="row small-margin-top medium-margin-bottom">
                    <div class="col-md-12">
                        <h4 class="small-medium-margin-bottom">Salesforce Integration</h4>
                        <p>Salesforce settings are now found on the <a href="/salesforce">dedicated Salesforce integration</a> page.</p>
                        <p>If you are looking for the unmaintained v1 of the Zint-Salesforce integration, you can find those settings <a href="/crm-integrations">here</a>.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<tagging></tagging>


<div class="modal fade" id="confirmDeleteCustomColumnModal" tabindex="-1" role="dialog" aria-labelledby="confirmDeleteCustomColumnModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteCustomColumnModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>This will permanently delete this custom attribute "{{ customColumnNameToDelete }}" from your account and all entries you previously populated will be removed. This action is irreversible.</p>
                <button class="basic-button-base button-dark" (click)="deleteCustomColumn()">Delete "{{ customColumnNameToDelete }}"</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="confirmDeleteViewableUserModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteViewableUserModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="viewableUserToDelete && viewableUserColumnToDeleteOn">
                <p>This will remove editing and viewing access for {{ viewableUserToDelete.email }} on the attribute {{ viewableUserColumnToDeleteOn.name }}.</p>
                <button class="basic-button-base button-dark" (click)="deleteViewingAccessForUserOnColumn()">Confirm</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmDeleteEditableUserModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteEditableUserModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="editableUserToDelete && editableUserColumnToDeleteOn">
                <p>This will remove editing access for {{ editableUserToDelete.email }} on the attribute {{ editableUserColumnToDeleteOn.name }}.</p>
                <p>Viewing access will remain in place for this user and must be deleted separately</p>
                <button class="basic-button-base button-dark" (click)="deleteEditingAccessForUserOnColumn()">Confirm</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="confirmDeleteViewableTeamModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteViewableTeamModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="viewableTeamToDelete && viewableTeamColumnToDeleteOn">
                <p>This will remove viewing access for {{ viewableTeamToDelete.name }} on the attribute {{ viewableTeamColumnToDeleteOn.name }}.</p>
                <button class="basic-button-base button-dark" (click)="deleteViewingAccessForTeamOnColumn()">Confirm</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="confirmDeleteEditableTeamModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteEditableTeamModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="editableTeamToDelete && editableTeamColumnToDeleteOn">
                <p>This will remove viewing access for {{ editableTeamToDelete.name }} on the attribute {{ editableTeamColumnToDeleteOn.name }}.</p>
                <button class="basic-button-base button-dark" (click)="deleteEditingAccessForTeamOnColumn()">Confirm</button>
            </div>
        </div>
    </div>
</div>



