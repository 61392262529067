import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class SalesforceConfigService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingInitialData: false,
    loadingConfigChange: false,
    loadingExportConfig: false,
    editingExportConfig: false,
    loadingSalesforceSynchronisationDetails: false,
    loadingDashboardData: false,
  };

  getHasSalesforceSuperuserAccess(): Observable<any> {
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/get_salesforce_authorisation_level/',
        '',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError('getHasSalesforceSuperuserAccess', [])
        )
      );
  }

  enableOrDisableUseAdminCredentialsForReadQueries(
    useAdminCredentialsForReadQueries
  ): Observable<any> {
    this.isLoading['loadingConfigChange'] = true;
    let params = new HttpParams().set(
      'useAdminCredentialsForReadQueries',
      useAdminCredentialsForReadQueries
    );
    return this.http
      .post(
        baseUrl + '/enable_or_disable_use_admin_credentials/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingConfigChange'] = false)),
        catchError(
          this.pubMethods.handleError(
            'enableOrDisableUseAdminCredentialsForReadQueries',
            []
          )
        )
      );
  }

  getSalesforceConfigSettings(): Observable<any> {
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/load_salesforce_admin_config/',
        '',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError('getSalesforceConfigSettings', [])
        )
      );
  }

  doRevokeSalesforceAccess(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/revoke_salesforce_credentials/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('doRevokeSalesforceAccess', []))
      );
  }

  getSalesforceOAuthDetails(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/get_oauth_details/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('getSalesforceOAuthDetails', []))
      );
  }

  addUserToSalesforceExporter(userId): Observable<any> {
    this.isLoading['loadingConfigChange'] = true;
    let params = new HttpParams().set('userId', userId);
    return this.http
      .post(
        baseUrl + '/add_user_to_salesforce_config/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingConfigChange'] = false)),
        catchError(
          this.pubMethods.handleError('addUserToSalesforceExporter', [])
        )
      );
  }

  removeUserFromSalesforceExporter(userId): Observable<any> {
    this.isLoading['loadingConfigChange'] = true;
    let params = new HttpParams().set('userId', userId);
    return this.http
      .post(
        baseUrl + '/remove_user_from_salesforce_config/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingConfigChange'] = false)),
        catchError(
          this.pubMethods.handleError('removeUserFromSalesforceExporter', [])
        )
      );
  }

  addTeamToSalesforceExporter(teamId): Observable<any> {
    this.isLoading['loadingConfigChange'] = true;
    let params = new HttpParams().set('teamId', teamId);
    return this.http
      .post(
        baseUrl + '/add_team_to_salesforce_config/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingConfigChange'] = false)),
        catchError(
          this.pubMethods.handleError('addTeamToSalesforceExporter', [])
        )
      );
  }

  removeTeamFromSalesforceExporter(teamId): Observable<any> {
    this.isLoading['loadingConfigChange'] = true;
    let params = new HttpParams().set('teamId', teamId);
    return this.http
      .post(
        baseUrl + '/remove_team_from_salesforce_config/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingConfigChange'] = false)),
        catchError(
          this.pubMethods.handleError('removeTeamFromSalesforceExporter', [])
        )
      );
  }

  getSalesforceExportConfigMappingOptions(): Observable<any> {
    this.isLoading['loadingExportConfig'] = true;
    let params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/get_salesforce_export_config_mapping_options/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingExportConfig'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getSalesforceExportConfigMappingOptions',
            []
          )
        )
      );
  }

  updateCurrentSalesforceExportConfig(): Observable<any> {
    this.isLoading['loadingExportConfig'] = true;
    let params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/get_salesforce_export_config_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingExportConfig'] = false)),
        catchError(
          this.pubMethods.handleError('updateCurrentSalesforceExportConfig', [])
        )
      );
  }

  chooseSalesforceObject(selectedSalesforceObjectId): Observable<any> {
    this.isLoading['editingExportConfig'] = true;
    let params = new HttpParams().set(
      'selectedSalesforceObjectId',
      selectedSalesforceObjectId
    );
    return this.http
      .post(
        baseUrl + '/choose_salesforce_export_config_sobject/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['editingExportConfig'] = false)),
        catchError(this.pubMethods.handleError('chooseSalesforceObject', []))
      );
  }

  addNewZintSalesforceMapping(
    zintAttribute,
    salesforceFieldIdSelected
  ): Observable<any> {
    this.isLoading['editingExportConfig'] = true;
    let params = new HttpParams()
      .set('zintAttribute', zintAttribute)
      .set('salesforceFieldIdSelected', salesforceFieldIdSelected);
    return this.http
      .post(
        baseUrl + '/add_new_zint_attribute_to_salesforce_field_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['editingExportConfig'] = false)),
        catchError(
          this.pubMethods.handleError('addNewZintSalesforceMapping', [])
        )
      );
  }

  addNewCustomColumnSalesforceMapping(
    customColumnId,
    salesforceFieldIdSelected
  ): Observable<any> {
    this.isLoading['editingExportConfig'] = true;
    let params = new HttpParams()
      .set('customColumnId', customColumnId)
      .set('salesforceFieldIdSelected', salesforceFieldIdSelected);
    return this.http
      .post(
        baseUrl + '/add_new_custom_column_to_salesforce_field_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['editingExportConfig'] = false)),
        catchError(
          this.pubMethods.handleError('addNewCustomColumnSalesforceMapping', [])
        )
      );
  }

  addNewStringConstantSalesforceMapping(
    constant,
    salesforceFieldIdSelected
  ): Observable<any> {
    this.isLoading['editingExportConfig'] = true;
    let params = new HttpParams()
      .set('constant', constant)
      .set('salesforceFieldIdSelected', salesforceFieldIdSelected);
    return this.http
      .post(
        baseUrl + '/add_new_string_constant_to_salesforce_field_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['editingExportConfig'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addNewStringConstantSalesforceMapping',
            []
          )
        )
      );
  }

  addNewPropensityModelComponentsSalesforceMapping(
    propensityModelId,
    salesforceFieldIdSelected
  ): Observable<any> {
    this.isLoading['editingExportConfig'] = true;
    let params = new HttpParams()
      .set('propensityModelId', propensityModelId)
      .set('salesforceFieldIdSelected', salesforceFieldIdSelected);
    return this.http
      .post(
        baseUrl +
          '/add_new_propensity_model_components_to_salesforce_field_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['editingExportConfig'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addNewStringConstantSalesforceMapping',
            []
          )
        )
      );
  }

  deleteAttributeExportMapping(id): Observable<any> {
    this.isLoading['editingExportConfig'] = true;
    let params = new HttpParams().set('mapping_id', id);
    return this.http
      .post(
        baseUrl + '/delete_salesforce_field_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['editingExportConfig'] = false)),
        catchError(
          this.pubMethods.handleError('deleteAttributeExportMapping', [])
        )
      );
  }

  updateAttributeExportMappingOverwriteStatus(id, overwrite): Observable<any> {
    this.isLoading['editingExportConfig'] = true;
    let params = new HttpParams()
      .set('mapping_id', id)
      .set('overwrite', overwrite);
    return this.http
      .post(
        baseUrl + '/choose_salesforce_field_mapping_overwrite_status/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['editingExportConfig'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updateAttributeExportMappingOverwriteStatus',
            []
          )
        )
      );
  }

  getSalesforceSynchronisationDetails(): Observable<any> {
    this.isLoading['loadingSalesforceSynchronisationDetails'] = true;
    let params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/get_existing_salesforce_synchronisation_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ =>
            (this.isLoading['loadingSalesforceSynchronisationDetails'] = false)
        ),
        catchError(
          this.pubMethods.handleError('getSalesforceSynchronisationDetails', [])
        )
      );
  }

  getSalesforceObjectsForDashboard(): Observable<any> {
    this.isLoading['loadingDashboardData'] = true;
    let params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/get_dashboard_salesforce_objects/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingDashboardData'] = false)),
        catchError(
          this.pubMethods.handleError('getSalesforceObjectsForDashboard', [])
        )
      );
  }
}
