<div class="modal fade" id="salesforceModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="salesforceModalLabel">Export to Salesforce</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">


                <div class="alert alert-danger" *ngIf="generalErrors && generalErrors.length > 0">
                    <h5><i class="fal fa-exclamation-triangle"></i> The following general errors were raised:</h5>
                    <ul>
                        <li *ngFor="let generalError of generalErrors" class="font-italic">{{ generalError }}</li>
                    </ul>
                </div>

                <ng-container *ngIf="!preCheckSalesforceMatches || preCheckSalesforceMatches.length == 0">
                    <div class="small-margin-bottom">
                        <div *ngIf="(!companyIds && inputParams.maximumCompanies) || (companyIds.length == 0 && inputParams.maximumCompanies)">
                            Please select a minimum of 1 and a maximum of {{ inputParams.maximumCompanies }} organisations to export at a time.
                        </div>
                        <div *ngIf="companyIds && companyIds.length > 1">
                            Export the selected companies to Salesforce.
                        </div>
                        <div *ngIf="companyIds && companyIds.length == 1">
                            Export this company to Salesforce.
                        </div>
                    </div>
                    <button
                        *ngIf="companyIds && companyIds.length >= 1"
                        class="basic-button-base button-dark btn vertically-centre-text full-width"
                        [attr.disabled]="isLoading.isLoadingSalesforceExport ? 'disabled': null"
                        (click)="exportCompaniesToSalesforcePreCheck()">
                        <span>Validate Data&nbsp;</span>
                        <ng-container *ngIf="isLoading.isLoadingSalesforceExport">
                            <i class="fal fa-spinner fa-spin"></i>
                        </ng-container>
                        <ng-container *ngIf="!isLoading.isLoadingSalesforceExport">
                            <i class="fab fa-salesforce salesforce-icon"></i>
                        </ng-container>
                    </button>
                </ng-container>



                <div *ngIf="preCheckSalesforceMatches && preCheckSalesforceMatches.length > 0">
                    <p>We found the following matches in your Salesforce. Please confirm the details below:</p>
                    <div class="row" *ngFor="let preCheckSalesforceMatch of preCheckSalesforceMatches">
                        <div class="col-md-6">
                            <h4>{{ preCheckSalesforceMatch.name }}:</h4>
                            <div *ngIf="preCheckSalesforceMatch.matches && preCheckSalesforceMatch.matches.length > 0">
                                <ng-container *ngFor="let salesforceEntry of preCheckSalesforceMatch.matches; let first = first">
                                    <label class="highlighted-label">
                                        <input type="radio"
                                               [value]="salesforceEntry.salesforce_id"
                                               [ngClass]="{'first-radio-option': first}"
                                               [(ngModel)]="companyIdsToSalesforceIds[preCheckSalesforceMatch.id]"
                                        >
                                        {{ salesforceEntry.salesforce_name }}
                                    </label><br>
                                </ng-container>
                            </div>
                            <div *ngIf="!preCheckSalesforceMatch.matches || preCheckSalesforceMatch.matches.length == 0">
                                <label>
                                    <input type="checkbox"
                                           class="new-record-checkbox"
                                           [(ngModel)]="companyIdsToSalesforceIds[preCheckSalesforceMatch.id]"/>
                                    Create a new record in Salesforce
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="companyErrors && companyErrors[preCheckSalesforceMatch.id]">
                            <div class="alert alert-danger">
                                <p class="font-italic"><i class="fal fa-exclamation-triangle"></i> {{ companyErrors[preCheckSalesforceMatch.id] }}</p>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="recordsCreated && recordsCreated[preCheckSalesforceMatch.id]">
                            <div class="alert alert-info">
                                <a target="_blank" [attr.href]="recordsCreated[preCheckSalesforceMatch.id].url"><i class="fal fa-check-square"></i> Record Created</a>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="recordsUpdated && recordsUpdated[preCheckSalesforceMatch.id]">
                            <div class="alert alert-info">
                                <a target="_blank" [attr.href]="recordsUpdated[preCheckSalesforceMatch.id].url"><i class="fal fa-check-square"></i> Record Updated</a>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <hr>
                        </div>
                    </div>

                    <button
                        class="basic-button-base button-dark btn vertically-centre-text full-width"
                        *ngIf="allowExportButton"
                        [attr.disabled]="isLoading.isLoadingSalesforceExport ? 'disabled': null"
                        (click)="exportCompaniesToSalesforce()">
                        <span>Export&nbsp;</span>
                        <ng-container *ngIf="isLoading.isLoadingSalesforceExport">
                            <i class="fal fa-spinner fa-spin"></i>
                        </ng-container>
                        <ng-container *ngIf="!isLoading.isLoadingSalesforceExport">
                            <i class="fab fa-salesforce salesforce-icon"></i>
                        </ng-container>
                    </button>
                </div>


            </div>
        </div>
    </div>
</div>
