import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { baseUrl } from '../../globals';

@Component({
  selector: 'download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.css'],
})
export class DownloadLinkComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {}

  openDownloadLink(): string {
    var downloadId = this.route.snapshot.paramMap.get('id');
    if (downloadId) {
      return baseUrl + '/download_companies_csv_from_download_request/' + downloadId;
    }
  }

}
