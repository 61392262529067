<div class="custom-slider overflow-hidden">
  <span *ngIf="formName" class="zint-slider-name">{{formName}}:</span>  
  <div class="d-flex justify-content-between mb-1">
    <input *ngIf="isRange" class="slider-input" type="text" pattern="^-?[0-9]+[kmbKMB]{1}$"
    [value]="minValue"
    (keyup.enter)="handleChange('lower', $event)"
    (blur)="handleChange('lower', $event)"
    title>
    <input type="text" class="slider-input" pattern="^-?[0-9]+[kmbKMB]{1}$" [value]="maxValue"
      (keyup.enter)="handleChange('upper', $event)"
      (blur)="handleChange('upper', $event)"
      title>
  </div>
  <ngx-slider *ngIf="isRange else singleSlider" [(value)]="minValue" [(highValue)]="maxValue"
    [options]="options" (userChangeEnd)="handleEmit($event)"></ngx-slider>
  <ng-template #singleSlider>
    <ngx-slider [(value)]="maxValue" [options]="options"
      (userChangeEnd)="handleEmit()"></ngx-slider>
  </ng-template>
</div>
