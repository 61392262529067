import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from '../globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { AuthenticationService } from './authentication.service';
import { CookieOptions } from 'ngx-cookie/lib/cookie.model';

@Injectable()
export class PushMessagesService {
  messages = [];

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private cookieservice: CookieService,
    private pubMethods: publicMethods
  ) {}

  getUserMessages(): void {
    const zintCachedPushMessages = this.cookieservice.get('zintCachedPushMessages');
    const messagesAreHidden = this.cookieservice.get('zintHidePushMessagingWindow') === 'true';
    if (zintCachedPushMessages === undefined && !messagesAreHidden) {
      this.getUserMessagesFromEndpoint().subscribe(data =>
        this.updateUserMessages(data)
      );
    } else {
      this.messages = JSON.parse(zintCachedPushMessages);
    }
  }

  getUserMessagesFromEndpoint(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/get_user_messages/',
        '',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('getUserMessages', []))
      );
  }

  updateUserMessages(data): void {
    if (data.messages !== undefined) {
      const d = new Date();
      // cache messages for 2 hour
      d.setSeconds(60 * 60 * 2);

      this.pubMethods.setCookieAndExpiry({
        name: 'zintCachedPushMessages',
        value: data.message,
        expiry: d,
      });
    }
    if (data.messages) {
      this.messages = data.messages;
    }
  }

  ignorePushMessage(msg): Observable<any> {
    let _params = new HttpParams().set('id', msg.id).set('type', msg.type);
    return this.http
      .post(
        baseUrl + '/ignore_push_message/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('ignorePushMessage', []))
      );
  }

  deleteCachedPushMessages(): void {
    this.cookieservice.remove('zintCachedPushMessages');
    this.cookieservice.remove('zintHidePushMessagingWindow');
  }
}
