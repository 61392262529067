<div class="container">

    <div class="row medium-margin-top medium-margin-bottom">
        <div class="col-md-12 small-margin-bottom">
            <h2>Current queries</h2>
            <p>This is how Zint records are currently matched to Salesforce records.</p>
        </div>
        <div class="col-md-12">
            <div *ngIf="isLoading.isLoadingCRMQueries; else queriesBlock" style="font-size: 50px; text-align: center">
                <i class="fal fa-spinner fa-spin"></i>
            </div>
            <ng-template #queriesBlock>
            <table class="table table-bordered">
                <ng-container *ngIf="crmQueries">
                    <ng-container *ngFor="let query of crmQueries">
                        <tr>
                            <td>{{ query.humanFriendlyString }}</td>
                            <td class="text-center">
                                <button class="basic-button-base button-dark" (click)="deleteQuery(query.id)" style="min-width: 100%; width: 100px">Delete</button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
            </ng-template>
        </div>
    </div>

    <div class="row medium-margin-top medium-margin-bottom">
        <div class="col-md-12 small-margin-bottom">
            <h2>Add new query</h2>
            <p>Determine how Zint records are matched to Salesforce records.</p>
        </div>
        
        <div class="col-sm">
            <select [(ngModel)]="selectedSalesforceObject" class="custom-select" [disabled]="queryBuilderExpressions?.length > 0 || isLoading.isSyncingSalesforceObjects">
                <ng-container *ngFor="let sobject of salesforceObjects">
                    <option [ngValue]="sobject">{{sobject.label}}</option>
                </ng-container>
            </select>
        </div>
        <div class="col-sm">
            <ng-container *ngIf="queryBuilderExpressions?.length < 1">
            <button class="basic-button-base button-dark" (click)="buildNewQuery(selectedSalesforceObject.name)" [disabled]="isLoading.isSyncingSalesforceObjects" style="min-width: 100%; width: 100px">Create new query</button>
            </ng-container>
        </div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
    </div>

    <ng-container *ngIf="queryBuilderExpressions?.length < 1">
    <div class="row medium-margin-top medium-margin-bottom">
        <div class="col-md-12 small-margin-bottom">
            <h2>Advanced</h2>
            <p>If your Salesforce Administrator has added or changed fields in Salesforce CRM, you can synchronise these changes using the button below.</p>
            <p>This is only required if changes have been made in Salesforce.</p>
        </div>
        <div class="col-sm">
            <ng-container *ngIf="queryBuilderExpressions?.length < 1">
                <div *ngIf="isLoading.isSyncingSalesforceObjects; else syncBlock" style="font-size: 30px; text-align: center">
                    <i class="fal fa-spinner fa-spin"></i>
                </div>
                <ng-template #syncBlock>
                <button class="basic-button-base button-dark" (click)="syncSalesforceObjects()"  style="min-width: 100%; width: 100px">
                    Sync object schema
                </button>
                </ng-template>
            </ng-container>
        </div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
    </div>
    </ng-container>

    <ng-container *ngFor="let expression of queryBuilderExpressions; index as i">

    <ng-container *ngIf="i > 0">
    <div class="row medium-margin-top medium-margin-bottom">
        <div class="col-sm">
            Logical operator:
            <select [(ngModel)]="queryBuilderExpressions[i].logicalOperator" class="custom-select">
                <ng-container *ngFor="let operator of logicalOperators">
                    <option [ngValue]="operator">{{operator}}</option>
                </ng-container>
            </select>
        </div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
    </div>
    </ng-container>

    <div class="row medium-margin-top medium-margin-bottom">
        <div class="col-sm">
            Salesforce Field:
            <select [(ngModel)]="queryBuilderExpressions[i].sField" class="custom-select">
                <ng-container *ngFor="let field of salesforceObjectFieldMapping[selectedSalesforceObject.name]">
                    <option [ngValue]="field.name">{{field.label}}</option>
                </ng-container>
            </select>
        </div>

        <div class="col-sm">
            Comparison operator:
            <select [(ngModel)]="queryBuilderExpressions[i].comparisonOperator" class="custom-select">
                <ng-container *ngFor="let operator of comparisonOperators | keyvalue">
                    <option [ngValue]="operator.value">{{operator.key}}</option>
                </ng-container>
            </select>
        </div>

        <div class="col-sm">
            Comparison type:
            <select [(ngModel)]="queryBuilderExpressions[i].comparisonType" class="custom-select">
                <option value="zintAttribute">Zint field</option>
                <option value="fixedValue">Number value</option>
            </select>
        </div>

        <div class="col-sm">
            <ng-container *ngIf="queryBuilderExpressions[i].comparisonType === 'zintAttribute'">
                Zint field:
                <select [(ngModel)]="queryBuilderExpressions[i].zintField" class="custom-select">
                    <ng-container *ngFor="let field of zintFields">
                        <option [ngValue]="field">{{field}}</option>
                    </ng-container>
                </select>
            </ng-container>
            <ng-container *ngIf="queryBuilderExpressions[i].comparisonType === 'fixedValue'">
                Fixed value:
                <input type="text" class="form-control" [(ngModel)]="queryBuilderExpressions[i].fixedValue">
            </ng-container>
        </div>

        <div class="col-sm">
            <ng-container *ngIf="i != 0">
            <br />
            <button class="basic-button-base button-dark" (click)="removeCondition(i)"><i class="fal fa-trash"></i></button>
            </ng-container>
        </div>

    </div>
    </ng-container>

    <ng-container *ngIf="queryBuilderExpressions?.length > 0">
    <div class="row medium-margin-top medium-margin-bottom">  
        <div class="col-sm">
            <button class="basic-button-base button-dark" (click)="buildNewQuery(selectedSalesforceObject.name)" style="min-width: 100%; width: 100px">Add another condition</button>
        </div>

        <div class="col-sm">
            <button class="basic-button-base button-dark" (click)="saveNewQuery()" style="min-width: 100%; width: 100px">Save query</button>
        </div>
        
        <div class="col-sm">
            <button class="basic-button-base button-dark" (click)="cancelNewQuery()" style="min-width: 100%; width: 100px">Cancel</button>
        </div>

        <div class="col-sm"></div>
        <div class="col-sm"></div>
    </div>
    </ng-container>

    <hr>
</div>
