<span class="fa-stack fa-2x" [ngClass]="{
    'gradient_0': inputParams.percentage < 5,
    'gradient_5': inputParams.percentage < 10,
    'gradient_10': inputParams.percentage < 15,
    'gradient_15': inputParams.percentage < 20,
    'gradient_20': inputParams.percentage < 25,
    'gradient_25': inputParams.percentage < 30,
    'gradient_30': inputParams.percentage < 35,
    'gradient_35': inputParams.percentage < 40,
    'gradient_40': inputParams.percentage < 45,
    'gradient_45': inputParams.percentage < 50,
    'gradient_50': inputParams.percentage < 55,
    'gradient_55': inputParams.percentage < 60,
    'gradient_60': inputParams.percentage < 65,
    'gradient_65': inputParams.percentage < 70,
    'gradient_70': inputParams.percentage < 75,
    'gradient_75': inputParams.percentage < 80,
    'gradient_80': inputParams.percentage < 85,
    'gradient_85': inputParams.percentage < 90,
    'gradient_90': inputParams.percentage < 95,
    'gradient_95': inputParams.percentage < 100,
    'gradient_100': inputParams.percentage >= 100
    }">
    <i class="fal fa-certificate fa-stack-2x" style="font-size: 70px;"></i>
    <span class="fa-stack-1x" style="font-size: 20px; margin-top: 3px; margin-left: 3px;">{{ inputParams.percentage }} %</span>
</span>
