import { Component, OnInit } from '@angular/core';
import { PushMessagesService } from '../../../services/push-messages.service';
import { CookieOptions } from 'ngx-cookie/lib/cookie.model';
import { CookieService } from 'ngx-cookie';
import { publicMethods } from '../../../globals';
declare var zE: any; // Zendesk SDK variable

@Component({
  selector: 'push-messages',
  templateUrl: './push-messages.component.html',
  styleUrls: ['./push-messages.component.css'],
})
export class PushMessagesComponent implements OnInit {
  showingMessages = false;

  constructor(
    public pushMessagesService: PushMessagesService,
    public cookieservice: CookieService,
    public pubMethod: publicMethods
  ) {}

  ngOnInit(): void {
    if (this.cookieservice.get('zintHidePushMessagingWindow') !== 'true') {
      this.showingMessages = true;
    }
  }

  openZendeskChat(): void {
    zE('messenger', 'open');
  }

  closeMessageWindow(): void {
    this.showingMessages = false;
    // hide the message window for 6 hours.
    const d = new Date();
    d.setSeconds(60 * 60 * 6);

    this.pubMethod.setCookieAndExpiry({
      name: 'zintHidePushMessagingWindow',
      value: 'true',
      expiry: d,
    });
  }

  ignorePushMessage(msg): void {
    this.pushMessagesService
      .ignorePushMessage(msg)
      .subscribe(data => this.removeMessage(data));
  }

  removeMessage(data): void {
    if (data.success) {
      this.pushMessagesService
        .getUserMessagesFromEndpoint()
        .subscribe(data => this.pushMessagesService.updateUserMessages(data));
    }
  }
}
