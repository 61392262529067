import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nameOnlyDisplay' })
export class NameOnlyDisplayPipe implements PipeTransform {
  transform(inputString) {
    try {
      JSON.parse(inputString);
      return JSON.parse(inputString)['name'];
    } catch (error) {
      return null;
    }
  }
}
