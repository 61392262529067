
<div class="container">
    <div class="row">
        <div class="col-md-12" *ngIf="isLoading.loadingExportConfig">
            <div style="font-size: 20px; text-align: center">
                <i class="fal fa-spinner fa-spin"></i>
            </div>
        </div>
        <div class="col-md-6">
            <h4>Current Configuration:</h4>
            <br><br>
            <p>Select which Salesforce Object to export to:</p>

            <div class="input-group">
                <select [(ngModel)]="selectedSalesforceObjectId" class="custom-select">
                    <option *ngFor="let salesforceObject of salesforceObjects" [ngValue]="salesforceObject.id" [attr.selected]="salesforceObject.id == selectedSalesforceObjectId ? 'selected' : null">{{salesforceObject.label}}</option>
                </select>
                <div class="input-group-append" >
                    <button class="basic-button-base button-dark" data-toggle="modal" data-target="#overwriteSalesforceObject">Submit</button>
                </div>
            </div>

        </div>
    </div>




    <ng-container *ngIf="selectedSalesforceObject">

        <div class="row medium-margin-top">
            <div class="col-md-12">
                <h5>Zint Company Attributes to Export:</h5>
            </div>
            <div class="col-md-12">
                <table class="table table-striped table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>Zint Attribute</th>
                            <th>Salesforce Attribute</th>
                            <th>Overwrite Existing?</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!selectedZintAttributeMappings || selectedZintAttributeMappings.length == 0">
                            <td colspan="100%">No mappings yet defined</td>
                        </tr>
                        <tr *ngFor="let zintSFMapping of selectedZintAttributeMappings">
                            <td class="align-middle">{{zintSFMapping.zint_attribute}}</td>
                            <td class="align-middle">{{zintSFMapping.sf_field.label}}</td>
                            <td class="align-middle">
                                <input type="checkbox"
                                   [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null"
                                   [(ngModel)]="zintSFMapping.overwrite"
                                   (ngModelChange)="updateAttributeExportMappingOverwriteStatus(zintSFMapping.id, zintSFMapping.overwrite)"/>
                            </td>
                            <td class="align-middle">
                                <button class="basic-button-base button-dark btn-sm"
                                        (click)="deleteAttributeExportMapping(zintSFMapping.id)"
                                        [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null">
                                    <i class="fal fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="basic-button-base button-dark" *ngIf="!showNewZintAttributeExportMapping" (click)="showNewZintAttributeExportMapping = true">+ New Zint Export Mapping</button>
            </div>
            <ng-container *ngIf="showNewZintAttributeExportMapping">
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Export:</span>
                        </div>
                        <select [(ngModel)]="zintAttributeSelected" class="custom-select">
                            <option *ngFor="let zintField of zintFields" [ngValue]="zintField">{{zintField}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">To Salesforce:</span>
                        </div>
                        <select [(ngModel)]="salesforceFieldIdSelected" class="custom-select" *ngIf="selectedSalesforceObject && selectedSalesforceObject.fields">
                            <option *ngFor="let salesforceField of selectedSalesforceObject.fields" [ngValue]="salesforceField.id">{{salesforceField.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <button class="basic-button-base button-dark" (click)="addNewZintSalesforceMapping()" [attr.disabled]="!zintAttributeSelected || !salesforceFieldIdSelected ? 'disabled': null">Submit</button>
                </div>
            </ng-container>
        </div>

        <div class="row medium-margin-top">
            <div class="col-md-12">
                <h5>Custom Columns to Export:</h5>
            </div>
            <div class="col-md-12">
                <table class="table table-striped table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>Custom Column</th>
                            <th>Salesforce Attribute</th>
                            <th>Overwrite Existing?</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!selectedCustomColumnMappings || selectedCustomColumnMappings.length == 0">
                            <td colspan="100%">No mappings yet defined</td>
                        </tr>
                        <tr *ngFor="let customColumnMapping of selectedCustomColumnMappings">
                            <td class="align-middle">{{customColumnMapping.custom_column_name}}</td>
                            <td class="align-middle">{{customColumnMapping.sf_field.label}}</td>
                            <td class="align-middle">
                                <input type="checkbox"
                                   [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null"
                                   [(ngModel)]="customColumnMapping.overwrite"
                                   (ngModelChange)="updateAttributeExportMappingOverwriteStatus(customColumnMapping.id, customColumnMapping.overwrite)"/>
                            </td>
                            <td class="align-middle">
                                <button class="basic-button-base button-dark btn-sm"
                                        (click)="deleteAttributeExportMapping(customColumnMapping.id)"
                                        [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null">
                                    <i class="fal fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="basic-button-base button-dark" *ngIf="!showNewCustomColumnExportMapping" (click)="showNewCustomColumnExportMapping = true">+ New Custom Column Export Mapping</button>
            </div>
            <ng-container *ngIf="showNewCustomColumnExportMapping">
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Export:</span>
                        </div>
                        <select [(ngModel)]="customColumnIdSelected" class="custom-select">
                            <option *ngFor="let customColumn of customColumns" [ngValue]="customColumn.id">{{customColumn.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">To Salesforce:</span>
                        </div>
                        <select [(ngModel)]="salesforceFieldIdSelected" class="custom-select" *ngIf="selectedSalesforceObject && selectedSalesforceObject.fields">
                            <option *ngFor="let salesforceField of selectedSalesforceObject.fields" [ngValue]="salesforceField.id">{{salesforceField.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <button class="basic-button-base button-dark" (click)="addNewCustomColumnSalesforceMapping()" [attr.disabled]="!customColumnIdSelected || !salesforceFieldIdSelected ? 'disabled': null">Submit</button>
                </div>
            </ng-container>
        </div>

        <div class="row medium-margin-top medium-margin-bottom">
            <div class="col-md-12">
                <h5>Text Constants to Export:</h5>
            </div>
            <div class="col-md-12">
                <table class="table table-striped table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>Constant</th>
                            <th>Salesforce Attribute</th>
                            <th>Overwrite Existing?</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!selectedStringConstantMappings || selectedStringConstantMappings.length == 0">
                            <td colspan="100%">No mappings yet defined</td>
                        </tr>
                        <tr *ngFor="let stringConstantMapping of selectedStringConstantMappings">
                            <td class="align-middle">{{stringConstantMapping.string_constant}}</td>
                            <td class="align-middle">{{stringConstantMapping.sf_field.label}}</td>
                            <td class="align-middle">
                                <input type="checkbox"
                                   [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null"
                                   [(ngModel)]="stringConstantMapping.overwrite"
                                   (ngModelChange)="updateAttributeExportMappingOverwriteStatus(stringConstantMapping.id, stringConstantMapping.overwrite)"/>
                            </td>
                            <td class="align-middle">
                                <button class="basic-button-base button-dark btn-sm"
                                        (click)="deleteAttributeExportMapping(stringConstantMapping.id)"
                                        [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null">
                                    <i class="fal fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="basic-button-base button-dark" *ngIf="!showNewStringConstantExportMapping" (click)="showNewStringConstantExportMapping = true">+ New Text Constant Mapping</button>
            </div>
            <ng-container *ngIf="showNewStringConstantExportMapping">
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Export:</span>
                        </div>
                        <input type="text" class="form-control" name="stringConstantSelected" [(ngModel)]="stringConstantSelected">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">To Salesforce:</span>
                        </div>
                        <select [(ngModel)]="salesforceFieldIdSelected" class="custom-select" *ngIf="selectedSalesforceObject && selectedSalesforceObject.fields">
                            <option *ngFor="let salesforceField of selectedSalesforceObject.fields" [ngValue]="salesforceField.id">{{salesforceField.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <button class="basic-button-base button-dark" (click)="addNewStringConstantSalesforceMapping()" [attr.disabled]="!stringConstantSelected || !salesforceFieldIdSelected ? 'disabled': null">Submit</button>
                </div>
            </ng-container>
        </div>


        <div class="row medium-margin-top medium-margin-bottom">
            <div class="col-md-12">
                <h5>Propensity Model Components to Export:</h5>
            </div>
            <div class="col-md-12">
                <table class="table table-striped table-sm table-bordered">
                    <thead>
                        <tr>
                            <th>Propensity Model Component</th>
                            <th>Salesforce Attribute</th>
                            <th>Overwrite Existing?</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!selectedPropensityModelComponentsMappings || selectedPropensityModelComponentsMappings.length == 0">
                            <td colspan="100%">No mappings yet defined</td>
                        </tr>
                        <tr *ngFor="let selectedPropensityModelComponentsMapping of selectedPropensityModelComponentsMappings">
                            <td class="align-middle">{{selectedPropensityModelComponentsMapping.propensity_model_name}}</td>
                            <td class="align-middle">{{selectedPropensityModelComponentsMapping.sf_field.label}}</td>
                            <td class="align-middle">
                                <input type="checkbox"
                                   [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null"
                                   [(ngModel)]="selectedPropensityModelComponentsMapping.overwrite"
                                   (ngModelChange)="updateAttributeExportMappingOverwriteStatus(selectedPropensityModelComponentsMapping.id, selectedPropensityModelComponentsMapping.overwrite)"/>
                            </td>
                            <td class="align-middle">
                                <button class="basic-button-base button-dark btn-sm"
                                        (click)="deleteAttributeExportMapping(selectedPropensityModelComponentsMapping.id)"
                                        [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null">
                                    <i class="fal fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="basic-button-base button-dark" *ngIf="!showNewPropensityModelComponentsExportMapping" (click)="showNewPropensityModelComponentsExportMapping = true">+ New Propensity Model Components Mapping</button>
            </div>
            <ng-container *ngIf="showNewPropensityModelComponentsExportMapping">
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Export:</span>
                        </div>
                        <select [(ngModel)]="propensityModelIdSelected" class="custom-select">
                            <option *ngFor="let propensityModel of propensityModels" [ngValue]="propensityModel.id">{{propensityModel.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">To Salesforce:</span>
                        </div>
                        <select [(ngModel)]="salesforceFieldIdSelected" class="custom-select" *ngIf="selectedSalesforceObject && selectedSalesforceObject.fields">
                            <option *ngFor="let salesforceField of selectedSalesforceObject.fields" [ngValue]="salesforceField.id">{{salesforceField.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <button class="basic-button-base button-dark" (click)="addNewPropensityModelComponentsSalesforceMapping()" [attr.disabled]="!propensityModelIdSelected || !salesforceFieldIdSelected ? 'disabled': null">Submit</button>
                </div>
            </ng-container>
        </div>




    </ng-container>


</div>






<div class="modal fade" id="overwriteSalesforceObject" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="overwriteSalesforceObjectLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p>Your previous configuration will be deleted.</p>
                        <button type="button" class="basic-button-base button-dark control-buttons" (click)="chooseSalesforceObject()" [attr.disabled]="isLoading.editingExportConfig ? 'disabled': null">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
