<div class="card mt-2 overflow-auto">
    <div class="card-body">

        <div class="col-md-12 small-margin-bottom">
            <h4>{{eventSourceType | removeTrailingEventType | camelCaseToProper}} Rules:</h4>
        </div>

        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th *ngFor="let paramName of Object.keys(parameterSchema.properties).sort()">
                        {{paramName | underscoreToUpper}}
                    </th>
                    <th>Description</th>
                    <th>Importance</th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngIf="ruleSet">
                <tr *ngFor="let rule of filterEventType(ruleSet.rules, eventSourceType)">
                    <ng-container *ngIf="rule.event_source_type === eventSourceType">
                        <ng-container *ngFor="let key of Object.keys(rule.event_source_parameters).sort()">
                            <td class="td-widths" [ngSwitch]="rule.event_source_parameters[key]">
                                <ng-container *ngSwitchCase="true">
                                    <i class="fal fa-check"></i>
                                </ng-container>
                                <ng-container *ngSwitchCase="false">
                                    <i class="fal fa-times"></i>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{rule.event_source_parameters[key]}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <td>{{rule.description}} </td>
                        <td>{{rule.importance}} </td>
                        <td>
                            <button class="basic-button-base button-dark" (click)="removeRule(rule.id)"><i
                                    class="fal fa-trash"></i></button>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <!-- Dynamic Input Boxes From Parameters Schema  -->
                    <ng-container *ngIf="parameterSchema">
                        <ng-container *ngFor="let paramName of Object.keys(parameterSchema.properties).sort()">
                            <ng-container *ngIf="parameterSchema.properties[paramName].type === 'string'">
                                <ng-container *ngIf="parameterSchema.properties[paramName].enum; else stringInput">
                                    <td class="td-widths">
                                        <select [(ngModel)]="newParameters[paramName]" class="custom-select">
                                            <ng-container
                                                *ngFor="let enumValue of parameterSchema.properties[paramName].enum">
                                                <option>{{enumValue}}</option>
                                            </ng-container>
                                        </select>
                                    </td>
                                </ng-container>
                                <ng-template #stringInput>
                                    <td class="td-widths">
                                        <ng-container
                                            *ngIf="ruleSet && paramName.includes('search_term')  && opensearchEventSourceTypes.includes(eventSourceType); else rawInput">
                                            <div style="min-width: 250px">
                                                <opensearch-term-config (searchTerm)="addSearchTerm($event, paramName)"
                                                    [suggestedTerms]="ruleSet.suggested_categorised_search_keywords"
                                                    [eventSourceType]="eventSourceType"
                                                    [extraParam]="Object.values(newParameters)[1]"
                                                    [isCreated]="created">
                                                </opensearch-term-config>
                                            </div>
                                        </ng-container>

                                        <ng-template #rawInput>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="newParameters[paramName]"
                                                placeholder="{{paramName | underscoreToUpper}}">
                                        </ng-template>
                                    </td>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="parameterSchema.properties[paramName].type === 'boolean'">
                                <td class="td-widths">
                                    <input type="checkbox" [name]="paramName" value="false"
                                        [(ngModel)]="newParameters[paramName]" /> {{paramName | underscoreToUpper}}
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <td>
                        <input type="text" class="form-control" [(ngModel)]="newDescription">
                    </td>
                    <td>
                        <select [(ngModel)]="newImportance" class="custom-select">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </td>
                    <td>
                        <button class="basic-button-base button-dark" (click)="createNewRule()">Create <i
                                class="fal fa-arrow-alt-circle-right"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>


    </div>
</div>