<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
    <div class="col-md-8">
        <h5>
            New Imports Exports for {{event_metadata.month}}/{{event_metadata.year}}
        </h5>
        <hr>
        <div *ngFor="let notification of notifications">
            <ng-container *ngIf="notification.event_metadata.new_category === false">
                <div *ngIf="notification.event_metadata.imp_events_count">
                    <div notification-search-term [notification]="notification"
                    [searchTermString]="'Had ' + notification.event_metadata.imp_events_count + ' Import Event' + (notification.event_metadata.imp_events_count === 1 ? '' : 's')">
                    </div>
                </div>
                <div *ngIf="notification.event_metadata.exp_events_count">
                    <div notification-search-term [notification]="notification"
                    [searchTermString]="'Had ' + notification.event_metadata.exp_events_count + ' Export Event' + (notification.event_metadata.exp_events_count === 1 ? '' : 's')">
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="notification.event_metadata.new_category === true">
                <div notification-search-term [notification]="notification"
                searchTerm="{{ notification.event_metadata.import_or_export | titlecase }} New Catagory:"
                [searchTermHTML]="notification.event_metadata.new_category_description">
                </div>
            </ng-container>
        </div>
        <div class="row small-margin-top">
            <div class="col-md-12">
                <a href="/profile/{{companyNumber}}" target="_blank">
                    <span class="badge badge-link">
                        <i class="fal fa-external-link"></i>
                        &nbsp;View company page
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1578575437130-527eed3abbec?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80'"
        [htmlBelowImage]="htmlBelowImage"></div>
</div>
