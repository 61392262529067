import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class SalesforceExporterService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingData: false,
    loadingQueryResults: false,
    loadingExportableObjects: false,
    updatingRecord: false,
    creatingRecord: false,
    gettingSalesforceExportValues: false,
  };

  getResultsFromSalesforceQueries(companyId): Observable<any> {
    const _params = new HttpParams().set('companyId', companyId);
    this.isLoading['loadingQueryResults'] = true;
    return this.http
      .post(
        baseUrl + '/run_export_queries/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingQueryResults'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getResultsFromSalesforceQueries',
            [],
            this.isLoading
          )
        )
      );
  }

  listExportableSobjects(): Observable<any> {
    this.isLoading['loadingExportableObjects'] = true;
    return this.http
      .post(
        baseUrl + '/list_exportable_objects/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingExportableObjects'] = false)),
        catchError(
          this.pubMethods.handleError(
            'list_exportable_objects',
            [],
            this.isLoading
          )
        )
      );
  }

  updateSalesforceRecord(
    sobjectId,
    salesforceId,
    updateSalesforceRecord,
    companyId
  ): Observable<any> {
    const _params = new HttpParams()
      .set('sobjectId', sobjectId)
      .set('salesforceId', salesforceId)
      .set(
        'exportFieldsToValues',
        this.pubMethods.stringifyListForGetParams(updateSalesforceRecord)
      )
      .set('companyId', companyId);
    this.isLoading['updatingRecord'] = true;
    return this.http
      .post(
        baseUrl + '/update_salesforce_record/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['updatingRecord'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updateSalesforceRecord',
            [],
            this.isLoading
          )
        )
      );
  }

  createNewSalesforceRecord(
    sobjectId,
    companyId,
    createFieldsToValues
  ): Observable<any> {
    const _params = new HttpParams()
      .set('sobjectId', sobjectId)
      .set('companyId', companyId)
      .set(
        'exportFieldsToValues',
        this.pubMethods.stringifyListForGetParams(createFieldsToValues)
      );
    this.isLoading['creatingRecord'] = true;
    return this.http
      .post(
        baseUrl + '/create_salesforce_record/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['creatingRecord'] = false)),
        catchError(
          this.pubMethods.handleError(
            'createNewSalesforceRecord',
            [],
            this.isLoading
          )
        )
      );
  }

  getSalesforceValuesToExport(sobjectId, companyId): Observable<any> {
    const _params = new HttpParams()
      .set('sobjectId', sobjectId)
      .set('companyId', companyId);
    this.isLoading['gettingSalesforceExportValues'] = true;
    return this.http
      .post(
        baseUrl + '/get_salesforce_values_for_export/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['gettingSalesforceExportValues'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getSalesforceValuesToExport',
            [],
            this.isLoading
          )
        )
      );
  }
}
