import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AccountService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    isLoadingBulkDownloadDetails: false,
    loadingSavedSearchesAddRemoveUserOrTeam: false,
    loadingSavedSearches: false,
    loadingInitialData: false,
    loadingTags: false,
  };



  // ------------ Bulk Download Management ------------

  getBulkDownloadDetails(): Observable<any> {
    this.isLoading['loadingSavedSearches'] = true;
    return this.http
      .post(baseUrl + '/get_bulk_download_details/', {}, this.auth.helperGetVerifiedPostDict())
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('getBulkDownloadDetails', [], this.isLoading)
        )
      );
  }

  // ------------ Bulk Download Management ------------


  // ------------ Saved Searches ------------

  getSavedSearchDetails(): Observable<any> {
    this.isLoading['loadingSavedSearches'] = true;
    return this.http
      .post(
        baseUrl + '/get_saved_search_details/',
        '',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSavedSearches'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getSavedSearchDetails',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteSavedSearchByID(savedSearchId): Observable<any> {
    this.isLoading['loadingSavedSearches'] = true;
    let params = new HttpParams().set('savedSearchId', savedSearchId);
    return this.http
      .post(
        baseUrl + '/delete_saved_search/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSavedSearches'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deleteSavedSearchByID',
            [],
            this.isLoading
          )
        )
      );
  }

  addUserToSavedSearch(userId, savedSearchId): Observable<any> {
    this.isLoading['loadingSavedSearchesAddRemoveUserOrTeam'] = true;
    let params = new HttpParams()
      .set('userId', userId)
      .set('savedSearchId', savedSearchId);
    return this.http
      .post(
        baseUrl + '/add_user_to_saved_search/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ =>
            (this.isLoading['loadingSavedSearchesAddRemoveUserOrTeam'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'addUserToSavedSearch',
            [],
            this.isLoading
          )
        )
      );
  }

  addTeamToSavedSearch(orgId, savedSearchId): Observable<any> {
    this.isLoading['loadingSavedSearchesAddRemoveUserOrTeam'] = true;
    let params = new HttpParams()
      .set('orgId', orgId)
      .set('savedSearchId', savedSearchId);
    return this.http
      .post(
        baseUrl + '/add_team_to_saved_search/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ =>
            (this.isLoading['loadingSavedSearchesAddRemoveUserOrTeam'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'addTeamToSavedSearch',
            [],
            this.isLoading
          )
        )
      );
  }

  removeUserFromSavedSearch(userId, savedSearchId): Observable<any> {
    this.isLoading['loadingSavedSearchesAddRemoveUserOrTeam'] = true;
    let params = new HttpParams()
      .set('userId', userId)
      .set('savedSearchId', savedSearchId);
    return this.http
      .post(
        baseUrl + '/remove_user_from_saved_search/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ =>
            (this.isLoading['loadingSavedSearchesAddRemoveUserOrTeam'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'removeUserFromSavedSearch',
            [],
            this.isLoading
          )
        )
      );
  }

  removeTeamFromSavedSearch(orgId, savedSearchId): Observable<any> {
    this.isLoading['loadingSavedSearchesAddRemoveUserOrTeam'] = true;
    let params = new HttpParams()
      .set('orgId', orgId)
      .set('savedSearchId', savedSearchId);
    return this.http
      .post(
        baseUrl + '/remove_team_from_saved_search/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ =>
            (this.isLoading['loadingSavedSearchesAddRemoveUserOrTeam'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'removeTeamFromSavedSearch',
            [],
            this.isLoading
          )
        )
      );
  }

  // ------------ Saved Searches ------------

  deleteUserSearchHistory(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/delete_search_history/',
        '',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('deleteUserSearchHistory', []))
      );
  }

  saveAccountTrackingPreferences(shouldTrack): Observable<any> {
    let params = new HttpParams().set('shouldTrack', shouldTrack);
    return this.http
      .post(
        baseUrl + '/save_account_tracking_preferences/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('saveAccountTrackingPreferences', [])
        )
      );
  }

  toggleDownloadDateTracking(downloadDateTrackingEnabled): Observable<any> {
    let params = new HttpParams().set(
      'downloadDateTrackingEnabled',
      downloadDateTrackingEnabled
    );
    return this.http
      .post(
        baseUrl + '/toggle_private_download_date_tracking/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('toggleDownloadDateTracking', [])
        )
      );
  }

  getUserAccountDetails(): Observable<any> {
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/get_user_account_details/',
        '',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(this.pubMethods.handleError('getUserAccountDetails', []))
      );
  }

  updateColumnFormat(columns, endpoint): Observable<any> {
    let params = new HttpParams().set(
      'columns',
      this.pubMethods.stringifyListForGetParams(columns)
    );
    return this.http
      .post(baseUrl + endpoint, params, this.auth.helperGetVerifiedPostDict())
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('updateCompanyResultsFormat', [])
        )
      );
  }

  updateCompanyViewCustomColumnOrder(columns): Observable<any> {
    let params = new HttpParams().set(
      'columns',
      this.pubMethods.stringifyListForGetParams(columns)
    );
    return this.http
      .post(
        baseUrl + '/save_company_view_custom_column_order/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('updateCompanyResultsFormat', [])
        )
      );
  }

  createCollaborativeCustomColumn(
    newColumnName,
    newColumnType,
    newColumnOptionsEnabled,
    newColumnOptions,
    newColumnIsLockable,
    newColumnLockDays,
    newColumnMaximumDuplicateEntries
  ): Observable<any> {
    let params = new HttpParams()
      .set('newColumnName', newColumnName)
      .set('newColumnType', newColumnType)
      .set('newColumnOptionsEnabled', String(newColumnOptionsEnabled))
      .set(
        'newColumnOptions',
        this.pubMethods.stringifyListForGetParams(newColumnOptions)
      )
      .set('newColumnIsLockable', String(newColumnIsLockable))
      .set('newColumnLockDays', String(newColumnLockDays))
      .set(
        'newColumnMaximumDuplicateEntries',
        String(newColumnMaximumDuplicateEntries)
      );
    return this.http
      .post(
        baseUrl + '/create_collaborative_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('createCollaborativeCustomColumn', [])
        )
      );
  }

  createCustomColumn(
    newColumnName,
    newColumnType,
    newColumnOptionsEnabled,
    newColumnOptions
  ): Observable<any> {
    let params = new HttpParams()
      .set('newColumnName', newColumnName)
      .set('newColumnType', newColumnType)
      .set('newColumnOptionsEnabled', String(newColumnOptionsEnabled))
      .set(
        'newColumnOptions',
        this.pubMethods.stringifyListForGetParams(newColumnOptions)
      );
    return this.http
      .post(
        baseUrl + '/create_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('createCustomColumn', []))
      );
  }

  deleteCustomColumnFromId(columnId): Observable<any> {
    let params = new HttpParams().set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/delete_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('deleteCustomColumnFromId', []))
      );
  }

  updateCustomColumnName(columnId, columnName): Observable<any> {
    let params = new HttpParams()
      .set('columnId', columnId)
      .set('columnName', columnName);
    return this.http
      .post(
        baseUrl + '/update_custom_column_name/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('updateCustomColumnName', []))
      );
  }

  addOptionFromExistingColumn(columnId, optionToAdd): Observable<any> {
    let params = new HttpParams()
      .set('columnId', columnId)
      .set('optionToAdd', JSON.stringify(optionToAdd));
    return this.http
      .post(
        baseUrl + '/add_option_to_existing_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('addOptionFromExistingColumn', [])
        )
      );
  }

  callAddNewViewableUser(userId, columnId): Observable<any> {
    let params = new HttpParams()
      .set('userId', userId)
      .set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/add_new_viewable_user/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('callAddNewViewableUser', []))
      );
  }

  calladdNewViewableTeam(teamId, columnId): Observable<any> {
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/add_viewable_team_to_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('calladdNewViewableTeam', []))
      );
  }

  callAddNewEditableUser(userId, columnId): Observable<any> {
    let params = new HttpParams()
      .set('userId', userId)
      .set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/add_new_editable_user/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('callAddNewEditableUser', []))
      );
  }

  calladdNewEditableTeam(teamId, columnId): Observable<any> {
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/add_editable_team_to_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('calladdNewEditableTeam', []))
      );
  }

  callDeleteViewingAccessForUserOnColumn(userId, columnId): Observable<any> {
    let params = new HttpParams()
      .set('userId', userId)
      .set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/delete_viewing_access_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('deleteViewingAccessForUserOnColumn', [])
        )
      );
  }

  callDeleteViewingAccessForTeamOnColumn(teamId, columnId): Observable<any> {
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/remove_viewable_team_from_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError(
            'callDeleteViewingAccessForTeamOnColumn',
            []
          )
        )
      );
  }

  callDeleteEditingAccessForUserOnColumn(userId, columnId): Observable<any> {
    let params = new HttpParams()
      .set('userId', userId)
      .set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/delete_editing_access_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('deleteEditingAccessForUserOnColumn', [])
        )
      );
  }

  callDeleteEditingAccessForTeamOnColumn(teamId, columnId): Observable<any> {
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('columnId', columnId);
    return this.http
      .post(
        baseUrl + '/remove_editable_team_from_custom_column/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError(
            'callDeleteEditingAccessForTeamOnColumn',
            []
          )
        )
      );
  }
}
