import { Pipe, PipeTransform } from '@angular/core';
import { baseUrl } from '../globals';

export interface IAccountLinkOptions {
  companyId: string | number;
  totalNumberOfPages?: string | number;
  accountsType?: string;
  pdfURL?: string;
  fileName?: string;
}

@Pipe({ name: 'xbrlOrOcrAccountsLink' })
export class XbrlOrOcrAccountsLinkPipe implements PipeTransform {
  transform(
    dataObj: Record<string, any>,
    useCase: string,
    options: IAccountLinkOptions
  ): string {
    switch (useCase) {
      case 'accountsSearch':
        return this.processAccountsViewerLink(dataObj, options);
      case 'insights':
        return this.processInsightsLink(dataObj, options);
      default:
        return '';
    }
  }

  processAccountsViewerLink(
    accountObj: Record<string, any>,
    options: IAccountLinkOptions
  ): string {
    if (!accountObj) return '';
    const {
      accounts_type,
      file_name,
      page_number,
      accounts_url,
      number_of_pages,
    } = accountObj;
    const { companyId } = options;

    if (!accounts_type) return accounts_url;

    if (accounts_type === 'ocr') {
      return accounts_url;
    }
    if (accounts_type === 'xbrl') {
      const accountsViewerURL = `${baseUrl}/accounts_viewer/?file_name=${companyId}/${file_name}&page=${page_number}&total_pages=${number_of_pages}`;
      return accountsViewerURL;
    }
  }

  processInsightsLink(
    insightObj: Record<string, any>,
    options: IAccountLinkOptions
  ): string {
    if (!insightObj) return '';

    const { accountsType, pdfURL, companyId, fileName, totalNumberOfPages } =
      options;

    if (accountsType === 'ocr') {
      const pagePlusOne = insightObj.page + 1;
      return pdfURL + '#page=' + pagePlusOne;
    }

    if (accountsType === 'xbrl') {
      const accountsViewerURL = `${baseUrl}/accounts_viewer/?file_name=${companyId}/${fileName}&page=${insightObj.page}&total_pages=${totalNumberOfPages}`;
      return accountsViewerURL;
    }

    return '';
  }
}
