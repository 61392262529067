import { Component, OnInit, Input } from '@angular/core';
import { CommunicationService } from '../../services/communication.service';
import { TaggingService } from '../../services/tagging.service';
import { DataSharingService } from '../../services/data-sharing.service';
declare var $: any;

@Component({
  selector: 'tag-editing',
  templateUrl: './tag-editing.component.html',
  styleUrls: ['./tag-editing.component.css'],
})
export class TagEditingComponent implements OnInit {
  emailOnAccount: string;
  isSuperUser: boolean;

  hasUserTags: boolean = true;
  hasOrganisationTags: boolean = true;
  tagViewableUserToDelete: any;
  viewableTagToDeleteOn: any;
  tagEditableUserToDelete: any;
  editableTagToDeleteOn: any;

  viewableTeamToDeleteOnTag: any;
  viewableTeamTagToDeleteOn: any;
  editableTeamTagToDeleteOn: any;
  editableTeamToDeleteOnTag: any;

  constructor(
    private communicationService: CommunicationService,
    private taggingService: TaggingService,
    private data: DataSharingService
  ) {}

  isLoading = this.taggingService.isLoading;

  @Input()
  inputParams: any = {
    inputTags: Array,
    tagTypeString: '',
    organisationUsers: Array,
    organisationTeams: Array,
  };

  ngOnInit() {
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.communicationService.getEmailMessage().subscribe(accountEmail => {
      this.emailOnAccount = accountEmail;
    });

    // This waits up to 15 seconds to ensure the tags have been pulled before updating the status hasOrganisationTags
    // and hasUserTags
    var numberOfLoops = 0;
    var checkingIntervalToSeeIfTagsUpdated = setInterval(() => {
      if (
        (this.inputParams.inputTags && this.inputParams.inputTags.length) ||
        numberOfLoops >= 15
      ) {
        this.updateTypeOfTagNumbers();
        clearInterval(checkingIntervalToSeeIfTagsUpdated);
      }
      numberOfLoops = numberOfLoops + 1;
    }, 1000);
  }

  updateTypeOfTagNumbers(): void {
    if (this.inputParams.inputTags != undefined) {
      this.hasOrganisationTags =
        this.inputParams.inputTags.filter(tag => tag.isOrgTag == true).length >
        0;
      this.hasUserTags =
        this.inputParams.inputTags.filter(tag => tag.isOrgTag == false).length >
        0;
    }
  }

  updateTagDetailsAfterChange(data): void {
    this.inputParams.inputTags
      .filter(tag => tag.id == data.updated_tag.id)
      .map(tag => (tag.view_users = data.updated_tag.view_users));
    this.inputParams.inputTags
      .filter(tag => tag.id == data.updated_tag.id)
      .map(tag => (tag.edit_users = data.updated_tag.edit_users));
    this.inputParams.inputTags
      .filter(tag => tag.id == data.updated_tag.id)
      .map(tag => (tag.view_teams = data.updated_tag.view_teams));
    this.inputParams.inputTags
      .filter(tag => tag.id == data.updated_tag.id)
      .map(tag => (tag.edit_teams = data.updated_tag.edit_teams));
    this.updateTypeOfTagNumbers();
    $('#confirmDeleteViewableUserOnTagModal').modal('hide');
    $('#confirmDeleteEditableUserOnTagModal').modal('hide');
    $('#confirmDeleteViewableTeamOnTagModal').modal('hide');
    $('#confirmDeleteEditableTeamOnTagModal').modal('hide');
  }

  addNewViewableUserToTag(userId, tagId): void {
    this.taggingService
      .addViewableUserToTag(userId, tagId)
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  deleteViewingAccessForUserOnTag(): void {
    this.taggingService
      .removeViewableUserFromTag(
        this.tagViewableUserToDelete.userId,
        this.viewableTagToDeleteOn.id
      )
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  addNewViewableTeamToTag(teamId, tagId): void {
    this.taggingService
      .addViewableTeamToTag(teamId, tagId)
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  deleteViewingAccessForTeamOnTag(): void {
    this.taggingService
      .removeViewableTeamFromTag(
        this.viewableTeamToDeleteOnTag.id,
        this.viewableTeamTagToDeleteOn.id
      )
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  addNewEditableUserToTag(userId, tagId): void {
    this.taggingService
      .addEditableUserToTag(userId, tagId)
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  deleteEditingAccessForUserOnTag(): void {
    this.taggingService
      .removeEditableUserFromTag(
        this.tagEditableUserToDelete.userId,
        this.editableTagToDeleteOn.id
      )
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  addNewEditableTeamToTag(teamId, tagId): void {
    this.taggingService
      .addEditableTeamToTag(teamId, tagId)
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  deleteEditingAccessForTeamOnTag(): void {
    this.taggingService
      .removeEditableTeamFromTag(
        this.editableTeamToDeleteOnTag.id,
        this.editableTeamTagToDeleteOn.id
      )
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  triggerDeleteTag(tagID): void {
    this.data.changeDataSourceTagIdToDelete(tagID);
  }
}
