import { Component, Input, OnInit } from '@angular/core';
import { ResultsFormatEditorService } from '../../../services/results-format-editor.service';
import { publicMethods } from '../../../globals';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'results-format-editor',
  templateUrl: './results-format-editor.component.html',
  styleUrls: ['./results-format-editor.component.css'],
})
export class ResultsFormatEditorComponent implements OnInit {
  @Input()
  inputParams: any = {
    isSuperUser: false,
  };

  individualResultsFormatSettings: Array<any>;
  organisationResultsFormatSettings: Array<any>;

  unusedResultHeaders: any;
  zintHeaderNames: Array<any>;
  customColumns: Array<any>;

  resultsFormatBeingEdited: any;
  nameBeingChanged = '';

  newResultsFormatName = '';

  constructor(
    private resultsFormatEditorService: ResultsFormatEditorService,
    private pubMethods: publicMethods
  ) {}

  isLoading = this.resultsFormatEditorService.isLoading;

  ngOnInit(): void {
    this.getInitialUserResultsFormatData();
  }

  getInitialUserResultsFormatData() {
    this.resultsFormatEditorService
      .getUserResultsFormat()
      .subscribe(data => this.updateUIWithInitialUserResultsFormatData(data));
  }

  updateUIWithInitialUserResultsFormatData(data) {
    this.zintHeaderNames = data.results_header_names;
    this.customColumns = data.custom_columns;

    this.individualResultsFormatSettings = data.individual;
    this.organisationResultsFormatSettings = data.organisation;

    this.activateUserSettings();
  }

  activateUserSettings() {
    if (this.individualResultsFormatSettings.length > 0) {
      this.resultsFormatBeingEdited = this.individualResultsFormatSettings[0];
      this.userResultsFormatEditingChanged();
    } else {
      this.resultsFormatBeingEdited = null;
    }
  }

  activateOrganisationSettings() {
    if (this.organisationResultsFormatSettings.length > 0) {
      this.resultsFormatBeingEdited = this.organisationResultsFormatSettings[0];
      this.userResultsFormatEditingChanged();
    } else {
      this.resultsFormatBeingEdited = null;
    }
  }

  saveResultsFormatName(resultsFormatSettings) {
    this.resultsFormatEditorService
      .updateResultsFormatName(
        'user',
        resultsFormatSettings.id,
        this.nameBeingChanged
      )
      .subscribe(data =>
        this.validateSuccessfulNameChange(resultsFormatSettings, data)
      );
  }

  saveOrganisationResultsFormatName(resultsFormatSettings) {
    this.resultsFormatEditorService
      .updateResultsFormatName(
        'organisation',
        resultsFormatSettings.id,
        this.nameBeingChanged
      )
      .subscribe(data =>
        this.validateSuccessfulNameChange(resultsFormatSettings, data)
      );
  }

  validateSuccessfulNameChange(resultsFormatSettings, data) {
    if (data.success) {
      resultsFormatSettings.name = this.nameBeingChanged;
      this.pubMethods.showInfoMessage('Your settings have been updated.');
    }
  }

  validateSuccessfulSettingsChange(data) {
    if (data.success) {
      this.pubMethods.showInfoMessage('Your settings have been updated.');
    }
  }

  addOrRemoveHeaderToResults(headerName, add): void {
    if (add) {
      var arrayToRemove = this.unusedResultHeaders;
      var arrayToAdd = this.resultsFormatBeingEdited.format;
    } else {
      var arrayToAdd = this.unusedResultHeaders;
      var arrayToRemove = this.resultsFormatBeingEdited.format;
    }
    if (arrayToRemove.length > 1 || add) {
      var index = arrayToRemove.indexOf(headerName);
      if (index !== -1) arrayToRemove.splice(index, 1);
      arrayToAdd.unshift(headerName);
    }
  }

  userResultsFormatEditingChanged() {
    this.updateUnusedHeaders(this.resultsFormatBeingEdited.format);
  }

  updateUnusedHeaders(userResultsFormat) {
    // Firstly, parse the data from the backend.
    this.resultsFormatBeingEdited.format = [];
    userResultsFormat.forEach(
      function (el) {
        try {
          this.resultsFormatBeingEdited.format.push(JSON.parse(el));
        } catch (err) {
          this.resultsFormatBeingEdited.format.push(el);
        }
      }.bind(this)
    );

    // Set the unused headers on standard header names.
    this.unusedResultHeaders = this.zintHeaderNames.filter(
      headerName => userResultsFormat.indexOf(headerName) == '-1'
    );

    // Set the unused headers on custom columns.
    // Firstly, add the propensity model component columns to the list of all custom columns.
    var data_custom_columns = this.customColumns;
    var propensityModelComponentsColumns = data_custom_columns
      .map(col => Object.assign({}, col))
      .filter(col => col.is_propensity);
    propensityModelComponentsColumns.map(
      el => (el.is_propensity_model_components_column = true)
    );
    data_custom_columns = data_custom_columns.concat(
      propensityModelComponentsColumns
    );

    var unusedResultsCustomColumns = data_custom_columns
      .map(function (el) {
        return {
          name: el.name,
          id: el.id,
          is_propensity_model_components_column:
            el.is_propensity_model_components_column ? true : false,
        };
      })
      .filter(
        columnDetail =>
          this.resultsFormatBeingEdited.format
            .map(
              // Check uniqueness on the ID of the column and on whether it is a propensity model component column.
              function (el) {
                return el.id + '__' + el.is_propensity_model_components_column;
              }
            )
            .indexOf(
              columnDetail.id +
                '__' +
                columnDetail.is_propensity_model_components_column
            ) == -1
      );
    this.unusedResultHeaders = this.unusedResultHeaders.concat(
      unusedResultsCustomColumns
    );
  }

  createNewResultsFormat() {
    this.resultsFormatEditorService
      .createNewResultsFormat('user', this.newResultsFormatName, [
        'Company Name',
      ])
      .subscribe(data => this.postCreateAddNewIndividualResultsFormat(data));
  }

  createNewOrganisationResultsFormat() {
    this.resultsFormatEditorService
      .createNewResultsFormat('organisation', this.newResultsFormatName, [
        'Company Name',
      ])
      .subscribe(data => this.postCreateAddNewOrganisationResultsFormat(data));
  }

  postCreateAddNewIndividualResultsFormat(data) {
    this.individualResultsFormatSettings.push(data);
    this.resultsFormatBeingEdited =
      this.individualResultsFormatSettings[
        this.individualResultsFormatSettings.length - 1
      ];
    this.userResultsFormatEditingChanged();
  }

  postCreateAddNewOrganisationResultsFormat(data) {
    this.organisationResultsFormatSettings.push(data);
    this.resultsFormatBeingEdited =
      this.organisationResultsFormatSettings[
        this.organisationResultsFormatSettings.length - 1
      ];
    this.userResultsFormatEditingChanged();
  }

  saveResultsFormat(): void {
    this.resultsFormatEditorService
      .saveResultsFormat(
        'user',
        this.resultsFormatBeingEdited.id,
        this.resultsFormatBeingEdited.format,
        this.resultsFormatBeingEdited.name
      )
      .subscribe(data =>
        this.processIndividualResultsFormatColumnsChanged(
          this.resultsFormatBeingEdited.id,
          data
        )
      );
  }

  saveOrganisationResultsFormat(): void {
    this.resultsFormatEditorService
      .saveResultsFormat(
        'organisation',
        this.resultsFormatBeingEdited.id,
        this.resultsFormatBeingEdited.format,
        this.resultsFormatBeingEdited.name
      )
      .subscribe(data =>
        this.processOrganisationResultsFormatColumnsChanged(
          this.resultsFormatBeingEdited.id,
          data
        )
      );
  }

  dragSort(event: CdkDragDrop<any>): void {
    moveItemInArray(
      this.resultsFormatBeingEdited.format,
      event.previousIndex,
      event.currentIndex
    );
  }

  processIndividualResultsFormatColumnsChanged(idOfChangedFormat, data): void {
    if (data.id) {
      this.resultsFormatBeingEdited = data;
      this.individualResultsFormatSettings =
        this.individualResultsFormatSettings.filter(
          format => format.id !== idOfChangedFormat
        );
      this.individualResultsFormatSettings.push(data);
      this.userResultsFormatEditingChanged();
    }
  }

  processOrganisationResultsFormatColumnsChanged(
    idOfChangedFormat,
    data
  ): void {
    if (data.id) {
      this.resultsFormatBeingEdited = data;
      this.organisationResultsFormatSettings =
        this.organisationResultsFormatSettings.filter(
          format => format.id !== idOfChangedFormat
        );
      this.organisationResultsFormatSettings.push(data);
      this.userResultsFormatEditingChanged();
    }
  }

  changeDefaultFormat(formatId): void {
    if (formatId) {
      this.resultsFormatEditorService
        .selectDefaultResultsFormat('user', formatId)
        .subscribe(data => this.validateSuccessfulSettingsChange(data));
    }
  }

  changeDefaultOrganisationFormat(formatId): void {
    this.resultsFormatEditorService
      .selectDefaultResultsFormat('organisation', formatId)
      .subscribe(data => this.validateSuccessfulSettingsChange(data));
  }

  deleteFormat(): void {
    if (this.resultsFormatBeingEdited) {
      this.resultsFormatEditorService
        .deleteResultsFormat('user', this.resultsFormatBeingEdited.id)
        .subscribe(data =>
          this.processFormatDeletedResponse(
            data,
            this.resultsFormatBeingEdited.id
          )
        );
    }
  }

  deleteOrganisationFormat(): void {
    if (this.resultsFormatBeingEdited) {
      this.resultsFormatEditorService
        .deleteResultsFormat('organisation', this.resultsFormatBeingEdited.id)
        .subscribe(data =>
          this.processFormatDeletedResponse(
            data,
            this.resultsFormatBeingEdited.id
          )
        );
    }
  }

  processFormatDeletedResponse(data, idDeleted): void {
    if (data.success) {
      this.pubMethods.showInfoMessage('Deleted Successfully');
      this.individualResultsFormatSettings =
        this.individualResultsFormatSettings.filter(
          format => format.id !== idDeleted
        );
      this.organisationResultsFormatSettings =
        this.organisationResultsFormatSettings.filter(
          format => format.id !== idDeleted
        );
      if (
        this.individualResultsFormatSettings &&
        this.individualResultsFormatSettings.length > 0
      ) {
        this.resultsFormatBeingEdited = this.individualResultsFormatSettings[0];
      } else {
        this.resultsFormatBeingEdited = null;
      }
    }
  }
}
