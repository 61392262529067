<div class="modal fade" id="customColumnEditModal" tabindex="-1" role="dialog" aria-labelledby="customColumnEditModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="customColumnEditModalLabel">Edit Custom Attributes on these Organisations</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <span>Choose Attribute</span>
                <select [(ngModel)]="columnToAssignSelected" class="custom-select" name="columnToAssignSelected">
                    <ng-container *ngFor="let customColumn of inputParams.customColumns">
                         <option *ngIf="customColumn.can_edit || isSuperUser" [ngValue]="customColumn">{{customColumn.name}}</option>
                    </ng-container>
                </select>
                <br><br>
                <span *ngIf="columnToAssignSelected">Set Value:</span>
                <custom-column-edit
                        *ngIf="columnToAssignSelected"
                        [inputParams]="{
                            'customColumn': columnToAssignSelected,
                            'companyIds': companyIds,
                            'displayTight': false,
                            'submitThroughButton': true,
                            'isSuperUser': isSuperUser,
                            'teamMembers': inputParams.teamMembers
                         }" (onCustomColumnSaved)="showBulkAssignedMessageAndUpdateResults($event[0])"></custom-column-edit>
                <span *ngIf="bulkAssignedMessageVisible"> &nbsp;These attributes were successfully updated.</span>
            </div>
        </div>
    </div>
</div>
