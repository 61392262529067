import { Component, OnInit } from '@angular/core';
import { MarketMappingReportService } from '../../services/market-mapping-report.service';
import { publicMethods, baseUrl } from '../../globals';

@Component({
  selector: 'market-mapping-report',
  templateUrl: './market-mapping-report.component.html',
  styleUrls: ['./market-mapping-report.component.css'],
})
export class MarketMappingReportComponent implements OnInit {
  userTags: Array<any> = [];
  customColumns: Array<any> = [];
  selectedTagId: number;
  selectedClosedDateCustomColumnId: number;
  selectedChurnedDateCustomColumnId: number;
  selectedContractValueCustomColumnId: number;
  selectedFinancialYearMonthStart: number;

  isRunningReport = false;
  percentageComplete = 0;

  constructor(
    private pubMethods: publicMethods,
    private marketMappingReportService: MarketMappingReportService
  ) {}

  isLoading = this.marketMappingReportService.isLoading;

  ngOnInit() {
    document.title = 'Market Mapping';
    this.testIfAllowedToRun();
  }

  testIfAllowedToRun() {
    this.marketMappingReportService
      .getMarketMappingStatus()
      .subscribe(data => this.updateMarketMappingInfo(data));
  }

  updateMarketMappingInfo(data): void {
    if (data.is_running) {
      this.isRunningReport = true;
      this.percentageComplete = data.percent_complete;
      setTimeout(() => {
        this.testIfAllowedToRun();
      }, 5000);
    } else {
      this.isRunningReport = false;
      this.percentageComplete = 0;
      this.userTags = data.user_tags;
      this.customColumns = data.custom_columns;
    }
  }

  cancelMarketMappingReport() {
    this.marketMappingReportService
      .cancelMarketMappingProcess()
      .subscribe(data => this.updateMarketMappingInfo(data));
  }

  submitMarketMappingReport(): void {
    if (
      !this.selectedTagId ||
      !this.selectedClosedDateCustomColumnId ||
      !this.selectedChurnedDateCustomColumnId ||
      !this.selectedContractValueCustomColumnId ||
      !this.selectedFinancialYearMonthStart
    ) {
      this.pubMethods.log(
        'Please ensure you have selected all required options.'
      );
    } else {
      this.marketMappingReportService
        .submitMarketMappingReportDetails(
          this.selectedTagId,
          this.selectedClosedDateCustomColumnId,
          this.selectedChurnedDateCustomColumnId,
          this.selectedContractValueCustomColumnId,
          this.selectedFinancialYearMonthStart
        )
        .subscribe(data => this.updateMarketMappingInfo(data));
    }
  }
}
