import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ZendeskSsoService } from '../../services/zendesk-sso.service';
import { PushMessagesService } from '../../services/push-messages.service';
import { ZintGrowService } from '../../services/zint-grow.service';

declare var zE: any; // Zendesk SDK variable

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  logoutDelay: number = 20000;
  timeLeft: number;
  timer: any;
  constructor(
    private auth: AuthenticationService,
    private zintGrowService: ZintGrowService,
    private zendeskService: ZendeskSsoService,
    private pushMessagesService: PushMessagesService
  ) {}

  ngOnInit(): void {
    this.startCountdown();
    this.logout();
  }

  logout(): void {
    // Logout the user from Zendesk chat as well.
    this.zendeskService.logoutZendeskUser();
    this.pushMessagesService.deleteCachedPushMessages();
    this.zintGrowService.removeZGUnreadCountCookie();
    this.auth.logoutUser().subscribe();
  }

  startCountdown() {
    this.timeLeft = this.logoutDelay / 1000;
    this.timer = setInterval(() => {
      this.timeLeft -= 1;
      if (this.timeLeft <= 0) {
        this.stopCountdown();
        this.redirectToLogin();
      }
    }, 1000);
  }

  redirectToLogin() {
    window.location.replace('/login');
  }

  stopCountdown() {
    clearInterval(this.timer);
  }

  ngOnDestroy(): void {
    this.stopCountdown();
  }
}
