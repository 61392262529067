
@if (isParentOrgSuperUser && bulkDownloadDetails.length > 0){
    <mat-expansion-panel class="mb-3">
        <mat-expansion-panel-header>
            <mat-panel-title style="font-weight: 100; font-size: 1.5em">
                <i class="fal fa-download mr-1"></i> Manage Bulk Exports
            </mat-panel-title>
        </mat-expansion-panel-header>
        <hr>
        @for (bulkDownload of bulkDownloadDetails; track $index){
            <div class="large-margin-bottom">
                <h4 class="fw-100 mb-4">Bulk Download Quota {{$index + 1}}</h4>
                <div class="row mb-5">
                    <div class="col-md">
                        <div class="zint-data-point-holder">
                            <h4>Quota Remaining</h4>
                            <span>{{bulkDownload.current_counter}}</span>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="zint-data-point-holder">
                            <h4>Expires</h4>
                            <span>{{bulkDownload.expires | date:'d MMM y'}}</span>
                        </div>
                    </div>
                </div>

                @defer (when organisationTeams || organisationUsers){
                    <permissions [objectWithPermissions]="bulkDownload"
                             [isSuperUser]="isParentOrgSuperUser"
                             [organisationTeams]="organisationTeams"
                             [organisationUsers]="organisationUsers"
                             [postParamName]="'bulkDownloadId'"
                             [urlAppendString]="'bulk_download'"
                             [showEditPermissions]="false"
                             (permissionChanged)="getBulkDownloadDetails()"
                    ></permissions>
                }
            </div>
        }
    </mat-expansion-panel>
}



