import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AiPitchBuilderService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    awaitingResponse: false,
    isLoadingConfig: false,
  };

  getAIPitchBuilderConfig(): Observable<any> {
    let params = new HttpParams();
    this.isLoading['isLoadingConfig'] = true;
    return this.http
      .post(
        baseUrl + '/get_pitch_builder_config/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingAlerts'] = false)),
        catchError(
          this.pubMethods.handleError('getAlertDetails', [], this.isLoading)
        )
      );
  }

  AddNewWhoWeHelp(
    newWhoWeHelpCategory,
    newWhoWeHelpHowWeHelp
  ): Observable<any> {
    let params = new HttpParams()
      .set('newWhoWeHelpCategory', newWhoWeHelpCategory)
      .set('newWhoWeHelpHowWeHelp', newWhoWeHelpHowWeHelp);
    this.isLoading['awaitingResponse'] = true;
    return this.http
      .post(
        baseUrl + '/pitch_builder_new_who_we_help/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['awaitingResponse'] = false)),
        catchError(
          this.pubMethods.handleError('callDeleteAlert', [], this.isLoading)
        )
      );
  }

  submitConfiguration(aboutUs, tagLine, whoWeHelp, clients): Observable<any> {
    let params = new HttpParams()
      .set('aboutUs', aboutUs)
      .set('tagLine', tagLine)
      .set('whoWeHelp', this.pubMethods.stringifyListForGetParams(whoWeHelp))
      .set('clients', clients);
    this.isLoading['awaitingResponse'] = true;
    return this.http
      .post(
        baseUrl + '/pitch_builder_submit_new_configuration/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['awaitingResponse'] = false)),
        catchError(
          this.pubMethods.handleError('callDeleteAlert', [], this.isLoading)
        )
      );
  }
}
