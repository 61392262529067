<div class="financials-table-container">
  <table class="table financials-table table-layout-fixed">
    <thead>
      <tr class="header-row">
        <th scope="col" class="sticky-col">{{tableName}}</th>
        @for (colObj of tableData.columns; track $index) {
          <th scope="col">
            <a [href]="colObj.url_to_pdf_file" target="_blank" class="text-reset" matTooltip="View Accounts">
              {{colObj.column_name}}
              <i class="fal fa-external-link text-primary"></i>
            </a>
          </th>
        }
        <th *ngIf="showTrend" scope="col">Trend</th>
      </tr>
    </thead>

    <tbody>
      @for (rowObj of tableData.rows; track $index) {
      <tr>
        <th scope="row" class="sticky-col text-break" [width]="80">{{rowObj.name}}</th>
        @for (colObj of tableData.columns; track $index) {
        <td>
          @if (rowObj.data[colObj.column_name]) {
          {{ rowObj.data[colObj.column_name] | companyDataPipe: pubMethods.getFormattingType(rowObj.type) }}
          }
          @else {
          &#65343;&#65343;&#65343;&#65343;
          }
        </td>

        }
        @if (showTrend) {
        <td>
          <sparkline [data]="trendData[rowObj.name]" [formatType]="rowObj.type" [width]="90" [height]="22" [lineColor]="'var(--zint-grey-500)'">
          </sparkline>
        </td>
        }
      </tr>
      }
    </tbody>
  </table>
</div>