import { Component, Input } from '@angular/core';

@Component({
  selector: 'ai-insights-modal',
  templateUrl: './ai-insights-modal.component.html',
  styleUrl: './ai-insights-modal.component.css',
})
export class AiInsightsModalComponent {
  @Input() financials: Record<string, any>[] = [];
  @Input() aiAccountsSummaries: Record<string, any>[] = [];
  @Input() companyId: string = '';

  getSelectedYearAccountObj(
    yearAccountID: string | number
  ): Record<string, any>[] {
    return this.financials.filter(obj => obj.id === yearAccountID);
  }
}
