import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'upload-companies',
  templateUrl: './upload-companies.component.html',
  styleUrls: ['./upload-companies.component.css'],
})
export class UploadCompaniesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
