import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { from } from 'rxjs/internal/observable/from';

@Injectable()
export class GlobalSearchService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingCompanyNameSearch: false,
  };

  searchCompanyByName(companyName): Observable<any> {
    if (companyName.length > 2) {
      this.isLoading['loadingCompanyNameSearch'] = true;
      let params = new HttpParams().set('company_name', String(companyName));

      return this.http
        .post(
          baseUrl + '/company_name_search/',
          params,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => (this.isLoading['loadingCompanyNameSearch'] = false)),
          catchError(
            this.pubMethods.handleError(
              'searchCompanyByName',
              [],
              this.isLoading
            )
          )
        );
    } else {
      return from([]);
    }
  }
}
