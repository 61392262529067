import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'priority-dots',
  templateUrl: './priority-dots.component.html',
  styleUrls: ['./priority-dots.component.css'],
})
export class PriorityDotsComponent implements OnInit {
  @Input() importance: number;
  @Input() shownInTopPanel: boolean;

  priorityColors = [
    '',
    'var(--zint-yellow-200)',
    'var(--zint-yellow-400)',
    'var(--zint-orange-400)',
    'var(--zint-red-300)',
    'var(--zint-red-600)',
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.importance < 0 || this.importance > 5) {
      this.importance = 0;
    }
  }
}
