import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CompanyMatcherV2Service } from '../../services/company-matcher-v2.service';
import { GlobalSearchService } from '../../services/global-search.service';
import { baseUrl } from '../../globals';
import { ZintNgxSliderComponent } from '../zint-ngx-slider/zint-ngx-slider.component';

@Component({
  selector: 'company-matcher',
  templateUrl: './company-matcher.component.html',
  styleUrls: ['./company-matcher.component.css'],
})
export class CompanyMatcherComponent implements OnInit {
  lowerMatchLevel: number = null;
  upperMatchLevel: number = null;

  @Input()
  inputParams: any = {
    searchMatcherDetailsEndpoint: '',
    downloadMatcherDetailsForFilterEndpoint: '',
    downloadMatcherDetailsForPageEndpoint: '',
  };

  @ViewChild('matchLevelSliderElement')
  matchLevelSliderElement: ZintNgxSliderComponent;

  total: any;
  numberOfResults: any;
  pageNumber = 1;
  resultsPerPage = 100;
  numberOfPages: any;

  isLoading = this.companyMatcherV2Service.isLoading;
  columnHeaders: Array<any>;
  rowsOfMatchedData: Array<any>;

  companyNameSearch = '';
  shortlistedCompaniesByName: Array<any>;

  rowIdShowingSearch;

  constructor(
    private companyMatcherV2Service: CompanyMatcherV2Service,
    private companyMatcherGlobalSearchService: GlobalSearchService
  ) {}

  ngOnInit(): void {
    this.lowerMatchLevel = 0;
    this.upperMatchLevel = 100;

    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
    });
  }

  loadDataForUser(): void {
    this.searchMatcherResults(true);
  }

  updateRadio(possibleMatch, possibleMatchesInRow): void {
    const radioInput = document.getElementById(
      'possibleMatch__' + possibleMatch.id
    ) as HTMLInputElement;
    const originalSelectState = possibleMatch.selected;
    this.unselectPossibleMatches(possibleMatchesInRow);
    if (!originalSelectState) {
      radioInput.checked = true;
      possibleMatch.selected = possibleMatch.id;
    }
  }

  updateDataOnPage(data): void {
    this.columnHeaders = data.column_headers;
    this.rowsOfMatchedData = data.rows;
    this.numberOfResults = data.count;
    if (data.total) {
      this.total = data.total;
    }
    this.numberOfPages = Math.ceil(this.numberOfResults / this.resultsPerPage);
    this.updateMatchLevel(this.matchLevelSliderElement);
  }

  downloadPageOfResults(): void {
    var rowIds = this.rowsOfMatchedData.map(function (row) {
      return row.row_id;
    });
    window.open(
      baseUrl +
        this.inputParams.downloadMatcherDetailsForPageEndpoint +
        '?rowIds=[' +
        rowIds +
        ']'
    );
  }

  downloadAllResults(): void {
    window.open(
      baseUrl +
        this.inputParams.downloadMatcherDetailsForFilterEndpoint +
        '?lowerMatchLevel=' +
        this.lowerMatchLevel +
        '&upperMatchLevel=' +
        this.upperMatchLevel
    );
  }

  unselectPossibleMatches(possibleMatches): void {
    possibleMatches.forEach(function (possibleMatch) {
      const radioInput = document.getElementById(
        'possibleMatch__' + possibleMatch.id
      ) as HTMLInputElement;
      radioInput.checked = false;
      possibleMatch.selected = null;
    });
  }

  selectAllFirstMatches(): void {
    this.rowsOfMatchedData.forEach(function (row) {
      if (row.possible_matches && row.possible_matches.length > 0) {
        const radioInput = document.getElementById(
          'possibleMatch__' + row.possible_matches[0].id
        ) as HTMLInputElement;
        radioInput.checked = true;
        row.possible_matches[0].selected = row.possible_matches[0].id;
      }
    });
  }

  processCompaniesFromNameSearch(data): void {
    if (data) {
      this.shortlistedCompaniesByName = data;
    }
  }

  matcherSearchForCompanyByName(): void {
    if (this.companyNameSearch.length > 2) {
      this.companyMatcherGlobalSearchService
        .searchCompanyByName(this.companyNameSearch)
        .subscribe(data => this.processCompaniesFromNameSearch(data));
    }
  }

  addCompanyToRow(row, company): void {
    row.possible_matches.push({
      id: 'uniqueId' + String(Date.now()),
      number: company.company_number,
      name: company.company_name,
      company_id: company.company_id,
      probability: null,
    });
  }

  updateMatchLevel(sliderEvent: any): void {
    if (sliderEvent['lower'] !== undefined) {
      this.lowerMatchLevel = sliderEvent['lower'];
    }
    if (sliderEvent['upper'] !== undefined) {
      this.upperMatchLevel = sliderEvent['upper'];
    }
  }

  searchMatcherResults(isInit): void {
    this.companyMatcherV2Service
      .searchMatchedData(
        this.inputParams.searchMatcherDetailsEndpoint,
        this.lowerMatchLevel,
        this.upperMatchLevel,
        this.pageNumber,
        this.resultsPerPage,
        isInit
      )
      .subscribe(data => this.updateDataOnPage(data));
  }

  @Output() ParentFunctionForSelectedOnPage: EventEmitter<any> =
    new EventEmitter();
  ChildFunctionForSelectedOnPage(): void {
    var rowIdsToSelectedCompanyIds = {};
    for (var row of this.rowsOfMatchedData) {
      for (var possibleMatch of row.possible_matches) {
        if (possibleMatch.selected) {
          rowIdsToSelectedCompanyIds[parseInt(row.row_id)] =
            possibleMatch.company_id;
        }
      }
    }
    this.ParentFunctionForSelectedOnPage.emit(rowIdsToSelectedCompanyIds);
  }

  @Output() ParentFunctionForAll: EventEmitter<any> = new EventEmitter();
  ChildFunctionForAll(): void {
    this.ParentFunctionForAll.emit([
      this.lowerMatchLevel,
      this.upperMatchLevel,
    ]);
  }

  @Output() ParentCancelMatchingProcess: EventEmitter<any> = new EventEmitter();
  ChildCancelMatchingProcess(): void {
    this.ParentCancelMatchingProcess.emit();
    setTimeout(() => this.loadDataForUser(), 1000);
  }
}
