import { Component, Input } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexTooltip,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexStroke,
  ApexGrid,
  ApexMarkers,
  ApexAnnotations,
  ApexNoData,
  ApexPlotOptions,
} from 'ng-apexcharts';
import { CompanyDataPipe } from '../../../pipes/company-data.pipe';
import { publicMethods } from '../../../globals';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  colors: string[];
  labels: string[] | number[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
  grid: ApexGrid;
  markers: ApexMarkers;
  annotations: ApexAnnotations;
  noData: ApexNoData;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'sparkline',
  templateUrl: './sparkline.component.html',
  styleUrl: './sparkline.component.css',
  providers: [CompanyDataPipe],
})
export class SparklineComponent {
  @Input() data: Array<number> = [];
  @Input() formatType: string = 'funds';
  @Input() width: number = 200;
  @Input() height: number = 24;
  @Input() thickness: number = 2;
  @Input() showTooltip: boolean = true;
  @Input() tooltipLabel: string = '';
  @Input() lineColor: string = 'var(--zint-vivid-blue)';

  chartConfig: Partial<ChartOptions> = {};

  constructor(
    private companyDataPipe: CompanyDataPipe,
    private pubMethods: publicMethods
  ) {}

  ngOnInit() {
    const self = this;
    this.chartConfig = {
      series: [
        {
          name: this.tooltipLabel,
          data: this.data,
          color: this.lineColor, // corresponds with tooltip marker
        },
      ],
      chart: {
        type: 'line',
        width: this.width,
        height: this.height,
        sparkline: {
          enabled: true,
        },
      },

      stroke: {
        width: this.isSandwichedByNulls(this.data) ? 2.8 : this.thickness, // thickness must be more for the isolated points in null sandwiches
        colors: [this.lineColor],
        curve: 'smooth',
      },

      tooltip: {
        enabled: this.showTooltip,
        style: {
          fontSize: '12px',
        },
        followCursor: true,
        x: {
          show: false,
        },
        y: {
          formatter: function (val) {
            if (val === null) return 'no data';
            return `${self.companyDataPipe.transform(val, self.pubMethods.getFormattingType(self.formatType))}`;
          },
          title: {
            formatter: function (seriesName) {
              return seriesName;
            },
          },
        },
        marker: {
          show: false,
        },
      },
    };
  }

  isSandwichedByNulls(arr): boolean {
    return arr.some(
      (current, index) =>
        current !== null &&
        typeof current === 'number' &&
        arr[index - 1] === null &&
        arr[index + 1] === null
    );
  }
}
