<div class="container">
    <div class="row">
        <div class="col-md-12 small-margin-bottom">
            <h4>Generate a Market Mapping Report</h4>

            <div *ngIf="isLoading.loadingInitialData && !isRunningReport" style="font-size: 20px; text-align: center">
                <i class="fal fa-spinner fa-spin"></i>
            </div>


            <div class="col-md-12" *ngIf="isRunningReport">
                <div class="progress medium-margin-top">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuenow]="percentageComplete" aria-valuemin="0" aria-valuemax="100" [style.width]="percentageComplete + '%'" style="background-color: #000A36"></div>
                </div>
                <p *ngIf="percentageComplete == 0">Your market mapping report is in a queue and will run shortly.</p>
                <p *ngIf="percentageComplete != 0">Your market mapping report is running and is {{ percentageComplete | number  }}% complete.</p>
                <p>You can safely navigate away from this page and will be emailed when the process is complete.</p>
                <button class="basic-button-base button-dark small-margin-top" style="font-size: 23px; float: right" (click)="cancelMarketMappingReport()" [attr.disabled]="isLoading.loadingCancelMarketMappingReport ? 'disabled': null"><ng-container *ngIf="isLoading.loadingCancelMarketMappingReport"><i class="fal fa-spinner fa-spin"></i></ng-container> Cancel Process</button>
            </div>


            <div *ngIf="!isLoading.loadingInitialData && !isRunningReport">

                <span>Select a tag to run the market mapping report for:<br></span>
                <div class="form-group select-table overflowing-table" style="padding: 15px">
                    <span *ngIf="userTags && userTags.length == 0">You do not have any companies tagged</span>
                    <select [(ngModel)]="selectedTagId" name="selectedTagId" class="custom-select">
                        <option *ngFor="let tag of userTags" [ngValue]="tag.id">{{tag.name}}</option>
                    </select>
                </div>


                <span>Select the closed date custom column:<br></span>
                <div class="form-group select-table overflowing-table" style="padding: 15px">
                    <span *ngIf="customColumns && customColumns.length == 0">You do not have any custom columns.</span>
                    <select [(ngModel)]="selectedClosedDateCustomColumnId" name="selectedClosedDateCustomColumnId" class="custom-select">
                        <ng-container *ngFor="let customColumn of customColumns">
                            <option *ngIf="customColumn.column_type == 'Date' && customColumn.id != selectedChurnedDateCustomColumnId" [ngValue]="customColumn.id">{{customColumn.name}}</option>
                        </ng-container>
                    </select>
                </div>


                <span>Select the churned date custom column:<br></span>
                <div class="form-group select-table overflowing-table" style="padding: 15px">
                    <select [(ngModel)]="selectedChurnedDateCustomColumnId" name="selectedChurnedDateCustomColumnId" class="custom-select">
                        <ng-container *ngFor="let customColumn of customColumns">
                            <option *ngIf="customColumn.column_type == 'Date' && customColumn.id != selectedClosedDateCustomColumnId" [ngValue]="customColumn.id">{{customColumn.name}}</option>
                        </ng-container>
                    </select>
                </div>


                <span>Select the contract value custom column:<br></span>
                <div class="form-group select-table overflowing-table" style="padding: 15px">
                    <select [(ngModel)]="selectedContractValueCustomColumnId" name="selectedContractValueCustomColumnId" class="custom-select">
                        <ng-container *ngFor="let customColumn of customColumns">
                            <option *ngIf="customColumn.column_type == 'Number'" [ngValue]="customColumn.id">{{customColumn.name}}</option>
                        </ng-container>
                    </select>
                </div>

                <span>Select the month your financial year starts (used for year-by-year analysis):<br></span>
                <div class="form-group select-table overflowing-table" style="padding: 15px">
                    <select [(ngModel)]="selectedFinancialYearMonthStart" name="selectedFinancialYearMonthStart" class="custom-select">
                        <option [ngValue]="1">Jan</option>
                        <option [ngValue]="2">Feb</option>
                        <option [ngValue]="3">Mar</option>
                        <option [ngValue]="4">Apr</option>
                        <option [ngValue]="5">May</option>
                        <option [ngValue]="6">Jun</option>
                        <option [ngValue]="7">Jul</option>
                        <option [ngValue]="8">Aug</option>
                        <option [ngValue]="9">Sep</option>
                        <option [ngValue]="10">Oct</option>
                        <option [ngValue]="11">Nov</option>
                        <option [ngValue]="12">Dec</option>
                    </select>
                </div>

                <button
                        class="basic-button-base button-dark"
                        (click)="submitMarketMappingReport()"
                        style="float: right"
                        [attr.disabled]="isLoading.validatingMarketMappingData ? 'disabled': null">
                    <ng-container *ngIf="isLoading.validatingMarketMappingData">
                        <i class="fal fa-spinner fa-spin"></i>
                    </ng-container>
                    <ng-container *ngIf="!isLoading.validatingMarketMappingData">
                        <i class="fal fa-book"></i>
                    </ng-container> Generate Report
                </button>
            </div>
        </div>
    </div>
</div>
