import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCaseToProper' })
export class CamelCaseToProperPipe implements PipeTransform {
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  transform(inputString) {
    var result = inputString.replace(/([A-Z])/g, ' $1');
    return this.toTitleCase(result);
  }
}
