<div class="container">

    <div class="row medium-margin-bottom" *ngIf="isParentOrgSuperUser">
        <div class="col-md-12">
            <h3>Organisation-Level Settings:</h3>
            <p>Manage teams and view administrator settings for your entire organisation:</p>
            <a href="/manage-organisation" class="basic-button-base button-dark" type="button"><i class="fal fa-building"></i> Organisation Settings</a>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <h3>Manage Your Team:</h3>
            <table class="table table-striped">
                <tr>
                    <td>Team Name</td>
                    <td>{{ organisationName }}</td>
                </tr>
                <tr>
                    <td>Maximum Number of Users</td>
                    <td>{{ maxNumberOfAccounts }}</td>
                </tr>
                <tr>
                    <td>Monthly Download Limit between all Users</td>
                    <td>{{ downloadsUsed }} / {{ totalDownloadsBetweenAllAccounts }}</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="row medium-margin-top">
        <div class="col-md-6">
            <h5 class="text-center">Tag Viewing Status:</h5>
            <div class="input-group">
                <select [(ngModel)]="tagViewingStatus" class="custom-select">
                    <option *ngFor="let tagViewingStatusOption of tagViewingStatusOptions" [ngValue]="tagViewingStatusOption[0]">
                        {{ tagViewingStatusOption[1] }}
                    </option>
                </select>
                <div class="input-group-append">
                    <button class="basic-button-base button-dark" type="button" (click)="updateTagViewLevel()" [attr.disabled]="isLoading.loadingData ? 'disabled': null" style="border-left-width: 2px">Update</button>
                </div>
            </div>
        </div>
        <div class="col-md-6 text-center" *ngIf="hasSalesforceV2Access && !paramsOrgId">
            <h5>CRM integrations settings for your team:</h5>
            <a href="/salesforce"><i class="fab fa-salesforce"></i> Salesforce</a>
        </div>
    </div>


    <div class="row medium-margin-top">
        <div class="col-md-12">

            <h5>Manage Users:</h5>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Email Address</th>
                        <th>Downloads Assigned</th>
                        <th>Reset Password</th>
                        <th>Delete User Account</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let teamMember of teamMembers">
                        <td>
                            <span *ngIf="teamMemberIdEditingEmail != teamMember.userId"> {{ teamMember.email }} <i class="fal fa-edit cursor-pointer" (click)="teamMemberIdEditingEmail = teamMember.userId"></i> </span>
                            <div class="input-group" *ngIf="teamMemberIdEditingEmail == teamMember.userId">
                                <input type="text" class="form-control" [(ngModel)]="teamMember.email">
                                <div class="input-group-append">
                                    <button class="basic-button-base button-dark" type="button" style="border-left-width: 2px" (click)="teamMemberIdEditingEmail = null"><i class="fal fa-times"></i></button>
                                    <button class="basic-button-base button-dark" type="button" (click)="updateUserEmailAddress(teamMember.userId, teamMember.email)" [attr.disabled]="isLoading.loadingData ? 'disabled': null"><i class="fal fa-save"></i></button>
                                </div>
                            </div>
                        </td>

                        <td>
                            <span *ngIf="teamMemberIdEditingDownloads != teamMember.userId"> {{ teamMember.downloadsUsed }} / {{ teamMember.downloadLimit }} <i class="fal fa-edit cursor-pointer" (click)="teamMemberIdEditingDownloads = teamMember.userId"></i> </span>
                            <div class="input-group" *ngIf="teamMemberIdEditingDownloads == teamMember.userId">
                                <input type="number" class="form-control" [(ngModel)]="teamMember.downloadLimit">
                                <div class="input-group-append">
                                    <button class="basic-button-base button-dark" type="button" style="border-left-width: 2px" (click)="teamMemberIdEditingDownloads = null"><i class="fal fa-times"></i></button>
                                    <button class="basic-button-base button-dark" type="button" (click)="updateUserDownloadLimit(teamMember.userId, teamMember.downloadLimit)" [attr.disabled]="isLoading.loadingData ? 'disabled': null"><i class="fal fa-save"></i></button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button class="basic-button-base button-dark" type="button" (click)="sendPasswordResetEmail(teamMember.userId)" [attr.disabled]="isLoading.loadingData ? 'disabled': null">Send Password Reset Email</button>
                        </td>

                        <td>
                            <div style="text-align: center;">
                                <button class="basic-button-base button-dark"
                                        *ngIf="!teamMember.isOrganisationAdmin"
                                        (click)="teamMemberToDelete = teamMember;"
                                        style="margin-top: 5px"
                                        data-toggle="modal"
                                        data-target="#confirmDeleteUserAccountModal">
                                    <i class="fal fa-trash"></i> Delete
                                </button>
                            </div>
                        </td>

                    </tr>

                    <tr *ngIf="(teamMembers && teamMembers.length < maxNumberOfAccounts) || teamMembers == []">
                        <td colspan="5" class="text-center">
                            <button *ngIf="!showAddTeamMember" class="basic-button-base button-dark" type="button" (click)="showAddTeamMember = true" [attr.disabled]="isLoading.loadingData ? 'disabled': null">Add New Team Member <i class="fal fa-plus-circle"></i></button>
                            <div class="col-md-6">
                                <div class="input-group" *ngIf="showAddTeamMember">
                                    <input type="text" class="form-control" [(ngModel)]="newTeamMemberEmail" placeholder="New User Email Address">
                                    <div class="input-group-append">
                                        <button class="basic-button-base button-dark" type="button" style="border-left-width: 2px" (click)="showAddTeamMember = false "><i class="fal fa-times"></i></button>
                                        <button class="basic-button-base button-dark" type="button" (click)="addNewTeamMember(newTeamMemberEmail)" [attr.disabled]="isLoading.loadingData ? 'disabled': null"><i class="fal fa-save"></i></button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>


        </div>
    </div>
</div>



<div class="modal fade" id="confirmDeleteUserAccountModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteUserAccountModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="teamMemberToDelete">
                <p>WARNING: This will irreversibly delete the user account {{ teamMemberToDelete.email }}, and all associated data.</p>
                <p>{{ teamMemberToDelete.email }} will no longer be able to login, and their data will be permanently lost.</p>
                <button class="basic-button-base button-dark" (click)="deleteUserAccount()" [attr.disabled]="isLoading.loadingData ? 'disabled': null">
                    <ng-container *ngIf="isLoading.loadingData">
                        <i class="fal fa-spinner fa-spin"></i>
                    </ng-container> Confirm
                </button>
            </div>
        </div>
    </div>
</div>
