import { Component, OnInit } from '@angular/core';
import { LearningPortalService } from '../../services/learning-portal.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { CommunicationService } from '../../services/communication.service';
import { publicMethods } from '../../globals';
declare var $: any;

@Component({
  selector: 'learning-portal',
  templateUrl: './learning-portal.component.html',
  styleUrls: ['./learning-portal.component.css'],
})
export class LearningPortalComponent implements OnInit {
  courses;
  activeCourse;
  activeLesson;
  activeVideoUrl: string;
  urlLastUpdated: Date;
  searchTerm: string = '';
  searchResults;

  constructor(
    private learningPortalService: LearningPortalService,
    private pubMethods: publicMethods
  ) {}

  isLoading = this.learningPortalService.isLoading;

  ngOnInit() {
    this.getLearningPortalDetails();
  }

  updateLearningOptions(data): void {
    this.courses = data.courses;
  }

  getLearningPortalDetails(): void {
    this.learningPortalService
      .learningPortalDetails()
      .subscribe(data => this.updateLearningOptions(data));
  }

  // This is v complicated. Lots of binding and unbinding to stop users sharing the videos and accessing offline.
  updateActiveVideoUrl(data, shouldBindVideoToEvent, forceStartTime): void {
    this.urlLastUpdated = new Date();
    this.activeVideoUrl = data.video_url;
    if (shouldBindVideoToEvent) {
      var videoBindingSelf = this;
      $(document).ready(function () {
        var positionNumber = videoBindingSelf.activeLesson.position_number;
        let $el = $('#lessonVideo' + positionNumber);
        // Disable right click to stop downloads
        $el.bind('contextmenu', function (e) {
          return false;
        });
        $el.off();
        $el.on('loadedmetadata', function () {
          if (forceStartTime !== null) {
            this.currentTime = forceStartTime;
          } else {
            this.currentTime =
              this.duration * (videoBindingSelf.activeLesson.progress / 100);
          }
        });
        $el.on('ended', function () {
          videoBindingSelf.updateProgressOfLesson(100);
        });
        var secondsLastProcessed = null;
        var updateEveryNumberOfSeconds = 15;
        $el.on('timeupdate', function () {
          var currentSeconds = parseInt(this.currentTime);
          if (
            currentSeconds % updateEveryNumberOfSeconds == 0 &&
            secondsLastProcessed != currentSeconds
          ) {
            console.log('timeupdate 15 secs');
            secondsLastProcessed = currentSeconds;
            var percentageComplete = Math.round(
              (currentSeconds / this.duration) * 100
            );
            videoBindingSelf.updateProgressOfLesson(percentageComplete);
          }
        });
      });
    }
  }

  updateCourseProgress(data, activeCourse): void {
    activeCourse.progress = data.course_progress;
  }

  updateProgressOfLesson(percentageComplete): void {
    if (percentageComplete > this.activeLesson.progress) {
      this.activeLesson.progress = percentageComplete;
      this.learningPortalService
        .updateLessonProgress(
          this.activeCourse.position_number,
          this.activeLesson.position_number,
          percentageComplete
        )
        .subscribe(data => this.updateCourseProgress(data, this.activeCourse));
    }
  }

  getCourseFromNumber(coursePositonNumber) {
    let matchingCourses = this.courses.filter(
      course => course.position_number == coursePositonNumber
    );
    if (matchingCourses.length > 0) {
      return matchingCourses[0];
    }
    return null;
  }

  getLessonFromNumbers(coursePositonNumber, lessonPositonNumber) {
    let matchingCourse = this.getCourseFromNumber(coursePositonNumber);
    let matchingLessons = matchingCourse.lessons.filter(
      lesson => lesson.position_number == lessonPositonNumber
    );
    if (matchingLessons.length > 0) {
      return matchingLessons[0];
    }
    return null;
  }

  updateActiveCourseAndGetVideoFromPositionNumber(coursePositonNumber): void {
    let courseRequired = this.getCourseFromNumber(coursePositonNumber);
    this.updateActiveCourseAndGetVideo(courseRequired);
  }

  updateActiveLessonAndGetVideoFromPositionNumber(
    coursePositonNumber,
    lessonPositionNumber
  ): void {
    let courseRequired = this.getCourseFromNumber(coursePositonNumber);
    let lessonRequired = this.getLessonFromNumbers(
      coursePositonNumber,
      lessonPositionNumber
    );
    this.updateActiveLessonAndGetVideo(courseRequired, lessonRequired);
  }

  updateActiveCourseAndGetVideo(course): void {
    this.activeVideoUrl = null;
    this.activeCourse = course;
    this.activeLesson = null;
    this.learningPortalService
      .getActiveVideoUrlForCourse(course.position_number)
      .subscribe(data => this.updateActiveVideoUrl(data, false, null));
  }

  updateActiveLessonAndGetVideo(course, lesson): void {
    this.activeVideoUrl = null;
    this.activeCourse = course;
    if (lesson) {
      this.activeLesson = lesson;
    } else {
      if (!this.activeLesson) {
        if (this.activeCourse.lessons && this.activeCourse.lessons.length > 0) {
          this.activeLesson = this.activeCourse.lessons[0];
        }
      } else {
        var currentIndex = this.activeCourse.lessons
          .map(function (lesson) {
            return lesson.position_number;
          })
          .indexOf(this.activeLesson.position_number);
        if (currentIndex + 1 < this.activeCourse.lessons.length) {
          this.activeLesson = this.activeCourse.lessons[currentIndex + 1];
        } else {
          this.pubMethods.showInfoMessage(
            'You have reached the end of the course "' +
              this.activeCourse.title +
              '"'
          );
          this.activeLesson = null;
          this.activeCourse = null;
        }
      }
    }
    if (this.activeCourse && this.activeLesson) {
      this.learningPortalService
        .getActiveVideoUrlForLesson(
          this.activeCourse.position_number,
          this.activeLesson.position_number
        )
        .subscribe(data => this.updateActiveVideoUrl(data, true, null));
    }
  }

  updateSearchResults(data): void {
    this.searchResults = data.results;
    if (data.results.courses.length == 0 && data.results.lessons.length == 0) {
      this.pubMethods.log('No matches found');
    }
  }

  searchCoursesAndLessons(): void {
    if (!this.isLoading.loadingCourseSearch) {
      if (this.searchTerm.length < 3) {
        this.pubMethods.log('Please enter at least 3 characters.');
      } else {
        this.learningPortalService
          .searchCoursesAndLessons(this.searchTerm)
          .subscribe(data => this.updateSearchResults(data));
      }
    }
  }
}
