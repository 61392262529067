<div class="modal fade" id="openSearchModal" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalTitle">{{ openSearchModalTitle }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex flex-column">
        <div class="px-4 py-2 border-bottom">
          @switch (currentOpenSearchType) {
          @case ('website-search') {
          <h6>Search this website</h6>
          <input type="text" class="opensearch-modal-inputs-container full-width" placeholder="enter search term..."
            maxlength="100" [(ngModel)]="fullWebsiteSearchTerm" (keyup.enter)="fullWebsiteSearch()">
          }

          @case ('accounts-search') {
          <h6>Year ending</h6>
          <select [(ngModel)]="accountsDateSelected" class="custom-select border-rounded-05 mb-3">
            <option selected="selected" value="any">Any Year</option>
            @for (date of yearAccountsMadeUpToDates; track $index) {
            <option [ngValue]="date">
              {{date| date:'d MMM y' }}
            </option>
            }
          </select>
          <h6>Search for</h6>
          <input type="text" class="opensearch-modal-inputs-container full-width" placeholder="enter search term..."
            maxlength="100" [(ngModel)]="accountsSearchText" (keyup.enter)="accountsSearch()">

          }
          }

          <div class="mt-3 mb-2 d-flex flex-row-reverse justify-content-between">
            <button class="rounded-btn-base rounded-btn-vivid btn-p-lg"
              (click)="currentOpenSearchType === 'website-search' ? fullWebsiteSearch() : accountsSearch()"><i
                class="fal fa-search mr-1"></i>Search</button>

            @if (currentOpenSearchType === 'website-search') {
            <span class="align-self-end">Found {{fullWebsiteResults?.length || 0}} results.</span>
            }
            @else if (currentOpenSearchType === 'accounts-search') {
            <span class="align-self-end">Found {{accountsResults?.number_of_results || 0}} results.</span>
            }
          </div>
        </div>

        @switch (currentOpenSearchType) {
        @case ('website-search') {
        @if (loadingStatus.loadingFullWebsiteResults) {
        <zint-loader class="mx-auto"></zint-loader>
        }
        @else if (fullWebsiteResults?.length) {
        <div class="opensearch-result-container">
          @for (result of fullWebsiteResults; track $index) {
          <div class="website-result-card">
            <h5>{{result.title}}</h5>
            <span [innerHTML]="result.summary"></span>
            <a [href]="result.url" target="_blank" class="rounded-btn-base rounded-btn-light btn-p-md btn-link mt-2">Open page <i
                class="fal fa-external-link ml-1"></i></a>
          </div>
          }
        </div>
        }
        }
        @case (('accounts-search')) {
        @if (loadingStatus.loadingAccountsSearchResults) {
        <zint-loader class="mx-auto"></zint-loader>
        }
        @else if (accountsResults?.accounts_results?.length) {
        <div class="opensearch-result-container">
          @for (account of accountsResults.accounts_results; track $index) {
          <div class="accounts-result-card">
            <div class="iframe-and-text-row">
              <div class="iframe-wrapper" [ngClass]="{'wrap-xhtml': account.do_spaces_url.endsWith('.xhtml')}">
                <iframe [ngClass]="account.do_spaces_url.endsWith('.xhtml') ? 'frame-xhtml':'frame'"
                  [src]="account.do_spaces_url | safe">
                </iframe>
              </div>
              <span class="search-text-wrapper" [innerHTML]="account.search_text"></span>
            </div>
            <div class="result-info-row">
              <span matTooltip="year ending">
                <i class="fal fa-calendar-day mr-1"></i>
                {{account.made_up_to | date: 'dd MMM yyyy'}}
              </span>
              <span matTooltip="page number">
                <i class="fal" [ngClass]="account.accounts_type === 'ocr' ? 'fa-file-pdf': 'fa-file-xml'"></i>
                {{account.processedPageNumber}}
              </span>
            </div>
            <a [href]="account | xbrlOrOcrAccountsLink: 'accountsSearch': {companyId: companyNumber}" target="_blank"
              class="rounded-btn-base rounded-btn-light btn-p-md btn-link mt-2">View accounts <i
                class="fal fa-external-link fa-xs ml-1"></i></a>
          </div>
          }
        </div>
        }
        }
        }
      </div>
      <div class="modal-footer">
        <button type="button" class="rounded-btn-base btn-p-lg" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>