import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'website-search',
  templateUrl: './website-search.component.html',
  styleUrls: ['./website-search.component.css'],
})
export class WebsiteSearchComponent implements OnInit {
  @Input()
  notifications: any;

  constructor(private datePipe: DatePipe) {}

  event_metadata: any;
  companyNumber: any;
  htmlBelowImage: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.notifications[0].company_number;
    this.htmlBelowImage = ``;
  }
}
