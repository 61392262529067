import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  Options,
  CustomStepDefinition,
  LabelType,
  ChangeContext,
} from '@angular-slider/ngx-slider';
import { IConfigSlider, RangeType } from '../../classes/custom-range-slider';
import { ISliderUpdateValues } from '../../classes/types';

@Component({
  selector: 'zint-ngx-slider',
  templateUrl: './zint-ngx-slider.component.html',
  styleUrl: './zint-ngx-slider.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class ZintNgxSliderComponent {
  @Input() prefix: string = '';
  @Input() postfix: string = '';
  @Input() maxPostfix: string = '';
  @Input() isRange: boolean = true;
  @Input() showTicks: boolean = false;
  @Input() rangeValues: Array<number>;
  @Input() min: number = null;
  @Input() max: number = null;
  @Input() preDefinedSteps: number[];
  @Input() noSteps: boolean = false;
  @Input() sliderConfig: IConfigSlider;
  @Input() formName: string = '';

  @Output() sliderChange = new EventEmitter<any>();

  minValue: number;
  maxValue: number;

  options: Options;

  constructor() {}

  ngOnInit() {
    this.buildSliderOption();
  }

  buildSliderOption(): void {
    if (this.sliderConfig) {
      this.minValue = this.sliderConfig.min;
      this.maxValue = this.sliderConfig.max;
      this.min = this.sliderConfig.min;
      this.max = this.sliderConfig.max;
      this.prefix = this.sliderConfig.prefix ?? '';
      this.postfix = this.sliderConfig.postfix ?? '';
      this.maxPostfix = this.sliderConfig.maxPostfix ?? '';
      this.isRange = this.sliderConfig.isRange ?? true;
      this.preDefinedSteps = this.sliderConfig.steps ?? null;
      this.showTicks = this.sliderConfig.showTicks ?? false;
      this.formName = this.sliderConfig.formName ?? '';
    } else {
      this.minValue = this.min;
      this.maxValue = this.max;
    }

    this.options = {
      floor: this.min,
      ceil: this.max,
      showSelectionBar: true,
      animateOnMove: false,
      stepsArray: this.generateStepsArray(),
      enforceStepsArray: false,
      translate: (value: number, label: LabelType): string => {
        let lowLabelValue: number;
        let highLabelValue: number;

        if (!this.isRange) {
          lowLabelValue = this.maxValue;
          highLabelValue = this.maxValue;
        } else {
          lowLabelValue = this.minValue;
          highLabelValue = this.maxValue;
        }

        switch (label) {
          case LabelType.Low: //lower thumb label
            return this.formatLowerThumbLabel(lowLabelValue);
          case LabelType.High: //upper thumb label
            return `${this.formatLabel(highLabelValue)}${this.showMaxPostfix(this.maxValue)}`;
          case LabelType.Ceil: //ceiling limit of slider
            return `${this.formatLabel(this.options.ceil)}${this.maxPostfix}`;
          case LabelType.Floor: // floor limit of slider
            return this.formatLabel(this.options.floor);
          default:
            return `${value}`;
        }
      },
    };
  }

  generateStepsArray(): CustomStepDefinition[] {
    let stepsArray = [];

    if (this.noSteps) return null;

    if (this.preDefinedSteps) {
      stepsArray = this.preDefinedSteps.map(step => {
        return { value: step };
      });
      return stepsArray;
    }

    if (this.max <= 1000) return null;
    const customSteps = this.calculateLogSteps(this.min, this.max, 20);
    stepsArray = customSteps.map(step => {
      return { value: step };
    });

    return stepsArray;
  }

  calculateSteps(minVal: number, maxVal: number, numSteps = 10): number[] {
    const logMinVal = minVal === 0 ? 0 : Math.log10(minVal);
    const logMaxVal = Math.log10(maxVal);

    const stepSize = (logMaxVal - logMinVal) / (numSteps - 1);

    let steps: number[] = Array.from({ length: numSteps }, (_, i) => {
      const stepVal = logMinVal + stepSize * i;

      const roundedStep = stepVal === 0 ? 0 : Math.pow(10, stepVal);

      return Number(roundedStep.toPrecision(1));
    });

    return steps;
  }

  calculateLogSteps(minVal: number, maxVal: number, numSteps = 10): number[] {
    let negativeSteps: number[] = [];
    let positiveSteps: number[] = [];

    let steps = minVal < 0 ? Math.ceil(numSteps / 2) : numSteps;

    if (minVal < 0) {
      negativeSteps = this.calculateSteps(0, Math.abs(minVal), steps);

      negativeSteps = negativeSteps.map(step => -1 * step).reverse();
    }

    if (maxVal >= 0) {
      const minArg = minVal <= 0 ? 0 : minVal;
      positiveSteps = this.calculateSteps(minArg, maxVal, steps);
    }

    const allSteps = [...negativeSteps, ...positiveSteps];
    let allStepsSet = Array.from(new Set(allSteps));

    return allStepsSet;
  }

  formatLabel(value: number): string {
    if (value === undefined || value === null || isNaN(value))
      return `${this.minValue}`;
    let valueFormatted: string = '';
    const showNegative = value < 0 ? '-' : '';
    const absValue = Math.abs(value);
    if (this.postfix === '%') {
      valueFormatted = `${absValue}`;
    } else if (absValue >= 1000000000) {
      valueFormatted = parseFloat((absValue / 1000000000).toFixed(1)) + 'b';
    } else if (absValue >= 1000000) {
      valueFormatted = parseFloat((absValue / 1000000).toFixed(1)) + 'm';
    } else if (absValue >= 1000) {
      valueFormatted = parseFloat((absValue / 1000).toFixed(1)) + 'k';
    } else {
      valueFormatted = `${absValue}`;
    }

    valueFormatted = `${showNegative}${this.prefix}${valueFormatted} ${this.postfix}`;
    return `${valueFormatted}`;
  }

  showMaxPostfix(value: number) {
    if (value === this.max) {
      return this.maxPostfix;
    } else return '';
  }

  formatLowerThumbLabel(value: number): string {
    if (this.isRange) return `${this.formatLabel(value)}`;

    // format the single/non-range slider thumb
    if (!this.isRange && value === this.max) {
      return `${this.formatLabel(value)}${this.showMaxPostfix(this.maxValue)}`;
    } else return `${this.formatLabel(value)}`;
  }

  processInput(which: RangeType, inputValue: string): number {
    if (inputValue && !isNaN(parseFloat(inputValue)))
      return parseFloat(inputValue);

    return which === 'lower' ? this.minValue : this.maxValue;
  }

  handleChange(which: RangeType, event: any) {
    let value = event.target?.value;
    const regex = /^-?[0-9]+(\.[0-9]+)?[kmbKMB]?$/;

    // If input is invalid, replace with current val
    if (!regex.test(value)) {
      event.target.value = which === 'lower' ? this.minValue : this.maxValue;
      return;
    }

    const lastChar = value.slice(-1)?.toLowerCase();
    value = this.processInput(which, value);
    switch (lastChar) {
      case 'k':
        value *= 1000;
        break;
      case 'm':
        value *= 1000000;
        break;
      case 'b':
        value *= 1000000000;
        break;
      default:
        break;
    }

    // cap at default minmax
    if (value < this.min || value > this.max) {
      event.target.value = which === 'lower' ? this.min : this.max;

      value = which === 'lower' ? this.min : this.max;
    }

    let newLower = which === 'lower' ? value : this.minValue;
    let newUpper = which === 'upper' ? value : this.maxValue;

    // swap values if needed
    if (newUpper !== null && newLower > newUpper) {
      [newLower, newUpper] = [newUpper, newLower];
    }

    this.minValue = newLower;
    this.maxValue = newUpper;

    this.handleEmit();
  }

  handleWithMaxPostfix(upperValue: number): number | null {
    if (!this.maxPostfix) return upperValue;
    if (this.maxPostfix && upperValue < this.max) return upperValue;
    return null;
  }

  handleEmit(changeValue?: ChangeContext) {
    let lower: number, upper: number;
    if (changeValue) {
      const { value, highValue } = changeValue;
      lower = value;
      upper = this.handleWithMaxPostfix(highValue);
    } else {
      lower = this.minValue;
      upper = this.handleWithMaxPostfix(this.maxValue);
    }

    this.sliderChange.emit({ lower, upper });
  }

  updateSliderValues(updatedValues: ISliderUpdateValues): void {
    if (updatedValues.hasOwnProperty('lowerUpdate')) {
      this.minValue = updatedValues.lowerUpdate;
    }

    if (updatedValues.hasOwnProperty('upperUpdate')) {
      this.maxValue = updatedValues.upperUpdate;
    }

    if (this.sliderConfig) {
      const emitted = {
        lower: this.minValue !== this.min ? this.minValue : null,
        upper: this.maxValue !== this.max ? this.maxValue : null,
      };

      this.sliderChange.emit(emitted);
    }
  }

  reset() {
    this.buildSliderOption();
  }
}
