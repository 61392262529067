import { Component, OnInit } from '@angular/core';
import { ZintGrowService } from '../../../../services/zint-grow.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'zint-grow-modal',
  templateUrl: './zint-grow-modal.component.html',
  styleUrls: ['./zint-grow-modal.component.css'],
})
export class ZintGrowModalComponent implements OnInit {
  iframeSrc: string = 'https://i3.ytimg.com/vi/AKV2EBA9zKg/maxresdefault.jpg';

  showPlayIcon: boolean = true;

  constructor(
    private zintGrowService: ZintGrowService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  requestZintGrowActivation(): void {
    this.zintGrowService.requestZintGrowActivation().subscribe(res => {
      if (res.status === 'success') {
        this.snackBar.open(
          'We have received your activation request. We will enable this on your account, and be in touch. Thank you.',
          'OK',
          {
            duration: 5500,
          }
        );
      }
    });
  }

  populateYoutubeUrl(): void {
    this.iframeSrc =
      'https://www.youtube-nocookie.com/embed/AKV2EBA9zKg?autoplay=1';
    this.showPlayIcon = false;
  }
}
