import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class BasicCompanyMatcherService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingCompanyMatcherData: false,
    loadingCustomColumnSaving: false,
  };

  loadStatus(): Observable<any> {
    const _params = new HttpParams();
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/get_matcher_status/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError('loadStatus', [], this.isLoading)
        )
      );
  }

  loadMatcherSettings(): Observable<any> {
    const _params = new HttpParams();
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/get_matcher_settings/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError('loadMatcherSettings', [], this.isLoading)
        )
      );
  }

  loadMatcherProgress(): Observable<any> {
    const _params = new HttpParams();
    this.isLoading['loadingMatcherProgress'] = true;
    return this.http
      .post(
        baseUrl + '/get_matcher_progress/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingMatcherProgress'] = false)),
        catchError(
          this.pubMethods.handleError('loadMatcherProgress', [], this.isLoading)
        )
      );
  }

  submitMatcherMappings(
    approvedHeaders: Array<any>,
    shouldOverwrite: boolean
  ): Observable<any> {
    let _params = new HttpParams()
      .set(
        'approvedHeaders',
        this.pubMethods.stringifyListForGetParams(approvedHeaders)
      )
      .set('shouldOverwrite', shouldOverwrite);
    this.isLoading['loadingResults'] = true;
    return this.http
      .post(
        baseUrl + '/submit_matcher_mappings/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingResults'] = false)),
        catchError(
          this.pubMethods.handleError(
            'submitMatcherMappings',
            [],
            this.isLoading
          )
        )
      );
  }

  startMatchingProcess(): Observable<any> {
    this.isLoading['loadingCompanyMatcherData'] = true;
    return this.http
      .post(
        baseUrl + '/start_matching_process/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCompanyMatcherData'] = false)),
        catchError(
          this.pubMethods.handleError(
            'cancelMatchingProcess',
            [],
            this.isLoading
          )
        )
      );
  }

  cancelMatchingProcess(): Observable<any> {
    this.isLoading['loadingCompanyMatcherData'] = true;
    return this.http
      .post(
        baseUrl + '/end_matching_process/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCompanyMatcherData'] = false)),
        catchError(
          this.pubMethods.handleError(
            'cancelMatchingProcess',
            [],
            this.isLoading
          )
        )
      );
  }

  createMatcherRecordsForSelectedOnPage(
    rowIdsToSelectedCompanyId
  ): Observable<any> {
    const _params = new HttpParams().set(
      'rowIdsToSelectedCompanyId',
      this.pubMethods.stringifyDictForGetParams(rowIdsToSelectedCompanyId)
    );
    this.isLoading['loadingDataSync'] = true;
    return this.http
      .post(
        baseUrl + '/create_matcher_records_for_page/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingDataSync'] = false)),
        catchError(
          this.pubMethods.handleError(
            'createMatcherRecordsForSelectedOnPage',
            [],
            this.isLoading
          )
        )
      );
  }

  createMatcherRecordsForAll(
    lowerMatchLevel,
    upperMatchLevel
  ): Observable<any> {
    const _params = new HttpParams()
      .set('lowerMatchLevel', lowerMatchLevel)
      .set('upperMatchLevel', upperMatchLevel);
    this.isLoading['loadingDataSync'] = true;
    return this.http
      .post(
        baseUrl + '/create_matcher_records_for_all/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingDataSync'] = false)),
        catchError(
          this.pubMethods.handleError(
            'createMatcherRecordsForAll',
            [],
            this.isLoading
          )
        )
      );
  }
}
