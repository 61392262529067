import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../../services/communication.service';
import { AiPitchBuilderService } from '../../services/ai-pitch-builder.service';

@Component({
  selector: 'ai-pitch-builder',
  templateUrl: './ai-pitch-builder.component.html',
  styleUrls: ['./ai-pitch-builder.component.css'],
})
export class AiPitchBuilderComponent implements OnInit {
  hasAcceptedTermsAndConditions = false;
  isSuperUser = false;
  hasAIPitchBuilderAccess = false;
  creditsAvailable = 0;

  aboutUs: string;
  tagLine: string;
  whoWeHelp: any;
  clients: string;

  newWhoWeHelpCategory: string;
  newWhoWeHelpHowWeHelp: string;

  constructor(
    private communicationService: CommunicationService,
    private aiPitchBuilderService: AiPitchBuilderService
  ) {}

  ngOnInit(): void {
    document.title = 'Pitch Builder';
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.communicationService
      .getHasAIPitchBuilderAccessMessage()
      .subscribe(hasAIPitchBuilder => {
        this.hasAIPitchBuilderAccess = hasAIPitchBuilder;
      });
    this.aiPitchBuilderService
      .getAIPitchBuilderConfig()
      .subscribe(data => this.updatePitchBuilderConfig(data));
  }

  updatePitchBuilderConfig(data) {
    if (data.pitch_builder_settings) {
      this.aboutUs = data.pitch_builder_settings.about_us_bullet_points;
      this.tagLine = data.pitch_builder_settings.tagline;
      this.whoWeHelp = data.pitch_builder_settings.who_we_help;
      this.clients = data.pitch_builder_settings.clients;
    }
    this.creditsAvailable = data.credits;
  }

  AddNewWhoWeHelp(): void {
    this.aiPitchBuilderService
      .AddNewWhoWeHelp(this.newWhoWeHelpCategory, this.newWhoWeHelpHowWeHelp)
      .subscribe(data => this.newWhoWeHelpAdded(data));
  }

  newWhoWeHelpAdded(data): void {
    this.whoWeHelp = data.who_we_help;
    this.newWhoWeHelpCategory = '';
    this.newWhoWeHelpHowWeHelp = '';
  }

  submitConfiguration(): void {
    this.aiPitchBuilderService
      .submitConfiguration(
        this.aboutUs,
        this.tagLine,
        this.whoWeHelp,
        this.clients
      )
      .subscribe(data => this.updatePitchBuilderConfig(data));
  }
}
