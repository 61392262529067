import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'add-remove-individual',
  templateUrl: './add-remove-individual.component.html',
  styleUrls: ['./add-remove-individual.component.css'],
})
export class AddRemoveIndividualComponent implements OnInit {
  @Output() ParentFunctionOnAddNewIndividual: EventEmitter<any> =
    new EventEmitter();
  ChildFunctionOnAddNewIndividual(individualId, objectAddingOn): void {
    this.ParentFunctionOnAddNewIndividual.emit([individualId, objectAddingOn]);
  }

  @Output() ParentFunctionOnClickToDeleteIndividual: EventEmitter<any> =
    new EventEmitter();
  ChildFunctionOnClickToDeleteIndividual(individual, objectRemovingOn): void {
    this.ParentFunctionOnClickToDeleteIndividual.emit([
      individual,
      objectRemovingOn,
    ]);
  }

  constructor() {}

  @Input()
  inputParams: any = {
    confirmDeleteModalId: String,
    objectAddingOrRemovingIndividual: Object,
    attributeNameForIndividuals: String,
    organisationUsers: Array,
  };

  isAddingIndividual = false;

  ngOnInit(): void {}
}
