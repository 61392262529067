import {booleanAttribute, Component, Input} from '@angular/core';
import {CRMIntegrationsService} from '../../../services/crm-integrations.service';
import {CustomColumnService} from '../../../services/custom-column.service';

@Component({
  selector: 'hubspot-import-config',
  templateUrl: './hubspot-import-config.component.html',
  styleUrl: './hubspot-import-config.component.css'
})
export class HubspotImportConfigComponent {
   @Input({transform: booleanAttribute}) isParentOrgSuperUser: false;

   hubspotImportSettingObjects: any;

   hubspotPropertiesAvailable: any;
   customColumnsAvailable: any;

   // Mappings to show the new rows
   showAddNewRows = {};

   // the values selected for creating new.
   selectedHubspotProperty;
   selectedCustomColumn;

   constructor(
    private crmService: CRMIntegrationsService,
    private customColumnService: CustomColumnService,
  ) {}


  isLoading = this.crmService.isLoading;

   ngOnInit(): void {
     if (this.isParentOrgSuperUser){
         this.crmService.getHubspotImportSettings().subscribe(response => {
              this.hubspotImportSettingObjects = response.import_config;
         });

        this.crmService.getHubspotFieldsForImportConfig().subscribe(response => {
              this.hubspotPropertiesAvailable = response.hubspot_properties;
        });

        this.customColumnService.getCustomColumns().subscribe(response => {
            this.customColumnsAvailable = response.custom_columns;
        });

     }
   }


   createNewImportMapping(selectedHubspotObjectImportConfig): void {
        this.crmService.addHubspotImportFieldMapping(selectedHubspotObjectImportConfig.id, this.selectedHubspotProperty.name, this.selectedCustomColumn.id).subscribe(response => {
            if (response.success){
                this.hubspotImportSettingObjects = response.import_config;
            };
        });
   }


   deleteImportMapping(selectedHubspotObjectImportConfig, fieldMapping): void {
       this.crmService.deleteHubspotImportFieldMapping(selectedHubspotObjectImportConfig.id, fieldMapping.id).subscribe(response => {
            if (response.success){
                this.hubspotImportSettingObjects = response.import_config;
            };
       });
   }


   createNewImportConfiguration(): void {
        this.crmService.createNewHubspotImportConfiguration().subscribe(response => {
            if (response.success){
                this.hubspotImportSettingObjects = response.import_config;
            };
       });
   }



}
