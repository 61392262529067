

@if(isLoading.isLoadingHubspotImportSettings){
    <zint-loader></zint-loader>
}


@if(!isLoading.isLoadingHubspotImportSettings && hubspotImportSettingObjects?.length === 0){
    <div class="text-center">
        <div class="d-flex justify-content-center">
            <div class="hubspot-import-icon">
                <span class="fa-stack fa-4x">
                  <i class="fab fa-solid fa-hubspot"></i>
                  <i class="fal fa-file-import"></i>
                </span>
            </div>
        </div>
        <p class="mt-5">There is no import configuration activate on your organisation.</p>
        <p>Activate to enable the regular sync of data from HubSpot into Zint.</p>
        <button class="rounded-btn-base rounded-btn-light btn-p-md" (click)="createNewImportConfiguration()" [attr.disabled]="isLoading.isLoadingCreateNewImportConfiguration ? 'disabled': null">ACTIVATE</button>
    </div>
}


@for (hubspotImportSettingObject of hubspotImportSettingObjects; track $index) {

    <h4>Import Configuration for {{hubspotImportSettingObject.crm_object}} Objects</h4>

    <div class="zint-config-table-container mt-5">
        <table class="table">
            <thead>
                <tr>
                    <th>Hubspot</th>
                    <th></th>
                    <th>Zint</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            @for (fieldMapping of hubspotImportSettingObject.field_mappings; track $index) {
                <tr>
                    <td>
                        <i class="fab fa-hubspot"></i> {{fieldMapping.field_label}}
                    </td>
                    <td class="text-center">
                        <i class="fal fa-arrow-right"></i>
                    </td>
                    <td>
                        <zint-loader [size]="'xxSmall'" [shouldAnimate]="false" [isInline]="true"></zint-loader> &nbsp;{{fieldMapping.custom_column_name}}
                    </td>
                    <td class="align-middle text-center">
                        <three-dots-action [matMenuTriggerFor]="menu"></three-dots-action>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="deleteImportMapping(hubspotImportSettingObject, fieldMapping)">
                                <span><i class="fal fa-times-circle"></i> Delete</span>
                            </button>
                        </mat-menu>
                    </td>
                </tr>
            }
            @if(!showAddNewRows[hubspotImportSettingObject.id]){
                <tr>
                    <td colspan="4" class="text-center">
                        @if(hubspotImportSettingObject.field_mappings?.length === 0){
                            <div class="d-flex justify-content-center">
                                <div class="hubspot-import-icon">
                                    <span class="fa-stack fa-4x">
                                      <i class="fab fa-solid fa-hubspot"></i>
                                      <i class="fal fa-file-import"></i>
                                    </span>
                                </div>
                            </div>
                            <p>Add your first field mapping</p>
                        }
                        <button class="rounded-btn-base rounded-btn-light btn-p-sm" (click)="showAddNewRows[hubspotImportSettingObject.id] = true">+</button>
                    </td>
                </tr>
            }
            @else{
                <tr>
                    <td>
                        <i class="fab fa-hubspot"></i>
                        <select [(ngModel)]="selectedHubspotProperty" class="custom-select w-50 ml-2">
                            @for (hubspotProperty of hubspotPropertiesAvailable; track $index) {
                                <option [ngValue]="hubspotProperty" >{{hubspotProperty.label}}</option>
                            }
                        </select>
                        <span class="de-emphasised-text d-block ml-4">&nbsp;@if(selectedHubspotProperty){
                            {{selectedHubspotProperty.name}},
                            {{selectedHubspotProperty.type}}
                        }</span>
                    </td>
                    <td class="text-center">
                        <i class="fal fa-arrow-right"></i>
                    </td>
                    <td>
                        <zint-loader [size]="'xxSmall'" [shouldAnimate]="false" [isInline]="true"></zint-loader>
                        <select [(ngModel)]="selectedCustomColumn" class="custom-select w-50 ml-2">
                            @for (customColumn of customColumnsAvailable; track $index) {
                                @if(!customColumn.is_propensity && customColumn.is_collaborative_column && !customColumn.is_lockable && !customColumn.is_option_column){
                                    <option [ngValue]="customColumn" >{{customColumn.name}} {{customColumn.is_option_column}}</option>
                                }
                            }
                        </select>
                        <span class="de-emphasised-text d-block ml-3">&nbsp;@if(selectedCustomColumn){
                            {{selectedCustomColumn.column_type}}
                            @if(selectedCustomColumn.is_option_column){
                                , option
                            }
                        }</span>
                    </td>
                    <td>
                        @if(selectedCustomColumn && selectedHubspotProperty){
                            <button
                            class="rounded-btn-base rounded-btn-light btn-p-sm"
                            (click)="createNewImportMapping(hubspotImportSettingObject)"
                            >Add</button>
                        }
                    </td>
                </tr>
            }
            </tbody>
        </table>
    </div>

}
