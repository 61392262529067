import { Component, OnInit } from '@angular/core';
import { TaggingService } from '../../services/tagging.service';
import { CrossSellService } from '../../services/cross-sell.service';
import { publicMethods } from '../../globals';
import { DataSharingService } from '../../services/data-sharing.service';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'cross-sell',
  templateUrl: './cross-sell.component.html',
  styleUrls: ['./cross-sell.component.css'],
})
export class CrossSellComponent implements OnInit {
  isSuperUser: boolean = false;

  organisationUsers: Array<any> = [];
  userTags: Array<any> = [];
  userCrossSellTags: Array<any> = [];
  newCrossSellTagName: string = '';
  makeCrossSellTagCollaborative: boolean = false;
  isAllowed = false;
  percentComplete: number;

  constructor(
    private taggingService: TaggingService,
    private communicationService: CommunicationService,
    private crossSellService: CrossSellService,
    private pubMethods: publicMethods
  ) {}

  isLoading = this.taggingService.isLoading;

  ngOnInit() {
    document.title = 'Cross-Sell';
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.taggingService
      .getUserTags(false)
      .subscribe(data => this.updateUserTags(data));
    this.updateCrossSellTags();
    this.testIfAllowedToRun();
  }

  updateUserTags(data): void {
    this.userTags = data.user_tags;
    this.organisationUsers = data.team_members;
  }

  updateCrossSellTags() {
    this.taggingService
      .getUserCrossSellTags(false)
      .subscribe(data => (this.userCrossSellTags = data));
  }

  processIsAllowed(data) {
    if (data.is_allowed) {
      this.isAllowed = true;
      this.updateCrossSellTags();
    } else {
      this.isAllowed = false;
      this.percentComplete = data.percent_complete;
      setTimeout(() => {
        this.testIfAllowedToRun();
      }, 3000);
    }
  }

  testIfAllowedToRun() {
    this.crossSellService
      .testAllowed()
      .subscribe(data => this.processIsAllowed(data));
  }

  giveUpdate(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage('The process was successfully started.');
      this.isAllowed = false;
      this.percentComplete = 0;
      this.testIfAllowedToRun();
    }
  }

  startCrossSellProcess(): void {
    let selectedTagIds = this.userTags
      .filter(tag => tag.checked == true)
      .map(tag => tag.id);
    this.crossSellService
      .submitCrossSellProcess(
        selectedTagIds,
        this.newCrossSellTagName,
        this.makeCrossSellTagCollaborative
      )
      .subscribe(data => this.giveUpdate(data));
  }

  cancelCrossSellProcess(): void {
    this.crossSellService
      .cancelCrossSellProcess()
      .subscribe(data => this.processIsAllowed(data));
  }
}
