import { Component, OnInit } from '@angular/core';
import { ManageTeamsService } from '../../services/manage-teams.service';
import { publicMethods } from '../../globals';

@Component({
  selector: 'manage-teams',
  templateUrl: './manage-teams.component.html',
  styleUrls: ['./manage-teams.component.css'],
})
export class ManageTeamsComponent implements OnInit {
  topLevelPlanDetails: Record<string, any> = {};
  teams: Array<any> = [];
  childTeams: Array<any> = [];
  parentOrg: Record<string, any> = {};

  newTeamName: string;
  newTeamEmailOfSuperuser: string;

  constructor(
    private manageTeamsService: ManageTeamsService,
    private pubMethods: publicMethods
  ) {}

  isLoading = this.manageTeamsService.isLoading;

  ngOnInit(): void {
    this.getInitialData();
  }

  getInitialData(): void {
    this.manageTeamsService
      .loadInitialTeamData()
      .subscribe(data => this.updateTeamsinUI(data));
    this.manageTeamsService
      .loadTopLevelPlanDetails()
      .subscribe(data => this.updateTopLevelPlanDetails(data));
  }

  updateTopLevelPlanDetails(data): void {
    this.topLevelPlanDetails = data;
  }

  updateTeamsinUI(data): void {
    if (data.parent_organisation && data.teams) {
      let allTeams = [data.parent_organisation].concat(data.teams);
      this.teams = allTeams;
      this.childTeams = data.teams;
      this.parentOrg = data.parent_organisation;
    }
  }

  createNewTeam(): void {
    this.manageTeamsService
      .callCreateNewTeam(this.newTeamName, this.newTeamEmailOfSuperuser)
      .subscribe(data => this.appendNewTeamToUI(data));
  }

  appendNewTeamToUI(data): void {
    if (data.new_organisation) {
      this.teams.push(data.new_organisation);
      this.childTeams.push(data.new_organisation);
    }
  }

  updateTeamAssignedDownloads(teamId, assignedDownloads): void {
    this.manageTeamsService
      .setTeamAssignedDownloads(teamId, assignedDownloads)
      .subscribe(data => this.processDownloadsAssignedResponse(data));
  }

  processDownloadsAssignedResponse(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage('Download limits updated on team.');
    }
  }

  updateTeamAssignedPropensityModels(teamId, assignedPropensityModels): void {
    this.manageTeamsService
      .setTeamAssignedPropensityModels(teamId, assignedPropensityModels)
      .subscribe(data => this.processPropensityModelsAssignedResponse(data));
  }

  processPropensityModelsAssignedResponse(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage(
        'Propensity model limits updated on team.'
      );
    }
  }

  updateTeamAssignedUsers(teamId, assignedUsers): void {
    this.manageTeamsService
      .setTeamAssignedUsers(teamId, assignedUsers)
      .subscribe(data => this.processUsersAssignedResponse(data));
  }

  processUsersAssignedResponse(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage('User limits updated on team.');
    }
  }
}
