<div class="row justify-content-between">
    <div class="col-md-4" *ngIf="searchTermString || searchTermHTML">
        <p *ngIf="isRelatedOfficerEvent">{{searchTermString}}</p>
        <figure *ngIf="searchTermHTML" class="preview mb-1 px-2 text-sm" matTooltipPosition="below" [matTooltip]="searchTerm || null">
            <blockquote [innerHTML]=" '&quot;...'+ searchTermHTML +'...&quot;' "></blockquote>
          <figcaption class="blockquote-footer mb-0">
            {{searchTermString}}
          </figcaption>
        </figure>
    </div>
    <div class="col-md-4" *ngIf="searchTermLink">
        <a href="{{searchTermLink}}" target="_blank">
            <span class="badge badge-link xsmall-margin-right">
                <i class="fal fa-external-link"></i>
                View on company page
            </span>
        </a>
    </div>
    <div class="col-md-4">
        <span class="badge badge-info xsmall-margin-right" matTooltipPosition="above" matTooltip="{{notification.rule_description}}" *ngIf="notification.rule_description">
            <i class="fal fa-comment"></i>
        </span>
        <span class="badge badge-info xsmall-margin-right">
            <i class="fal fa-exclamation-triangle"></i>
            Importance: {{notification.importance}}
        </span>
        <span class="badge badge-info xsmall-margin-right">
            <i class="fal fa-info-circle"></i>
            {{notification.rule_set_name}}
        </span>
    </div>
</div>
