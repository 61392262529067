

<div class="container">
    <div class="row small-margin-top">

        <div class="col-md-12" *ngIf="isLoading.loadingPlanStatus">
            <div class="d-flex justify-content-center">
                <i class="fal fa-spin fa-spinner" style="font-size: 50px"></i>
            </div>
        </div>


        <ng-container *ngIf="!isLoading.loadingPlanStatus">

        <div class="col-md-4" *ngIf="products">
            <p>Select Plan:</p>
            <select [(ngModel)]="selectedProductOption" class="custom-select">
                <option *ngFor="let product of products" [ngValue]="product">{{product.name}}</option>
            </select>
            <ng-container *ngIf="selectedProductOption">
                <select [(ngModel)]="selectedPriceOption" class="custom-select small-margin-top">
                    <option *ngFor="let price of selectedProductOption.prices" [ngValue]="price">{{price.frequency}} - £{{price.price/100}}</option>
                </select>
            </ng-container>
            <div class="small-margin-top"  *ngIf="selectedProductOption && selectedPriceOption">
                <input type="checkbox" name="hasAcceptedTerms" value="true" [(ngModel)]="hasAcceptedTerms"/> By proceeding, you agree to the <a href="https://www.zint.io/contract-terms-0622/" target="_blank">Terms and Conditions</a>
                <br>
                <button class="basic-button-base button-dark full-width xsmall-margin-top" (click)="defGetStripeCheckoutSession()" [attr.disabled]="(!hasAcceptedTerms || isLoading.loadingCheckoutSession) ? 'disabled': null">
                    <i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingCheckoutSession"></i> Pay Now
                </button>
            </div>
        </div>


        <div class="col-md-8" *ngIf="selectedProductOption && selectedPriceOption">
            <table class="table table-striped table-bordered small-margin-top">
                <tbody>
                    <tr>
                        <td>Plan Name</td>
                        <td>{{selectedProductOption.name}}</td>
                    </tr>
                    <tr>
                        <td>Subscription Fees</td>
                        <td>
                            £{{selectedPriceOption.price/100}} + VAT
                            <br>
                            <span style="font-size: 10px">VAT is charged at 20%. If you are not a UK business or individual, please <a href="mailto:sales@zint.io?Subject=Non%20UK%20Tax%20Rate">contact us</a> for tax options.</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Services</td>
                        <td>
                            <div class="row">
                                <div class="col-md-6">
                                    <table class="table table-striped table-bordered table-sm">
                                        <tr>
                                            <th>Functionality:</th>
                                        </tr>
                                        <tr *ngFor="let functionality of selectedProductOption.product_feature_set.functionality">
                                            <td><i class="fal fa-check-circle"></i> {{functionality}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="table table-striped table-bordered table-sm">
                                        <tr>
                                            <th>Quotas:</th>
                                        </tr>
                                        <tr *ngFor="let quota of selectedProductOption.product_feature_set.quotas">
                                            <td><i class="fal fa-check-circle"></i> {{quota}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <span style="font-size: 10px" class="text-center"><sup>*</sup>Quotas marked with '∞' are subject to a fair use policy judged solely by Zint.</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Effective Date</td>
                        <td>Today</td>
                    </tr>
                    <tr>
                        <td>Initial Subscription Period</td>
                        <td>
                            <ng-container *ngIf="selectedPriceOption.frequency == 'Monthly'">
                                1 month
                            </ng-container>
                            <ng-container *ngIf="selectedPriceOption.frequency == 'Annual'">
                                12 months
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <td>Notice Period</td>
                        <td>
                            <ng-container *ngIf="selectedPriceOption.frequency == 'Monthly'">
                                24 hours
                            </ng-container>
                            <ng-container *ngIf="selectedPriceOption.frequency == 'Annual'">
                                30 days
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <td>Payment Period</td>
                        <td>
                            <ng-container *ngIf="selectedPriceOption.frequency == 'Monthly'">
                                1 month
                            </ng-container>
                            <ng-container *ngIf="selectedPriceOption.frequency == 'Annual'">
                                12 months
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <td>Payment Terms</td>
                        <td>In Advance</td>
                    </tr>
                    <tr>
                        <td>Zint</td>
                        <td>Zint Technology Limited incorporated and registered in England and Wales with company number 11373627 whose registered office is at International House, 24 Holborn Viaduct, London, United Kingdom, EC1A 2BN</td>
                    </tr>
                    <tr>
                        <td>Customer</td>
                        <td>The organisation associated with your email address.</td>
                    </tr>
                </tbody>
            </table>
        </div>

        </ng-container>

    </div>
</div>


