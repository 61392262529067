<div class="container">
    <div class="row medium-margin-bottom">
        <div class="col-md-12">
            <br>
            <div class="d-flex justify-content-center" *ngIf="isLoading.loadingSubscriptions">
                <i class="fal fa-spin fa-spinner" style="font-size: 50px"></i>
            </div>
        </div>

        <ng-container *ngIf="!isLoading.loadingSubscriptions">
            <div class="col-md-12 medium-margin-bottom">
                <h3>Current Plan:</h3>
                <ng-container *ngIf="!plan">
                    <p>You do not have a current plan.</p>
                </ng-container>
                 <table class="table table-striped table-bordered" *ngIf="plan">
                        <tbody>
                            <tr>
                                <td>Plan Name</td>
                                <td>{{ plan.name }}</td>
                            </tr>
                            <tr>
                                <td>Time Scale</td>
                                <td>{{ plan.time_scale }}</td>
                            </tr>
                            <tr>
                                <td>Plan Start Date</td>
                                <td>{{ plan.start_date | date:'d MMM y h:mm' }}</td>
                            </tr>
                        </tbody>
                 </table>
            </div>
        </ng-container>

        <ng-container *ngIf="!isLoading.loadingSubscriptions">
            <div class="col-md-12">
                <h3>Your Subscriptions:</h3>
                <ng-container *ngIf="subscriptions && subscriptions.length === 0">
                    <p>You do not have any subscriptions.</p>
                </ng-container>
                <ng-container *ngFor="let subscription of subscriptions">
                    <table class="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <td>Subscription Status</td>
                                <td>{{ subscription.status }}</td>
                            </tr>
                            <tr>
                                <td>Expires</td>
                                <td>{{ subscription.expiry_date_time | date:'d MMM y h:mm' }}</td>
                            </tr>
                            <tr>
                                <td>Feature Sets</td>
                                <td>
                                    {{subscription.feature_set}}
                                    <ng-container *ngFor="let featureSet of subscription.feature_sets">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <table class="table table-striped table-bordered table-sm">
                                                    <tr>
                                                        <th>Functionality:</th>
                                                    </tr>
                                                    <tr *ngFor="let functionality of featureSet.functionality">
                                                        <td><i class="fal fa-check-circle"></i> {{functionality}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="col-md-6">
                                                <table class="table table-striped table-bordered table-sm">
                                                    <tr>
                                                        <th>Quotas:</th>
                                                    </tr>
                                                    <tr *ngFor="let quota of featureSet.quotas">
                                                        <td><i class="fal fa-check-circle"></i> {{quota}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <span style="font-size: 10px" class="text-center"><sup>*</sup>quotas marked with '∞' are subject to a fair use policy judged solely by Zint.</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Manage Subscription</td>
                                <td>
                                    <ng-container *ngIf="subscription.stripe_billing_details">
                                        <button class="btn-sm basic-button-base button-dark full-width" (click)="getStripeSubscriptionPortal(subscription.stripe_billing_details.stripe_customer_id)">
                                            <i class="fal fa-spin fa-spinner" *ngIf="isLoading.loadingPortalSession"></i> Manage <i class="fal fa-external-link"></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="!subscription.stripe_billing_details">
                                        Please contact your account manager to update this subscription.
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>
        </ng-container>


    </div>
</div>
