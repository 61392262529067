import { Component } from '@angular/core';
import {AccountService} from '../../../services/account.service';
import {CommunicationService} from '../../../services/communication.service';
import {PermissionsService} from '../../../services/permissions.service';

@Component({
  selector: 'bulk-download-management',
  templateUrl: './bulk-download-management.component.html',
  styleUrl: './bulk-download-management.component.css'
})
export class BulkDownloadManagementComponent {

  isParentOrgSuperUser = false;
  bulkDownloadDetails = [];

  organisationTeams = [];
  organisationUsers = [];

  constructor(
      private accountService: AccountService,
      private commService: CommunicationService,
      private permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.commService.getIsParentOrgSuperUserMessage().subscribe(isParentOrgSuperUser => {
      this.isParentOrgSuperUser = isParentOrgSuperUser;
      if (this.isParentOrgSuperUser){
        this.permissionsService
          .getSuperuserTeamsAndUsers()
          .subscribe(data => {
            this.organisationTeams = data.organisation_teams;
            this.organisationUsers = data.organisation_users;
            this.getBulkDownloadDetails();
          });
      }
    });
  }

  getBulkDownloadDetails(): void {
      this.accountService.getBulkDownloadDetails().subscribe(
          response => {
            this.bulkDownloadDetails = response;
          }
      );
  }



}
