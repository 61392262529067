import { Component, Input, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'div[notification-search-term]',
  templateUrl: './notification-search-term.component.html',
  styleUrls: ['./notification-search-term.component.css'],
})
export class NotificationSearchTermComponent implements OnInit {
  @Input() notification: any;
  @Input() searchTerm: any;
  @Input() searchTermLink: any;
  @Input() searchTermString: any;
  @Input() searchTermHTML: any;

  isRelatedOfficerEvent: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.isRelatedOfficerEvent =
      this.notification.event_source_type === 'RelatedOfficerEventSourceType';
  }
}
