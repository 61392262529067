import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class DownloadService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService,
  ) {}

  isLoading = {
    loadingDownloadUrl: false,
  };

  downloadCompanies(companyIds, searchParams): Observable<any> {
    if (companyIds.length > 0) {
      this.isLoading['loadingDownloadUrl'] = true;
      let _params = searchParams.set(
        'companyIds',
        this.pubMethods.stringifyListForGetParams(companyIds)
      );
      return this.http
        .post(
          baseUrl + '/get_csv_download_id_from_ids/',
          _params,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => (this.isLoading['loadingDownloadUrl'] = false)),
          catchError(
            this.pubMethods.handleError('downloadCompanies', [], this.isLoading)
          )
        );
    } else if (searchParams) {
      this.isLoading['loadingDownloadUrl'] = true;
      return this.http
        .post(
          baseUrl + '/initiate_download_request/',
          searchParams,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => (this.isLoading['loadingDownloadUrl'] = false)),
          catchError(
            this.pubMethods.handleError('downloadCompanies', [], this.isLoading)
          )
        );
    }
  }

  getDownloadUsage(): Observable<any> {
    return this.http
    .get(baseUrl + '/get_user_download_usage/', this.auth.helperGetVerifiedPostDict())
    .pipe(
      tap(_ => _),
      catchError(this.pubMethods.handleError('getDownloadUsage', []))
    );
  }
}
