<div class="container">

    <div class="row small-margin-top small-margin-bottom">
        <div class="col-md-12">
            <h2>Results View</h2>
            <p>Design how data should be shown in the main search dashboard, individually, and across your team.</p>
        </div>
    </div>

    <div class="row" *ngIf="!isLoading.isLoadingInitialData">
        <div class="col-md-12">
            <ul class="nav nav-tabs small-margin-top" role="tablist" id="nav-items-holder">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" data-target="#user-views-tab" role="tab" (click)="activateUserSettings()">
                        Your Views
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" data-target="#organisation-views-tab" role="tab" (click)="activateOrganisationSettings()">
                        Organisation/Team Views
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <ng-container *ngIf="isLoading.isLoadingInitialData">
        <div class="row small-margin-top medium-margin-bottom">
            <div class="col-md-12 d-flex justify-content-center align-items-center">
                <zint-loader></zint-loader>
            </div>
        </div>
    </ng-container>


    <div class="tab-content">

        <div class="tab-pane active" role="tabpanel" id="user-views-tab">

            <ng-container *ngIf="!isLoading.isLoadingInitialData">

                <div class="row small-margin-top medium-margin-bottom">

                    <ng-container *ngIf="individualResultsFormatSettings">
                        <div class="col-md-6">
                            <h3>Default View:</h3>
                            <label>Choose your default view:</label>
                            <select class="custom-select" name="defaultFormat" (change)="changeDefaultFormat($event.target.value)">
                                <option></option>
                                <ng-container *ngFor="let individualResultsFormatSetting of individualResultsFormatSettings">
                                    <option [value]="individualResultsFormatSetting.id" [attr.selected]="individualResultsFormatSetting.is_default === true ? 'selected' : null">{{individualResultsFormatSetting.name}}</option>
                                </ng-container>
                                <ng-container *ngFor="let organisationResultsFormatSetting of organisationResultsFormatSettings">
                                    <option [value]="organisationResultsFormatSetting.id" [attr.selected]="organisationResultsFormatSetting.is_default === true ? 'selected' : null">{{organisationResultsFormatSetting.name}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </ng-container>

                </div>

                <div class="row small-margin-top medium-margin-bottom">
                    <div class="col-md-12">
                        <hr class="medium-margin-bottom">
                    </div>
                    <div class="col-md-6">
                        <h3>New View:</h3>
                        <label>Add a new view:</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="My New View" name="newResultsFormatName" [(ngModel)]="newResultsFormatName" [attr.disabled]="isLoading.isUpdatingAResultsFormat ? 'disabled': null">
                            <div class="input-group-append">
                                <button class="btn basic-button-base button-dark" type="button" (click)="createNewResultsFormat()" [attr.disabled]="isLoading.isUpdatingAResultsFormat ? 'disabled': null">
                                    <i class="fal fa-plus-circle" *ngIf="!isLoading.isUpdatingAResultsFormat"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoading.isUpdatingAResultsFormat"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="row small-margin-top medium-margin-bottom align-items-end">
                    <div class="col-md-12">
                        <hr class="medium-margin-bottom">
                    </div>
                    <ng-container *ngIf="resultsFormatBeingEdited">

                        <div class="col-md-12">
                            <h3>Edit Your Views:</h3>
                        </div>

                        <div class="col-md-4 small-margin-top">
                            <label>Select a view:</label>
                            <select [(ngModel)]="resultsFormatBeingEdited" class="custom-select" name="resultsFormatBeingEdited" (change)="userResultsFormatEditingChanged();">
                                <ng-container *ngFor="let individualResultsFormatSetting of individualResultsFormatSettings">
                                    <option [ngValue]="individualResultsFormatSetting">{{individualResultsFormatSetting.name}}</option>
                                </ng-container>
                            </select>
                            <br>
                        </div>

                        <div class="col-md-4 small-margin-top">
                            <label>Edit Name:</label>
                            <div class="input-group">
                                <input type="text" class="form-control" *ngIf="resultsFormatBeingEdited" name="nameBeingChanged" [(ngModel)]="nameBeingChanged" [attr.disabled]="isLoading.isUpdatingAResultsFormat ? 'disabled': null">
                                <div class="input-group-append">
                                    <button class="btn basic-button-base button-dark" type="button" (click)="saveResultsFormatName(resultsFormatBeingEdited)" [attr.disabled]="isLoading.isUpdatingAResultsFormat ? 'disabled': null">
                                        <i class="fal fa-save" *ngIf="!isLoading.isUpdatingAResultsFormat"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoading.isUpdatingAResultsFormat"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 small-margin-top">
                            <button class="basic-button-base button-dark mb-auto" (click)="deleteFormat()"><i class="fal fa-trash"></i> Delete View</button>
                        </div>

                    </ng-container>

                </div>



                <div class="row small-margin-top medium-margin-bottom">
                    <ng-container *ngIf="resultsFormatBeingEdited">

                        <div class="col-md-12">
                            <button class="basic-button-base button-dark" style="width: 150px" (click)="saveResultsFormat()">Update View</button>
                        </div>

                        <div class="col-md-6 small-margin-top">
                            <h4>Current Headers:</h4>
                            <div cdkDropList (cdkDropListDropped)="dragSort($event)">
                                <div *ngFor="let userResultsHeader of resultsFormatBeingEdited.format" class="cursor-move" cdkDrag>
                                    <i class="fal fa-arrow-alt-circle-right cursor-pointer" (click)="addOrRemoveHeaderToResults(userResultsHeader, false)"></i>
                                    {{ userResultsHeader.name || userResultsHeader }} <span *ngIf="userResultsHeader.is_propensity_model_components_column">Scoring Components</span>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-6 small-margin-top">
                            <h4>All Header Options:</h4>                            
                            <div>
                                <div *ngFor="let resultHeader of unusedResultHeaders">
                                    <i class="fal fa-arrow-alt-circle-left cursor-pointer" (click)="addOrRemoveHeaderToResults(resultHeader, true)"></i>
                                    {{ resultHeader.name || resultHeader }}  <span *ngIf="resultHeader.is_propensity_model_components_column">Scoring Components</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </div>









        <div class="tab-pane" role="tabpanel" id="organisation-views-tab">


            <ng-container *ngIf="!isLoading.isLoadingInitialData">

                <div class="row small-margin-top medium-margin-bottom">
                    <div class="col-md-12 medium-margin-top">
                        <p *ngIf="!inputParams.isSuperUser && organisationResultsFormatSettings.length === 0">Your organisation has not created any shared views yet.</p>
                    </div>
                    <div class="col-md-12">
                        <p *ngIf="inputParams.isSuperUser">Change Settings for your entire team here.</p>
                    </div>
                    <div class="col-md-12" *ngIf="inputParams.isSuperUser">
                        <hr class="small-margin-top small-margin-bottom">
                    </div>

                    <div class="col-md-6" *ngIf="inputParams.isSuperUser">
                        <h3>Default Organisation View:</h3>
                        <label>Choose the default view for your team or organisation:</label>
                        <select class="custom-select" name="defaultFormat" (change)="changeDefaultOrganisationFormat($event.target.value)">
                            <ng-container *ngFor="let organisationResultsFormatSetting of organisationResultsFormatSettings">
                                <option [value]="organisationResultsFormatSetting.id" [attr.selected]="organisationResultsFormatSetting.is_default">{{organisationResultsFormatSetting.name}}</option>
                            </ng-container>
                        </select>
                    </div>

                </div>

                <div class="row small-margin-top medium-margin-bottom" *ngIf="inputParams.isSuperUser">
                    <div class="col-md-12">
                        <hr class="medium-margin-bottom">
                    </div>
                    <div class="col-md-6">
                        <h3>New View:</h3>
                        <label>Add a new view:</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="My Team's New View" name="newResultsFormatName" [(ngModel)]="newResultsFormatName" [attr.disabled]="isLoading.isUpdatingAResultsFormat ? 'disabled': null">
                            <div class="input-group-append">
                                <button class="btn basic-button-base button-dark" type="button" (click)="createNewOrganisationResultsFormat()" [attr.disabled]="isLoading.isUpdatingAResultsFormat ? 'disabled': null">
                                    <i class="fal fa-plus-circle" *ngIf="!isLoading.isUpdatingAResultsFormat"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoading.isUpdatingAResultsFormat"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="row small-margin-top medium-margin-bottom align-items-end">
                    <div class="col-md-12">
                        <hr class="medium-margin-bottom">
                    </div>
                    <ng-container *ngIf="resultsFormatBeingEdited">

                        <div class="col-md-12">
                            <h3 *ngIf="inputParams.isSuperUser">Edit Your Views:</h3>
                            <h3 *ngIf="!inputParams.isSuperUser">Your Organisation Views:</h3>
                        </div>

                        <div class="col-md-4 small-margin-top">
                            <label>Select a view:</label>
                            <select [(ngModel)]="resultsFormatBeingEdited" class="custom-select" name="resultsFormatBeingEdited" (change)="userResultsFormatEditingChanged();">
                                <ng-container *ngFor="let organisationResultsFormatSetting of organisationResultsFormatSettings">
                                    <option [ngValue]="organisationResultsFormatSetting">{{organisationResultsFormatSetting.name}}</option>
                                </ng-container>
                            </select>
                            <br>
                        </div>

                        <div class="col-md-4 small-margin-top" *ngIf="inputParams.isSuperUser">
                            <label>Edit Name:</label>
                            <div class="input-group">
                                <input type="text" class="form-control" *ngIf="resultsFormatBeingEdited" name="nameBeingChanged" [(ngModel)]="nameBeingChanged" [attr.disabled]="isLoading.isUpdatingAResultsFormat ? 'disabled': null">
                                <div class="input-group-append">
                                    <button class="btn basic-button-base button-dark" type="button" (click)="saveOrganisationResultsFormatName(resultsFormatBeingEdited)" [attr.disabled]="isLoading.isUpdatingAResultsFormat ? 'disabled': null">
                                        <i class="fal fa-save" *ngIf="!isLoading.isUpdatingAResultsFormat"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoading.isUpdatingAResultsFormat"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 small-margin-top" *ngIf="inputParams.isSuperUser">
                            <button class="basic-button-base button-dark mb-auto" (click)="deleteOrganisationFormat()"><i class="fal fa-trash"></i> Delete View</button>
                        </div>

                    </ng-container>

                </div>



                <div class="row small-margin-top medium-margin-bottom">
                    <ng-container *ngIf="resultsFormatBeingEdited">

                        <div class="col-md-12" *ngIf="inputParams.isSuperUser">
                            <button class="basic-button-base button-dark" style="width: 150px" (click)="saveOrganisationResultsFormat()">Update View</button>
                        </div>

                        <div class="col-md-6 small-margin-top">
                            <h4>Current Headers:</h4>
                            <ng-container *ngIf="!inputParams.isSuperUser">
                                <div *ngFor="let userResultsHeader of resultsFormatBeingEdited.format">
                                    {{ userResultsHeader.name || userResultsHeader }} <span *ngIf="userResultsHeader.is_propensity_model_components_column">Scoring Components</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="inputParams.isSuperUser">
                                <div cdkDropList (cdkDropListDropped)="dragSort($event)">
                                    <div *ngFor="let userResultsHeader of resultsFormatBeingEdited.format" class="cursor-move" cdkDrag>
                                        <i class="fal fa-arrow-alt-circle-right cursor-pointer" (click)="addOrRemoveHeaderToResults(userResultsHeader, false)"></i>
                                        {{ userResultsHeader.name || userResultsHeader }} <span *ngIf="userResultsHeader.is_propensity_model_components_column">Scoring Components</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="col-md-6 small-margin-top" *ngIf="inputParams.isSuperUser">
                            <h4>All Header Options:</h4>
                            <div>
                                <div *ngFor="let resultHeader of unusedResultHeaders">
                                    <i class="fal fa-arrow-alt-circle-left cursor-pointer" (click)="addOrRemoveHeaderToResults(resultHeader, true)"></i>
                                    {{ resultHeader.name || resultHeader }}  <span *ngIf="resultHeader.is_propensity_model_components_column">Scoring Components</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </ng-container>
        </div>

    </div>


</div>
