import { Component, OnInit } from '@angular/core';
import { User } from '../../classes/user';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { publicMethods } from '../../globals';
import { ZendeskSsoService } from '../../services/zendesk-sso.service';
import { PushMessagesService } from '../../services/push-messages.service';
import { ZintGrowService } from '../../services/zint-grow.service';
import { RouteService } from '../../services/route.service';
declare var zE: any; // Zendesk SDK variable

@Component({
  selector: 'login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.css'],
})
export class LoginRegisterComponent implements OnInit {
  constructor(
    private auth: AuthenticationService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private pubMethods: publicMethods,
    private routeService: RouteService,
    private zendeskService: ZendeskSsoService,
    private pushMessagesService: PushMessagesService,
    private zintGrowService: ZintGrowService
  ) {}

  user: User = new User();
  showLogin = false;
  showRegister = false;
  hasAcceptedPrivacyPolicy = false;
  alreadyLoggedIn = false;
  showPasswordResetEmail = false;
  showPasswordResetPassword = false;
  showRequestNewActivationToken = false;
  resetToken = '';
  confirmationToken = '';
  isLoadingTokenVerification = false;

  postEventRedirectUrl = '/dashboard';

  ngOnInit(): void {
    let redirect = false;

    const urlTree = this._router.parseUrl(this._router.url);
    const urlWithoutParams = urlTree.root.children['primary'].segments
      .map(it => it.path)
      .join('/');
    const messageToShow = this.activatedRoute.snapshot.queryParams.message;
    if (messageToShow) {
      this.pubMethods.log(messageToShow);
    }
    if (
      urlWithoutParams === 'register' ||
      urlWithoutParams === 'login' ||
      urlWithoutParams === 'confirm_account'
    ) {
      redirect = true;
      const redirectUrlFromGetParam =
        this.activatedRoute.snapshot.queryParams.redirectUrl;
      // Only permit relative redirects. i.e. do not allow redirects to external pages.
      if (redirectUrlFromGetParam && redirectUrlFromGetParam.startsWith('/')) {
        this.postEventRedirectUrl = redirectUrlFromGetParam;
      }
    }
    if (urlWithoutParams === 'register') {
      this.showRegister = true;
    }
    if (urlWithoutParams === 'login') {
      this.showLogin = true;
      // Logout the user from Zendesk chat if they are not logged in.
      this.zendeskService.logoutZendeskUser();
      this.pushMessagesService.deleteCachedPushMessages();
      this.zintGrowService.removeZGUnreadCountCookie();
    }
    if (urlWithoutParams === 'reset_password') {
      this.showPasswordResetEmail = true;
    }
    if (urlWithoutParams === 'reset_password_confirm') {
      this.resetToken = this.activatedRoute.snapshot.queryParams['token'];
      // when you get to this point the csrf token has not been set.
      this.isLoadingTokenVerification = true;
      this.verifyResetToken();
    }
    if (urlWithoutParams === 'confirm_account') {
      this.confirmationToken =
        this.activatedRoute.snapshot.queryParams['token'];
      this.verifyAccountConfirmationToken();
    }
    if (urlWithoutParams === 'request_new_activation_token') {
      this.showRequestNewActivationToken = true;
    }
    if (redirect) {
      this.auth
        .isUserAuthenticated()
        .subscribe(response => this.showHideElements(response));
    }
  }

  showHideElements(response): void {
    if (response.success) {
      this.showRegister = this.showLogin = false;
      this.alreadyLoggedIn = true;
      this._router.navigateByUrl(this.postEventRedirectUrl);
    }
  }

  onLogin(): void {
    const redirectUrl =
      this.routeService.redirectUrl || this.postEventRedirectUrl;
    this.auth.login(this.user).subscribe(response => {
      return this.auth.handleAuthenticationResponse(
        response,
        true,
        redirectUrl
      );
    });
  }

  onRegister(): void {
    this.auth
      .register(this.user, this.postEventRedirectUrl)
      .subscribe(response =>
        this.auth.handleAuthenticationResponse(
          response,
          true,
          this.postEventRedirectUrl
        )
      );
  }

  dealWithAccountConfirmationResponse(response): void {
    if (response.success) {
      this.pubMethods.showInfoMessage(
        'Thank you for confirming your email address. Your account is now active.'
      );
      setTimeout(
        () => window.location.replace(this.postEventRedirectUrl),
        2000
      );
    } else {
      this.showRequestNewActivationToken = true;
    }
  }

  verifyAccountConfirmationToken(): void {
    this.auth
      .verifyAccount(this.confirmationToken)
      .subscribe(response =>
        this.dealWithAccountConfirmationResponse(response)
      );
  }

  dealWithPasswordResetRequestSubmission(response): void {
    if (response.success) {
      this.pubMethods.showInfoMessage(
        'Password reset instructions have been sent to the email address associated with your account.'
      );
    }
  }

  requestNewActivationToken(): void {
    this.auth
      .requestNewActivationToken()
      .subscribe(response => this.processNewActivationTokenRequest(response));
  }

  processNewActivationTokenRequest(response): void {
    if (response.success) {
      this.pubMethods.showInfoMessage(
        'Please check your inbox for a new email to activate your account.'
      );
    }
  }

  onPasswordReset(): void {
    this.auth
      .passwordReset(this.user)
      .subscribe(response =>
        this.dealWithPasswordResetRequestSubmission(response)
      );
  }

  updatePasswordResetFormVisibility(response): void {
    this.isLoadingTokenVerification = false;
    if (response.success) {
      this.showPasswordResetPassword = true;
    } else {
      this.pubMethods.log(
        'Invalid or expired link. Please request a new one <a href="https://app.zint.io/reset_password">here</a>'
      );
    }
  }

  verifyResetToken(): void {
    setTimeout(() => {
      this.auth
        .verifyResetToken(this.resetToken)
        .subscribe(response =>
          this.updatePasswordResetFormVisibility(response)
        );
    }, 2500);
  }

  dealWithPasswordResetResponse(response): void {
    if (response.success) {
      this.pubMethods.showInfoMessage(
        'Your password has been successfully reset. You may now login'
      );
    }
  }

  onPasswordResetChoosePassword(): void {
    this.auth
      .choosePassword(this.resetToken, this.user)
      .subscribe(response => this.dealWithPasswordResetResponse(response));
  }
}
