import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from '../globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { CookieService } from 'ngx-cookie';
import { CookieOptions } from 'ngx-cookie/lib/cookie.model';
declare var zE: any; // Zendesk SDK variable

@Injectable()
export class ZendeskSsoService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService,
    private cookieservice: CookieService
  ) {}

  isLoading = {
    isLoadingKnowledgeBase: false,
  };

  getKnowledgebaseRedirectURL(params): Observable<any> {
    const _params = new HttpParams({ fromObject: params });
    this.isLoading['isLoadingKnowledgeBase'] = true;
    return this.http
      .post(
        baseUrl + '/knowledge_base_auth/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingKnowledgeBase'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getKnowledgebaseRedirectURL',
            [],
            this.isLoading
          )
        )
      );
  }

  // ------------------- Related to Zendesk JWT for messaging -----------------------

  triggerZendeskMessagingJWTIfLoggedIn(response): void {
    // skip if localhost or if the user is on the logout page.
    if (baseUrl.includes('localhost') || window.location.pathname === '/logout') return;

    if (response.success) {
      this.getOrUpdateCurrentZendeskMessagingJWT();
    } else {
      this.logoutZendeskUser();
    }
  }

  getOrUpdateCurrentZendeskMessagingJWT(): void {
    var currentZendeskMessagingJWT = this.cookieservice.get('zendeskMessagingJWT');
    if (!currentZendeskMessagingJWT) {
      this.auth
        .getZenDeskMessagingJWT()
        .subscribe(data => this.updateZendeskMessagingJWT(data));
    } else {
      this.loginZendeskUser(currentZendeskMessagingJWT);
    }
  }

  updateZendeskMessagingJWT(data): void {
    if (data.token) {
      this.pubMethods.setCookieAndExpiry({
        name: 'zendeskMessagingJWT',
        value: data.token,
        expiry: new Date(data.expiry_timestamp * 1000),
      });

      this.loginZendeskUser(data.token);
    }
  }

  // make sure that the Zendesk SDK is loaded
  loginZendeskUser(JWT) {
    if (typeof zE === 'undefined') {
      setTimeout(() => this.loginZendeskUser(JWT), 100);
    } else {
      zE(() => {
        zE('messenger', 'loginUser', function (callback) {
          callback(JWT);
        });
      });
    }
  }

  logoutZendeskUser() {
    this.cookieservice.remove('zendeskMessagingJWT');
    zE('messenger', 'logoutUser');
  }

  // ------------------- Related to Zendesk JWT for messaging -----------------------
}
