import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'previous-next-buttons',
  templateUrl: './previous-next-buttons.component.html',
  styleUrls: ['./previous-next-buttons.component.css'],
})
export class PreviousNextButtonsComponent implements OnInit {
  @Input()
  inputParams: any = {
    isPreviousEnabled: true,
    isNextEnabled: true,
    overrideNextButtonText: '',
  };
  @Output() childPreviousButtonTrigger = new EventEmitter();
  @Output() childNextButtonTrigger = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  childPreviousButtonClicked(): void {
    this.childPreviousButtonTrigger.emit();
  }

  childNextButtonClicked(): void {
    this.childNextButtonTrigger.emit();
  }
}
