import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionsService } from '../../../services/permissions.service';
declare var $: any;

@Component({
  selector: 'permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css'],
})
export class PermissionsComponent implements OnInit {
  @Input()
  objectWithPermissions: any;
  @Input()
  isSuperUser: any;
  @Input()
  organisationTeams: any;
  @Input()
  organisationUsers: any;
  @Input()
  postParamName: string;
  @Input()
  urlAppendString: string;
  @Input()
  showViewPermissions = true;
  @Input()
  showEditPermissions = true;

  @Output()
  permissionChanged: EventEmitter<void> = new EventEmitter();

  teamToDelete: any;
  userToDelete: any;

  constructor(private permissionsService: PermissionsService) {}

  ngOnInit(): void {
  }

  addViewableUser(userId): void {
    this.permissionsService
      .addViewableUser(
        userId,
        this.objectWithPermissions.id,
        this.postParamName,
        this.urlAppendString
      )
      .subscribe(data => {
        this.objectWithPermissions = data;
        this.permissionChanged.emit();
      });
  }

  removeViewableUser(): void {
    this.permissionsService
      .removeViewableUser(
        this.userToDelete.userId,
        this.objectWithPermissions.id,
        this.postParamName,
        this.urlAppendString
      )
      .subscribe(
        data => {
          this.objectWithPermissions = data;
          this.permissionChanged.emit();
        },
        $(
          '#confirmDeleteViewableUserModal__' +
            this.postParamName +
            '__' +
            this.objectWithPermissions.id
        ).modal('hide')
      );
  }

  addEditableUser(userId): void {
    this.permissionsService
      .addEditableUser(
        userId,
        this.objectWithPermissions.id,
        this.postParamName,
        this.urlAppendString
      )
      .subscribe(data => (this.objectWithPermissions = data));
  }

  removeEditableUser(): void {
    this.permissionsService
      .removeEditableUser(
        this.userToDelete.userId,
        this.objectWithPermissions.id,
        this.postParamName,
        this.urlAppendString
      )
      .subscribe(
        data => (this.objectWithPermissions = data),
        $(
          '#confirmDeleteEditableUserModal__' +
            this.postParamName +
            '__' +
            this.objectWithPermissions.id
        ).modal('hide')
      );
  }

  addViewableTeam(teamId): void {
    this.permissionsService
      .addViewableTeam(
        teamId,
        this.objectWithPermissions.id,
        this.postParamName,
        this.urlAppendString
      )
      .subscribe(data => {
        this.objectWithPermissions = data;
        this.permissionChanged.emit();
      });
  }

  removeViewableTeam(): void {
    this.permissionsService
      .removeViewableTeam(
        this.teamToDelete.id,
        this.objectWithPermissions.id,
        this.postParamName,
        this.urlAppendString
      )
      .subscribe(
        data => {
          this.objectWithPermissions = data;
          this.permissionChanged.emit();
        },
        $(
          '#confirmDeleteViewableTeamModal__' +
            this.postParamName +
            '__' +
            this.objectWithPermissions.id
        ).modal('hide')
      );
  }

  addEditableTeam(teamId): void {
    this.permissionsService
      .addEditableTeam(
        teamId,
        this.objectWithPermissions.id,
        this.postParamName,
        this.urlAppendString
      )
      .subscribe(data => (this.objectWithPermissions = data));
  }

  removeEditableTeam(): void {
    this.permissionsService
      .removeEditableTeam(
        this.teamToDelete.id,
        this.objectWithPermissions.id,
        this.postParamName,
        this.urlAppendString
      )
      .subscribe(
        data => (this.objectWithPermissions = data),
        $(
          '#confirmDeleteEditableTeamModal__' +
            this.postParamName +
            '__' +
            this.objectWithPermissions.id
        ).modal('hide')
      );
  }
}
