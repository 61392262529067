<div>
  <div class="input-group mb-3">

    <input type="text" class="form-control"
      placeholder="''search terms'', power edit or try the lightbulb for inspiration"
      [disabled]="isEditingQuery" [(ngModel)]="userDefinedTerms"
      (keyup.enter)="addToSelectedTerms(userDefinedTerms)" (keydown.shift.enter)="populateQueryString($event.target.value)"/>
    <div class="input-group-append">
      <button class="btn btn-outline-dark" [disabled]="isEditingQuery" matTooltipPosition="above"
        matTooltip="Power edit" (click)="populateQueryString(userDefinedTerms)"><i
          class="far fa-money-check-edit fa-sm"></i></button>
    </div>
    <div class="input-group-append">
      <button [attr.disabled]="!this.suggestedTerms || this.suggestedTerms.length === 0 ? 'disabled' : null"
        class="btn btn-outline-warning" (click)="showModal()" matTooltipPosition="above"
        matTooltip="Inspire me">
        <i class="fad fa-lightbulb-on"></i>
      </button>
    </div>

  </div>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-baseline mb-2">
      <div>
        <button type="button" class="border-0 badge badge-pill p-2 mr-1 mb-1" [disabled]="isEditingQuery"
          *ngFor="let item of selectedTerms" [ngClass]="isEditingQuery ? 'badge-dark' : 'badge-info'"
          (click)="!isEditingQuery && removeSelectedTerm(item)" matTooltip="Remove">
          {{ item }}
        </button>
      </div>
    </div>
    <div *ngIf="isEditingQuery" class="input-group">
      <input type="text" class="form-control" placeholder="double-quoted terms followed by uppercase OR, AND, NOT" [(ngModel)]="selectedTermQueryString" #powerEditValue
      (keyup.enter)="powerEditQueryString($event.target.value)" autofocus/>
      <div class="input-group-append">
        <button role="button" class="input-group-text"
        (click)="powerEditQueryString(powerEditValue.value)">Enter&#9166;</button>
      </div>
      <div class="input-group-append">
        <span role="button" class="input-group-text"
          matTooltip="Use double quotes for ''search terms'', NOT for negative search, AND for multiple phrases, OR for different options. Each term taken as OR by default."
          (click)="showPowerEditInfo()"><i class="far fa-info-circle fa-sm"></i></span>
      </div>
    </div>

  </div>
</div>

<!-- MODAL FOR AI SUGGESTED TERMS -->
<div class="modal fade" id="suggestedKeywordModal{{eventSourceType}}" role="dialog">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="!reStart && shortlistedTerms.length" class="btn btn-light mr-2"
          matTooltipPosition="below" matTooltip="back" (click)="startModalAgain()"><i
            class="fas fa-arrow-square-left"></i></button>
        <h5 class="modal-title">Suggested Terms</h5>
        <button class="close" data-dismiss="modal">x</button>
      </div>
      <div class="modal-body">
        <div *ngIf="isQuickAddError" class="alert alert-danger p-0">
          <span class="pl-1">invalid input or duplicate</span>
        </div>
        <ng-container *ngIf="shortlistedTerms.length < 1 || reStart; else elseBlock">
          <div *ngFor="let category of suggestedTerms | keyvalue">
            <div *ngIf="category.value.length" class="col-sm mb-3">
              <div class="d-flex justify-content-between align-items-baseline">
                <h6><mark>{{ category.key | uppercase }}</mark></h6>
                <div class="d-flex">
                  <input *ngIf="category.value.isQuickAdding === true" type="text" class="form-control"
                    placeholder="Enter your term" (keydown.enter)="quickAddTerm($event.target.value, category.key)" />
                  <button class="btn btn-light rounded-0" matTooltipPosition="above"
                    matTooltip="Add your own term" (click)="toggleQuickAdd(category.key)"><i
                      class="fas fa-keyboard"></i></button>
                </div>
              </div>
              <div class="bg-light pt-1">
                <button *ngFor="let keywordObject of category.value" class="btn btn-outline-dark mr-1"
                  (click)="addToShortList(keywordObject)" matTooltipPosition="above"
                  [matTooltip]="'Result count - ' + keywordObject.count">
                  {{ keywordObject.term }} ~{{ keywordObject.count | roundResultCount }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #elseBlock>
          <div *ngFor="let shortlistedTerm of shortlistedTerms">
            <div class="input-group mb-1">
              <button *ngIf="!shortlistedTerm.isEditing" type="button" class="btn btn-outline-dark"
                (click)="addToSelectedTerms(shortlistedTerm.term)" matTooltipPosition="above"
                style="border-top-right-radius: 0; border-bottom-right-radius: 0 "
                [matTooltip]="'Result count - ' + shortlistedTerm.count">
                {{ shortlistedTerm.term }} ~{{ shortlistedTerm.count | roundResultCount }}
              </button>
              <div *ngIf="shortlistedTerm.isEditing" class="col-xs">
                <input type="text" class="col-sm form-control" [(ngModel)]="shortlistedTerm.term"
                  (keyup.enter)="addToSelectedTerms(shortlistedTerm.term)" />
              </div>

              <div class="input-group-append">

                <button class="btn btn-dark btn-sm" matTooltipPosition="above" matTooltip="edit"
                  (click)="editSuggestion(shortlistedTerm.term)"><i class="fas fa-pencil"></i>
                </button>

                <button class="btn btn-warning btn-sm" (click)="getSimilarTerms(shortlistedTerm.term)"
                 matTooltipPosition="above" matTooltip="Generate similar"
                  [attr.disabled]="this.isLoading.loadingSuggestions ? 'disabled': null">
                  <i class="fad fa-lightbulb-on"></i>
                  <ng-container class="ml-1"
                    *ngIf="this.isLoading.loadingSuggestions && this.termSearchingOn === shortlistedTerm.term">&nbsp;<i
                      class="fal fa-spinner fa-spin"></i></ng-container>
                </button>
              </div>

            </div>

          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- MODAL ENDS -->