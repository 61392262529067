<ng-container *ngIf="!isUserSubscribed">
  <section class="container">
  </section>
</ng-container>
<section class="container-fluid">
  <h2 class="mb-4">Manage your emails notifications</h2> 
  <div *ngIf="!isUserSubscribed" class="my-5 py-4 border border-info">
    <h4 class="text-center">You have been unsubscribed from Zint Grow emails.</h4>
    <p class="text-center">If you wish to resubscribe, please set your preferences and update below.</p>
  </div> 
  <div class="row mx-2 mx-lg-5 px-lg-5">
    <div class="col-12 row mb-1">
      <p class="col-md-8 col-lg-10 mb-1 mb-md-2">Select the <span class="font-weight-bold">lowest importance score</span> for the notifications to trigger sending you emails.</p>
      <select class="custom-select col-md-4 col-lg-2 ml-2 ml-md-0 rounded-0" [(ngModel)]="selectedImportanceThreshold" >      
        <option *ngFor="let threshold of [1,2,3,4,5]">{{threshold}}</option>
      </select>
    </div>
    <div class="col-12 row mb-2">
      <p class="col-md-8 col-lg-10 mb-1 mb-md-2">Select <span class="font-weight-bold">how often</span> you would like to receive emails.</p>
      <select class="custom-select col-md-4 col-lg-2 ml-2 ml-md-0 rounded-0" [(ngModel)]="selectedFrequency">        
        <option value="Daily">Daily</option>
        <option value="Weekly">Weekly</option>
      </select>
    </div>
    <div class="col-12 row">
    <button class="col-2 col-md-1 ml-auto basic-button-base button-dark" (click)="updateSettings()">Update</button>
    </div>
  </div>
  <div *ngIf="isUserSubscribed" class="mt-5">
    <p class="text-center"><a href="#confirmUnsubscribeModal" data-toggle="modal" data-target="#confirmUnsubscribeModal">Unsubscribe</a> from all Zint Grow emails!</p>
  </div>
</section>

<div class="modal fade" id="confirmUnsubscribeModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure you wish to unsubscribe?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              <p class="text-danger text-center">You will no longer receive any emails from Zint Grow! <br> You still wish to unsubscribe?</p>
              <div class="d-flex justify-content-center mb-4">
                <button class="basic-button-base button-purple" data-dismiss="modal">No</button>
                <button class="basic-button-base button-bear ml-2" data-dismiss="modal" (click)="confirmUnsubscribe()">Yes</button>
              </div>              
            </div>
            <div class="modal-footer">
                <button type="button" class="basic-button-base button-dark" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>