import { Component, Input } from '@angular/core';
import { IBackendDrivenTable } from '../../../classes/types';
import { publicMethods } from '../../../globals';

@Component({
  selector: 'backend-table',
  templateUrl: './backend-table.component.html',
  styleUrl: './backend-table.component.css',
})
export class BackendTableComponent {
  /*
data structure should match the following akin to /llm_energy_emissions_data/ response
  {
    columns: [
      {
        column_name: 'year 2',
        column_link: 'www.link1.com',
      },
      {
        column_name: 'year 1',
        column_link: 'www.link2.com',
      },
    ],
    rows: [
      { name: 'scope2', data: { 'year 2': 1000, 'year 1': 30 }, type: 'funds'},
      { name: 'total usage', data: { 'year 2': 1000, 'year 1': 5000 }, type: 'funds'},
    ],
  };
  */

  @Input() tableData: IBackendDrivenTable;
  @Input() tableName: string = '';
  @Input() formatting: string = '';
  @Input() showTrend: boolean = false;

  trendData: Record<string, any[]> = {};

  constructor(public pubMethods: publicMethods) {}

  ngOnInit() {
    this.getTrendData();
  }

  getTrendData() {
    this.tableData.rows?.forEach(rowObj => {
      this.trendData[rowObj.name] = Object.values(rowObj.data)
        .map(val => {
          return val;
        })
        .reverse();
    });
  }
}
