import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class StripePurchaseService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingPlanStatus: false,
    loadingCheckoutSession: false,
    loadingSubscriptions: false,
    loadingPortalSession: false,
  };

  getPurchaseStatus(purchaseId, forceLoginNext, promoCode): Observable<any> {
    let params = new HttpParams()
      .set('purchaseId', purchaseId)
      .set('forceLoginNext', forceLoginNext)
      .set('promoCode', promoCode);
    this.isLoading['loadingPlanStatus'] = true;
    return this.http
      .post(
        baseUrl + '/get_stripe_purchase_status/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingPlanStatus'] = false)),
        catchError(this.pubMethods.handleError('getPurchaseStatus', []))
      );
  }

  defGetStripeCheckoutSession(
    stripeProductId,
    stripePriceId,
    promoCode
  ): Observable<any> {
    let params = new HttpParams()
      .set('stripeProductId', stripeProductId)
      .set('stripePriceId', stripePriceId)
      .set('promoCode', promoCode);
    this.isLoading['loadingCheckoutSession'] = true;
    return this.http
      .post(
        baseUrl + '/get_stripe_checkout_session/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCheckoutSession'] = false)),
        catchError(
          this.pubMethods.handleError('defGetStripeCheckoutSession', [])
        )
      );
  }

  getSubscriptions(): Observable<any> {
    let params = new HttpParams();
    this.isLoading['loadingSubscriptions'] = true;
    return this.http
      .post(
        baseUrl + '/get_subscriptions/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSubscriptions'] = false)),
        catchError(this.pubMethods.handleError('getSubscriptions', []))
      );
  }

  getStripeSubscriptionPortal(stripeCustomerId): Observable<any> {
    let params = new HttpParams().set('stripeCustomerId', stripeCustomerId);
    this.isLoading['loadingPortalSession'] = true;
    return this.http
      .post(
        baseUrl + '/get_stripe_portal_session/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingPortalSession'] = false)),
        catchError(
          this.pubMethods.handleError('getStripeSubscriptionPortal', [])
        )
      );
  }
}
