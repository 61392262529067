import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class SalesforceImporterService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingInitialData: false,
    loadingMatcherProgress: false,
    loadingNextStepDetails: false,
    loadingCancellingProcess: false,
    loadingDataSync: false,
  };

  loadStatus(): Observable<any> {
    const _params = new HttpParams();
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/get_salesforce_harmoniser_status/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError('loadStatus', [], this.isLoading)
        )
      );
  }

  loadSalesforceHarmoniserSettings(): Observable<any> {
    const _params = new HttpParams();
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/get_salesforce_harmoniser_settings/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError('loadInitialData', [], this.isLoading)
        )
      );
  }

  loadSalesforceMatcherProgress(): Observable<any> {
    const _params = new HttpParams();
    this.isLoading['loadingMatcherProgress'] = true;
    return this.http
      .post(
        baseUrl + '/get_salesforce_matcher_progress/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingMatcherProgress'] = false)),
        catchError(
          this.pubMethods.handleError(
            'loadSalesforceMatcherProgress',
            [],
            this.isLoading
          )
        )
      );
  }

  chooseSalesforceObjectType(selectedSobjectType): Observable<any> {
    const _params = new HttpParams().set(
      'selectedSobjectType',
      selectedSobjectType
    );
    this.isLoading['loadingNextStepDetails'] = true;
    return this.http
      .post(
        baseUrl + '/choose_salesforce_harmoniser_sobject_type/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingNextStepDetails'] = false)),
        catchError(
          this.pubMethods.handleError(
            'chooseSalesforceObjectType',
            [],
            this.isLoading
          )
        )
      );
  }

  chooseSalesforceObjectUniqueIdColumnHeader(
    salesforceUniqueIdColumnName
  ): Observable<any> {
    const _params = new HttpParams().set(
      'salesforceUniqueIdColumnName',
      salesforceUniqueIdColumnName
    );
    this.isLoading['loadingNextStepDetails'] = true;
    return this.http
      .post(
        baseUrl + '/submit_column_header_representing_sobject_unique_id/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingNextStepDetails'] = false)),
        catchError(
          this.pubMethods.handleError(
            'chooseSalesforceObjectUniqueIdColumnHeader',
            [],
            this.isLoading
          )
        )
      );
  }

  chooseColumnHeadersToSynchronise(
    columnHeadersToSynchronise
  ): Observable<any> {
    const _params = new HttpParams().set(
      'columnHeadersToSynchronise',
      this.pubMethods.stringifyListForGetParams(columnHeadersToSynchronise)
    );
    this.isLoading['loadingNextStepDetails'] = true;
    return this.http
      .post(
        baseUrl + '/submit_column_headers_to_synchronise/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingNextStepDetails'] = false)),
        catchError(
          this.pubMethods.handleError(
            'chooseColumnHeadersToSynchronise',
            [],
            this.isLoading
          )
        )
      );
  }

  chooseColumnHeadersForMatching(columnHeadersToMatch): Observable<any> {
    const _params = new HttpParams().set(
      'columnHeadersToMatch',
      this.pubMethods.stringifyListForGetParams(columnHeadersToMatch)
    );
    this.isLoading['loadingNextStepDetails'] = true;
    return this.http
      .post(
        baseUrl + '/submit_column_headers_for_match_process/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingNextStepDetails'] = false)),
        catchError(
          this.pubMethods.handleError(
            'chooseColumnHeadersForMatching',
            [],
            this.isLoading
          )
        )
      );
  }

  startMatchingProcess(): Observable<any> {
    const _params = new HttpParams();
    this.isLoading['loadingNextStepDetails'] = true;
    return this.http
      .post(
        baseUrl + '/submit_salesforce_matching_process/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingNextStepDetails'] = false)),
        catchError(
          this.pubMethods.handleError(
            'startMatchingProcess',
            [],
            this.isLoading
          )
        )
      );
  }

  cancelMatchingProcess(): Observable<any> {
    const _params = new HttpParams();
    this.isLoading['loadingCancellingProcess'] = true;
    return this.http
      .post(
        baseUrl + '/cancel_salesforce_matching_process/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCancellingProcess'] = false)),
        catchError(
          this.pubMethods.handleError(
            'cancelMatchingProcess',
            [],
            this.isLoading
          )
        )
      );
  }

  createSalesforceSelectedOnPage(rowIdsToSelectedCompanyId): Observable<any> {
    const _params = new HttpParams().set(
      'rowIdsToSelectedCompanyId',
      this.pubMethods.stringifyDictForGetParams(rowIdsToSelectedCompanyId)
    );
    this.isLoading['loadingDataSync'] = true;
    return this.http
      .post(
        baseUrl + '/create_salesforce_records_for_page/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingDataSync'] = false)),
        catchError(
          this.pubMethods.handleError(
            'createSalesforceSelectedOnPage',
            [],
            this.isLoading
          )
        )
      );
  }

  createSalesforceRecordsForAll(
    lowerMatchLevel,
    upperMatchLevel
  ): Observable<any> {
    const _params = new HttpParams()
      .set('lowerMatchLevel', lowerMatchLevel)
      .set('upperMatchLevel', upperMatchLevel);
    this.isLoading['loadingDataSync'] = true;
    return this.http
      .post(
        baseUrl + '/create_salesforce_records_with_filter/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingDataSync'] = false)),
        catchError(
          this.pubMethods.handleError(
            'createSalesforceRecordsForAll',
            [],
            this.isLoading
          )
        )
      );
  }
}
