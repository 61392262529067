import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'add-remove-team',
  templateUrl: './add-remove-team.component.html',
  styleUrls: ['./add-remove-team.component.css'],
})
export class AddRemoveTeamComponent implements OnInit {
  @Output() ParentFunctionOnAddNewTeam: EventEmitter<any> = new EventEmitter();
  ChildFunctionOnAddNewTeam(teamId, objectAddingOn): void {
    this.ParentFunctionOnAddNewTeam.emit([teamId, objectAddingOn]);
  }

  @Output() ParentFunctionOnClickToDeleteTeam: EventEmitter<any> =
    new EventEmitter();
  ChildFunctionOnClickToDeleteTeam(team, objectRemovingOn): void {
    this.ParentFunctionOnClickToDeleteTeam.emit([team, objectRemovingOn]);
  }

  constructor() {}

  @Input()
  inputParams: any = {
    confirmDeleteModalId: String,
    objectAddingOrRemovingTeam: Object,
    organisationTeams: Array,
    attributeNameForTeams: String,
  };

  isAddingTeam = false;

  ngOnInit(): void {}
}
