<div class="container">
    <div class="row">

        <div class="col-md-12 medium-margin-bottom" *ngIf="!activeCourse">
            <div class="card">
                <div class="card-body">
                    <h3 style="margin-left: 20px">Search Courses and Lessons:</h3>
                    <div class="input-group" style="padding-left: 20px; padding-right: 20px">
                        <input type="text" class="form-control" [(ngModel)]="searchTerm" placeholder="e.g. tagging organisations" (keyup.enter)="searchCoursesAndLessons()">
                        <div class="input-group-append">
                            <button class="btn basic-button-base button-dark" type="button" (click)="searchCoursesAndLessons()">
                                <i class="fal fa-search" *ngIf="!isLoading.loadingCourseSearch"></i>
                                <i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingCourseSearch"></i>
                            </button>
                            <button class="btn basic-button-base button-dark" type="button" (click)="searchResults = {}">
                                <i class="fal fa-times"></i>
                            </button>
                        </div>
                    </div>

                    <div class="small-margin-top" *ngIf="searchResults">
                        <table class="learning-portal-search-results-table">
                            <tr *ngFor="let courseResult of searchResults.courses" (click)="updateActiveCourseAndGetVideoFromPositionNumber(courseResult.position_number)" class="cursor-pointer">
                                <td>{{ courseResult.title }} (Course {{ courseResult.position_number }})</td>
                                <td style="font-style: italic" [innerHTML]="courseResult.extract"></td>
                                <td><span class="badge badge-info"> Course </span></td>
                            </tr>
                            <tr *ngFor="let lessonResult of searchResults.lessons" (click)="updateActiveLessonAndGetVideoFromPositionNumber(lessonResult.course_position_number, lessonResult.position_number)" class="cursor-pointer">
                                <td>{{ lessonResult.title }} (Course {{ lessonResult.course_position_number }}; Lesson {{ lessonResult.position_number }})</td>
                                <td style="font-style: italic" [innerHTML]="lessonResult.extract"></td>
                                <td><span class="badge badge-info"> Lesson </span></td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        </div>


        <ng-container *ngIf="!activeCourse">
            <div class="col-md-12" *ngFor="let course of courses">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <h3 class="card-title">{{course.title}}</h3>
                            </div>
                            <div class="col-lg-4 small-margin-top">
                                <p class="card-text">{{ course.summary_sentence }}</p>
                                <button class="basic-button-base button-dark" (click)="updateActiveCourseAndGetVideo(course)">View Introduction</button>
                            </div>
                            <div class="col-lg-6 small-margin-top">
                                <table>
                                    <tr *ngFor="let lesson of course.lessons" (click)="updateActiveLessonAndGetVideo(course, lesson)" class="cursor-pointer">
                                        <td>
                                            <i class="fal fa-arrow-alt-right"></i>
                                            {{ lesson.position_number }}
                                            {{ lesson.title }}
                                            ( {{ lesson.length_of_video / 60 | number:'1.0-0' }} mins )
                                        </td>
                                        <td style="padding-left: 20px">
                                            <ng-container *ngIf="lesson.progress == 100">
                                                Complete
                                                <i class="fal fa-badge-check"></i>
                                            </ng-container>
                                            <ng-container *ngIf="lesson.progress > 0 && lesson.progress != 100">
                                                {{ lesson.progress }} %
                                            </ng-container>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-lg-2 small-margin-top">
                                <percentage-badge [inputParams]="{'percentage': course.progress}"></percentage-badge>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </ng-container>


        <div class="col-md-12 medium-margin-bottom" *ngIf="activeCourse && !activeLesson">
            <h2>{{ activeCourse.title }}</h2>
            <div class="embed-responsive embed-responsive-16by9 small-margin-bottom small-margin-top" *ngIf="activeVideoUrl">
                <video [attr.id]="'courseVideo' + activeCourse.position_number" class="embed-responsive-item" *ngIf="activeVideoUrl" controls disablepictureinpicture controlsList="nodownload">
                    <source [attr.src]="activeVideoUrl" type="video/mp4">
                    Your browser does not support this type of video. Please consider using Google Chrome.
                </video>
            </div>
            <p>{{ activeCourse.summary_sentence }}</p>
            <button class="basic-button-base button-dark" (click)="activeCourse = null; activeLesson = null">Back to all courses</button>
            <button class="basic-button-base button-dark" (click)="updateActiveLessonAndGetVideo(activeCourse)" *ngIf="activeCourse.lessons && activeCourse.lessons.length > 0">Begin First Lesson</button>
        </div>


        <div class="col-md-12 medium-margin-bottom" *ngIf="activeCourse && activeLesson">
            <h2 style="display: inline">{{ activeLesson.title }}</h2>
            <percentage-badge [inputParams]="{'percentage': activeLesson.progress}" style="float: right; margin-bottom: 20px; margin-top: -15px;"></percentage-badge>
            <div class="embed-responsive embed-responsive-16by9 small-margin-bottom small-margin-top" *ngIf="activeVideoUrl">
                <video [attr.id]="'lessonVideo' + activeLesson.position_number" class="embed-responsive-item" *ngIf="activeVideoUrl" controls disablepictureinpicture controlsList="nodownload">
                    <source [attr.src]="activeVideoUrl" type="video/mp4">
                    Your browser does not support this type of video. Please consider using Google Chrome.
                </video>
            </div>
            <p>{{ activeLesson.summary_paragraph }}</p>
            <hr>
            <div *ngIf="activeLesson.resources && activeLesson.resources.length > 0">
                <h5>Additional Resources:</h5>
                <p *ngFor="let resource of activeLesson.resources">
                    <a href="{{ resource.link }}" target="_blank">{{ resource.title }}</a>
                </p>
            </div>
            <button class="basic-button-base button-dark" (click)="activeCourse = null; activeLesson = null">Back to all courses</button>
            <button class="basic-button-base button-dark" (click)="updateActiveLessonAndGetVideo(activeCourse)">Next Lesson</button>
        </div>


    </div>
</div>
