import { Component, OnInit } from '@angular/core';
import { CalendarIntegrationService } from '../../services/calendar-integration.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'calendar-integration',
  templateUrl: './calendar-integration.component.html',
  styleUrls: ['./calendar-integration.component.css'],
})
export class CalendarIntegrationComponent implements OnInit {
  googleOauthLink: string = '';
  isGoogleAccountAuthorised: boolean = false;
  microsoftOauthLink: string = '';
  isMicrosoftAccountAuthorised: boolean = false;
  calendarSummaries: any[] = [];
  summariesToDisplay: any[] = [];
  countPerPage: number = 10;
  currentPage: number = 1;
  noMoreData: boolean = false;
  hasAcceptedTermsAndConditions: boolean = false;

  constructor(
    private calendarIntegrationService: CalendarIntegrationService,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    document.title = 'Calendar Integration';
    this.calendarIntegrationService.getGoogleOauthState().subscribe(data => {
      this.updateGoogleState(data);
    });
    this.calendarIntegrationService.getMicrosoftOauthState().subscribe(data => {
      this.updateMicrosoftState(data);
    });
    // TODO load data seems to be duplicated here?
  }

  updateMicrosoftState(data): void {
    this.isMicrosoftAccountAuthorised = data['is_authorised'];
    this.microsoftOauthLink = data['authorisation_url'];
    if (this.isMicrosoftAccountAuthorised) {
      this.calendarIntegrationService
        .getCalendarSummaries()
        .subscribe(output => {
          this.calendarSummaries = output;
          this.loadMoreData();
        });
    }
  }

  updateGoogleState(data): void {
    this.isGoogleAccountAuthorised = data['is_authorised'];
    this.googleOauthLink = data['authorisation_url'];
    if (this.isGoogleAccountAuthorised) {
      this.calendarIntegrationService
        .getCalendarSummaries()
        .subscribe(output => {
          this.calendarSummaries = output;
          this.loadMoreData();
        });
    }
  }

  loadMoreData(): void {
    if (this.summariesToDisplay.length === this.calendarSummaries.length) {
      return;
    }

    const startIndex = (this.currentPage - 1) * this.countPerPage;
    const endIndex = startIndex + this.countPerPage;
    const nextData = this.calendarSummaries.slice(startIndex, endIndex);

    this.summariesToDisplay = this.summariesToDisplay.concat(nextData);
    this.currentPage = this.currentPage + 1;

    if (this.summariesToDisplay.length === this.calendarSummaries.length) {
      this.noMoreData = true;
    }
  }

  processWebsiteSummary(summary: string): string {
    // split where a full stop is followed by a space and input line breaks
    const splitSummary = summary.split(/\.\s/g);

    return splitSummary
      .map(sentence => sentence + '.\n')
      .slice(0, -1)
      .join('');
  }

  jumpToMeeting(eventId: string): void {
    this.viewportScroller.scrollToAnchor(eventId);
  }

  scrollToTop(): void {
    this.viewportScroller.scrollToPosition([0, 120]);
  }
}
