import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {baseUrl, publicMethods} from '../globals';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarIntegrationService {

  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingGoogleOauthState: false,
    loadingMicrosoftOauthState: false,
    loadingCalendarSummaries: false
  }


  getGoogleOauthState(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/get_google_oauth_status/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingGoogleOauthState'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getGoogleOauthState',
            [],
            this.isLoading
          )
        )
      );
  }

  getMicrosoftOauthState(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/get_microsoft_oauth_status/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingMicrosoftOauthState'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getMicrosoftOauthState',
            [],
            this.isLoading
          )
        )
      );
  }

  getCalendarSummaries(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/get_calendar_event_summaries/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCalendarSummaries'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getCalendarSummaries',
            [],
            this.isLoading
          )
        )
      );
  }




}
