import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../../services/communication.service';
import { CRMIntegrationsService } from '../../services/crm-integrations.service';
import { publicMethods } from '../../globals';
declare var $: any;

@Component({
  selector: 'crm-integrations',
  templateUrl: './crm-integrations.component.html',
  styleUrls: ['./crm-integrations.component.css'],
})
export class CrmIntegrationsComponent implements OnInit {
  isSuperUser: boolean;
  isSalesforceAuthorised: boolean;
  selectedSalesforceObject: string;
  salesforceAutomaticallyEnabledForAll: boolean;

  companyAttributeUniqueIdentifiers: Array<any>;
  salesforceObjectOptions: Array<any>;
  salesforceObjectFieldNames: Array<any>;

  salesforceAttributeToSaveAsConstant: string;
  salesforceConstantValue: string;

  resultsHeaders: Array<any>;
  customColumns: Array<any>;
  teamMembers: Array<any>;

  isLoading = this.crmIntegrationsService.isLoading;

  zintAttributesToSalesforceAttributesMapping: any = {};
  zintCustomColumnsToSalesforceAttributesMapping: any = {};
  salesforceAttributesToConstants: any = {};

  salesforceAttributeUniqueIdentifier: string;
  zintAttributeUniqueIdentifier: string;

  customColumnPrependString: string = 'custom_column__';

  constructor(
    private communicationService: CommunicationService,
    private crmIntegrationsService: CRMIntegrationsService,
    private pubMethods: publicMethods
  ) {}

  ngOnInit() {
    document.title = 'CRM Integrations';
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.getCRMDetails();
  }

  getCRMDetails(): void {
    this.crmIntegrationsService
      .getCRMIntegrationDetails()
      .subscribe(data => this.updateCRMIntegrationDetails(data));
  }

  updateCRMIntegrationDetails(data): void {
    this.isSalesforceAuthorised = data.has_enabled_salesforce_integration;
    if (this.isSalesforceAuthorised) {
      this.teamMembers = data.team_members;
      this.salesforceAutomaticallyEnabledForAll =
        data.automatically_give_all_users_access;
      this.companyAttributeUniqueIdentifiers =
        data.zint_company_attribute_unique_identifiers;
      this.salesforceObjectOptions = data.salesforce_objects;
      this.selectedSalesforceObject = data.export_salesforce_object_name;
      this.salesforceAttributesToConstants =
        data.export_salesforce_attribute_constants_to_set;
      this.resultsHeaders = data.results_header_names;
      for (var resultsHeader of this.resultsHeaders) {
        this.zintAttributesToSalesforceAttributesMapping[resultsHeader] =
          data.export_zint_to_salesforce_attribute_mappings[resultsHeader] ||
          'ignore';
      }
      this.customColumns = data.custom_columns;
      for (var customColumn of this.customColumns) {
        this.zintCustomColumnsToSalesforceAttributesMapping[customColumn.id] =
          data.export_zint_to_salesforce_attribute_mappings[
            this.customColumnPrependString + customColumn.id
          ] || 'ignore';
      }

      if (this.selectedSalesforceObject) {
        this.salesforceObjectFieldNames =
          data.salesforce_object_attribute_names;
      }

      if (data.salesforce_unique_identifier) {
        this.salesforceAttributeUniqueIdentifier =
          data.salesforce_unique_identifier;
      }
      if (data.zint_unique_identifier_organisation_attribute) {
        this.zintAttributeUniqueIdentifier =
          data.zint_unique_identifier_organisation_attribute;
      }
      if (data.zint_unique_identifier_custom_column) {
        this.zintAttributeUniqueIdentifier =
          this.customColumnPrependString +
          data.zint_unique_identifier_custom_column;
      }
    }
  }

  CRMIntegrationUpdated(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage('Your preferences have been updated.');
    }
  }

  userAccessLevelsUpdated(data): void {
    if (data.success) {
      this.teamMembers = data.team_members;
      this.salesforceAutomaticallyEnabledForAll =
        data.automatically_give_all_users_access;
      this.pubMethods.showInfoMessage('User access settings updated.');
    }
  }

  updateSalesforceUserAccess(teamMemberId, hasAccess): void {
    this.crmIntegrationsService
      .updateUserAccessLevels(teamMemberId, hasAccess)
      .subscribe(data => this.userAccessLevelsUpdated(data));
  }

  updateSalesforceEnabledForAll(): void {
    this.crmIntegrationsService
      .changeSalesforceEnableForAllSettings(
        this.salesforceAutomaticallyEnabledForAll
      )
      .subscribe(data => this.userAccessLevelsUpdated(data));
  }

  updateCRMIntegrationDetailsAndCloseModal(data): void {
    this.updateCRMIntegrationDetails(data);
    $('#verifyChangeSalesforceObjectModal').modal('hide');
    this.pubMethods.showInfoMessage('The Salesforce record type was changed.');
  }

  updateSelectedSalesforceObjectOption(): void {
    if (this.selectedSalesforceObject) {
      this.crmIntegrationsService
        .selectSalesforceObject(this.selectedSalesforceObject)
        .subscribe(data => this.updateCRMIntegrationDetailsAndCloseModal(data));
    }
  }

  updateAttributeMappingOptions(
    selectedZintAttribute,
    isForCustomColumn
  ): void {
    if (isForCustomColumn) {
      // selectedZintAttribute will be the ID here if it is a isForCustomColumn
      var newlySelectedSalesforceAttribute =
        this.zintCustomColumnsToSalesforceAttributesMapping[
          selectedZintAttribute
        ];
    } else {
      var newlySelectedSalesforceAttribute =
        this.zintAttributesToSalesforceAttributesMapping[selectedZintAttribute];
    }
    for (let zintAttr in this.zintAttributesToSalesforceAttributesMapping) {
      let salesforceAttr =
        this.zintAttributesToSalesforceAttributesMapping[zintAttr];
      if (
        salesforceAttr == newlySelectedSalesforceAttribute &&
        zintAttr != selectedZintAttribute
      ) {
        this.zintAttributesToSalesforceAttributesMapping[zintAttr] = 'ignore';
      }
    }

    for (let zintAttr in this.zintCustomColumnsToSalesforceAttributesMapping) {
      let salesforceAttr =
        this.zintCustomColumnsToSalesforceAttributesMapping[zintAttr];
      if (
        salesforceAttr == newlySelectedSalesforceAttribute &&
        zintAttr != selectedZintAttribute
      ) {
        this.zintCustomColumnsToSalesforceAttributesMapping[zintAttr] =
          'ignore';
      }
    }
  }

  saveAttributeMappings(): void {
    this.crmIntegrationsService
      .saveZintToSalesforceMappings(
        this.zintCustomColumnsToSalesforceAttributesMapping,
        this.zintAttributesToSalesforceAttributesMapping
      )
      .subscribe(data => this.CRMIntegrationUpdated(data));
  }

  addSalesforceAttributeToConstantValue(): void {
    if (!this.salesforceAttributeToSaveAsConstant) {
      this.pubMethods.showInfoMessage(
        'Please select a Salesforce attribute from the dropdown.'
      );
      return;
    }
    if (!this.salesforceConstantValue) {
      this.pubMethods.showInfoMessage(
        'Please enter the constant you would like to save for every record exported.'
      );
      return;
    }
    this.crmIntegrationsService
      .addSalesforceAttributeToConstantMapping(
        this.salesforceAttributeToSaveAsConstant,
        this.salesforceConstantValue
      )
      .subscribe(data => this.salesforceAttributesToConstantUpdated(data));
  }

  deleteSalesforceAttributeConstantMapping(salesforceAttributeName): void {
    this.crmIntegrationsService
      .deleteSalesforceAttributeToConstantMapping(salesforceAttributeName)
      .subscribe(data => this.salesforceAttributesToConstantUpdated(data));
  }

  salesforceAttributesToConstantUpdated(data): void {
    if (data.success) {
      this.salesforceAttributeToSaveAsConstant = null;
      this.salesforceConstantValue = '';
      this.salesforceAttributesToConstants =
        data.export_salesforce_attribute_constants_to_set;
      this.pubMethods.showInfoMessage('Your preferences have been updated.');
    }
  }

  saveUniqueIdentifierMapping(data): void {
    if (
      !this.zintAttributeUniqueIdentifier ||
      !this.salesforceAttributeUniqueIdentifier
    ) {
      this.pubMethods.log(
        'Please select both the Zint attribute and the Salesforce attribute.'
      );
    }
    this.crmIntegrationsService
      .saveZintToSalesforceUniqueIdentifierMapping(
        this.zintAttributeUniqueIdentifier,
        this.salesforceAttributeUniqueIdentifier
      )
      .subscribe(data => this.CRMIntegrationUpdated(data));
  }
}
