import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'accounts-search',
  templateUrl: './accounts-search.component.html',
  styleUrls: ['./accounts-search.component.css'],
})
export class AccountsSearchComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor(private datePipe: DatePipe) {}

  event_metadata: any;
  companyNumber: any;
  mainHtml: any;
  htmlBelowImage: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.notifications[0].company_number;
    this.htmlBelowImage =
      `<i class="fal fa-table"></i> Accounts Submitted: ` +
      this.datePipe.transform(this.event_metadata.submitted_string, 'd MMM y');
  }
}
