import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class MarketMappingReportService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingInitialData: false,
    validatingMarketMappingData: false,
    loadingCancelMarketMappingReport: false,
  };

  getMarketMappingStatus(): Observable<any> {
    this.isLoading['loadingInitialData'] = true;
    return this.http
      .post(
        baseUrl + '/get_market_mapping_status/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getMarketMappingInitialisationData',
            [],
            this.isLoading
          )
        )
      );
  }

  cancelMarketMappingProcess(): Observable<any> {
    this.isLoading['loadingCancelMarketMappingReport'] = true;
    return this.http
      .post(
        baseUrl + '/cancel_market_mapping_report/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError(
            'cancelMarketMappingProcess',
            [],
            this.isLoading
          )
        )
      );
  }

  submitMarketMappingReportDetails(
    tagId,
    closedDateColumnId,
    churnedDataColumnId,
    contractValueColumnId,
    financialYearMonthStart
  ): Observable<any> {
    this.isLoading['validatingMarketMappingData'] = true;
    let _params: HttpParams;
    _params = new HttpParams()
      .set('tagId', tagId)
      .set('closedDateColumnId', closedDateColumnId)
      .set('churnedDataColumnId', churnedDataColumnId)
      .set('contractValueColumnId', contractValueColumnId)
      .set('financialYearMonthStart', financialYearMonthStart);
    return this.http
      .post(
        baseUrl + '/validate_market_mapping_data/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getMarketMappingInitialisationData',
            [],
            this.isLoading
          )
        )
      );
  }
}
