import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AlertsService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    isLoadingAlerts: false,
    isDeletingAlert: false,
    isLoadingNotifications: false,
    isLoadingMarkAsRead: false,
    isLoadingBasicAlerts: false,
    isBulkMarkingCompaniesAsRead: false,
  };

  getAlertDetails(): Observable<any> {
    let params = new HttpParams();
    this.isLoading['isLoadingAlerts'] = true;
    return this.http
      .post(
        baseUrl + '/get_alert_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingAlerts'] = false)),
        catchError(
          this.pubMethods.handleError('getAlertDetails', [], this.isLoading)
        )
      );
  }

  getBasicAlertDetails(): Observable<any> {
    let params = new HttpParams();
    this.isLoading['isLoadingBasicAlerts'] = true;
    return this.http
      .post(
        baseUrl + '/get_basic_alert_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingBasicAlerts'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getBasicAlertDetails',
            [],
            this.isLoading
          )
        )
      );
  }

  callDeleteAlert(alertId): Observable<any> {
    let params = new HttpParams().set('alertId', alertId);
    this.isLoading['isDeletingAlert'] = true;
    return this.http
      .post(
        baseUrl + '/delete_alert/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isDeletingAlert'] = false)),
        catchError(
          this.pubMethods.handleError('callDeleteAlert', [], this.isLoading)
        )
      );
  }

  getAlertNotifications(): Observable<any> {
    let params = new HttpParams();
    this.isLoading['isLoadingNotifications'] = true;
    return this.http
      .post(
        baseUrl + '/get_alert_notifications/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingNotifications'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getAlertNotifications',
            [],
            this.isLoading
          )
        )
      );
  }

  markAlertCompaniesAsRead(alertId): Observable<any> {
    let params = new HttpParams().set('alertId', alertId);
    this.isLoading['isLoadingMarkAsRead'] = true;
    return this.http
      .post(
        baseUrl + '/mark_alert_companies_as_read/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingMarkAsRead'] = false)),
        catchError(
          this.pubMethods.handleError('markAlertAsRead', [], this.isLoading)
        )
      );
  }

  bulkMarkAlertCompaniesAsRead(companyIds, searchParams): Observable<any> {
    if (companyIds.length > 0) {
      this.isLoading['isBulkMarkingCompaniesAsRead'] = true;
      let _params = new HttpParams().set(
        'companyIds',
        this.pubMethods.stringifyListForGetParams(companyIds)
      );
      return this.http
        .post(
          baseUrl + '/bulk_mark_companies_as_read_from_ids/',
          _params,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => (this.isLoading['isBulkMarkingCompaniesAsRead'] = false)),
          catchError(
            this.pubMethods.handleError(
              'bulkMarkAlertCompaniesAsRead',
              [],
              this.isLoading
            )
          )
        );
    } else if (searchParams) {
      this.isLoading['isBulkMarkingCompaniesAsRead'] = true;
      return this.http
        .post(
          baseUrl + '/bulk_mark_companies_as_read_from_query/',
          searchParams,
          this.auth.helperGetVerifiedPostDict()
        )
        .pipe(
          tap(_ => (this.isLoading['isBulkMarkingCompaniesAsRead'] = false)),
          catchError(
            this.pubMethods.handleError(
              'bulkMarkAlertCompaniesAsRead',
              [],
              this.isLoading
            )
          )
        );
    }
  }
}
