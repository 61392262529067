<div class="container">
    <div class="row">
        <h2>Salesforce Settings</h2>
        <br>
        <ul class="nav nav-tabs small-margin-top" style="width:100%" role="tablist" id="nav-items-holder">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" data-target="#salesforce-authorisation" role="tab">
                    Authorisation
                </a>
            </li>
            <ng-container *ngIf="hasSalesforceSuperuserAccess">
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" data-target="#salesforce-configuration" role="tab">
                        General Configuration
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" data-target="#synchronisation-settings" role="tab">
                        Synchronisation Settings
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" data-target="#salesforce-importer" role="tab">
                        Importer/Synchroniser
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" data-target="#exporter-config" role="tab">
                        Exporter Configuration
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" data-target="#salesforce-query-builder" role="tab">
                        Exporter Query Builder
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>


<div class="container-fluid" style="padding-left: 3%; padding-right: 3%">
    <div class="row">
        <div class="tab-content" style="width: 100%">
            <div class="tab-pane active" role="tabpanel" id="salesforce-authorisation">
                <div class="row small-margin-top">
                    <div class="col-md-12">
                        <salesforce-oauth></salesforce-oauth>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="hasSalesforceSuperuserAccess">
                <div class="tab-pane" role="tabpanel" id="salesforce-configuration">
                    <div class="row small-margin-top">
                        <div class="col-md-12">
                            <admin-config></admin-config>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" role="tabpanel" id="synchronisation-settings">
                    <div class="row small-margin-top">
                        <div class="col-md-12">
                            <salesforce-sync-details></salesforce-sync-details>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" role="tabpanel" id="salesforce-importer">
                    <div class="row small-margin-top">
                        <div class="col-md-12">
                            <salesforce-importer></salesforce-importer>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" role="tabpanel" id="exporter-config">
                    <div class="row small-margin-top">
                        <div class="col-md-12">
                            <export-config></export-config>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" role="tabpanel" id="salesforce-query-builder">
                    <div class="row small-margin-top">
                        <div class="col-md-12">
                            <crm-query-builder></crm-query-builder>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
