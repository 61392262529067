import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class CompanyMatcherV2Service {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingData: false,
  };

  searchMatchedData(
    endpoint,
    lowerMatchLevel,
    upperMatchLevel,
    pageNumber,
    resultsPerPage,
    isInit
  ): Observable<any> {
    let _params = new HttpParams()
      .set('lowerMatchLevel', lowerMatchLevel)
      .set('upperMatchLevel', upperMatchLevel)
      .set('pageNumber', pageNumber)
      .set('resultsPerPage', resultsPerPage)
      .set('isInit', isInit);
    this.isLoading['loadingData'] = true;
    return this.http
      .post(baseUrl + endpoint, _params, this.auth.helperGetVerifiedPostDict())
      .pipe(
        tap(_ => (this.isLoading['loadingData'] = false)),
        catchError(
          this.pubMethods.handleError('searchMatchedData', [], this.isLoading)
        )
      );
  }
}
