import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { DataSharingService } from '../../services/data-sharing.service';
import { DownloadService } from '../../services/download.service';
declare var $: any;

@Component({
  selector: 'downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css'],
})
export class DownloadsComponent implements OnInit, OnChanges {
  companyIds: Array<any> = [];
  searchParams: HttpParams = null;
  downloadUrl: string;
  downloadRequestId: string;

  userDownloadUsage: number;
  userDownloadLimit: number;
  userRemainingDownloads: number;
  selectedCompanyCount: number = 0;
  // TODO: some of this is already known from companyIds/searchParams subscriptions and could be merged
  @Input() inputParams: any; // {'totalCompaniesSelected': totalCompaniesSelected, 'totalCompanyCount': companyCount.count, 'hasSelectedTotalNumberMatchingFilter': hasSelectedTotalNumberMatchingFilter}

  constructor(
    private downloadService: DownloadService,
    private data: DataSharingService
  ) {}

  isLoading = this.downloadService.isLoading;

  ngOnInit() {
    var downloadsSelf = this;
    $('#downloadModal').on('hide.bs.modal', function (e) {
      downloadsSelf.downloadUrl = null;
      downloadsSelf.downloadRequestId = null;
    });
    this.data.currentSelectedCompanyIds.subscribe(
      companyIds => (this.companyIds = companyIds)
    );
    this.data.currentSelectedSearchParams.subscribe(
      searchParams => (this.searchParams = searchParams)
    );

    this.downloadService.getDownloadUsage().subscribe(data => {
      this.userDownloadUsage = data.downloads_this_period;
      this.userDownloadLimit = data.total_download_limit;
      this.userRemainingDownloads = this.userDownloadLimit - this.userDownloadUsage;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputParams.currentValue.hasSelectedTotalNumberMatchingFilter) {
      this.selectedCompanyCount = changes.inputParams.currentValue.totalCompanyCount
    } else {
      this.selectedCompanyCount = changes.inputParams.currentValue.totalCompaniesSelected
    }
  }

  processDownloadLink(output): void {
    if (output.download_url) {
      this.downloadUrl = output.download_url;
      window.open(this.downloadUrl);
    } else if (output.download_request_id) {
      console.log(output.download_request_id)
      this.downloadRequestId = output.download_request_id;
    }
  }

  generateCSVDownloadUrl(): void {
    this.downloadUrl = null;
    this.downloadRequestId = null;
    this.downloadService
      .downloadCompanies(this.companyIds, this.searchParams)
      .subscribe(output => this.processDownloadLink(output));
  }
}
