import { Component, OnInit, ViewChild } from '@angular/core';
import { publicMethods, baseUrl } from '../../globals';
import { BasicCompanyMatcherService } from '../../services/basic-company-matcher.service';
import { CompanyMatcherComponent } from '../company-matcher/company-matcher.component';

@Component({
  selector: 'upload-companies-v2',
  templateUrl: './upload-companies-v2.component.html',
  styleUrls: ['./upload-companies-v2.component.css'],
})
export class UploadCompaniesV2Component implements OnInit {
  stepNumber = 0;

  additionalHeaders: any;
  customColumns: any;
  allocatedCustomColumnIds: Array<any> = [];
  shouldOverwrite: boolean = false;

  isLoading: any;

  rowsProcessed: any;
  totalNumberOfRows: any;
  userHasAccess = false;
  status: any;

  failedRows: Array<any>;

  // Required for file upload
  self = this;
  // Required for timeout caller
  continuousCaller;

  @ViewChild('childCompanyMatcher')
  companyMatcherComponent: CompanyMatcherComponent;

  constructor(
    private basicCompanyMatcherService: BasicCompanyMatcherService,
    private pubMethods: publicMethods
  ) {}

  ngOnInit(): void {
    document.title = 'Matcher';
    this.isLoading = this.basicCompanyMatcherService.isLoading;
    this.loadPage();
  }

  loadPage(): void {
    this.basicCompanyMatcherService
      .loadStatus()
      .subscribe(data => this.updatePageBasedUponStatus(data));
  }

  updatePageBasedUponStatus(data): void {
    this.totalNumberOfRows = data.total_rows;
    this.userHasAccess = true;
    this.status = data['status'];
    if (this.status === 'defining_settings') {
      this.loadSettings();
    }
    if (this.status === 'matching') {
      this.testCompanyMatcherProgress();
    }
    if (this.status === 'completed_matching') {
      this.initMatcher();
    }
  }

  loadSettings(): void {
    this.basicCompanyMatcherService
      .loadMatcherSettings()
      .subscribe(data => this.updateConfigurationSettings(data));
  }

  updateConfigurationSettings(data): void {
    if (data.mappings !== null) {
      this.stepNumber = 2;
    } else {
      this.stepNumber = 0;
    }
  }

  awaitResultsFromMatchingProcess(data): void {
    this.rowsProcessed = data.rows_processed;
    this.totalNumberOfRows = data.total_rows;
    this.status = data.status;
    if (this.status === 'matching') {
      this.continuousCaller = setTimeout(() => {
        this.testCompanyMatcherProgress();
      }, 10000);
    }
    if (this.status === 'completed_matching') {
      this.initMatcher();
    }
  }

  testCompanyMatcherProgress() {
    this.basicCompanyMatcherService
      .loadMatcherProgress()
      .subscribe(data => this.awaitResultsFromMatchingProcess(data));
  }

  initMatcher(): void {
    var self = this;
    setTimeout(function () {
      self.companyMatcherComponent.loadDataForUser();
    }, 1);
  }

  // Step 0

  downloadSampleCSVFile(): void {
    window.open(baseUrl + '/download_company_matcher_sample_csv/');
  }

  fileUploadComplete(response, _self) {
    _self.updateConfigAndGoToNextStep(response);
  }

  updateConfigAndGoToNextStep(data): void {
    if (data.custom_columns && data.additional_columns) {
      this.customColumns = data.custom_columns;
      this.additionalHeaders = data.additional_columns;
      this.stepNumber = 1;
    }
  }

  // Step 0

  // Step 1
  updateAllocatedCustomColumnIds() {
    this.allocatedCustomColumnIds = this.additionalHeaders
      .filter(
        el =>
          el.selectedCustomColumn != undefined &&
          el.selectedCustomColumn != 'ignore_column'
      )
      .map(function (el) {
        return el.selectedCustomColumn.id;
      });
  }

  submitMatcherMappings() {
    this.basicCompanyMatcherService
      .submitMatcherMappings(this.additionalHeaders, this.shouldOverwrite)
      .subscribe(data => this.updatePageBasedUponStatus(data));
  }
  // Step 1

  // Step 2
  startMatchingProcess() {
    this.basicCompanyMatcherService
      .startMatchingProcess()
      .subscribe(data => this.updatePageBasedUponStatus(data));
  }
  // Step 2

  cancelUserMatchingProcess() {
    this.allocatedCustomColumnIds = [];
    this.failedRows = [];
    this.stepNumber = 0;
    this.basicCompanyMatcherService
      .cancelMatchingProcess()
      .subscribe(data => this.updatePageBasedUponStatus(data));
  }

  showNumberUpdated(data): void {
    if (data['failed_rows']) {
      this.failedRows = data.failed_rows;
    }
    if (data['created']) {
      this.pubMethods.showInfoMessage(
        'Processed ' + data['created'] + ' rows.'
      );
    }
  }

  createMatcherRecordsForSelectedOnPage(rowIdsToSelectedCompanyIds): void {
    this.basicCompanyMatcherService
      .createMatcherRecordsForSelectedOnPage(rowIdsToSelectedCompanyIds)
      .subscribe(data => this.showNumberUpdated(data));
  }

  createMatcherRecordsForAll(params): void {
    let lowerMatchLevel = params[0];
    let upperMatchLevel = params[1];
    this.basicCompanyMatcherService
      .createMatcherRecordsForAll(lowerMatchLevel, upperMatchLevel)
      .subscribe(data => this.showNumberUpdated(data));
  }
}
