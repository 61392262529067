import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class LearningPortalService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingCourseSearch: false,
  };

  learningPortalDetails(): Observable<any> {
    let params = new HttpParams(); //.set("savedSearchId", savedSearchId);
    return this.http
      .post(
        baseUrl + '/get_learning_portal_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('learningPortalDetails', []))
      );
  }

  getActiveVideoUrlForCourse(courseNumber): Observable<any> {
    let params = new HttpParams().set('courseNumber', courseNumber);
    return this.http
      .post(
        baseUrl + '/get_active_video_url_for_course/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('getActiveVideoUrlForCourse', [])
        )
      );
  }

  getActiveVideoUrlForLesson(courseNumber, lessonNumber): Observable<any> {
    let params = new HttpParams()
      .set('courseNumber', courseNumber)
      .set('lessonNumber', lessonNumber);
    return this.http
      .post(
        baseUrl + '/get_active_video_url_for_lesson/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(
          this.pubMethods.handleError('getActiveVideoUrlForLesson', [])
        )
      );
  }

  updateLessonProgress(
    courseNumber,
    lessonNumber,
    percentage
  ): Observable<any> {
    let params = new HttpParams()
      .set('courseNumber', courseNumber)
      .set('lessonNumber', lessonNumber)
      .set(
        'percentage',
        this.pubMethods.stringifyNumberForGetParam(percentage)
      );
    return this.http
      .post(
        baseUrl + '/update_lesson_progress/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('updateProgressOfLesson', []))
      );
  }

  searchCoursesAndLessons(searchTerm): Observable<any> {
    let params = new HttpParams().set('searchTerm', searchTerm);
    this.isLoading['loadingCourseSearch'] = true;
    return this.http
      .post(
        baseUrl + '/search_courses_and_lessons/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCourseSearch'] = false)),
        catchError(
          this.pubMethods.handleError(
            'learningPortalDetails',
            [],
            this.isLoading
          )
        )
      );
  }
}
