<main class="page-container">
    <section class="marketing">
            <iframe class="iframe-embed"
     title="marketing" frameBorder="0" src="https://landing-embed.zint.io/"></iframe>
        
    </section>

    <section class="container left-side">

        <p *ngIf="alreadyLoggedIn">You are already logged in!</p>

        <div class="row mx-0 justify-content-xl-center" *ngIf="showRegister">
            <div class="col col-xl-8 card register-login-padding">
                <h1>Register</h1>
                <form (ngSubmit)="onRegister()" novalidate>
                    <div class="form-group small-margin-top">
                        <label for="register-email">Email</label>
                        <input type="text" class="form-control" id="register-email" placeholder="enter email" [(ngModel)]="user.email" name="email" required>
                    </div>
                    <div class="form-group" id="register-pw-group">
                        <label for="register-password">Password</label>
                        <input type="password" class="form-control" id="register-password" placeholder="enter password" [(ngModel)]="user.password" name="password" required>
                    </div>
                    <br>
                    <input type="checkbox" name="hasAcceptedPrivacyPolicy" value="true" [(ngModel)]="hasAcceptedPrivacyPolicy"/> I agree to the <a href="https://www.zint.io/privacy-policy/" target="_blank">Privacy Policy</a><br>
                    <div class="xsmall-margin-top">
                    <button type="submit" class="basic-button-base button-dark full-width" [attr.disabled]="!hasAcceptedPrivacyPolicy ? 'disabled': null">Register</button>
                    <br>
                    <br>
                    <hr>
                    <a href="mailto:customersuccess@zint.io?Subject=Join%20Existing%20Organisation" target="_blank" class="zint-href login-register-hyperlink">Looking to join an existing organisation?</a><br>
                    <a href="mailto:sales@zint.io?Subject=Platform%20Demo" target="_blank" class="zint-href login-register-hyperlink">Platform Demo?</a><br>
                    <span class="zint-href login-register-hyperlink" (click)="this.showLogin = true; this.showRegister = false">Looking to Login?</span>&nbsp;
                    </div>
                </form>
            </div>
        </div>

        <hr class="medium-margin-top" *ngIf="showLogin && showRegister">

        <div class="row mx-0 justify-content-xl-center" *ngIf="showLogin">
            <div class="col col-xl-8 card register-login-padding">
                <h1>Login</h1>
                <form (ngSubmit)="onLogin()" novalidate>
                    <div class="form-group small-margin-top">
                        <label for="login-email">Email</label>
                        <input type="text" class="form-control" id="login-email" placeholder="enter email" [(ngModel)]="user.email" name="email" required>
                    </div>
                    <div class="form-group">
                        <label for="login-password">Password</label>
                        <input type="password" class="form-control" id="login-password" placeholder="enter password" [(ngModel)]="user.password" name="password" required>
                    </div>
                    <button type="submit" class="basic-button-base button-dark full-width">Login</button>&nbsp;
                    <br>
                    <br>
                    <hr>
                    <a href="mailto:customersuccess@zint.io?Subject=Join%20Existing%20Organisation" target="_blank" class="zint-href login-register-hyperlink">Looking to join an existing organisation?</a><br>
                    <a href="https://www.zint.io/privacy-policy/" target="_blank" class="zint-href login-register-hyperlink">Privacy Policy</a><br>
                    <span class="zint-href login-register-hyperlink" (click)="this.showLogin = false; this.showPasswordResetEmail = true">Forgotten Password?</span><br>
                </form>
            </div>
            
        </div>


        <div class="row justify-content-md-center" *ngIf="showPasswordResetEmail">
            <div class="col col-xl-8 card register-login-padding">
                <h1>Reset password</h1>
                <form (ngSubmit)="onPasswordReset()" novalidate>
                    <div class="form-group">
                        <label for="login-email">Email</label>
                        <input type="text" class="form-control" id="password-reset-email" placeholder="enter email" [(ngModel)]="user.email" name="email" required>
                    </div>
                    <button type="submit" class="basic-button-base button-dark">Submit</button>&nbsp;
                </form>
            </div>
        </div>

        <div class="row justify-content-md-center" *ngIf="isLoadingTokenVerification">
            <i class="fal fa-spinner fa-spin"></i>
        </div>

        <div class="row justify-content-md-center" *ngIf="showPasswordResetPassword">
            <div class="col col-xl-8 card register-login-padding">
                <h1>Set a new password</h1>
                <form (ngSubmit)="onPasswordResetChoosePassword()" novalidate>
                    <div class="form-group">
                        <label for="login-email">New Password</label>
                        <input type="password" class="form-control" id="password-reset-password" placeholder="enter password" [(ngModel)]="user.password" name="password" required>
                    </div>
                    <button type="submit" class="basic-button-base button-dark">Submit</button>&nbsp;
                </form>
            </div>
        </div>



        <div class="row justify-content-md-center" *ngIf="showRequestNewActivationToken">
            <div class="col col-xl-8 card register-login-padding">
                <h1>Request a new activation token</h1>
                <form (ngSubmit)="requestNewActivationToken()" novalidate>
                    <p>Please <a href="/login?redirectUrl=%2Frequest_new_activation_token">login</a> to perform this action.</p>
                    <p>This will email a new link to activate your account for your currently signed-in account.</p>
                    <button type="submit" class="basic-button-base button-dark">Submit</button>&nbsp;
                </form>
            </div>
        </div>



    </section>

</main>
