import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ZintPropensityScoreService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingPropensityScore: false,
    loadingSavePropensityScoreComponent: false,
    loadingIsSavingTAM: false,
    loadingIsModifyingModel: false,
  };

  getUserPropensityScoreDetails(withScoreComponents): Observable<any> {
    let _params = new HttpParams().set(
      'withScoreComponents',
      withScoreComponents
    );
    this.isLoading['loadingPropensityScore'] = true;
    return this.http
      .post(
        baseUrl + '/get_propensity_score_details/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingPropensityScore'] = false)),
        catchError(
          this.pubMethods.handleError('createUserTag', [], this.isLoading)
        )
      );
  }

  savePropensityScoreComponent(
    selectedPropensityModelId,
    propensityScoreComponentDescription,
    propensityScoreWeighting,
    jsonParams
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', selectedPropensityModelId)
      .set(
        'propensityScoreComponentDescription',
        propensityScoreComponentDescription
      )
      .set('propensityScoreWeighting', propensityScoreWeighting)
      .set('jsonParams', jsonParams);
    this.isLoading['loadingSavePropensityScoreComponent'] = true;
    return this.http
      .post(
        baseUrl + '/save_propensity_score_component/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['loadingSavePropensityScoreComponent'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'savePropensityScoreComponent',
            [],
            this.isLoading
          )
        )
      );
  }

  savePropensityScoreTAM(TAMName, jsonParams): Observable<any> {
    let _params = new HttpParams()
      .set('TAMName', TAMName)
      .set('jsonParams', jsonParams);
    this.isLoading['loadingIsSavingTAM'] = true;
    return this.http
      .post(
        baseUrl + '/save_propensity_score_tam/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsSavingTAM'] = false)),
        catchError(
          this.pubMethods.handleError(
            'savePropensityScoreTAM',
            [],
            this.isLoading
          )
        )
      );
  }

  duplicatePropensityModel(propensityModelToDeleteID): Observable<any> {
    let _params = new HttpParams().set(
      'propensityModelID',
      propensityModelToDeleteID
    );
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/duplicate_propensity_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'duplicatePropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  deletePropensityModel(propensityModelToDeleteID): Observable<any> {
    let _params = new HttpParams().set(
      'propensityModelID',
      propensityModelToDeleteID
    );
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/delete_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deletePropensityScoreModel',
            [],
            this.isLoading
          )
        )
      );
  }

  deletePropensityScoreModel(propensityModelToDeleteID): Observable<any> {
    let _params = new HttpParams().set(
      'propensityModelID',
      propensityModelToDeleteID
    );
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/delete_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deletePropensityScoreModel',
            [],
            this.isLoading
          )
        )
      );
  }

  deletePropensityScoreComponent(
    propensityScoreComponentToDeleteID,
    propensityModelToDeleteID
  ): Observable<any> {
    let _params = new HttpParams()
      .set(
        'propensityScoreComponentToDeleteID',
        propensityScoreComponentToDeleteID
      )
      .set('propensityModelID', propensityModelToDeleteID);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/delete_propensity_score_component/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deleteOrganisationPropensityScoreComponent',
            [],
            this.isLoading
          )
        )
      );
  }

  addViewingAccessForUserOnPropensityModel(
    propensityModelId,
    viewableUserId
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('viewableUserId', viewableUserId);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/add_viewable_user_to_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addViewingAccessForUserOnPropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  addViewingAccessForTeamOnPropensityModel(
    propensityModelId,
    viewableTeamId
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('viewableOrgId', viewableTeamId);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/add_viewable_team_to_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addViewingAccessForTeamOnPropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  addEditingAccessForUserOnPropensityModel(
    propensityModelId,
    editableUserId
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('editableUserId', editableUserId);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/add_editable_user_to_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addEditingAccessForUserOnPropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  addEditingAccessForTeamOnPropensityModel(
    propensityModelId,
    editableTeamId
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('editableOrgId', editableTeamId);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/add_editable_team_to_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addEditingAccessForTeamOnPropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteViewingAccessForUserOnPropensityModel(
    propensityModelToDeleteID,
    viewableUserToDeleteID
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelToDeleteID)
      .set('viewableUserToDeleteID', viewableUserToDeleteID);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/delete_viewable_user_from_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deleteViewingAccessForUserOnPropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteViewingAccessForTeamOnPropensityModel(
    propensityModelToDeleteID,
    viewableTeamToDeleteID
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelToDeleteID)
      .set('viewableOrgId', viewableTeamToDeleteID);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/delete_viewable_team_from_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deleteViewingAccessForTeamOnPropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteEditingAccessForUserOnPropensityModel(
    propensityModelToDeleteID,
    editableUserToDeleteID
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelToDeleteID)
      .set('editableUserToDeleteID', editableUserToDeleteID);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/delete_editable_user_from_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deleteEditingAccessForUserOnPropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteEditingAccessForTeamOnPropensityModel(
    propensityModelToDeleteID,
    editableTeamToDeleteID
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelToDeleteID)
      .set('editableOrgId', editableTeamToDeleteID);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/delete_editable_team_from_propensity_score_model/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deleteEditingAccessForTeamOnPropensityModel',
            [],
            this.isLoading
          )
        )
      );
  }

  updatePropensityScoreComponentWeighting(
    propensityModelId,
    propensityScoreComponentId,
    weighting
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('propensityScoreComponentId', propensityScoreComponentId)
      .set('propensityScoreWeighting', weighting);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/update_propensity_score_component_weighting/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updateOrganisationPropensityScoreComponentWeighting',
            [],
            this.isLoading
          )
        )
      );
  }

  updatePropensityScoreComponentAnnotation(
    propensityModelId,
    propensityScoreComponentId,
    annotation
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('propensityScoreComponentId', propensityScoreComponentId)
      .set('annotation', annotation);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/update_propensity_score_component_annotation/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updatePropensityScoreComponentAnnotation',
            [],
            this.isLoading
          )
        )
      );
  }

  updatePropensityScoreComponentName(
    propensityModelId,
    propensityScoreComponentId,
    name
  ): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('propensityScoreComponentId', propensityScoreComponentId)
      .set('name', name);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/update_propensity_score_component_name/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updatePropensityScoreComponentName',
            [],
            this.isLoading
          )
        )
      );
  }

  updatePropensityModelName(propensityModelId, name): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('name', name);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/update_propensity_model_name/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updatePropensityModelName',
            [],
            this.isLoading
          )
        )
      );
  }

  overwritePropensityScoreTAM(propensityModelId, jsonParams): Observable<any> {
    let _params = new HttpParams()
      .set('propensityModelID', propensityModelId)
      .set('jsonParams', jsonParams);
    this.isLoading['loadingIsModifyingModel'] = true;
    return this.http
      .post(
        baseUrl + '/overwrite_propensity_model_tam/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingIsModifyingModel'] = false)),
        catchError(
          this.pubMethods.handleError(
            'overwritePropensityScoreTAM',
            [],
            this.isLoading
          )
        )
      );
  }
}
