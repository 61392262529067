import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'percentage-badge',
  templateUrl: './percentage-badge.component.html',
  styleUrls: ['./percentage-badge.component.css'],
})
export class PercentageBadgeComponent implements OnInit {
  constructor() {}

  @Input()
  inputParams: any = {
    percentage: Array,
  };

  ngOnInit() {}
}
