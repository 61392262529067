import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class CRMQueryBuilderService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    isLoadingCRMQueries: false,
    isLoadingSalesforceObjects: false,
    isSyncingSalesforceObjects: false,
    isLoadingQueryBuilderConstants: false,
  };

  getCRMQueries(): Observable<any> {
    this.isLoading['isLoadingCRMQueries'] = true;
    return this.http
      .post(
        baseUrl + '/list_crm_queries/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingCRMQueries'] = false)),
        catchError(
          this.pubMethods.handleError('getCRMQueries', [], this.isLoading)
        )
      );
  }

  createCRMQuery(queryBuilderExpressions): Observable<any> {
    let params = new HttpParams().set(
      'query',
      JSON.stringify(queryBuilderExpressions)
    );
    return this.http
      .post(
        baseUrl + '/create_crm_query/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('createCRMQuery', []))
      );
  }

  deleteCRMQuery(id): Observable<any> {
    let params = new HttpParams().set('queryId', id);
    return this.http
      .post(
        baseUrl + '/delete_crm_query/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('deleteCRMQuery', []))
      );
  }

  getSalesforceObjects(): Observable<any> {
    this.isLoading['isLoadingSalesforceObjects'] = true;
    return this.http
      .post(
        baseUrl + '/list_salesforce_objects/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSalesforceObjects'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getSalesforceObjects',
            [],
            this.isLoading
          )
        )
      );
  }

  syncSalesforceObjects(): Observable<any> {
    this.isLoading['isSyncingSalesforceObjects'] = true;
    return this.http
      .post(
        baseUrl + '/sync_salesforce_objects/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isSyncingSalesforceObjects'] = false)),
        catchError(
          this.pubMethods.handleError(
            'syncSalesforceObjects',
            [],
            this.isLoading
          )
        )
      );
  }

  getQueryBuilderConstants(): Observable<any> {
    this.isLoading['isLoadingQueryBuilderConstants'] = true;
    return this.http
      .post(
        baseUrl + '/query_builder_constants/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingQueryBuilderConstants'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getQueryBuilderConstants',
            [],
            this.isLoading
          )
        )
      );
  }

  renderFriendlyString(node) {
    // Only leaf nodes have a value
    if (node.value != undefined) {
      if (node.type === 'SalesforceFieldOperand') {
        return node.sobject.label + ':' + node.value;
      } else if (node.type === 'ZintFieldOperand') {
        return 'Zint:' + node.value;
      } else {
        return node.value;
      }
    }
    return (
      this.renderFriendlyString(node.lhs) +
      ' ' +
      node.op +
      ' ' +
      this.renderFriendlyString(node.rhs)
    );
  }
}
