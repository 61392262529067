import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { DashboardService } from './dashboard.service';
import { CommunicationService } from './communication.service';

@Injectable()
export class CustomColumnService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService,
    private dashboardService: DashboardService,
    private communicationService: CommunicationService
  ) {}

  isLoading = {
    loadingCustomColumns: false,
    loadingSettingCustomColumnsFromQuery: false,
  };

  getCustomColumns(): Observable<any> {
    this.isLoading['loadingCustomColumns'] = true;
    let _params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/get_custom_columns/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCustomColumns'] = false)),
        catchError(
          this.pubMethods.handleError('getCustomColumns', [], this.isLoading)
        )
      );
  }

  setCustomColumnFreeFromQuery(
    customColumnId,
    value,
    searchParams
  ): Observable<any> {
    this.isLoading['loadingSettingCustomColumnsFromQuery'] = true;
    let _params = searchParams
      .set('columnId', customColumnId)
      .set('value', value);
    return this.http
      .post(
        baseUrl + '/save_custom_column_free_from_query/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['loadingSettingCustomColumnsFromQuery'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'setCustomColumnFreeFromQuery',
            [],
            this.isLoading
          )
        )
      );
  }

  setCustomColumnOptionFromQuery(
    customColumnId,
    optionSelectedId,
    searchParams
  ): Observable<any> {
    this.isLoading['loadingSettingCustomColumnsFromQuery'] = true;
    let _params = searchParams
      .set('columnId', customColumnId)
      .set('optionSelectedId', optionSelectedId);
    return this.http
      .post(
        baseUrl + '/save_custom_column_option_from_query/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['loadingSettingCustomColumnsFromQuery'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'setCustomColumnFreeFromQuery',
            [],
            this.isLoading
          )
        )
      );
  }
}
