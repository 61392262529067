<div class="modal fade" id="exportToSalesforceModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exportToSalesforceModalLabel"><i class="fab fa-salesforce salesforce-icon"></i> Export to Salesforce</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row"  *ngIf="isLoading.gettingSalesforceExportValues">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center">
                            <i class="fal fa-spin fa-spinner" style="font-size: 50px"></i>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="createFieldsToValues && createFieldsToValues.length != 0">
                    <table class="table table-striped table-bordered table-sm xsmall-margin-top">
                        <tbody>
                            <tr *ngFor="let createFieldToValue of createFieldsToValues">
                                <td style="vertical-align: middle">
                                    {{createFieldToValue.label}}
                                </td>
                                <td style="vertical-align: middle">
                                    <input type="text" class="form-control" [(ngModel)]="createFieldToValue.value" (change)="createFieldToValue.changed = true">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="basic-button-base button-dark float-right" data-toggle="modal" (click)="createNewSalesforceRecord()" [attr.disabled]="(isLoading.updatingRecord || isLoading.creatingRecord) ? 'disabled': null">
                            <i class="fab fa-salesforce salesforce-icon"></i>
                            Create New Record <i *ngIf="isLoading.creatingRecord" class="fal fa-spinner fa-spin"></i>
                    </button>
                </ng-container>

                <ng-container *ngIf="updateFieldsToValues && updateFieldsToValues.length != 0">
                    <table class="table table-striped table-bordered table-sm xsmall-margin-top">
                        <tbody>
                            <tr *ngFor="let updateFieldToValue of updateFieldsToValues">
                                <td style="vertical-align: middle">
                                    {{updateFieldToValue.label}}
                                </td>
                                <td style="vertical-align: middle">
                                    <input type="text" class="form-control" [(ngModel)]="updateFieldToValue.value" (change)="updateFieldToValue.changed = true">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="basic-button-base button-dark float-right" (click)="updateSalesforceRecord(sobjectIdForUpdate, salesforceIdForUpdate, updateFieldsToValues)" [attr.disabled]="(isLoading.updatingRecord || isLoading.creatingRecord) ? 'disabled': null">
                         <i class="fab fa-salesforce salesforce-icon"></i> Update Record <i *ngIf="isLoading.updatingRecord" class="fal fa-spinner fa-spin"></i>
                    </button>
                </ng-container>

                <ng-container *ngIf="(!updateFieldsToValues || updateFieldsToValues.length == 0) && (!createFieldsToValues || createFieldsToValues.length == 0) && !isLoading.gettingSalesforceExportValues">
                    <div class="row"  *ngIf="isLoading.loadingQueryResults || isLoading.loadingExportableObjects">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-center">
                                <i class="fal fa-spin fa-spinner" style="font-size: 50px"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!isLoading.loadingQueryResults && !isLoading.loadingExportableObjects">
                        <div class="col-md-12" *ngIf="urlOfSalesforceObjectUpdatedOrCreated">
                            <a class="basic-button-base button-dark w-100" [attr.href]="urlOfSalesforceObjectUpdatedOrCreated" target="_blank">
                                <i class="fab fa-salesforce salesforce-icon"></i>
                                View Salesforce Record
                                <i class="fal fa-external-link"></i>
                            </a>
                        </div>
                        <div class="col-md-12" *ngIf="!urlOfSalesforceObjectUpdatedOrCreated">
                            <div class="btn-group float-right" role="group">
                                <button class="basic-button-base button-dark" data-toggle="modal" data-target="#validateCreateSalesforceModal" (click)="getValuesToExport('createFieldsToValues')" [attr.disabled]="(isLoading.updatingRecord || isLoading.creatingRecord) ? 'disabled': null">
                                    <i class="fab fa-salesforce salesforce-icon"></i> Create New Record With Edits
                                </button>
                                <button class="basic-button-base button-dark" data-toggle="modal" (click)="createNewSalesforceRecord()" [attr.disabled]="(isLoading.updatingRecord || isLoading.creatingRecord) ? 'disabled': null">
                                    <i class="fab fa-salesforce salesforce-icon"></i>
                                    Create New Record With Defaults <i *ngIf="isLoading.creatingRecord" class="fal fa-spinner fa-spin"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="medium-margin-top" *ngFor="let queryResult of queryResults">
                                <h5>
                                    <ng-container *ngIf="queryResult.results && queryResult.results.length == 0">No</ng-container>
                                    Matching {{queryResult.sobject.label}} records</h5>
                                <table class="table table-striped table-bordered table-sm xsmall-margin-top" *ngIf="queryResult.results && queryResult.results.length != 0">
                                    <thead>
                                        <tr>
                                            <td style="width: 100px">Link</td>
                                            <td *ngFor="let field of queryResult.fields">{{field.label}}</td>
                                            <td style="width: 203px">Update Record</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let salesforceObject of queryResult.results">
                                            <td><a href="{{salesforceObject['url_of_object']}}" target="_blank">{{salesforceObject['Id'].substring(0,8)}} <i class="fal fa-external-link"></i></a></td>
                                            <td *ngFor="let field of queryResult.fields">
                                                {{salesforceObject[field.name]}}
                                            </td>
                                            <td>
                                                <div class="btn-group float-right" role="group">
                                                     <button class="basic-button-base button-dark" (click)="getValuesToExport('updateFieldsToValues'); sobjectIdForUpdate = queryResult.sobject.id; salesforceIdForUpdate = salesforceObject.Id">
                                                         With Edits
                                                     </button>
                                                     <button class="basic-button-base button-dark" (click)="updateSalesforceRecord(queryResult.sobject.id, salesforceObject.Id, updateSalesforceRecord)" [attr.disabled]="(isLoading.updatingRecord || isLoading.creatingRecord) ? 'disabled': null">
                                                         With Defaults
                                                     </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="xsmall-margin-top xsmall-margin-bottom">
                                    Query:
                                    <div (click)="queryResult.showQuery = true" style="cursor: pointer; display: inline" *ngIf="!queryResult.showQuery">
                                        &nbsp;<i class="fal fa-eye"></i>&nbsp;
                                    </div>
                                    <i *ngIf="queryResult.showQuery">{{queryResult.query}}</i>
                                </div>
                                <span *ngIf="queryResult.error"><i class="fal fa-exclamation-triangle"></i> Error running query: {{queryResult.error}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>


