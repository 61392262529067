<div class="d-flex">
                      <button [disabled]="!isActionableByUser" class="btn border-0 no-focus-outline" [matTooltip]="isActionableByUser ? 'mark as read' : 'actionable by the tracking user'" matTooltipPosition="above"
                        (click)="toggleRead(notificationGroup.id)">
                        <i [ngClass]="isMarkedCompleted ? 'fas' : 'fad'" class="fa-check fa-lg mr-2"
                          [style.--fa-primary-opacity]="isMarkedCompleted ? 1 : 0.4"
                          [style.color]="isMarkedCompleted ? 'var(--zint-purple-500)' : null">
                        </i>                                       
                      </button>
                      
                      <button [disabled]="!isActionableByUser" class="btn border-0 no-focus-outline" [matTooltip]="isActionableByUser ? 'mark as useful': 'actionable by the tracking user'"
                        matTooltipPosition="above" (click)="toggleUseful(notificationGroup.id)"><i
                          class="fas fa-thumbs-up fa-lg mr-2"
                          [style.color]="isLiked ? 'var(--zint-purple-500)': 'var(--zint-grey-400)'"></i></button>

                      <button [disabled]="!isActionableByUser" class="btn border-0 no-focus-outline" [matTooltip]="isActionableByUser ? 'report issues/inaccuracies' : 'actionable by the tracking user'"
                        matTooltipPosition="above" (click)="reportIssues()"><i
                          [style.color]="isProblematic ? 'var(--zint-red-300)': 'var(--zint-grey-400)'"                          
                          class="fas fa-exclamation-triangle fa-lg mr-2"></i></button>

                      <button class="btn border-0 no-focus-outline" matTooltip="share this notification"
                        matTooltipPosition="above" (click)="share()"><i
                          [style.color]="isSharing ? 'var(--zint-purple-500)': 'var(--zint-grey-400)'"
                          class="fas fa-share-alt fa-lg"></i></button>
                    </div>
                    <div *ngIf="isReporting" class="position-relative">
                      <input type="text" maxlength="180" class="pr-4" placeholder="enter your comment"
                        (keyup.enter)="submitReport(notificationGroup.id, $event.target.value)" />
                      <span class="input-report-suffix">&#9166;</span>
                    </div>
