import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../classes/user';
import { CookieService } from 'ngx-cookie';
import { ZendeskSsoService } from '../../services/zendesk-sso.service';
import { PushMessagesService } from '../../services/push-messages.service';
import { baseUrl } from '../../globals';

@Component({
  selector: 'authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
})
export class AuthenticationComponent implements OnInit {
  constructor(
    private auth: AuthenticationService,
    private cookieservice: CookieService,
    private zendeskService: ZendeskSsoService,
    private pushMessagesService: PushMessagesService
  ) {}

  user: User = new User();

  ngOnInit(): void {
    this.getCurrentAuthentication();
  }

  getCurrentAuthentication(): void {
    var currentCsrf = this.cookieservice.get('csrftoken');
    if (!currentCsrf) {
      this.auth.getCSRFFromServer().subscribe();
    } else {
      this.auth.isUserAuthenticated().subscribe(data => {
        this.auth.handleAuthenticationResponse(data, false, null);

        this.zendeskService.triggerZendeskMessagingJWTIfLoggedIn(data);
        if (data.success && window && window.location.pathname !== '/logout') {
          // On logout we are in an intermediate state so don't bother getting messages in that case.
          this.pushMessagesService.getUserMessages();
        }
      });
    }
  }
}
