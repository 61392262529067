import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'news-search',
  templateUrl: './news-search.component.html',
  styleUrls: ['./news-search.component.css'],
})
export class NewsSearchComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor(private datePipe: DatePipe) {}

  event_metadata: any;
  companyNumber: any;
  mainHtml: any;
  htmlBelowImage: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.notifications[0].company_number;
    this.mainHtml = `
    <h3>
        <img class="favicon-offset"
             src="https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=24&url=${this.event_metadata.article_link}">
        ${this.event_metadata.article_title}
    </h3>`;
    this.htmlBelowImage =
      `<i class="fal fa-newspaper"></i> Published: ` +
      this.datePipe.transform(this.event_metadata.published_date, 'd MMM y');
  }
}
