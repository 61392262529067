import { Component, Input, OnInit } from '@angular/core';
import { EventSourceTypes } from '../../../globals';
import { CompanyDataPipe } from '../../../pipes/company-data.pipe';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ZintGrowService } from '../../../services/zint-grow.service';

declare var $: any;

@Component({
  selector: 'event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
  providers: [CompanyDataPipe],
})
export class EventDetailsComponent implements OnInit {
  @Input() eachNotification: Record<string, any> = {};
  @Input() groupImportance: number;

  eventDetailsData: any;
  extraDetails: any = null;
  zintLink: string = null;
  zintLinkTooltip: string = null;
  detailsTooltip: string = null;

  randomId = (Math.random() + 1).toString(36).substring(2);
  accountsSummaryId: string | number = null;
  accountsSummary: Record<string, any> = null;
  summaryYearAccountId: string | number;
  companyNumber: string | number;

  constructor(
    private companyDataPipe: CompanyDataPipe,
    private datePipe: DatePipe,
    private titleCase: TitleCasePipe,
    private zintGrowService: ZintGrowService
  ) {}

  isLoading = this.zintGrowService.isLoading;

  ngOnInit(): void {
    this.handleMetadata();
  }

  handleMetadata(): void {
    const { eventMetadata, eventSourceType, companyNumber } =
      this.eachNotification;
    this.companyNumber = companyNumber;

    switch (eventSourceType) {
      case EventSourceTypes.NewsSearchEventSourceType:
        this.handleNewsData(eventMetadata);
        break;

      case EventSourceTypes.OfficerEventSourceType:
        this.handleOfficerData(eventMetadata);
        break;

      case EventSourceTypes.PSCEventSourceType:
        this.eventDetailsData = `<h6>PSC ${eventMetadata.changeType}</h6> - ${eventMetadata.name}`;
        break;

      case EventSourceTypes.AccountsSearchEventSourceType:
        this.handleAccountsSearchData(eventMetadata);
        break;

      case EventSourceTypes.AccountsFilingEventSourceType:
        this.handleAccountsFilingData(eventMetadata);
        break;

      case EventSourceTypes.WebsiteSearchEventSourceType:
        this.handleWebsiteSearchData(eventMetadata);
        break;

      case EventSourceTypes.ImportExportEventSourceType:
        this.handleImportExportData(eventMetadata);
        break;

      case EventSourceTypes.HiringEventSourceType:
        this.handleHiringData(eventMetadata);
        break;

      case EventSourceTypes.RelatedOfficerEventSourceType:
        this.handleRelatedOfficerData(eventMetadata);
        break;

      case EventSourceTypes.CorporateStructureEventSourceType:
        this.handleCorporateStructureData(eventMetadata);
        break;

      case EventSourceTypes.RelatedPSCEventSourceType:
        this.handleRelatedPSCData(eventMetadata);
        break;

      default:
        break;
    }
  }

  handleAccountsSearchData(event_metadata: Record<string, any>): void {
    this.detailsTooltip = 'View accounts';

    const searchTerm = encodeURIComponent(event_metadata.searchTerm);

    const zintLink = `/profile/${this.eachNotification.companyNumber}?accountsSearch=${searchTerm}&accountsDate=${event_metadata.madeUpToString}`;

    const pageNumberURLHash = event_metadata.matchingPages[0] + 1;
    const companyHouseLink = `${event_metadata.accountsUrl}#page=${pageNumberURLHash}`;

    this.eventDetailsData = `<a class="text-reset" target="_blank" href="${companyHouseLink}">${event_metadata.preview}<i class="fal fa-external-link "></i></a>`;

    this.extraDetails = `<a href=${zintLink} target="_blank"><span class="extra-details">found on pages: ${event_metadata.matchingPages}</span></a>`;
  }

  handleAccountsFilingData(event_metadata: Record<string, any>): void {
    if (event_metadata.hasOwnProperty('accountsSummaryId')) {
      this.detailsTooltip = 'View AI summaries';
      this.zintLinkTooltip = 'View accounts';
      this.accountsSummaryId = event_metadata.accountsSummaryId;
    }

    const formattedDate = this.datePipe.transform(
      event_metadata.madeUpToString,
      'd MMM y'
    );

    const madeUpToString = formattedDate
      ? `Made up to ${formattedDate}`
      : `Accounts Filed`;

    const formattedRevenue = this.companyDataPipe.transform(
      event_metadata.revenue,
      'Revenue'
    );

    const preview = event_metadata.hasOwnProperty('accountsSummaryId')
      ? `<span role="button"><i class="far fa-robot fa-border"></i>&nbsp;${
          event_metadata.summaryPreview || 'AI Insights'
        }</span>`
      : '';

    const revenueString = formattedRevenue
      ? ` - Revenue: ${formattedRevenue}`
      : '';

    this.eventDetailsData = `<h6>${madeUpToString}${revenueString}</h6>${preview}`;

    this.zintLink = event_metadata.accountsUrl;
  }

  openModal(): void {
    // If not AccountsFiling event or no summaryId, no modal
    const guardCondition =
      this.eachNotification.eventSourceType !==
        EventSourceTypes.AccountsFilingEventSourceType ||
      !this.accountsSummaryId;

    if (guardCondition) return;

    $('#accountsSummaryModal__' + this.randomId).modal();

    this.zintGrowService
      .getAccountSummaryById(this.accountsSummaryId)
      .subscribe(data => {
        if (Object.keys(data).length) {
          this.accountsSummary = data;
          this.summaryYearAccountId = Object.keys(data)[0];
        }
      });
  }

  handleNewsData(event_metadata: Record<string, any>): void {
    this.detailsTooltip = 'View article';
    this.zintLinkTooltip = 'View on Zint';

    const searchTerm = encodeURIComponent(event_metadata.searchTerm);

    const newsSource =
      event_metadata.newsSource === 'self_published'
        ? 'selfPublishedCompanyNewsSearch'
        : 'companyNewsSearch';

    this.zintLink = `/profile/${this.eachNotification.companyNumber}?${newsSource}=${searchTerm}`;

    const articleLink = `${event_metadata.articleLink}`;

    this.eventDetailsData = `<a class="text-reset" target="_blank" href="${articleLink}"><h6 class="">${event_metadata.articleTitle}</h6><span>${event_metadata.preview}</span><i class="fal fa-external-link"></i></a>`;
  }

  handleWebsiteSearchData(event_metadata: Record<string, any>): void {
    const searchTerm = encodeURIComponent(event_metadata.searchTerm);
    this.zintLinkTooltip = 'View on Zint';
    this.detailsTooltip = 'View Website';

    this.zintLink = `/profile/${this.eachNotification.companyNumber}?fullWebsiteSearch=${searchTerm}`;

    this.eventDetailsData = `<a class="text-reset" target="_blank" href="${event_metadata.urls[0]}"><span>${event_metadata.preview}</span><i class="fal fa-external-link"></i></a>`;
  }

  handleHiringData(event_metadata: Record<string, any>): void {
    this.detailsTooltip = event_metadata.jobDescriptionSearchTerm;

    const formattedDate = this.datePipe.transform(
      event_metadata.publishedDate,
      'd MMM y'
    );
    this.eventDetailsData = `<h6>Hiring for ${event_metadata.jobTitle} - ${formattedDate}</h6><span>${event_metadata.preview}</span>`;
    this.zintLink = event_metadata.link;
    this.zintLinkTooltip = 'View job listing';
  }

  handleCorporateStructureData(event_metadata: Record<string, any>): void {
    const {
      typeOfChange,
      changeType,
      childNodeName,
      parentNodeName,
      childCompanyNumber,
      parentCompanyNumber,
    } = event_metadata;

    const childLink = `/profile/${childCompanyNumber}`;
    const parentLink = `/profile/${parentCompanyNumber}`;

    if (typeOfChange === 'sibling') {
      this.eventDetailsData = `<span><a href=${childLink} target="_blank">${childNodeName}</a> was ${changeType} as a sibling company with a shared parent <a href=${parentLink} target="_blank">${parentNodeName}</a>.</span>`;
    } else if (typeOfChange === 'child') {
      this.eventDetailsData = `<span><a href=${childLink} target="_blank">${childNodeName}</a> was ${changeType} as a child of <a href=${parentLink}>${parentNodeName}</a>.</span>`;
    } else if (typeOfChange === 'parent') {
      this.eventDetailsData = `<span><a href=${parentLink} target="_blank">${parentNodeName}</a> was ${changeType} as a parent of <a href=${childLink} target="_blank">${childNodeName}</a>.</span>`;
    }
  }

  handleOfficerData(event_metadata: Record<string, any>): void {
    const icon = `<i class="fal fa-user-${
      event_metadata.changeType === 'appointed' ? 'plus' : 'minus'
    }"></i>`;
    const changeType = this.titleCase.transform(event_metadata.changeType);

    this.eventDetailsData = `<h6>${icon} ${'Officer'} ${changeType}</h6><span>${event_metadata.occupation} - ${event_metadata.name}</span>`;
  }

  handleRelatedOfficerData(event_metadata: Record<string, any>): void {
    const {
      changeType,
      name,
      occupation,
      companyName,
      occupationContains,
      companyNumber,
    } = event_metadata;

    const companyLink = `/profile/${companyNumber} target="_blank"`;
    this.eventDetailsData = `<h6>Related Officer ${changeType} - ${name}</h6><span>The officer has been ${changeType} as <strong>${occupation}</strong> at <a href=${companyLink}><strong>${companyName}</strong></a>.</span>`;

    this.extraDetails = occupationContains
      ? `${occupationContains} matches occupation ${occupation}.`
      : `Officer ${changeType}.`;
  }

  handleImportExportData(event_metadata: Record<string, any>): void {
    const {
      newCategory,
      impEventsCount,
      expEventsCount,
      newCategoryDescription,
      importOrExport,
    } = event_metadata;

    if (newCategory === true) {
      this.eventDetailsData = `<span>${this.titleCase.transform(
        importOrExport
      )} New Category: ${newCategoryDescription}</span>`;
    }

    if (newCategory !== true) {
      if (impEventsCount) {
        this.eventDetailsData = `<span>Number of Import Event: ${impEventsCount}</span>`;
      }
      if (expEventsCount) {
        this.eventDetailsData = `<span>Number of Export Event: ${expEventsCount}</span>`;
      }
    }
  }

  handleRelatedPSCData(event_metadata: Record<string, any>): void {
    const {
      name,
      changeType,
      occupationContains,
      occupation,
      companyNumber,
      companyName,
    } = event_metadata;

    const companyLink = `/profile/${companyNumber} target="_blank"`;
    const title = `<h6>Related PSC ${changeType || 'appointed'} - ${name}</h6>`;
    const text = `<strong>${name}</strong> has become a PSC at
          <a href=${companyLink}><strong>${companyName}</strong></a>`;

    this.eventDetailsData = `${title}<span>${text}</span>`;
    this.extraDetails = occupationContains
      ? `${occupationContains} matches occupation ${occupation}.`
      : `PSC ${changeType || 'appointed'}.`;
  }
}
