import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.css'],
})
export class ImportExportComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor(private datePipe: DatePipe) {}

  event_metadata: any;
  companyNumber: any;
  mainHtml: any;
  htmlBelowImage: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.notifications[0].company_number;
    this.htmlBelowImage = `Imports and Exports for ${this.event_metadata.month}/${this.event_metadata.year}`;
  }
}
