import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ResultsFormatEditorService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    isLoadingInitialData: false,
    isUpdatingAResultsFormat: false,
  };

  getBasicUserResultsFormat(): Observable<any> {
    this.isLoading['isLoadingInitialData'] = true;
    let params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/get_basic_user_results_format_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getBasicUserResultsFormat',
            [],
            this.isLoading
          )
        )
      );
  }

  getUserResultsFormat(): Observable<any> {
    this.isLoading['isLoadingInitialData'] = true;
    let params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/get_user_results_format_details/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingInitialData'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getUserResultsFormat',
            [],
            this.isLoading
          )
        )
      );
  }

  updateResultsFormatName(orgOrUser, resultsId, name): Observable<any> {
    this.isLoading['isUpdatingAResultsFormat'] = true;
    let params = new HttpParams().set('id', resultsId).set('name', name);
    return this.http
      .post(
        baseUrl + '/update_name_of_' + orgOrUser + '_results_view/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isUpdatingAResultsFormat'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updateResultsFormatName',
            [],
            this.isLoading
          )
        )
      );
  }

  createNewResultsFormat(orgOrUser, name, columns): Observable<any> {
    this.isLoading['isUpdatingAResultsFormat'] = true;
    let params = new HttpParams()
      .set('columns', this.pubMethods.stringifyListForGetParams(columns))
      .set('name', name);
    return this.http
      .post(
        baseUrl + '/add_new_' + orgOrUser + '_results_view/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isUpdatingAResultsFormat'] = false)),
        catchError(
          this.pubMethods.handleError(
            'createNewResultsFormat',
            [],
            this.isLoading
          )
        )
      );
  }

  saveResultsFormat(orgOrUser, id, columns, name): Observable<any> {
    this.isLoading['isUpdatingAResultsFormat'] = true;
    let params = new HttpParams()
      .set('id', id)
      .set('columns', this.pubMethods.stringifyListForGetParams(columns))
      .set('name', name);
    return this.http
      .post(
        baseUrl + '/update_' + orgOrUser + '_results_view/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isUpdatingAResultsFormat'] = false)),
        catchError(
          this.pubMethods.handleError('saveResultsFormat', [], this.isLoading)
        )
      );
  }

  selectDefaultResultsFormat(orgOrUser, id): Observable<any> {
    this.isLoading['isUpdatingAResultsFormat'] = true;
    let params = new HttpParams().set('id', id);
    return this.http
      .post(
        baseUrl + '/select_' + orgOrUser + '_default_results_view/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isUpdatingAResultsFormat'] = false)),
        catchError(
          this.pubMethods.handleError(
            'selectDefaultResultsFormat',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteResultsFormat(orgOrUser, id): Observable<any> {
    this.isLoading['isUpdatingAResultsFormat'] = true;
    let params = new HttpParams().set('id', id);
    return this.http
      .post(
        baseUrl + '/delete_' + orgOrUser + '_results_view/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isUpdatingAResultsFormat'] = false)),
        catchError(
          this.pubMethods.handleError('deleteResultsFormat', [], this.isLoading)
        )
      );
  }
}
