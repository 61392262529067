import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DataSharingService } from '../../services/data-sharing.service';
import { publicMethods } from '../../globals';
import { CommunicationService } from '../../services/communication.service';
declare var $: any;

@Component({
  selector: 'custom-column-bulk-edit',
  templateUrl: './custom-column-bulk-edit.component.html',
  styleUrls: ['./custom-column-bulk-edit.component.css'],
})
export class CustomColumnBulkEditComponent implements OnInit {
  @Input()
  inputParams: any = {
    customColumns: Array,
    teamMembers: Array,
  };

  columnToAssignSelected: any;
  companyIds: Array<any> = [];
  bulkAssignedMessageVisible: boolean = false;

  constructor(
    private communicationService: CommunicationService,
    private data: DataSharingService,
    private pubMethods: publicMethods,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.data.currentSelectedCompanyIds.subscribe(
      companyIds => (this.companyIds = companyIds)
    );
  }

  showBulkAssignedMessageAndUpdateResults(): void {
    this.bulkAssignedMessageVisible = true;
    setTimeout(_ => (this.bulkAssignedMessageVisible = false), 800);
  }
}
