import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ZintGrowService } from '../../../services/zint-grow.service';
import { CompanyDataPipe } from '../../../pipes/company-data.pipe';
import { CorporateStructureComponent } from './corporate-structure/corporate-structure.component';

@Component({
  selector: 'event-source-type-component',
  templateUrl: './event-source-type-component.component.html',
  styleUrls: ['./event-source-type-component.component.css'],
  providers: [CompanyDataPipe],
})
export class EventSourceTypeComponentComponent implements OnInit {
  @Input()
  group: any;

  @Input()
  isSuperuser: boolean;

  @ViewChild(CorporateStructureComponent)
  corporateStructure: CorporateStructureComponent;

  isMarkedCompleted: boolean;
  isUseful: boolean = false;
  isInaccurate: boolean = false;
  isSharing: boolean = false;
  emailAddress: string = '';

  constructor(
    private _snackBar: MatSnackBar,
    private zintGrowService: ZintGrowService,
    private companyDataPipe: CompanyDataPipe
  ) {}

  ngOnInit(): void {
    this.isMarkedCompleted = this.group.is_marked_completed;
    this.isUseful = this.group.is_liked;
  }

  markAsCompleted(notificationId: number) {
    this.isMarkedCompleted = !this.isMarkedCompleted;
    this.zintGrowService
      .toggleNotificationGroupMarkedCompleted(notificationId)
      .subscribe();
  }

  toggleUseful(notificationId: number) {
    this.isUseful = !this.isUseful;
    this.zintGrowService
      .sendNotificationGroupFeedback(notificationId, this.isUseful)
      .subscribe(() => {
        this._snackBar.open('Thank you for your feedback.', 'X', {
          duration: 1500,
        });
      });
  }

  reportIssues() {
    this.isInaccurate = !this.isInaccurate;
  }

  submitReport(notificationId: any, feedback: any) {
    this.zintGrowService
      .sendNotificationGroupFeedback(notificationId, null, feedback)
      .subscribe(() => {
        this._snackBar.open('Thank you for your feedback.', 'Close', {
          duration: 1500,
        });
        this.isInaccurate = false;
      });
  }

  share() {
    const emailSubject = `Sharing Zint Grow Notification for ${this.group.company_name}`;

    const eventType = this.group.event_source_type;

    const metaData = this.group.notifications[0].event_metadata;

    const eventImportance = this.group.notifications[0].importance || '';

    let eventDetails: string;
    let trimmedPreview: string;
    let sourceURL: string;
    // NewsSearchEventSourceType
    if (eventType === 'NewsSearchEventSourceType') {
      // line below replaces html tags from the preview with single quotes
      trimmedPreview = metaData.preview.replace(/<\/?[^>]+(>|$)/g, "'");
      sourceURL = metaData.article_link;
      eventDetails = `${metaData.article_title} %0A ${encodeURIComponent(
        sourceURL
      )} %0A%0A ${trimmedPreview}`;
    } // AccountsFilingEventSourceType
    else if (eventType === 'AccountsFilingEventSourceType') {
      const formattedRevenue = this.companyDataPipe.transform(
        metaData.revenue,
        'Revenue'
      );
      const filingURL = metaData.accounts_url;
      eventDetails = `A New Account Filing with a revenue figure of ${formattedRevenue} %0A ${encodeURIComponent(
        filingURL
      )}`;
    } // WebsiteSearchEventSourceType
    else if (eventType === 'WebsiteSearchEventSourceType') {
      trimmedPreview = metaData.preview.replace(/<\/?[^>]+(>|$)/g, "'");
      sourceURL = metaData.urls[0];

      eventDetails = `${trimmedPreview} %0A ${encodeURIComponent(sourceURL)}`;
    } // AccountsSearchEventSourceType
    else if (eventType === 'AccountsSearchEventSourceType') {
      trimmedPreview = metaData.preview.replace(/<\/?[^>]+(>|$)/g, "'");
      sourceURL = metaData.accounts_url;
      eventDetails = `${trimmedPreview} %0A%0A ${encodeURIComponent(
        sourceURL
      )}`;
    } // HiringEventSourceType
    else if (eventType === 'HiringEventSourceType') {
      sourceURL = metaData.link;
      eventDetails = `The company is hiring for ${
        metaData.job_title
      } %0A ${encodeURIComponent(sourceURL)}%0A`;
    } // OfficerEventSourceType
    else if (eventType === 'OfficerEventSourceType') {
      eventDetails = `${metaData.occupation} ${metaData.change_type} - ${metaData.name}`;
    } // RelatedOfficerEventSourceType
    else if (eventType === 'RelatedOfficerEventSourceType') {
      const changeMessage =
        metaData.change_type === 'appointed'
          ? `been appointed as ${metaData.occupation} at`
          : `resigned as ${metaData.occupation} from`;

      eventDetails = `An officer from ${this.group.company_name} has ${changeMessage} ${metaData.company_name}`;
    } // PSCEventSourceType
    else if (eventType === 'PSCEventSourceType') {
      eventDetails = `A PSC - Person of Significant Control is ${
        metaData.change_type === 'added' ? 'added' : 'removed'
      } for ${metaData.name}.`;
    } // ImportExportEventSourceType
    else if (eventType === 'ImportExportEventSourceType') {
      const imports = `Import Event(s) - ${metaData.imp_events_count || 0}`;
      const exports = `Export Event(s) - ${metaData.exp_events_count || 0}`;

      eventDetails = `Imports/Exports for this company  %0A%0A ${imports} %0A ${exports}`;
    } // CorporateStructureEventSourceType
    else if (eventType === 'CorporateStructureEventSourceType') {
      eventDetails =
        this.corporateStructure.corporateStructureHighlight.nativeElement.innerText.split(
          '\n'
        )[0];
    }

    const emailBody = `Hi, I thought you might be interested in this notification from Zint Grow for ${this.group.company_name}.%0A%0A Details: ${eventDetails}%0A%0A Importance: ${eventImportance}%0A%0A%0A%0A%0A%0A%0ASent from Zint Grow`;

    const mailToString = `mailto:?subject=${emailSubject}&body=${emailBody}`;

    window.location.href = mailToString;
  }
}
