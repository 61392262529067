<div class="modal fade" id="markAlertedCompaniesAsReadModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="markAlertedCompaniesAsReadModalLabel">Mark Organisations Matching Alerts As Read</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p>Any selected organisations in this search that match alerts you have created will be marked as read.</p>
                        <button type="button" class="basic-button-base button-dark control-buttons" (click)="markCompaniesAsRead()" [attr.disabled]="isLoading.isBulkMarkingCompaniesAsRead ? 'disabled': null"><i class="fal fa-spinner fa-spin" *ngIf="isLoading.isBulkMarkingCompaniesAsRead"></i> Mark as Read</button>
                    </div>
                </div>

                <div class="row xsmall-margin-top" *ngIf="numberOfCompaniesMarkedAsRead != null">
                    <div class="col-md-12">
                        <span>{{ numberOfCompaniesMarkedAsRead }} organisations were marked as read.</span><br>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
