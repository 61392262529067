import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { publicMethods } from '../../globals';

@Component({
  selector: 'event-souce-type-rules',
  templateUrl: './event-souce-type-rules.component.html',
  styleUrls: ['./event-souce-type-rules.component.css'],
})
export class EventSourceTypeRulesComponent implements OnInit {
  Object = Object;

  @Input()
  ruleSet: Record<string, any>;
  @Input()
  eventSourceType: any;
  @Input()
  parameterSchema: any;

  newImportance = 3;
  newDescription = '';
  newParameters = {};

  created = false;

  opensearchEventSourceTypes = [
    'AccountsSearchEventSourceType',
    'HiringEventSourceType',
    'NewsSearchEventSourceType',
    'WebsiteSearchEventSourceType',
  ];

  @Output()
  newRule = new EventEmitter<object>();

  @Output()
  deleteRule = new EventEmitter<number>();

  constructor(
    private _snackBar: MatSnackBar,
    private pubMethod: publicMethods
  ) {}

  ngOnInit(): void {
    this.setDefaultNewParamsObject();
  }

  setDefaultNewParamsObject() {
    for (let paramName of Object.keys(this.parameterSchema.properties)) {
      if (this.parameterSchema.properties[paramName].type === 'string') {
        if (this.parameterSchema.properties[paramName].enum) {
          this.newParameters[paramName] =
            this.parameterSchema.properties[paramName].enum[0];
        } else {
          this.newParameters[paramName] = '';
        }
      }
      if (this.parameterSchema.properties[paramName].type === 'boolean') {
        this.newParameters[paramName] = false;
      }
    }
  }

  createNewRule() {
    const newRule = {
      description: this.newDescription,
      event_source_parameters: this.newParameters,
      event_source_type: this.eventSourceType,
      importance: Number(this.newImportance),
      send_email: false,
    };

    const isOpenSearch = this.opensearchEventSourceTypes.some(
      eventSourceType => eventSourceType === newRule.event_source_type
    );

    let isHiringSearchTermEmpty, isHiringJobTitleEmpty, isHiringRuleValid;

    if (newRule.event_source_type === 'HiringEventSourceType') {
      isHiringSearchTermEmpty = this.pubMethod.isEmptyObjectOrArray(
        this.newParameters['job_description_search_term']
      );
      isHiringJobTitleEmpty = this.pubMethod.isEmptyObjectOrArray(
        this.newParameters['job_title_contains']
      );

      /**
       * return TRUE
          When both fields have values OR
          When only one of the fields is empty.
  
          Return FALSE when both fields are empty.
       */
      isHiringRuleValid =
        (!isHiringJobTitleEmpty && !isHiringSearchTermEmpty) ||
        isHiringJobTitleEmpty !== isHiringSearchTermEmpty;
    }

    const isSearchTermValid =
      newRule.event_source_type === 'HiringEventSourceType'
        ? isHiringRuleValid
        : newRule.event_source_parameters['search_term']?.length > 0;

    const snackMessage =
      newRule.event_source_type === 'HiringEventSourceType'
        ? 'Either a search term or job title is required.'
        : 'Please enter a search term before creating a rule.';

    if (isOpenSearch && !isSearchTermValid) {
      this._snackBar.open(snackMessage, 'Dismiss', {
        duration: 2500,
      });
      return;
    }

    this.newRule.emit(newRule);
    this.setDefaultNewParamsObject();
    this.newImportance = 3;
    this.newDescription = '';
    this.created = true;
  }

  addSearchTerm(event: string, paramName: string): void {
    if (!event.length) return;
    this.newParameters[paramName] = event;
    this.created = false;
  }

  removeRule(id) {
    this.deleteRule.emit(id);
  }

  filterEventType(rules, eventSourceType) {
    if (!rules) {
      return;
    }

    const filteredRules = rules.filter(
      rule => rule.event_source_type === eventSourceType
    );
    return filteredRules;
  }
}
