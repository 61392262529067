
<div class="d-flex justify-content-center small-margin-top" *ngIf="isLoading.loadingTags">    
    <zint-loader></zint-loader>
</div>

<p *ngIf="!isLoading.loadingTags && (!inputParams.inputTags || inputParams.inputTags.length == 0)" class="small-margin-top text-center">You have not yet created any {{ inputParams.tagTypeString }} tags.</p>

<h5 *ngIf="inputParams.inputTags && inputParams.inputTags.length > 0" class="small-margin-top">Your {{ inputParams.tagTypeString | titlecase }} Tags</h5>

<p *ngIf="inputParams.inputTags && inputParams.inputTags.length > 0 && !hasUserTags">You have not created any tags yet</p>

<table *ngIf="inputParams.inputTags && inputParams.inputTags.length > 0 && hasUserTags" class="table table-striped table-bordered small-margin-top">
    <thead>
        <tr>
            <td>Tag name</td>
            <td *ngIf="isSuperUser">Tag Owner</td>
            <td>Delete tag</td>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let tag of inputParams.inputTags">
            <tr *ngIf="!tag.isOrgTag">
                <td>{{ tag.name }}</td>
                <td *ngIf="isSuperUser"> <span *ngIf="emailOnAccount != tag.owner" class="badge badge-danger" matTooltipPosition="above" matTooltip="Not owned by you. Delete with caution!"><i class="fal fa-exclamation-triangle"></i></span> {{ tag.owner }}</td>
                <td><button class="basic-button-base button-dark" data-toggle="modal" data-target="#tagModal" (click)="triggerDeleteTag(tag.id)"><i class="fal fa-trash"></i></button></td>
            </tr>
        </ng-container>
    </tbody>
</table>



<h5 *ngIf="inputParams.inputTags && inputParams.inputTags.length > 0" class="medium-margin-top">{{ inputParams.tagTypeString | titlecase }} Tags Shared Across your Organisation</h5>

<p *ngIf="inputParams.inputTags && inputParams.inputTags.length > 0 && !hasOrganisationTags">There are no shared tags accessible by your account.</p>

<table *ngIf="inputParams.inputTags && inputParams.inputTags.length > 0 && hasOrganisationTags" class="table table-striped table-bordered small-margin-top">
    <thead>
        <tr>
            <td>Tag name</td>
            <td *ngIf="isSuperUser">View Access</td>
            <td *ngIf="isSuperUser">Edit Access</td>
            <td *ngIf="isSuperUser">Delete tag</td>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let tag of inputParams.inputTags">
            <tr *ngIf="tag.isOrgTag">
                <td>{{ tag.name }}</td>
                <td *ngIf="isSuperUser">

                    <ng-container *ngIf="tag.view_teams">

                        <add-remove-team
                                 [inputParams]="{
                                    'confirmDeleteModalId': 'confirmDeleteViewableTeamOnTagModal',
                                    'objectAddingOrRemovingTeam': tag,
                                    'attributeNameForTeams': 'view_teams',
                                    'organisationTeams': inputParams.organisationTeams
                                }"
                                (ParentFunctionOnAddNewTeam)="addNewViewableTeamToTag($event[0], $event[1].id)"
                                (ParentFunctionOnClickToDeleteTeam)="viewableTeamToDeleteOnTag = $event[0]; viewableTeamTagToDeleteOn = $event[1];"
                        ></add-remove-team>

                        <add-remove-individual
                                 [inputParams]="{
                                    'confirmDeleteModalId': 'confirmDeleteViewableUserOnTagModal',
                                    'objectAddingOrRemovingIndividual': tag,
                                    'attributeNameForIndividuals': 'view_users',
                                    'organisationUsers': inputParams.organisationUsers
                                }"
                                (ParentFunctionOnAddNewIndividual)="addNewViewableUserToTag($event[0], $event[1].id)"
                                (ParentFunctionOnClickToDeleteIndividual)="tagViewableUserToDelete = $event[0]; viewableTagToDeleteOn = $event[1];"
                        ></add-remove-individual>

                    </ng-container>

                </td>


                <td *ngIf="isSuperUser">
                    <ng-container *ngIf="tag.edit_teams">


                        <add-remove-team
                                 [inputParams]="{
                                    'confirmDeleteModalId': 'confirmDeleteEditableTeamOnTagModal',
                                    'objectAddingOrRemovingTeam': tag,
                                    'attributeNameForTeams': 'edit_teams',
                                    'organisationTeams': inputParams.organisationTeams
                                }"
                                (ParentFunctionOnAddNewTeam)="addNewEditableTeamToTag($event[0], $event[1].id)"
                                (ParentFunctionOnClickToDeleteTeam)="editableTeamToDeleteOnTag = $event[0]; editableTeamTagToDeleteOn = $event[1];"
                        ></add-remove-team>

                        <add-remove-individual
                                 [inputParams]="{
                                    'confirmDeleteModalId': 'confirmDeleteEditableUserOnTagModal',
                                    'objectAddingOrRemovingIndividual': tag,
                                    'attributeNameForIndividuals': 'edit_users',
                                    'organisationUsers': inputParams.organisationUsers
                                }"
                                (ParentFunctionOnAddNewIndividual)="addNewEditableUserToTag($event[0], $event[1].id)"
                                (ParentFunctionOnClickToDeleteIndividual)="tagEditableUserToDelete = $event[0]; editableTagToDeleteOn = $event[1];"
                        ></add-remove-individual>

                    </ng-container>

                </td>
                <td *ngIf="isSuperUser">
                    <ng-container *ngIf="tag.edit_teams && tag.view_teams">
                        <button class="basic-button-base button-dark" data-toggle="modal" data-target="#tagModal" (click)="triggerDeleteTag(tag.id)"><i class="fal fa-trash"></i></button>
                    </ng-container>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

















<div class="modal fade" id="confirmDeleteViewableUserOnTagModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteViewableUserOnTagModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="tagViewableUserToDelete && viewableTagToDeleteOn">
                <p>This will remove editing and viewing access for {{ tagViewableUserToDelete.email }} on the tag {{ viewableTagToDeleteOn.name }}.</p>
                <button class="basic-button-base button-dark" (click)="deleteViewingAccessForUserOnTag()">Confirm</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="confirmDeleteEditableUserOnTagModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteEditableUserOnTagModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="tagEditableUserToDelete && editableTagToDeleteOn">
                <p>This will remove editing access for {{ tagEditableUserToDelete.email }} on the tag {{ editableTagToDeleteOn.name }}.</p>
                <p>Viewing access will remain in place for this user and must be deleted separately</p>
                <button class="basic-button-base button-dark" (click)="deleteEditingAccessForUserOnTag()">Confirm</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="confirmDeleteViewableTeamOnTagModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteViewableTeamOnTagModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="viewableTeamToDeleteOnTag && viewableTeamTagToDeleteOn">
                <p>This will remove view access for the team {{ viewableTeamToDeleteOnTag.name }} on the tag {{ viewableTeamTagToDeleteOn.name }}.</p>
                <button class="basic-button-base button-dark" (click)="deleteViewingAccessForTeamOnTag()">Confirm</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="confirmDeleteEditableTeamOnTagModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmDeleteEditableTeamOnTagModalLabel">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="editableTeamToDeleteOnTag && editableTeamTagToDeleteOn">
                <p>This will remove edit access for the team {{ editableTeamToDeleteOnTag.name }} on the tag {{ editableTeamTagToDeleteOn.name }}.</p>
                <button class="basic-button-base button-dark" (click)="deleteEditingAccessForTeamOnTag()">Confirm</button>
            </div>
        </div>
    </div>
</div>
