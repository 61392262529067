<div class="container">
    <div class="row">
        <div class="col-md-12">

            <div class="d-flex justify-content-center small-margin-top" *ngIf="isLoading.loadingListTerritories">
                <zint-loader></zint-loader>
            </div>

            <div id="saved-searches-tab" *ngIf="!isLoading.loadingListTerritories" >

                <h2 class="small-margin-top">Territories:</h2>

                <h5 class="medium-margin-top" *ngIf="territories">Organisation Territories:</h5>
                <p class="small-margin-top" *ngIf="!territories || territories.length == 0">There are no territories associated with your account.</p>
                <p class="small-margin-top">Note that if you enforce a territory, every user that this territory is shared with will only be able to search within that territory. This may significantly reduce search results for these users. If a user is affected by more than one enforced territory, only the first enforced territory will be imposed on their searches and others will be ignored.</p>

                <table *ngIf="territories && territories.length > 0" class="table table-striped table-bordered small-margin-top" style="table-layout:fixed;">
                    <thead>
                        <tr>
                            <td>Territory Name</td>
                            <td style="width: 40%">Parameters</td>
                            <td>Users Shared With</td>
                            <td>Teams Shared With</td>
                            <td>Enforced?</td>
                            <td>Delete</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let territory of territories">
                            <td>{{ territory.name }}</td>
                            <td class="overflow-scroll"> <search-parameters [inputParams]="{'searchTags': territory.searchParams, 'enableClearing': false}"></search-parameters> </td>
                            <!-- Add users to territory -->
                            <td>
                                <table class="table table-sm table-within-table sub-table" [ngClass]="{'zero-margin-bottom': !territory.users_viewable_by || territory.users_viewable_by.length == 0}">
                                    <tr *ngFor="let user of territory.users_viewable_by">
                                        <td>{{ user.email }}</td>
                                        <td>
                                            <button class="basic-button-base button-dark" style="font-size: 10px" (click)="removeUserFromTerritory(user.userId, territory.id)"><i class="fal fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </table>
                                <select class="custom-select" (change)="addUserToTerritory($event.target.value, territory.id)" *ngIf="territory.isAddingUser">
                                    <ng-container>
                                        <option selected></option>
                                        <ng-container *ngFor="let organisationUser of organisationUsersAndTeams.organisation_users;">
                                            <option [attr.value]="organisationUser.userId" *ngIf="organisationUser | filterLoopPipe:territory.users_viewable_by:'userId'">{{organisationUser.email}}</option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                                <button *ngIf="!territory.isAddingUser && organisationUsersAndTeams.organisation_users && territory.users_viewable_by && organisationUsersAndTeams.organisation_users.length != territory.users_viewable_by.length" class="btn-sm basic-button-base button-dark full-width" (click)="territory.isAddingUser = true">
                                    <i class="fal fa-plus"></i> Add User
                                </button>
                            </td>
                            <!-- Add teams to territory -->
                            <td>
                                <table class="table table-sm table-within-table sub-table" [ngClass]="{'zero-margin-bottom': !territory.organisations_viewable_by || territory.organisations_viewable_by.length == 0}">
                                    <tr *ngFor="let team of territory.organisations_viewable_by">
                                        <td>{{ team.name }}</td>
                                        <td>
                                            <button class="basic-button-base button-dark" style="font-size: 10px" (click)="removeTeamFromTerritory(team.id, territory.id)"><i class="fal fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </table>
                                <select class="custom-select" (change)="addTeamToTerritory($event.target.value, territory.id)" *ngIf="territory.isAddingTeam">
                                    <ng-container>
                                        <option selected></option>
                                        <ng-container *ngFor="let team of organisationUsersAndTeams.organisation_teams;">
                                            <option [attr.value]="team.id" *ngIf="team | filterLoopPipe:territory.organisations_viewable_by:'id'">{{team.name}}</option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                                <button *ngIf="!territory.isAddingTeam && territory.organisations_viewable_by && territory.organisations_viewable_by.length != organisationUsersAndTeams.organisation_teams.length" class="btn-sm basic-button-base button-dark full-width" (click)="territory.isAddingTeam = true">
                                    <i class="fal fa-plus"></i> Add Team
                                </button>
                            </td>
                            <td>
                            <input type="checkbox"
                               [attr.disabled]="isLoading.loadingTogglingTerritoryEnforcement ? 'disabled': null"
                               [(ngModel)]="territory.is_enforced"
                               (ngModelChange)="updateTerritoryEnforcement(territory)"/>
                            </td>
                            <td><button class="basic-button-base button-dark" (click)="deleteTerritory(territory.id)"><i class="fal fa-trash"></i></button></td>
                        </tr>
                    </tbody>
                </table>

            </div>


        </div>
    </div>
</div>
