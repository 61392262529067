<div class="container">
    <div class="row small-margin-top">

        <div class="col-md-12">
            <h4>Company Matcher</h4>
        </div>

        <div class="col-md-12 small-margin-bottom small-margin-top alert alert-danger"
             role="alert" style="max-height: 250px; overflow-y: scroll"
             *ngIf="failedRows && failedRows.length > 0 && status === 'completed_matching'">
            <div *ngFor="let failedRow of failedRows">
                <p><i class="fal fa-exclamation-triangle"></i> {{failedRow}}</p>
            </div>
        </div>


        <ng-container *ngIf="status === 'defining_settings'">

            <ng-container *ngIf="stepNumber == 0">
                <div class="col-md-12 medium-margin-top">
                    <p>Upload a file of your own data to update custom columns in Zint.</p>
                    <p>For example, you may want to update data in Zint on competitors, existing customers, or prospects already in your pipeline.</p>
                    <hr>
                    <p>The uploaded file should be in CSV format with the below column headers. It must include a row of headers.</p>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-bordered" style="width: auto">
                                <tr>
                                    <td>'Company Number'</td>
                                    <td>The 8 digit Companies House registration number of the organisation</td>
                                </tr>
                                <tr>
                                    <td>'Company Name'</td>
                                    <td>Name of the legal entity</td>
                                </tr>
                                <tr>
                                    <td>'Address'</td>
                                    <td>The registered address of the legal entity, including postcode</td>
                                </tr>
                                <tr>
                                    <td>'Website'</td>
                                    <td>The URL or domain of the organisation</td>
                                </tr>
                                <tr>
                                    <td>'Phone Number'</td>
                                    <td>The main contact telephone number of the organisation</td>
                                </tr>
                                <tr>
                                    <td>Additional Columns</td>
                                    <td>Additional data points that you will upload to Zint</td>
                                </tr>
                            </table>
                            <p><i class="fal fa-exclamation-triangle"></i> Additional columns must be included that can be mapped to custom attributes in your Zint account.</p>
                            <button type="button" style="margin-bottom: 20px !important;" class="mt-auto basic-button-base button-dark control-buttons xsmall-margin-bottom" (click)="downloadSampleCSVFile()"><i class="fal fa-spinner fa-spin" *ngIf="isLoading.loadingDownloadUrl"></i> <i class="fal fa-download" *ngIf="!isLoading.loadingDownloadUrl"></i> Download a Sample File</button>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-md-12 medium-margin-top">
                    <p>Our matching algorithm will automatically analyse the data you submit and return the closest matching organisations.</p>
                    <file-upload [properties]="{endpoint: '/company_matcher_file_upload/', responseProcessor: fileUploadComplete, allowedFileExtensions: ['csv'], allowMultipleFiles: false, componentInstance: self}"></file-upload>
                    <br>
                    <br>
                </div>
            </ng-container>


            <div class="col-md-12 small-margin-top" *ngIf="stepNumber == 1">
                <div *ngIf="additionalHeaders && additionalHeaders.length > 0">
                    <div *ngIf="additionalHeaders | mapToIterable">
                        <p>Please map the following columns identified in your CSV file to your custom attributes in Zint:</p>
                        <table class="table table-striped">
                            <thead>
                                <th>Inputted Header Name</th>
                                <th>Column Number</th>
                                <th>Map to</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let header of additionalHeaders">
                                    <td>
                                        {{ header.headerName }}
                                    </td>
                                    <td>
                                        {{ header.columnNumber + 1 }}
                                        {{ header.can_view }}
                                        {{ header.can_edit }}
                                    </td>
                                    <td>
                                        <select [(ngModel)]="header['selectedCustomColumn']" class="custom-select" (change)="updateAllocatedCustomColumnIds()">
                                            <option value="ignore_column" selected="selected">Ignore this column</option>
                                            <ng-container *ngFor="let customColumn of customColumns">
                                                <option *ngIf="customColumn.can_edit && (allocatedCustomColumnIds.indexOf(customColumn.id) < 0 || (header.selectedCustomColumn && header.selectedCustomColumn.id == customColumn.id))" [ngValue]="customColumn">
                                                    {{ customColumn.name }}
                                                </option>
                                            </ng-container>
                                        </select>
                                        <span *ngIf="header.selectedCustomColumn && header.selectedCustomColumn.column_type == 'Date'"><i class="fal fa-exclamation-circle"></i> Ensure dates are uploaded in the format yyyy-mm-dd</span>
                                        <span *ngIf="header.selectedCustomColumn && header.selectedCustomColumn.options && header.selectedCustomColumn.options.length > 0"><br *ngIf="header.selectedCustomColumn.column_type == 'Date'"><i class="fal fa-exclamation-circle"></i> Ensure submitted values are an exact match to one of:
                                            <ng-container *ngFor="let option of header.selectedCustomColumn.options">{{ option.value }}, </ng-container>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <label>
                            <input type="checkbox"
                               name="shouldOverwrite"
                               [(ngModel)]="shouldOverwrite"/>
                            Overwrite Existing Entries?
                        </label>

                    </div>
                </div>
                <button type="button" class="basic-button-base button-dark float-right" (click)="submitMatcherMappings()">Continue</button>
            </div>

            <div class="col-md-12 small-margin-top" *ngIf="stepNumber == 2">
                <p>Your CSV file will be added to a queue and we will process all {{totalNumberOfRows}} rows.</p>
                <p>Existing custom column entries will <ng-container *ngIf="!shouldOverwrite">not</ng-container> be overwritten.</p>
                <button type="button" class="basic-button-base button-dark float-right" (click)="startMatchingProcess()" style="margin-left: 10px;"><i class="fa fa-play"></i> Start Matching Process</button>
                <button class="basic-button-base button-dark float-right" (click)="cancelUserMatchingProcess()"><i class="fal fa-times-octagon"></i> Cancel Process</button>
            </div>


        </ng-container>


        <div class="col-md-12 small-margin-top" *ngIf="status === 'matching'">
            <p *ngIf="this.rowsProcessed === null">Your file is in a queue and will be processed shortly.</p>

            <ng-container *ngIf="this.rowsProcessed !== null">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuenow]="(this.rowsProcessed / this.totalNumberOfRows)*100" aria-valuemin="0" aria-valuemax="100" [style.width]="(this.rowsProcessed / this.totalNumberOfRows)*100 + '%'" style="background-color: #000A36"></div>
                </div>
                <p>Your uploaded file is being analysed and is {{ (this.rowsProcessed / this.totalNumberOfRows)*100  | number:'1.1-1' }}% complete.</p>
            </ng-container>

            <p>You can safely navigate away from this page and will be emailed when the process is complete.</p>
            <button class="basic-button-base button-dark float-right" (click)="cancelUserMatchingProcess()" style="margin-left: 10px;"><i class="fal fa-times-octagon"></i> Cancel Process</button>
        </div>

    </div>
</div>


<div class="container-fluid">
    <company-matcher
            [inputParams]="{
                searchMatcherDetailsEndpoint: '/search_regular_matcher_details/',
                downloadMatcherDetailsForPageEndpoint: '/download_regular_matcher_records_for_page/',
                downloadMatcherDetailsForFilterEndpoint: '/download_regular_matcher_records_with_filter/'
            }"
            (ParentCancelMatchingProcess)="cancelUserMatchingProcess()"
            (ParentFunctionForAll)="createMatcherRecordsForAll($event)"
            (ParentFunctionForSelectedOnPage)="createMatcherRecordsForSelectedOnPage($event)"
            *ngIf="status === 'completed_matching'"
            #childCompanyMatcher></company-matcher>
</div>

