<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="justify-content-center" *ngIf="!errorMessage">
                <p>Loading the Knowledge Base</p>
            </div>
            <div class="justify-content-center" *ngIf="errorMessage">
                <p>{{errorMessage}}</p>
            </div>
            <div class="justify-content-center" *ngIf="showSpinner">
                <i class="fal fa-spin fa-spinner" style="font-size: 25px"></i>
            </div>
        </div>
    </div>
</div>
