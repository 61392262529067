import { Component, OnInit, Input } from '@angular/core';
import { FileUploadService } from '../../services/fileupload.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  fileNames = [];

  constructor(
    private fileUploadService: FileUploadService,
    private messageService: MessageService
  ) {}

  @Input() properties: any = {
    endpoint: '',
    responseProcessor: function () {},
    allowedFileExtensions: [],
    allowMultipleFiles: false,
    componentInstance: null,
  };

  isLoading = false;

  ngOnInit() {}

  newFileDetected(event): void {
    this.isLoading = true;
    var files = event.srcElement.files;
    if (!this.properties.allowMultipleFiles) {
      files = [files[0]];
    }
    this.fileNames = [];
    for (var i = 0; i < files.length; i++) {
      let _file = files[i];
      let fileSizeInMb = _file.size / 1000000;
      if (fileSizeInMb > 15) {
        // upload limit is 15mb in nginx config
        this.messageService.show(
          'File size must not exceed 15MB. Please reload and try again.'
        );
        this.messageService.hide(6000);
        this.isLoading = false;
        return;
      }
      var fileNameWithExtension = _file.name;
      var extension = fileNameWithExtension.split('.').pop().toLowerCase();
      var fileName = fileNameWithExtension.substr(
        0,
        fileNameWithExtension.lastIndexOf('.')
      );
      this.fileNames.push(fileName);
      if (this.properties.allowedFileExtensions.indexOf(extension) < 0) {
        this.messageService.show('This is not a valid file type');
        this.isLoading = false;
        return;
      }
    }

    var self = this;
    this.fileUploadService
      .uploadFile(files, {}, this.properties.endpoint)
      .subscribe(response =>
        (function () {
          self.isLoading = false;
          self.properties.responseProcessor(
            response,
            self.properties.componentInstance
          );
        })()
      );
  }

  triggerFileSelector(event): void {
    var fileSelectorButton = event.srcElement.nextSibling;
    fileSelectorButton.click();
  }
}
