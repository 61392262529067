import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { CookieService } from 'ngx-cookie';
import { ZendeskSsoService } from '../../services/zendesk-sso.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'zendesk-auth',
  templateUrl: './zendesk-auth.component.html',
  styleUrls: ['./zendesk-auth.component.css'],
})
export class ZendeskAuthComponent implements OnInit {
  errorMessage = '';
  showSpinner = true;

  constructor(
    private auth: AuthenticationService,
    private cookieservice: CookieService,
    private zendeskSSOService: ZendeskSsoService,
    private route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.authoriseKnowledgeBase();
  }

  authoriseKnowledgeBase(): void {
    var currentCsrf = this.cookieservice.get('csrftoken');
    if (!currentCsrf) {
      this.auth.getCSRFFromServer().subscribe(() => this.reauthorise());
    } else {
      this.auth
        .isUserAuthenticated()
        .subscribe(data => this.processResponse(data));
    }
  }

  reauthorise(): void {
    this.auth
      .isUserAuthenticated()
      .subscribe(data => this.processResponse(data));
  }

  processResponse(response): void {
    this.zendeskSSOService
      .getKnowledgebaseRedirectURL(this.route.queryParams['_value'])
      .subscribe(data => this.redirectToKnowledgeBase(data));
  }

  redirectToKnowledgeBase(data): void {
    if (data.url) {
      window.location.replace(data.url);
    } else {
      if (data.reason === 'no_access') {
        this.showSpinner = false;
        this.errorMessage =
          'You do not have access to the knowledge base. Please contact us to authorise your access.';
      } else if (data.reason === 'not_logged_in') {
        this.errorMessage = 'Please login first. Redirecting you now...';
        this.showSpinner = true;
        setTimeout(function () {
          window.location.replace('/login?redirectUrl=' + data.redirection_url);
        }, 1500);
      } else {
        this.showSpinner = false;
        this.errorMessage =
          'An unknown error occurred accessing the knowledge base.';
      }
    }
  }
}
