<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
  <div class="col-md-8">
    <h5>
      <ng-container>
        <i class="fa fa-users"></i>
      </ng-container>
    Related Officer {{event_metadata.change_type}} - {{event_metadata.name}}    
  </h5>
    <hr>
  <div>
     <p>The officer has been {{event_metadata.change_type}} as <strong>{{event_metadata.occupation}}</strong> at <strong>{{event_metadata.company_name}}</strong>.
      </p>
    <div *ngFor="let notification of notifications">
      <ng-container *ngIf="notification.event_metadata.occupation_contains; else elseBlock">   
        <div notification-search-term        
             [notification]="notification"
             [searchTerm]="notification.event_metadata.occupation_contains"           
             [searchTermString]="' matches occupation ' + event_metadata.occupation + '.'"
        ></div>
      </ng-container>

      <ng-template #elseBlock>
        <div notification-search-term
               [notification]="notification"          
               [searchTermString]="'Officer ' + event_metadata.change_type + '.'"
          ></div>
      </ng-template>
      </div>
      
  </div>
  <div class="row small-margin-top">
    <div class="col-md-12">
        <a href="/profile/{{companyNumber}}" target="_blank">
            <span class="badge badge-link">
                <i class="fal fa-external-link"></i> 
                View company page
            </span>
        </a>
    </div>
</div>
  </div>
  <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bGVhZGVyc2hpcCUyMHRlYW18ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=1169&q=80'"
        [htmlBelowImage]="htmlBelowImage"
        
    ></div>
</div>