import { Injectable } from '@angular/core';

@Injectable()
export class MessageService {
  message: string = '';
  infoMessage: string = '';

  show(message: string) {
    this.message = message;
  }

  showAndHideAfterDelay(message: string, delay: number) {
    this.message = message;

    this.hide(delay);
  }

  hide(optionalDelay?: number) {
    setTimeout(() => {
      this.message = '';
    }, optionalDelay);
  }

  showInfo(message: string) {
    this.infoMessage = message;
  }

  hideInfo() {
    this.infoMessage = '';
  }
}
