import { Component, OnInit } from '@angular/core';
import { SalesforceConfigService } from '../../../services/salesforce-config.service';
declare var $: any;

@Component({
  selector: 'export-config',
  templateUrl: './export-config.component.html',
  styleUrls: ['./export-config.component.css'],
})
export class ExportConfigComponent implements OnInit {
  zintFields = [];
  customColumns = [];
  propensityModels = [];
  salesforceObjects = [];

  selectedSalesforceObjectId: any;
  selectedSalesforceObject: any;
  selectedZintAttributeMappings: any;
  selectedCustomColumnMappings: any;
  selectedStringConstantMappings: any;
  selectedPropensityModelComponentsMappings: any;

  showNewZintAttributeExportMapping = false;
  zintAttributeSelected: any;
  customColumnIdSelected: any;
  stringConstantSelected: any;
  propensityModelIdSelected: any;
  salesforceFieldIdSelected: any;

  showNewCustomColumnExportMapping = false;
  showNewStringConstantExportMapping = false;
  showNewPropensityModelComponentsExportMapping = false;

  constructor(private salesforceConfigService: SalesforceConfigService) {}

  isLoading = this.salesforceConfigService.isLoading;

  ngOnInit(): void {
    this.salesforceConfigService
      .getSalesforceExportConfigMappingOptions()
      .subscribe(data => this.updateExportMappingOptions(data));

    this.salesforceConfigService
      .updateCurrentSalesforceExportConfig()
      .subscribe(data => this.updateCurrentSettings(data));
  }

  updateExportMappingOptions(data): void {
    this.zintFields = data.zint_fields;
    this.customColumns = data.custom_columns;
    this.salesforceObjects = data.salesforce_objects;
    this.propensityModels = data.propensity_models;
  }

  updateCurrentSettings(data): void {
    if (data.export_config) {
      this.selectedSalesforceObject = data.export_config.salesforce_object;
      this.selectedSalesforceObjectId = data.export_config.salesforce_object.id;
      this.selectedZintAttributeMappings =
        data.export_config.zint_attribute_mappings;
      this.selectedCustomColumnMappings =
        data.export_config.custom_column_mappings;
      this.selectedStringConstantMappings =
        data.export_config.string_constant_mappings;
      this.selectedPropensityModelComponentsMappings =
        data.export_config.propensity_model_components_mappings;
    }
    this.showNewZintAttributeExportMapping = false;
    this.showNewCustomColumnExportMapping = false;
    this.showNewStringConstantExportMapping = false;
  }

  chooseSalesforceObject(): void {
    this.salesforceConfigService
      .chooseSalesforceObject(this.selectedSalesforceObjectId)
      .subscribe(data => this.chooseSalesforceObjectSuccess(data));
  }

  chooseSalesforceObjectSuccess(data): void {
    if (data.salesforce_object) {
      this.selectedSalesforceObject = data.salesforce_object;
      $('#overwriteSalesforceObject').modal('hide');
    }
  }

  addNewZintSalesforceMapping(): void {
    this.salesforceConfigService
      .addNewZintSalesforceMapping(
        this.zintAttributeSelected,
        this.salesforceFieldIdSelected
      )
      .subscribe(data => this.updateCurrentSettings(data));
  }

  addNewCustomColumnSalesforceMapping(): void {
    this.salesforceConfigService
      .addNewCustomColumnSalesforceMapping(
        this.customColumnIdSelected,
        this.salesforceFieldIdSelected
      )
      .subscribe(data => this.updateCurrentSettings(data));
  }

  addNewStringConstantSalesforceMapping(): void {
    this.salesforceConfigService
      .addNewStringConstantSalesforceMapping(
        this.stringConstantSelected,
        this.salesforceFieldIdSelected
      )
      .subscribe(data => this.updateCurrentSettings(data));
  }

  addNewPropensityModelComponentsSalesforceMapping(): void {
    this.salesforceConfigService
      .addNewPropensityModelComponentsSalesforceMapping(
        this.propensityModelIdSelected,
        this.salesforceFieldIdSelected
      )
      .subscribe(data => this.updateCurrentSettings(data));
  }

  deleteAttributeExportMapping(id): void {
    this.salesforceConfigService
      .deleteAttributeExportMapping(id)
      .subscribe(data => this.updateCurrentSettings(data));
  }

  updateAttributeExportMappingOverwriteStatus(id, overwrite): void {
    this.salesforceConfigService
      .updateAttributeExportMappingOverwriteStatus(id, overwrite)
      .subscribe(data => this.updateCurrentSettings(data));
  }
}
