import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'columnNameToIcon' })
export class ColumnNameToIconPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(inputString: string) {
    const starIconWithTooltip =
      '<i title="Propensity Model" class="far fa-sm fa-star"></i>';
    return this.sanitizer.bypassSecurityTrustHtml(
      inputString.replace('Propensity Model', starIconWithTooltip)
    );
  }
}
