import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'officer',
  templateUrl: './officer.component.html',
  styleUrls: ['./officer.component.css'],
})
export class OfficerComponent implements OnInit {
  @Input()
  notifications: any;

  @Input()
  markedAsRead: boolean = false;

  constructor() {}

  event_metadata: any;
  companyNumber: any;
  mainHtml: any;

  ngOnInit(): void {
    this.event_metadata = this.notifications[0].event_metadata;
    this.companyNumber = this.notifications[0].company_number;
    const addOrRemoveIcon =
      this.event_metadata.change_type === 'appointed'
        ? '<i class="fal fa-user-plus"></i>'
        : '<i class="fal fa-user-minus"></i>';
    this.mainHtml =
      `<h3>` +
      addOrRemoveIcon +
      ` Officer ${this.event_metadata.change_type} - ${this.event_metadata.name}
    </h3>`;
    if (this.event_metadata.occupation !== undefined) {
      this.mainHtml =
        this.mainHtml +
        `<span>${this.event_metadata.occupation} matches your search term "${this.event_metadata.occupation_contains}"</span>`;
    }
  }
}
