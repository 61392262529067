<div *ngIf="isLoading.loadingData" class="d-flex justify-content-center">
    <zint-loader></zint-loader>
</div>

<div class="container-fluid" [ngStyle]="{'opacity': (isLoading.loadingData) ? 0.1 : 1.0, 'pointer-events': (isLoading.loadingData) ? 'none' : 'inherit'}">

    <div class="row small-margin-top text-center">
        <div class="col-md-4 offset-4">
            <zint-ngx-slider #matchLevelSliderElement                
                postfix="%"
                [min]="0"
                [max]="100"
                (sliderChange)="updateMatchLevel($event)"
            ></zint-ngx-slider>
            <br>
            <ng-container *ngIf="total">
                <span>Found {{numberOfResults}} records from a total of {{total}}.</span>
                <button class="btn-sm basic-button-base button-dark xsmall-margin-top" style="margin-left: 10px" (click)="pageNumber = 1; searchMatcherResults(false)">Update Results</button>
            </ng-container>
        </div>
        <div class="col-md-4">
            <button class="btn-sm basic-button-base button-dark xsmall-margin-top float-right" (click)="ChildCancelMatchingProcess()">End Matching Process</button>
        </div>
    </div>

    <div class="row small-margin-top">
        <div class="col-md-12">
            <hr>
        </div>
         <div class="col-md-4 text-left">
             Create Records:<br>
             <div class="btn-group" role="group">
                <button class="btn-sm basic-button-base button-dark" (click)="ChildFunctionForSelectedOnPage()">For Selected On Page</button>
                <button class="btn-sm basic-button-base button-dark" (click)="ChildFunctionForAll()">For Top Result For All {{numberOfResults}}</button>
             </div>
         </div>
        <div class="col-md-4 text-center">
            Download Records:<br>
             <div class="btn-group" role="group">
                <button class="btn-sm basic-button-base button-dark" (click)="downloadPageOfResults()">For This Page</button>
                <button class="btn-sm basic-button-base button-dark" (click)="downloadAllResults()">For All {{numberOfResults}}</button>
            </div>
         </div>
        <div class="col-md-4 text-right">
            Results per page:
            <select class="custom-select" [(ngModel)]="resultsPerPage" style="width: 75px" (change)="pageNumber = 1; searchMatcherResults(false)">
                <option value="1">1</option>
                <option value="50">50</option>
                <option value="100" selected>100</option>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2500">2500</option>
            </select>
            <br>
            <span class="xsmall-margin-top">
                Page <input type="number" class="form-control d-inline xsmall-margin-top" min="1" max="{{numberOfPages}}" [(ngModel)]="pageNumber" (keyup.enter)="searchMatcherResults(false)" style="width: 75px">
                of {{numberOfPages}}
            </span>
            <br>
            <div class="btn-group xsmall-margin-top" role="group">
                <button class="btn-sm basic-button-base button-dark" [attr.disabled]="pageNumber == 1 ? 'disabled': null" (click)="pageNumber = pageNumber - 1; searchMatcherResults(false)">< Previous</button>
                <button class="btn-sm basic-button-base button-dark" [attr.disabled]="pageNumber == numberOfPages ? 'disabled': null" (click)="pageNumber = pageNumber + 1; searchMatcherResults(false)">Next ></button>
            </div>
        </div>
    </div>

    <div class="row small-margin-top">
        <div class="col-xs-6" style="max-width: 40%;  width: 100%; overflow-x: scroll">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <td *ngFor="let columnHeader of columnHeaders">
                            <div class="scrollable-table-div">
                                {{columnHeader.CSVHeader}}
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of rowsOfMatchedData" style="max-height: 10px;">
                        <td *ngFor="let cell of row.row_contents" style="vertical-align: middle">
                            <div class="scrollable-table-div">{{cell}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-xs-6" style="max-width: 60%; width: 100%">
            <table class="table table-striped table-bordered" style="border-left-width: 5px;" *ngIf="rowsOfMatchedData">
                <thead>
                    <tr>
                        <td colspan="1000%" class="text-center align-middle">
                            <div class="scrollable-table-div">
                                <span (click)="selectAllFirstMatches()" style="text-decoration: underline; cursor: pointer; font-size: 8px; float: left; margin-top: 7px;">Select Top Results</span>
                                <span>Possible Matching Companies</span>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of rowsOfMatchedData">
                        <ng-container *ngIf="!row.possible_matches || row.possible_matches.length == 0">
                            <td colspan="100%"><div class="scrollable-table-div"></div></td>
                        </ng-container>
                        <ng-container *ngIf="row.possible_matches">
                            <td *ngFor="let possibleMatch of row.possible_matches">
                                <div (click)="updateRadio(possibleMatch, row.possible_matches)" class="cursor-pointer scrollable-table-div">
                                    <input type="radio"
                                           name="possibleMatch__{{possibleMatch.id}}"
                                           id="possibleMatch__{{possibleMatch.id}}"
                                           [value]="possibleMatch.id"
                                           [(ngModel)]="possibleMatch.selected"
                                    >
                                    <span> &nbsp;
                                        <a
                                            href="/profile/{{ possibleMatch.number }}"
                                            target="_blank" class="basic-button-base button-dark"
                                            style="font-size: 9px; padding-left: 3px; padding-top: 1px; padding-bottom: 0px; padding-right: 2px;"
                                            onclick="var event = arguments[0]; event.stopPropagation();"
                                        >
                                            <i class="fal fa-external-link"></i>
                                        </a> &nbsp;{{ possibleMatch.name}}
                                    </span>
                                    <span class="badge" *ngIf="possibleMatch.probability">{{ possibleMatch.probability }}% </span>
                                </div>
                            </td>
                        </ng-container>

                        <td colspan="100%" style="text-align: center; vertical-align: middle;">
                            <button class="btn-sm basic-button-base button-dark" (click)="rowIdShowingSearch=row.row_id; companyNameSearch = ''; shortlistedCompaniesByName=[];" *ngIf="rowIdShowingSearch != row.row_id">
                                <i class="fal fa-plus-circle"></i>
                            </button>
                            <div class="nav-item dropdown" *ngIf="rowIdShowingSearch == row.row_id">
                                <input type="text"
                                       class="form-control"
                                       [(ngModel)]="companyNameSearch"
                                       (ngModelChange)="matcherSearchForCompanyByName()"
                                       id="navbarDropdownMenuLink__{{row.row_id}}"
                                       data-toggle="dropdown"
                                       placeholder=""
                                >
                                <div class="dropdown-menu" style="right: 0; left: unset">
                                    <ng-container *ngFor="let shortlistedCompany of shortlistedCompaniesByName">
                                        <table style="width: 100%; cursor: pointer" (click)="addCompanyToRow(row, shortlistedCompany)">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {{ shortlistedCompany.company_name }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ng-container>
                                </div>
                            </div>
                        </td>

                        <td colspan="100%" class="text-right align-middle">
                            <button class="btn-sm basic-button-base button-dark" (click)="unselectPossibleMatches(row.possible_matches)">
                                <i class="fal fa-eraser"></i>
                            </button>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</div>
